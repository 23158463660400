import React, { ReactElement } from 'react';

import { Flex, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import {
   SettingsItem,
   SettingsItemArrow,
   SettingsItemContent,
   SettingsItemIcon,
   SettingsItemTitle,
} from 'src/features/settings/shared/SharedComponents';
import { ActiveItemEnum } from './Insights';

import { ChevronRightElement, UserEditIcon } from 'src/assets/images';
import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';
import { RoutesEnum } from 'src/routes/routes';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/rootReducer';
import { useHistory } from 'react-router-dom';

interface IProps {
   setActiveItem: (item: ActiveItemEnum) => void;
   isItemActive: (itemToCheck: ActiveItemEnum) => boolean;
}

const InsightsRoot = ({ setActiveItem, isItemActive }: IProps): ReactElement => {
   const spaceId = useSelector((state: RootState) => state.systemReducer).spaceId;
   const history = useHistory();
   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         width={'100%'}
         height={'100%'}
         position={'relative'}
      >
         <StyledText
            width={'100%'}
            text={translate('spaceManagement.insights.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 32px 0'}
         />

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
            borderRight={`1px solid ${theme.colors.lightGray}`}
         >
            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.SPACE_OVERVIEW) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.SPACE_OVERVIEW);
                  history.push({
                     pathname: RoutesEnum.SPACE_MANAGEMENT_SPACE_OVERVIEW,
                     state: {
                        spaceId: spaceId,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.insights.spaceOverview.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.insights.spaceOverview.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.CHECKINS_INFO) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.CHECKINS_INFO);
                  history.push({
                     pathname: RoutesEnum.SPACE_MANAGEMENT_CHECKINS,
                     state: {
                        spaceId: spaceId,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.insights.checkinsInfo.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.insights.checkinsInfo.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.EARNINGS_INFO) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.EARNINGS_INFO);
                  history.push({
                     pathname: RoutesEnum.SPACE_MANAGEMENT_EARNINGS,
                     state: {
                        spaceId: spaceId,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.insights.earningsInfo.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.insights.earningsInfo.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
         </Flex>
      </Flex>
   );
};

export default InsightsRoot;
