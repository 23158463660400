import React, { ReactElement, useState, useEffect } from 'react';
import styled from 'styled-components';

import { StyledText, Flex, Button, Spinner } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { InlineElementsWrapper } from 'src/components/SharedComponents';

import CheckoutModal from '../modals/checkout/CheckoutModal';
import StartCoworkingModal from '../modals/start_coworking/StartCoworkingModal';

import ApiRequests from 'src/features/settings/data/ApiRequests';
import { IPlan } from 'src/features/settings/interfaces';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { config } from 'src/app/config';
import theme from 'src/styles/theme';
import { getFirebase } from 'react-redux-firebase';
import { PurpleArrowLeft } from 'src/assets/images';
import HeaderWrapper from 'src/components/HeaderWrapper';

export const PlanCard = styled.div`
   width: 48%;
   margin-bottom: 16px;
   margin-left: 5px;
   height: auto;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   background-color: ${theme.colors.white};
   padding: 24px 16px;
   cursor: pointer;
   border-radius: 15px;
   border: 1px solid ${theme.colors.gray};

   div {
      cursor: pointer;
   }

   &.selected {
      background-color: ${theme.colors.mainPurple};

      div {
         color: ${theme.colors.white} !important;
         background-color: ${theme.colors.mainPurple};
      }
   }
`;

export interface IDiscountData {
   percent_off: string;
   duration_in_months: number;
}

const PurchasePlan = (): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [customerData, setCustomerData] = useState<any>(null);
   const [customerLoading, setCustomerLoading] = useState(false);

   const [loading, setLoading] = useState(true);
   const [showCheckoutModal, setShowCheckoutModal] = useState(false);
   const [plans, setPlans] = useState<IPlan[]>([]);
   const [selectedPlan, setSelectedPlan] = useState<IPlan | null>(null);
   const [showPurchaseSuccessModal, setShowPurchaseSuccessModal] = useState(false);
   const [coupon, setCoupon] = useState('');
   const [discount, setDiscount] = useState<IDiscountData | null>(null);
   const [purchasePlanLoading, setPurchasePlanLoading] = useState(false);

   const clearValues = () => {
      setCoupon('');
      setDiscount(null);
      setSelectedPlan(null);
   };

   const getCustomer = async () => {
      try {
         setCustomerLoading(true);
         const response: any = await ApiRequests.getCustomer(currentUser?.uid as string);
         setCustomerData(response.data);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.getCustomer'));
      } finally {
         setCustomerLoading(false);
      }
   };

   const getPlans = async () => {
      try {
         const response = await ApiRequests.getPlans(currentUser?.uid as string);
         setPlans(response.data.plans);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('settings.billing.purchasePlan.error.getPlans'));
      } finally {
         setLoading(false);
      }
   };

   const subscribeToPlan = async () => {
      try {
         setPurchasePlanLoading(true);
         const response: any =
            discount && coupon
               ? await ApiRequests.subscribeToPlan(currentUser?.uid as string, {
                    payload: {
                       plan_id: selectedPlan?.plan_id,
                       coupon,
                    },
                 })
               : await ApiRequests.subscribeToPlan(currentUser?.uid as string, {
                    payload: {
                       plan_id: selectedPlan?.plan_id,
                    },
                 });

         if (response.success) {
            await getPlans();
            await getCustomer();
            clearValues();
            setShowPurchaseSuccessModal(true);
            NotificationsProvider.success(response.data.message);
         } else {
            NotificationsProvider.error(parseError(response));
         }
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('settings.billing.purchasePlan.error.subscribeToPlan'),
         );
      } finally {
         setPurchasePlanLoading(false);
         setShowCheckoutModal(false);
      }
   };
   const goBack = () => {
      history.back();
   };

   const onUpgradeClick = () => {
      if (selectedPlan) {
         setShowCheckoutModal(true);
      }
   };

   const isPlanSelected = (id: string) => {
      return selectedPlan && selectedPlan.plan_id === id;
   };

   const goToTermsOfServicePage = () => {
      window.open(config.TERMS_OF_SERVICE_PAGE, '_blank');
   };

   useEffect(() => {
      currentUser && void getPlans();
      //eslint-disable-next-line
   }, [currentUser]);

   useEffect(() => {
      if (!showCheckoutModal) {
         setSelectedPlan(null);
      }
   }, [showCheckoutModal]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <>
         {showCheckoutModal && selectedPlan && (
            <CheckoutModal
               isOpen={showCheckoutModal}
               setShowModal={setShowCheckoutModal}
               selectedPlan={selectedPlan}
               loading={purchasePlanLoading}
               discount={discount}
               setDiscount={setDiscount}
               coupon={coupon}
               setCoupon={setCoupon}
               onPurchaseClick={subscribeToPlan}
            />
         )}

         {showPurchaseSuccessModal && (
            <StartCoworkingModal isOpen={showPurchaseSuccessModal} setShowModal={setShowPurchaseSuccessModal} />
         )}

         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('common.purchasePlan')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 32px'}
            />
         </HeaderWrapper>

         {plans && plans.length > 0 ? (
            <>
               <Flex justifyContent={'space-between'} alignItems={'stretch'} flexWrap={'wrap'} width={'100%'}>
                  {plans.map((plan: any) => (
                     <PlanCard
                        className={`${isPlanSelected(plan.plan_id) ? 'selected' : ''}`}
                        key={plan.plan_id}
                        onClick={() => {
                           setSelectedPlan(plan);
                        }}
                     >
                        <StyledText
                           width={'100%'}
                           text={plan.plan_name}
                           variant={StyledTextVariantsType.HEADING_2}
                           margin={'0 0 24px 0'}
                        />
                        <Flex flexDirection={'column'} alignItems={'flex-start'} width={'100%'}>
                           <StyledText
                              width={'100%'}
                              text={translate('common.perMonth')}
                              variant={StyledTextVariantsType.PARAGRAPH_3}
                              colorVariant={theme.colors.gray}
                           />

                           <StyledText
                              width={'100%'}
                              text={`${plan.plan_price} ${plan.currency}`}
                              variant={StyledTextVariantsType.HEADING_3}
                           />
                        </Flex>
                     </PlanCard>
                  ))}
               </Flex>

               <Button
                  disabled={!selectedPlan}
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('common.choosePlan')}
                  width={'100%'}
                  margin={'70px 0 0 0'}
                  onClick={onUpgradeClick}
               />
            </>
         ) : (
            <StyledText
               width={'100%'}
               justifyContent={'center'}
               text={translate('common.noPlans')}
               variant={StyledTextVariantsType.PARAGRAPH_1}
               margin={'0 0 64px 0'}
            />
         )}

         <InlineElementsWrapper margin={'32px 0 0 0'} width={'100%'} textAlign={'center'}>
            <StyledText
               width={'100%'}
               justifyContent={'center'}
               text={'Read our'}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               cursor={'pointer'}
               margin={'0 5px 0 0'}
            />
            <StyledText
               width={'100%'}
               justifyContent={'center'}
               text={'Terms and Conditions'}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               cursor={'pointer'}
               onClick={goToTermsOfServicePage}
            />
         </InlineElementsWrapper>
      </>
   );
};

export default PurchasePlan;
