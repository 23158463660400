import React, { ReactElement, useEffect, useState } from 'react';
import { Flex, Input, StyledText, Button } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import theme from 'src/styles/theme';
import { translate } from 'src/translations/translations';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import ApiRequests from '../settings/data/ApiRequests';
import { IPlan } from 'src/features/settings/interfaces';

interface IProps {
   plan_id: string;
   coupon: string;
   setCoupon: any;
   discount: any;
   setDiscount: any;
}

const PlanDetails = (props: IProps): ReactElement => {
   const wide = useWindowWide(768);

   const [planDetails, setPlanDetails] = useState<IPlan | null>(null);

   const onCouponInput = (value: string) => {
      props.setCoupon(value);
   };

   const onValidateCouponClick = async () => {
      if (!props.coupon) {
         return NotificationsProvider.error(translate('checkoutModal.error.missingCoupon'));
      }

      try {
         const response = await ApiRequests.validateCoupon({
            payload: {
               plan_id: props.plan_id,
               coupon_id: props.coupon,
            },
         });

         if (!(response as any).success) {
            return NotificationsProvider.error(
               response.data?.message || translate('checkoutModal.error.validateCoupon'),
            );
         }

         props.setDiscount(response.data);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('checkoutModal.error.validateCoupon'));
      }
   };

   const getPercent = (discount: string) => {
      const discountPercent = parseFloat(discount);
      return `${Number(discountPercent.toFixed(2))}%`;
   };

   const getMonths = (monthsDuration: number) => {
      return `${monthsDuration} month${monthsDuration > 1 ? 's' : ''}`;
   };

   const getTotalAmount = (price: any, discount: string) => {
      const discountPercent = parseFloat(discount) / 100;
      const discountAmount = price * Number(discountPercent.toFixed(2));
      return price - discountAmount;
   };

   const getPlan = async () => {
      const response = await ApiRequests.getPlan(props.plan_id);
      setPlanDetails(response.data.plan);
   };

   useEffect(() => {
      getPlan();
      if (props.coupon != undefined) {
         onValidateCouponClick();
      }
   }, []);

   return (
      <Flex flexDirection="column" width={'40%'} padding={'0 0 0 3%'} paddingMobile={'0 0 0 2%'}>
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('signUp.planDetails.title')}
            variant={StyledTextVariantsType.HEADING_1}
         />
         <Flex
            flexDirection="column"
            borderBottom={`1px solid ${theme.colors.lightGray}`}
            padding={'5% 0'}
            paddingMobile={'5% 0'}
         >
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('common.monthlyMembership')}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               margin={'0 0 10px 0'}
               colorVariant={theme.colors.gray}
            />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={`${planDetails?.plan_name}`}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={`${planDetails?.plan_description}`}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               colorVariant={theme.colors.gray}
               margin={'10px 0 0 0'}
            />
         </Flex>
         <Flex
            flexDirection="column"
            borderBottom={`1px solid ${theme.colors.lightGray}`}
            padding={'5% 0'}
            paddingMobile={'5% 0'}
         >
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('signUp.planDetails.promo.code')}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('signUp.planDetails.promo.code.text')}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               colorVariant={theme.colors.gray}
               margin={'10px 0 0 0'}
            />
            <Input
               width={'100%'}
               type={'text'}
               margin={'20px 0 10px 0'}
               placeholder={translate('signUp.planDetails.promo.code.input.text')}
               validators={['required']}
               onChange={(value) => onCouponInput(value)}
               disabled={props.coupon != undefined ? true : false}
               value={props.coupon}
            />
            <Button
               variant={ButtonVariantsType.TRANSPARENT}
               text={translate('signUp.planDetails.promo.code.apply')}
               onClick={onValidateCouponClick}
            />
         </Flex>
         <Flex flexDirection="column" padding={'5% 0'} paddingMobile={'5% 0'}>
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('signUp.planDetails.order.info')}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <Flex justifyContent={'flex-start'} justifyContentMobile={'flex-start'} paddingMobile={'0'}>
               <Flex width="100%" paddingMobile={'0'}>
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={translate('signUp.planDetails.order.original.amount')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                     margin={'10px 0 0 0'}
                  />
                  <StyledText
                     width={'100%'}
                     justifyContentMobile={'flex-end'}
                     justifyContent={'flex-end'}
                     text={`${planDetails?.plan_price} ${planDetails?.currency}`}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     fontWeight={'bold'}
                     margin={'10px 0 0 0'}
                  />
               </Flex>
            </Flex>
            <Flex justifyContent={'flex-start'} paddingMobile={'0'}>
               <Flex width="100%" paddingMobile={'0'}>
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={translate('signUp.planDetails.order.discount.amount')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                     margin={'10px 0 0 0'}
                  />
                  <StyledText
                     width={'100%'}
                     justifyContentMobile={'flex-end'}
                     justifyContent={'flex-end'}
                     text={props.discount ? `${getPercent(props.discount.percent_off)}` : '-'}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     fontWeight={'bold'}
                     margin={'10px 0 0 0'}
                  />
               </Flex>
            </Flex>
            <Flex justifyContent={'flex-start'} paddingMobile={'0'}>
               <Flex width="100%" paddingMobile={'0'}>
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={translate('signUp.planDetails.order.discount.duration')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                     margin={'10px 0 0 0'}
                  />
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-end'}
                     justifyContentMobile={'flex-end'}
                     text={props.discount ? `${getMonths(props.discount.duration_in_months)}` : '-'}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     fontWeight={'bold'}
                     margin={'10px 0 0 0'}
                  />
               </Flex>
            </Flex>
            <Flex justifyContent={'flex-start'} paddingMobile={'0'}>
               <Flex width="100%" paddingMobile={'0'}>
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={translate('signUp.planDetails.order.discount.total.amount')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                     margin={'10px 0 0 0'}
                  />
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-end'}
                     justifyContentMobile={'flex-end'}
                     text={
                        props.discount
                           ? `${getTotalAmount(planDetails?.plan_price, props.discount.percent_off)} ${
                                planDetails?.currency
                             }`
                           : `${planDetails?.plan_price} ${planDetails?.currency}`
                     }
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     fontWeight={'bold'}
                     margin={'10px 0 0 0'}
                  />
               </Flex>
            </Flex>
            <StyledText
               width={'100%'}
               justifyContent={'flex-end'}
               justifyContentMobile={'flex-end'}
               text={translate('signUp.planDetails.order.VAT')}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               colorVariant={theme.colors.gray}
               margin={'10px 0 0 0'}
            />
         </Flex>
      </Flex>
   );
};

export default PlanDetails;
