import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFirebase } from 'react-redux-firebase';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Button, Flex, Input, ModalComponent, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { AppleButton, FacebookButton, GoogleButton } from 'src/assets/images';
import { RootState } from 'src/app/rootReducer';
import AuthApiCalls from './data/ApiRequests';
import SettingsApiCalls from 'src/features/settings/data/ApiRequests';
import { translate } from 'src/translations/translations';
import {
   logout,
   setCustomerRole,
   setIsCustomer,
   setToken,
   showLoginModal,
   showResetPasswordModal,
} from 'src/system/state/actions';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { UserRoles } from 'src/system/state/types';
import { signOutFromFirebase } from './authSlice';
import ApiRequests from 'src/features/settings/data/ApiRequests';

const LoginModal = (): ReactElement => {
   const dispatch = useDispatch();
   const firebase = getFirebase();
   const isOpen = useSelector((state: RootState) => state.systemReducer).showLoginModal;

   const [loading, setLoading] = useState(false);
   const [loginError, setLoginError] = useState('');
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');

   const [formFieldsValidity, setFormFieldsValidity] = useState({
      emailValid: false,
      passwordValid: false,
   });

   const clearValues = () => {
      setEmail('');
      setPassword('');
      setLoginError('');
      setLoading(false);
      setFormFieldsValidity({
         emailValid: false,
         passwordValid: false,
      });
   };

   const isFormValid = (): boolean => {
      return formFieldsValidity.emailValid && formFieldsValidity.passwordValid;
   };

   const clearAuth = async () => {
      await signOutFromFirebase();
      dispatch(logout());
   };

   async function loginUserOnFirebase(email: string, password: string) {
      try {
         setLoading(true);
         const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);

         if (user) {
            await loginOnApi(user);
         } else {
            dispatch(showLoginModal(false));
         }
      } catch (err: any) {
         setLoginError(err.message);
         setLoading(false);
      }
   }

   async function loginOnApi(user: any) {
      try {
         const response: any = await AuthApiCalls.createToken({
            payload: {
               email: user.email,
               uuid: user.uid,
            },
         });

         if (response.success && response.data) {
            dispatch(setToken(response.data.message));
            await loadCustomerData(user);
         } else {
            await clearAuth();
            NotificationsProvider.error(parseError(response) || translate('common.error.signIn'));
            dispatch(showLoginModal(false));
         }
      } catch (err) {
         await clearAuth();
         NotificationsProvider.error(parseError(err) || translate('common.error.signIn'));
         dispatch(showLoginModal(false));
      }
   }

   async function onResetPasswordClick() {
      dispatch(showLoginModal(false));
      dispatch(showResetPasswordModal(true));
   }

   async function loginWithGoogle() {
      try {
         setLoading(true);
         const googleAuthProvider = new (firebase as any).auth.GoogleAuthProvider();
         googleAuthProvider.addScope('email');
         const { user } = await firebase.auth().signInWithPopup(googleAuthProvider);

         if (user) {
            await createUserOnApiOrProceedToLoginOnApi(user, user.displayName as string, user.email as string);
         } else {
            dispatch(showLoginModal(false));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('common.error.googleSignIn'));
         dispatch(showLoginModal(false));
      }
   }

   async function loginWithFacebook() {
      try {
         setLoading(true);
         const facebookAuthProvider = new (firebase as any).auth.FacebookAuthProvider();
         facebookAuthProvider.addScope('email');
         const { user, additionalUserInfo } = await firebase.auth().signInWithPopup(facebookAuthProvider);
         const profile: any = additionalUserInfo?.profile;

         if (user && profile) {
            await createUserOnApiOrProceedToLoginOnApi(user, profile.name, profile.email);
         } else {
            dispatch(showLoginModal(false));
         }
      } catch (err) {
         dispatch(showLoginModal(false));
         NotificationsProvider.error(parseError(err) || translate('common.error.facebookSignIn'));
      }
   }

   async function loginWithApple() {
      try {
         setLoading(true);
         const oAuthProvider = new (firebase as any).auth.OAuthProvider('apple.com');
         oAuthProvider.addScope('email');
         oAuthProvider.addScope('name');
         const { user } = await firebase.auth().signInWithPopup(oAuthProvider);

         if (!user?.displayName) {
            user?.updateProfile({
               displayName: 'unknown user',
            });
         }

         if (user) {
            await createUserOnApiOrProceedToLoginOnApi(user, user.displayName as string, user.email as string);
         } else {
            dispatch(showLoginModal(false));
         }
      } catch (err) {
         dispatch(showLoginModal(false));
         NotificationsProvider.error(parseError(err) || translate('common.error.appleSignIn'));
      }
   }

   async function createUserOnApiOrProceedToLoginOnApi(firebaseUser: any, displayName: string, email: string) {
      const userRef = firebase.database().ref(`users/${firebaseUser?.uid}`);

      userRef.once(
         'value',
         async (snapshot) => {
            if (!snapshot.val()) {
               // Is user does not exist,
               // create user on our data and then continue
               await AuthApiCalls.createUser({
                  payload: {
                     name: displayName as string,
                     email: email as string,
                     uuid: firebaseUser?.uid,
                  },
               });
               await firebaseUser?.sendEmailVerification();
            }

            await loginOnApi(firebaseUser);
         },
         async (err) => {
            await clearAuth();
            setLoading(false);
            dispatch(showLoginModal(false));
            NotificationsProvider.error(parseError(err) || translate('common.error.signIn'));
         },
      );
   }

   async function loadCustomerData(user: any) {
      try {
         const response: any = await ApiRequests.getUserDetails(user?.uid as string);
         const customer = response?.data?.profile;

         let customerRole;

         if (customer.role === 4) {
            customerRole = UserRoles.SPACE_MANAGER;
         } else if (customer.role === 2) {
            customerRole = UserRoles.ENTERPRISE_MANAGER;
         } else {
            customerRole = UserRoles.DEFAULT_USER;
         }

         if (customer) {
            dispatch(setIsCustomer(true));
            dispatch(setCustomerRole(customerRole));
         } else {
            dispatch(setIsCustomer(false));
            dispatch(setCustomerRole(UserRoles.DEFAULT_USER));
         }
      } catch (err) {
         dispatch(setIsCustomer(false));
         dispatch(setCustomerRole(UserRoles.DEFAULT_USER));
         NotificationsProvider.error(parseError(err) || translate('common.error.getCustomer'));
      } finally {
         setLoading(false);
         dispatch(showLoginModal(false));
      }
   }

   const renderButtons = () => (
      <>
         <Button
            disabled={!isFormValid()}
            variant={ButtonVariantsType.PURPLE}
            isLoading={loading}
            text={translate('common.signIn')}
            width={'100%'}
            onClick={async () => {
               await loginUserOnFirebase(email, password);
            }}
         />
         <StyledText
            text={translate('common.joinWithSocialMediaAcc')}
            margin={'16px 0 16px 0'}
            justifyContent={'center'}
            width={'100%'}
            variant={StyledTextVariantsType.PARAGRAPH_2}
            colorVariant={theme.colors.gray}
         />
         <Flex alignItems={'center'} width={'100%'} justifyContent={'center'}>
            <AppleButton style={{ cursor: 'pointer', margin: '0 8px' }} onClick={loginWithApple} />
            <GoogleButton style={{ cursor: 'pointer', margin: '0 8px' }} onClick={loginWithGoogle} />
            <FacebookButton style={{ cursor: 'pointer', margin: '0 8px' }} onClick={loginWithFacebook} />
         </Flex>
      </>
   );

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'450px'}
         closeModal={() => {
            clearValues();
            dispatch(showLoginModal(false));
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <StyledText
               text={translate('loginModal.title')}
               margin={'0 0 16px 0'}
               width={'100%'}
               variant={StyledTextVariantsType.HEADING_1}
            />
            <StyledText
               text={translate('loginModal.subtitle')}
               margin={'0 0 40px 0'}
               width={'100%'}
               variant={StyledTextVariantsType.PARAGRAPH_1}
            />
            <Input
               width={'100%'}
               type={'email'}
               margin={'0 0 17px 0'}
               placeholder={translate('placeholders.email')}
               onChange={(value) => setEmail(value)}
               validators={['isEmail', 'required']}
               value={email}
               validate={(emailValid) => setFormFieldsValidity({ ...formFieldsValidity, emailValid })}
            />
            <Input
               width={'100%'}
               type={'password'}
               margin={'0 0 0 0'}
               placeholder={translate('placeholders.password')}
               onChange={(value) => setPassword(value)}
               validators={['required']}
               value={password}
               validate={(passwordValid) => setFormFieldsValidity({ ...formFieldsValidity, passwordValid })}
            />
            <StyledText
               text={loginError}
               colorVariant={theme.colors.red}
               variant={StyledTextVariantsType.PARAGRAPH_3}
               margin={'5px 0 0 10px'}
               width={'100%'}
            />
            <StyledText
               text={translate('loginModal.resetPassword.text')}
               margin={'8px 0 50px 16px'}
               width={'100%'}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               colorVariant={theme.colors.mainPurple}
               cursor={'pointer'}
               onClick={onResetPasswordClick}
            />
            {loading ? <CircularProgress size={25} /> : renderButtons()}
         </Flex>
      </ModalComponent>
   );
};

export default LoginModal;
