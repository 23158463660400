import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import theme from 'src/styles/theme';

interface IProps {
   title: string;
   value: string | number;
}

const Wrapper = styled.div`
   background: ${theme.colors.white};
   border: 1px solid ${theme.colors.lightGray};
   box-sizing: border-box;
   border-radius: 15px;
   padding: 55px 24px 45px 24px;
   min-height: 184px;
   width: 23%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-right: 10px;

   @media only screen and (max-width: 768px) {
      width: 100%;

      &:not(:last-of-type) {
         margin-bottom: 20px;
      }
   }
`;

const TodayInfoBox = ({ title, value }: IProps): ReactElement => {
   return (
      <Wrapper>
         <StyledText
            justifyContent={'center'}
            text={title === undefined || title === null ? 'n/a' : title}
            variant={StyledTextVariantsType.NOTE_TEXT}
            colorVariant={theme.colors.gray}
         />

         <StyledText
            justifyContent={'center'}
            text={value === undefined || value === null ? 'n/a' : value.toString()}
            variant={StyledTextVariantsType.HEADING_1}
            colorVariant={theme.colors.mainPurple}
         />
      </Wrapper>
   );
};

export default TodayInfoBox;
