import React, { ReactElement } from 'react';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import chartColors from 'src/styles/chart-colors';

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const options = {
   legend: {
      enabled: true,
      position: 'top',
   },
   animation: {
      animateScale: true,
   },
   tooltips: {
      enabled: false,
   },
   cutoutPercentage: 60,
   maintainAspectRatio: false,
   responsive: false,
};

const getLabels = (statistics: any) => {
   // eslint-disable-next-line
   let labels: any[] = [];

   Object.keys(statistics).forEach((key: any) => {
      labels.push(key);
   });

   return labels;
};

const getData = (statistics: any) => {
   // eslint-disable-next-line
   let dataArray: any[] = [];

   Object.keys(statistics).forEach((key: any) => {
      dataArray.push(statistics[key]);
   });

   return dataArray;
};

const getDataObject = (statistics: any) => {
   return {
      type: 'doughnut',
      labels: getLabels(statistics),
      datasets: [
         {
            data: getData(statistics),
            backgroundColor: chartColors.slice(0, getData(statistics).length),
            hoverOffset: 4,
         },
      ],
   };
};

interface IProps {
   data: any;
}

const DoughnutChart = ({ data }: IProps): ReactElement => {
   return <Doughnut options={options} data={getDataObject(data)} height={200} />;
};

export default DoughnutChart;
