import React, { ReactElement, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Flex } from 'src/components';
import Today from './today/Today';
import SpaceSettings from './settings/SpaceSettings';
import Insights from './insights/Insights';
import YourSpaces from './your_spaces/YourSpaces';

import { RootState } from 'src/app/rootReducer';
import { UserRoles, SpaceManagementTabEnum } from 'src/system/state/types';
import { setSpaceanagementTab } from 'src/system/state/actions';
import { RoutesEnum, spaceManagementRoutes, spaceManagementSettingsRoutes } from 'src/routes/routes';
import { Route, Switch, useHistory } from 'react-router-dom';

const SpaceManagement = (): ReactElement => {
   const dispatch = useDispatch();
   const history = useHistory();

   const selectedSpaceManagementTab = useSelector((state: RootState) => state.systemReducer).selectedSpaceManagementTab;
   const customerRole = useSelector((state: RootState) => state.systemReducer).customerRole;

   useEffect(() => {
      if (customerRole !== UserRoles.SPACE_MANAGER) {
         dispatch(setSpaceanagementTab(SpaceManagementTabEnum.SETTINGS));
         /*  history.push({
            pathname: RoutesEnum.SPACE_MANAGEMENT_SETTINGS,
         }); */
      }
      //eslint-disable-next-line
   }, [customerRole]);

   return (
      <Flex
         justifyContent="flex-start"
         alignItems="flex-start"
         flexWrap="nowrap"
         flex={1}
         minHeight={'100%'}
         mobileMinHeight={'100%'}
         position={'relative'}
         padding={'0 10% 3% 10%'}
      >
         {/*   {selectedSpaceManagementTab === SpaceManagementTabEnum.TODAY && <Today />}
         {selectedSpaceManagementTab === SpaceManagementTabEnum.SETTINGS && <SpaceSettings />}
         {selectedSpaceManagementTab === SpaceManagementTabEnum.INSIGHTS && <Insights />}
         {selectedSpaceManagementTab === SpaceManagementTabEnum.YOUR_SPACES && <YourSpaces />} */}

         {/*   <Switch>
            {spaceManagementRoutes.map((r) => (
               <Route exact key={r.name} path={r.path} component={r.component} />
            ))}
         </Switch> */}
      </Flex>
   );
};

export default SpaceManagement;
