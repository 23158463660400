import React, { ReactElement, useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import styled from 'styled-components';

import { StyledText, Flex, Spinner } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import VerticalBarChart from './VerticalBarChart';
import VerticalBarChartWithMaxHighlighted from './VerticalBarChartWithMaxHighlighted';
import DoughnutChart from './DoughnutChart';
import CurvedLineChart from './CurvedLineChart';

import ApiRequests from 'src/features/space_management/data/ApiRequests';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { useLocation } from 'react-router';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartContainer = styled.div<any>`
   background: #ffffff;
   border: 1px solid ${theme.colors.lightGray};
   box-sizing: border-box;
   border-radius: 15px;
   padding: 30px;
   height: ${(props) => props.height || 'auto'};

   p {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: ${theme.colors.black};
   }
`;

const SpaceOverview = (): ReactElement => {
   const location = useLocation();

   const [loading, setLoading] = useState(true);
   const [insights, setInsights] = useState<any>(null);
   const [spaceId, setSpaceId] = useState(location.state.spaceId);

   const wide = useWindowWide(768);

   const goBack = () => {
      history.back();
   };

   const getSpaceInsights = async () => {
      try {
         const response = await ApiRequests.getSpaceInsights(spaceId);

         if (response && response.data) {
            setInsights(response.data);
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpaceInsights'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('spaceManagement.error.getSpaceInsights'));
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (spaceId) {
         void getSpaceInsights();
      }
      //eslint-disable-next-line
   }, [spaceId]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('spaceManagement.insights.spaceOverview.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('spaceManagement.insights.spaceOverview.title')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}
         <div className="container">
            <div className="row">
               <div className="col col-md-6">
                  <ChartContainer>
                     <StyledText
                        width={'100%'}
                        justifyContent={'flex-start'}
                        text={translate('spaceManagement.insights.spaceOverview.chart1.title')}
                        variant={StyledTextVariantsType.BUTTON_TEXT}
                        margin={'0 0 24px 0'}
                     />

                     {insights && insights.checkin_overview && <VerticalBarChart data={insights.checkin_overview} />}
                  </ChartContainer>
               </div>
               <div className="col col-md-6">
                  <ChartContainer height={'100%'}>
                     <StyledText
                        width={'100%'}
                        justifyContent={'flex-start'}
                        text={translate('spaceManagement.insights.spaceOverview.chart2.title')}
                        variant={StyledTextVariantsType.BUTTON_TEXT}
                        margin={'0 0 24px 0'}
                     />

                     {insights && insights.checkins_by_type && <DoughnutChart data={insights.checkins_by_type} />}
                  </ChartContainer>
               </div>
            </div>

            <div className="row mt-5">
               <div className="col col-md-6">
                  <ChartContainer height={'100%'}>
                     <StyledText
                        width={'100%'}
                        justifyContent={'flex-start'}
                        text={translate('spaceManagement.insights.spaceOverview.chart3.title')}
                        variant={StyledTextVariantsType.BUTTON_TEXT}
                        margin={'0 0 24px 0'}
                     />

                     {insights && insights.earnings_overview && <CurvedLineChart data={insights.earnings_overview} />}
                  </ChartContainer>
               </div>

               <div className="col col-md-6">
                  <ChartContainer>
                     <StyledText
                        width={'100%'}
                        justifyContent={'flex-start'}
                        text={translate('spaceManagement.insights.spaceOverview.chart4.title')}
                        variant={StyledTextVariantsType.BUTTON_TEXT}
                        margin={'0 0 24px 0'}
                     />

                     {insights && insights.checkin_overview && (
                        <VerticalBarChartWithMaxHighlighted data={insights.checkin_overview} />
                     )}
                  </ChartContainer>
               </div>
            </div>
         </div>
      </Flex>
   );
};

export default SpaceOverview;
