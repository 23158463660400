import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import { Button, Flex, StyledText, Table } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn, TableItemAction } from 'src/components/table/interfaces';

import AddAdminModal from '../modals/AddAdminModal';

import { ICompany } from 'src/features/enterprise_management/interfaces';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';

import { translate } from 'src/translations/translations';
import { replaceLocation, RoutesEnum } from 'src/routes/routes';
import { config } from 'src/app/config';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

const EnterpriseAdmins = (): ReactElement => {
   const history = useHistory();
   const location = useLocation();

   const [company, setCompany] = useState<ICompany>(location.state.company);

   const wide = useWindowWide(768);

   const goBack = () => {
      history.goBack();
   };

   const [showAddNewAdminModal, setShowAddNewAdminModal] = useState(false);
   const [companyManagers, setCompanyManagers] = useState<any>([]);

   const getCompanyManagers = async () => {
      if (company && company.enterprise_id) {
         try {
            const response = await ApiRequests.getCompanyManagers(company.enterprise_id);
            setCompanyManagers(response.data);
            console.log(response.data);
         } catch (error) {
            NotificationsProvider.error(
               parseError(error) || translate('hrManagement.settings.error.getCompanyManagers'),
            );
         }
      } else {
         NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }
   };
   useEffect(() => {
      void getCompanyManagers();
      // eslint-disable-next-line
   }, []);

   const columns: Array<TableColumn<any>> = [
      {
         title: '',
         key: 'image',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (member: any) => `${member.photo ? config.STORAGE_URL + member.photo : ''}`,
      },
      {
         title: 'Name',
         key: 'name',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (companyManager: any) => <>{companyManager.name}</>,
      },
      {
         title: 'Email',
         key: 'email',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (companyManager: any) => <>{companyManager.email}</>,
      },
      {
         title: 'Joined At',
         key: 'joined_at',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{moment(checkin.joined_at).format('DD/MM/YYYY HH:mm')}</>,
      },
   ];

   const itemActions: Array<TableItemAction<any>> = [
      {
         title: translate('hrManagement.settings.companyManagers.view.table.action.viewProfile'),
         clickHandler: (companyManager: any) => {
            history.push(
               replaceLocation(RoutesEnum.ENTERPRISE_MANAGEMENT_USER_PROFILE, {
                  id: companyManager.uuid,
               }),
            );
         },
      },
   ];

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {showAddNewAdminModal && (
            <AddAdminModal
               isOpen={showAddNewAdminModal}
               setShowModal={setShowAddNewAdminModal}
               company={company}
               getCompanyManagers={getCompanyManagers}
            />
         )}

         <Flex
            margin={'0 0 32px 0'}
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirectionMobile={'column'}
            alignItemsMobile={'center'}
         >
            {wide ? (
               <StyledText
                  justifyContent={'flex-start'}
                  text={translate('hrManagement.settings.companyManagers.view.title')}
                  variant={StyledTextVariantsType.HEADING_1}
               />
            ) : (
               <HeaderWrapper>
                  <img
                     src={PurpleArrowLeft}
                     style={{ height: 34, cursor: 'pointer' }}
                     alt="navigate"
                     onClick={goBack}
                  />
                  <StyledText
                     justifyContent={'flex-start'}
                     text={translate('hrManagement.settings.companyManagers.view.title')}
                     variant={StyledTextVariantsType.HEADING_1}
                     margin={'0 0 0 32px'}
                  />
               </HeaderWrapper>
            )}

            <Button
               variant={ButtonVariantsType.PURPLE}
               text={translate('hrManagement.settings.companyManagers.view.headerButton.text')}
               onClick={() => {
                  setShowAddNewAdminModal(true);
               }}
            />
         </Flex>

         <Table
            onSelectAll={() => null}
            columns={columns}
            data={companyManagers}
            itemActions={itemActions}
            customClass={'w-100'}
         />
      </Flex>
   );
};

export default EnterpriseAdmins;
