import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFirebase } from 'react-redux-firebase';
import { Col, Container, Row } from 'react-bootstrap';
import moment from 'moment';
import styled from 'styled-components';

import { Flex, Spinner, StyledText, Table } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn, TableItemAction } from 'src/components/table/interfaces';
import { BorderedContainer, InlineElementsWrapper } from 'src/components/SharedComponents';

import TodayInfoBox from './TodayInfoBox';
import CheckInDetailsModal from './modals/CheckInDetailsModal';

import { RootState } from 'src/app/rootReducer';
import { ISpace } from 'src/features/space_management/interfaces';
import ApiRequests from 'src/features/space_management/data/ApiRequests';
import { setSpaceId } from 'src/system/state/actions';
import { translate } from 'src/translations/translations';
import { capitalize, parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { config } from 'src/app/config';
import Select from 'react-select';
import { ArrowDown } from 'src/assets/images';
import theme from 'src/styles/theme';
import Checkbox from 'src/components/Checkbox';

const BoxesContainer = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
   }
`;
const IconContainer = styled.div`
   svg > path {
      stroke: ${(props) => (props.color ? `${props.color}` : `initial`)};
   }
`;

const optionsStyles: any = {
   container: (styles: any) => ({
      ...styles,
   }),
   control: (styles: any) => ({
      ...styles,
      borderWidth: 0,
      boxShadow: 0,
      alignItems: 'center',
   }),

   singleValue: (styles: any) => {
      const result = { ...styles };
      const wide = useWindowWide(768);

      result.fontSize = wide ? 40 : 20;
      result.fontFamily = 'Helvetica-Neue-Bold';
      result.color = '#897DFD';
      result.top = 'auto';
      result.maxWidth = 'unset';
      result.position = 'static';
      result.transform = 'none';
      result.paddingTop = 2;

      return result;
   },
   input: (styles: any) => ({
      ...styles,
      color: 'white',
   }),
   option: (styles: any, optionState: any) => {
      const result = { ...styles };
      if (optionState.isSelected) {
         result.backgroundColor = '#897DFD';
      }
      return result;
   },
};

const Today = (): ReactElement => {
   const dispatch = useDispatch();
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;
   const spaceId = useSelector((state: RootState) => state.systemReducer).spaceId;

   const [loading, setLoading] = useState(true);
   const [spaces, setSpaces] = useState<ISpace[]>([]);
   const [statistics, setStatistics] = useState<any>(null);
   const [options, setOptions] = useState<any>(null);
   const [showCheckInDetailsModal, setShowCheckInDetailsModal] = useState(false);
   const [selectedCheckin, setSelectedCheckin] = useState<any>(null);
   const [selectedSpace, setSelectedSpace] = useState<any>(null);
   const [checkedOut, setCheckedOut] = useState<boolean>(false);

   const wide = useWindowWide(768);

   const selectSpace = (space: ISpace | any) => {
      dispatch(setSpaceId(space.value));
      setSelectedSpace(space);
   };

   const [isCheckedIn, setIsCheckedIn] = useState(false);
   const handleChangeCheckIn = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isCheckedIn) {
         setIsCheckedIn(e.target.checked);
         setIsCheckedAll(false);
         setIsCheckedOut(false);
      }
   };
   const [isCheckedOut, setIsCheckedOut] = useState(false);
   const handleChangeCheckOut = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isCheckedOut) {
         setIsCheckedAll(false);
         setIsCheckedIn(false);
         setIsCheckedOut(e.target.checked);
      }
   };
   const [isCheckedAll, setIsCheckedAll] = useState(true);
   const handleChangeAll = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isCheckedAll) {
         setIsCheckedAll(e.target.checked);
         setIsCheckedOut(false);
         setIsCheckedIn(false);
      }
   };

   const getAllSpaces = async () => {
      try {
         setLoading(true);
         const response = await ApiRequests.getAllSpaces(currentUser?.uid as string);
         console.log(response);
         if (response && response.data) {
            setSpaces(response.data);
            setSpaceId(response.data[0].uid);

            if (!spaceId && response.data.length > 0) {
               dispatch(setSpaceId(response.data[0].uid));
               setSelectedSpace(response.data[0]);
            } else {
               setSelectedSpace(response.data.find((space: any) => space.uid === spaceId));
            }

            const newArray = response.data.map((item: any) => ({
               value: item.uid,
               label: item.name,
            }));

            setOptions(newArray);
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpaces'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.getSpaces'));
      } finally {
         setLoading(false);
      }
   };

   const getSpaceStatistics = async () => {
      try {
         const response: any = await ApiRequests.getSpaceStatistics(spaceId);

         if (response && response.data) {
            setStatistics(response.data);
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpaceStatistics'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('spaceManagement.error.getSpaceStatistics'));
      } finally {
         setLoading(false);
      }
   };
   const checkOutFromSpace = async (payload: any) => {
      try {
         const response: any = await ApiRequests.checkOutFromSpace({
            payload: payload,
         });
         if (response && response.data) {
            await getSpaceStatistics();

            NotificationsProvider.success(response.data.message || translate('spaceManagement.success.checkOutUser'));
         } else {
            NotificationsProvider.error(response.error?.message || translate('spaceManagement.error.checkOutUser'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.checkOutUser'));
      }
   };

   useEffect(() => {
      if (currentUser) {
         void getAllSpaces();
      }
      // eslint-disable-next-line
   }, [currentUser]);

   useEffect(() => {
      if (spaceId) {
         if (!selectedSpace) {
            const currentSpace = spaces.find((el) => el.uid === spaceId);
            if (currentSpace) {
               setSelectedSpace({ label: currentSpace?.name, value: currentSpace?.uid });
            }
         }
         void getSpaceStatistics();
      }
      // eslint-disable-next-line
   }, [spaceId, spaces]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   const columns: Array<TableColumn<any>> = [
      {
         title: '',
         key: 'image',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => `${data.profile_picture ? config.STORAGE_URL + data.profile_picture : ''}`,
      },
      {
         title: 'User name',
         key: 'user_name',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{data.user_name}</>,
      },
      {
         title: 'Checkin ID',
         key: 'checkin_id',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{data.checkin_id}</>,
      },
      {
         title: 'Checkin type',
         key: 'checkin_type',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{capitalize(data.checkin_type)}</>,
      },
      {
         title: 'Checkin time',
         key: 'checkin_time',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{moment(checkin.checkin_time).format('DD/MM/YYYY HH:mm')}</>,
      },
      {
         title: 'Checkout time',
         key: 'checkout_time',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => (
            <>{checkin.checkout_time ? moment(checkin?.checkout_time).format('DD/MM/YYYY HH:mm') : ''}</>
         ),
      },
   ];

   const itemActions: Array<TableItemAction<any>> = [
      {
         title: 'View ticket',
         clickHandler: (item: any) => {
            setSelectedCheckin(item);
            setShowCheckInDetailsModal(true);
         },
      },
      {
         title: 'Check out',
         clickHandler: (item: any) => {
            setCheckedOut(true);

            item.did_checkout = true;
            const payload = {
               checkin_id: item.checkin_id,
               space_id: spaceId,
               uuid: currentUser?.uid,
            };
            checkOutFromSpace(payload);
         },
         disabled: checkedOut,
      },
   ];

   return (
      <Flex
         flexDirection="column"
         alignItems={'flex-start'}
         justifyContent={'flex-start'}
         width={'100%'}
         padding={'0 10% 3% 10%'}
         mobileMinHeight={'100%'}
      >
         {showCheckInDetailsModal && selectedCheckin && (
            <CheckInDetailsModal
               isOpen={showCheckInDetailsModal}
               setShowModal={setShowCheckInDetailsModal}
               checkInData={selectedCheckin}
               setSelectedCheckin={setSelectedCheckin}
            />
         )}
         <div style={{ display: 'flex', width: '100%', marginBottom: 38, justifyContent: 'flex-start' }}>
            <StyledText
               justifyContent={'flex-start'}
               text={translate('spaceManagement.today.title')}
               variant={wide ? StyledTextVariantsType.HEADING_1 : StyledTextVariantsType.HEADING_3}
            />

            <Select
               options={options}
               value={selectedSpace}
               onChange={selectSpace}
               styles={optionsStyles}
               components={{
                  DropdownIndicator: () => (
                     <IconContainer color={theme.colors.mainPurple}>
                        <ArrowDown
                           style={{
                              cursor: 'pointer',
                              width: wide ? '20px' : '15px',
                              marginLeft: 8,
                              position: 'absolute',
                              top: '55%',
                              right: '-10px',
                              transform: 'translateY(-50%)',
                           }}
                        />
                     </IconContainer>
                  ),
                  IndicatorSeparator: () => null,
               }}
            />
         </div>
         <StyledText
            text={translate('spaceManagement.boxes.title')}
            variant={StyledTextVariantsType.HEADING_3}
            margin={'0 0 24px 0'}
         />
         <BoxesContainer>
            <TodayInfoBox
               title={translate('spaceManagement.today.box1.title')}
               value={statistics?.checked_in_people_number}
            />
            <TodayInfoBox title={translate('spaceManagement.today.box2.title')} value={statistics?.desks_available} />

            <TodayInfoBox title={translate('spaceManagement.today.box3.title')} value={statistics?.earnings} />

            <TodayInfoBox title={translate('spaceManagement.today.box4.title')} value={statistics?.visits} />
         </BoxesContainer>
         <StyledText
            text={translate('spaceManagement.today.checkedIn.table.title')}
            variant={StyledTextVariantsType.HEADING_3}
            margin={'40px 0 24px 0'}
         />

         <InlineElementsWrapper margin={'0 0 38px 0'} width={'100%'}>
            <Row>
               <Col>
                  <BorderedContainer>
                     <div
                        style={{ display: 'flex', flexDirection: 'row', width: 'auto', justifyContent: 'flex-start' }}
                     >
                        <Checkbox handleChange={handleChangeAll} isChecked={isCheckedAll} label="All" />
                        <Checkbox handleChange={handleChangeCheckIn} isChecked={isCheckedIn} label="Checked in" />
                        <Checkbox handleChange={handleChangeCheckOut} isChecked={isCheckedOut} label="Checked out" />
                     </div>
                     <Table
                        onSelectAll={() => null}
                        columns={columns}
                        data={statistics?.checked_in_people.filter((item: any) => {
                           if (isCheckedOut) {
                              return item.did_checkout;
                           } else if (isCheckedIn) {
                              return !item.did_checkout;
                           } else {
                              return true;
                           }
                        })}
                        itemActions={itemActions}
                        customClass={'w-100'}
                     />
                  </BorderedContainer>
               </Col>
            </Row>
         </InlineElementsWrapper>
      </Flex>
   );
};

export default Today;
