import React from 'react';
import { TableColumn, TableItemAction } from './interfaces';

export interface TableHeaderProps<T> {
   columns: Array<TableColumn<T>>;
   renderActions: boolean;
   stickyHeader: boolean;
   itemActions?: Array<TableItemAction<T>>;
   actionsColumnName?: string;
   actionsSelectable?: boolean;
   allSelected?: boolean;
   onSelectAll?: (val: any) => void;
   clickHandler?: (val: any) => void;
}

const TableHeader = <T,>({
   columns,
   stickyHeader,
   renderActions,
   actionsColumnName,
   itemActions,
}: TableHeaderProps<T>): React.ReactElement => {
   const itemActionsColConf = columns.find((c) => c.key === 'itemActions');

   return (
      <>
         <thead>
            <tr>
               {columns
                  .filter((c) => !c.hidden && c.key !== 'itemActions')
                  .map((column) => (
                     <th
                        key={column.key}
                        className={`${stickyHeader ? 'sticky' : ''} px-0`}
                        style={{ width: column.width ? column.width : 'auto' }}
                     >
                        <button
                           type="button"
                           className="table-header-btn btn btn-link text-body text-nowrap py-0 px-0 pr-5 d-flex align-items-center"
                           onClick={column.clickHandler && column.clickHandler}
                           onKeyDown={column.clickHandler && column.clickHandler}
                        >
                           {column.title} {column.enableSort && <span className="fa fa-chevron-down ml-1" />}
                        </button>
                     </th>
                  ))}
               {renderActions && itemActions && (
                  <th
                     className={`${stickyHeader ? 'sticky' : ' '} px-0 text-end`}
                     style={{ width: itemActionsColConf?.width ? itemActionsColConf.width : 'unset' }}
                  >
                     {!actionsColumnName && (
                        <span className="btn btn-link text-body text-nowrap actions-btn">Actions</span>
                     )}
                     {actionsColumnName && (
                        <div className="action-wrapper">
                           <button
                              type="button"
                              className="table-header-btn btn btn-link text-body text-nowrap px-0 d-flex align-items-center ml-auto mr-3"
                           >
                              {actionsColumnName} <span className="fa fa-chevron-down ml-1" />
                           </button>
                        </div>
                     )}
                  </th>
               )}
            </tr>
         </thead>
      </>
   );
};

export default TableHeader;
