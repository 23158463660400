import React, { ReactElement, useEffect, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';

import { Flex, Spinner } from 'src/components';

import SettingsRoot from './SettingsRoot';

import { IUser } from './interfaces';
import ApiRequests from 'src/features/settings/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { settingsRoutes, accountRoutes, billingRoutes, RoutesEnum } from 'src/routes/routes';

export enum SettingsItemEnum {
   EMPTY = '',
   ACCOUNT_INFO = 'accountInfo',
   BILLING_INFO = 'billingInfo',
   ACTIVITY = 'activity',
   LEGAL_INFO = 'legalInfo',
}

export enum AccountSettingsItemEnum {
   EMPTY = '',
   BASIC_INFO = 'basicInfo',
   CHANGE_PASSWORD = 'changePassword',
   PROFILE_PHOTO = 'profilePhoto',
   SIGN_IN_METHODS = 'signInMethods',
   CHECKIN_CREDITS = 'checkinCredits',
   CHECKIN_HISTORY = 'checkinHistory',
}

export enum BillingSettingsItemEnum {
   EMPTY = '',
   PURCHASE_PLAN = 'purchasePlan',
   MANAGE_CREDIT_CARDS = 'manageCreditCards',
   BILLING_ADDRESS = 'billingAddress',
   INVOICES = 'invoices',
}

type ItemType = SettingsItemEnum | AccountSettingsItemEnum | BillingSettingsItemEnum;

const Settings = (): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [activeSettingsItem, setActiveSettingsItem] = useState(SettingsItemEnum.EMPTY);
   const [activeAccountSettingsItem, setActiveAccountSettingsItem] = useState(AccountSettingsItemEnum.EMPTY);
   const [activeBillingSettingsItem, setActiveBillingSettingsItem] = useState(BillingSettingsItemEnum.EMPTY);

   const [loading, setLoading] = useState(true);
   const [user, setUser] = useState<IUser>({
      uuid: '',
      name: '',
      email: '',
      biography: '',
      facebook: '',
      instagram: '',
      linkedin: '',
      photo: '',
      profession: '',
      skills: '',
      twitter: '',
      website: '',
   });

   const [isHidden, setIsHidden] = useState(false);

   const routeMatch = useRouteMatch<RoutesEnum>([RoutesEnum.ACCOUNT, RoutesEnum.BILLING_INFO, RoutesEnum.LEGAL_INFO]);

   const isSettingItemSelected = (selectedItem: ItemType, itemToCheck: ItemType) => {
      return selectedItem === itemToCheck;
   };

   const getUser = async () => {
      try {
         const response: any = await ApiRequests.getUserDetails(currentUser?.uid as string);
         if (response.data) {
            setUser(response.data.profile);
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.getUserDetails'));
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      currentUser && void getUser();
      //eslint-disable-next-line

      if (routeMatch?.isExact) {
         setIsHidden(true);
      }
   }, [currentUser]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         justifyContent="space-between"
         alignItems="stretch"
         flexWrap="nowrap"
         flex={1}
         minHeight={'100%'}
         mobileMinHeight={'100%'}
         position={'relative'}
         padding={'0 10% 3% 10%'}
         flexDirectionMobile={'column'}
      >
         <Flex
            width={'32%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
            hide={isHidden}
         >
            <SettingsRoot
               user={user}
               activeSettingsItem={activeSettingsItem}
               setActiveSettingsItem={setActiveSettingsItem}
               isSettingItemSelected={isSettingItemSelected}
               setActiveAccountSettingsItem={setActiveAccountSettingsItem}
               setActiveBillingSettingsItem={setActiveBillingSettingsItem}
            />
         </Flex>
         <Flex
            width={'66%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
         >
            <Switch>
               {settingsRoutes.map((r) => (
                  <Route exact key={r.name} path={r.path} component={r.component} />
               ))}
               {accountRoutes.map((r) => (
                  <Route exact key={r.name} path={r.path} component={r.component} />
               ))}
               {billingRoutes.map((r) => (
                  <Route exact key={r.name} path={r.path} component={r.component} />
               ))}
            </Switch>
         </Flex>
      </Flex>
   );
};

export default Settings;
