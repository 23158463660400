import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
   colors: {
      white: '#FFFFFF',
      black: '#000000',
      lightGray: '#DADADA',
      gray: '#ABABAB',
      grayBackground: '#f4f4f4',
      mainPurple: '#897DFD',
      green: '#1AA69A',
      red: '#CE4A4C',
      boxShadow: 'rgba(0, 0, 0, 0.15)',
   },
   typography: {
      regular: 'Helvetica-Neue-Regular',
      medium: 'Helvetica-Neue-Medium',
      bold: 'Helvetica-Neue-Bold',
      light: 'Helvetica-Neue-Light',
      italic: 'Helvetica-Neue-Italic',
      thin: 'Helvetica-Neue-Thin',
   },
   breakpoints: {
      xs: '480px',
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200px',
   },
};

export default theme;
