import React, { ReactElement, useState } from 'react';

import { Button, Input, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { getFirebase } from 'react-redux-firebase';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';
import { useHistory } from 'react-router';
import { RoutesEnum } from 'src/routes/routes';

const ChangePassword = (): ReactElement => {
   const history = useHistory();
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(false);
   const [newPassword, setNewPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');

   const [formFieldsValidity, setFormFieldsValidity] = useState({
      passwordValid: false,
      confirmPasswordValid: false,
   });
   const goBack = () => {
      history.goBack();
   };

   const isFormValid = (): boolean => {
      return formFieldsValidity.passwordValid && formFieldsValidity.confirmPasswordValid;
   };

   const updatePassword = async () => {
      setLoading(true);

      try {
         await currentUser?.updatePassword(newPassword);

         history.push(RoutesEnum.SETTINGS);
         NotificationsProvider.success(translate('settings.account.changePw.success'));
      } catch (error: any) {
         NotificationsProvider.error(error.message || translate('settings.account.changePw.error'));
      } finally {
         setLoading(false);
      }
   };

   return (
      <>
         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('settings.account.changePw.form.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 32px'}
            />
         </HeaderWrapper>

         <Input
            width={'100%'}
            margin={'0 0 24px 0'}
            type={'password'}
            value={newPassword}
            placeholder={translate('settings.account.changePw.form.labels.newPassword')}
            onChange={(value) => setNewPassword(value)}
            validators={['password']}
            validate={(passwordValid) => setFormFieldsValidity({ ...formFieldsValidity, passwordValid })}
         />

         <Input
            width={'100%'}
            margin={'0 0 72px 0'}
            type={'password'}
            value={confirmPassword}
            placeholder={translate('settings.account.changePw.form.labels.confirmPassword')}
            onChange={(value) => setConfirmPassword(value)}
            validators={[
               'password',
               {
                  key: 'matchValue',
                  options: { repeat: newPassword },
               },
            ]}
            validate={(confirmPasswordValid) => setFormFieldsValidity({ ...formFieldsValidity, confirmPasswordValid })}
         />

         <Button
            disabled={!isFormValid()}
            isLoading={loading}
            variant={ButtonVariantsType.PURPLE}
            text={translate('common.saveChanges')}
            width={'100%'}
            onClick={updatePassword}
         />
      </>
   );
};

export default ChangePassword;
