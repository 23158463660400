import React, { ReactElement, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { createStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { StyledText } from './index';
import { StyledTextVariantsType } from './StyledText';
import theme from 'src/styles/theme';

const UploadButtonWrapper = styled.div<any>`
   display: flex;
   flex-direction: column;
   width: ${(props) => props.width || 'fit-content'};
   justify-content: center;
   align-items: center;
   margin: ${(props) => props.margin || 0};
   cursor: pointer;
`;

const UploadButtonElement = styled.button<Partial<IProps>>`
   height: 52px;
   width: 100%;
   padding: 0 32px;
   background-color: ${(props) => props.backgroundColor || theme.colors.mainPurple};
   margin: ${(props) => props.margin || 0};
   border-radius: 15px;
   cursor: pointer;
   outline: none;
   border: none;

   input[type='file'] {
      display: none;
   }
`;

const UploadLinearProgress = withStyles(() =>
   createStyles({
      root: {
         height: 10,
         borderRadius: 3,
         width: '100%',
         marginTop: 10,
      },
      colorPrimary: {
         backgroundColor: '#897DFD',
         opacity: 0.8,
      },
      bar: {
         borderRadius: 5,
         backgroundColor: '#6252ff',
      },
   }),
)(LinearProgress);

interface IProps {
   keyValue: string;
   width?: string;
   text?: string;
   color?: string;
   backgroundColor?: string;
   margin?: string;
   onImageChange?: (event: any) => void;
   loading?: boolean;
   transparent?: boolean;
   multiple?: boolean;
}

const UploadButton = (props: IProps): ReactElement => {
   const fileInput = useRef(null);

   const handleFileChange = (e: any) => {
      props.onImageChange && props.onImageChange(e);
   };

   useEffect(() => {
      // @ts-ignore
      if (!props.loading && fileInput?.current?.files) {
         // @ts-ignore
         fileInput.current.files = null;
      }
   }, [props.loading]);

   return (
      <UploadButtonWrapper key={props.keyValue} margin={props.margin} width={props.width}>
         <UploadButtonElement
            disabled={props.loading}
            backgroundColor={props.backgroundColor}
            onClick={() => {
               if (fileInput && fileInput.current) {
                  // @ts-ignore
                  fileInput.current.click();
               }
            }}
         >
            <StyledText
               width={'100%'}
               justifyContent={'center'}
               text={props.text || 'Select file'}
               styles={{ whiteSpace: 'pre' }}
               variant={StyledTextVariantsType.BUTTON_TEXT}
               colorVariant={
                  props.backgroundColor && props.backgroundColor === 'transparent'
                     ? theme.colors.mainPurple
                     : theme.colors.white
               }
               cursor={'pointer'}
            />
            <input ref={fileInput} type="file" onChange={handleFileChange} multiple={!!props.multiple} />
         </UploadButtonElement>
         {/*{props.loading && <UploadLinearProgress />}*/}
      </UploadButtonWrapper>
   );
};

export default UploadButton;
