import React, { ReactElement } from 'react';
import { QRCode } from 'react-qrcode-logo';
import styled from 'styled-components';

const QRCodeWrapper = styled.div<any>`
   display: flex;
   width: ${(props) => props.width || 'auto'};
   height: auto;
   align-items: center;
   justify-content: center;
   margin: ${(props) => props.margin || '0'};
   background-color: #fff;
   padding: 10px;
   position: relative;

   canvas {
      width: 100% !important;
      height: auto !important;
   }

   img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20%;
      height: auto;
   }
`;

interface IProps {
   margin?: string;
   width?: string;
   qrCodeUrl: string;
   logo?: ReactElement;
}

const QRCodeComponent = ({ qrCodeUrl, margin, width, logo }: IProps): ReactElement => {
   return (
      <QRCodeWrapper margin={margin} width={width}>
         {qrCodeUrl && (
            <QRCode
               value={qrCodeUrl}
               logoImage={logo as any | undefined}
               logoWidth={60}
               logoHeight={60}
               quietZone={5}
               size={200}
               qrStyle={'dots'}
            />
         )}
      </QRCodeWrapper>
   );
};

export default QRCodeComponent;
