import React, { ReactElement, useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';

import { Flex } from 'src/components';
import Today from './today/Today';
import Insights from './insights/Insights';
import EnterpriseSettings from './settings/EnterpriseSettings';
import SpacesHomePage from '../spaces/SpacesPreview';

import { RootState } from 'src/app/rootReducer';
import { UserRoles, HRManagementTabEnum } from 'src/system/state/types';
import ApiRequests from './data/ApiRequests';
import { setEnterpriseId, setHRManagementTab } from 'src/system/state/actions';
import { parseError } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';

import { Route, Switch, useHistory } from 'react-router-dom';
import { enterpriseRoutes, insightsRoutes, RoutesEnum } from 'src/routes/routes';

const EnterpriseManagement = (): ReactElement => {
   const history = useHistory();
   const dispatch = useDispatch();
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const selectedHRManagementTab = useSelector((state: RootState) => state.systemReducer).selectedHRManagementTab;
   const customerRole = useSelector((state: RootState) => state.systemReducer).customerRole;
   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;

   const loadUserDetails = async (user: any) => {
      try {
         const response = await ApiRequests.getUserDetails(user.uid);
         if (response.data && response.data.enterprise) {
            localStorage.setItem('enterprise_uuid', response.data.enterprise.owner_uuid); //pass though redux I guess???
            dispatch(setEnterpriseId(response.data.enterprise.enterprise_id));
         } else {
            dispatch(setEnterpriseId(''));
         }
      } catch (err) {
         dispatch(setEnterpriseId(''));
         NotificationsProvider.error(parseError(err) || translate('hrManagement.error.getUserEnterpriseDetails'));
      }
   };

   useEffect(() => {
      if (customerRole !== UserRoles.ENTERPRISE_MANAGER && !enterpriseId) {
         dispatch(setHRManagementTab(HRManagementTabEnum.SETTINGS));
      }

      if (customerRole === UserRoles.ENTERPRISE_MANAGER) {
         history.push({
            pathname: RoutesEnum.ENTERPRISE_TODAY,
         });
         currentUser && void loadUserDetails(currentUser);
      }
      //eslint-disable-next-line
   }, [customerRole, enterpriseId, selectedHRManagementTab, currentUser]);

   return (
      <Flex
         justifyContent="flex-start"
         alignItems="flex-start"
         flexWrap="nowrap"
         width={'100%'}
         mobileMinHeight={'100%'}
         position={'relative'}
         padding={'0 10% 3% 10%'}
      >
         {/*  <Switch>
            {enterpriseRoutes.map((r) => (
               <Route exact key={r.name} path={r.path} component={r.component} />
            ))}
         </Switch> */}

         {/*  {selectedHRManagementTab === HRManagementTabEnum.TODAY && enterpriseId && (
            <Today enterpriseId={enterpriseId} />
         )}

            {selectedHRManagementTab === HRManagementTabEnum.INSIGHTS && enterpriseId && (
            <Insights enterpriseId={enterpriseId} />
         )} 
         {selectedHRManagementTab === HRManagementTabEnum.SETTINGS && (
            <EnterpriseSettings enterpriseId={enterpriseId} customerRole={customerRole} />
         )}

         {selectedHRManagementTab === HRManagementTabEnum.SPACES && <SpacesHomePage containerPadding={'0 0 0 0'} />} */}
      </Flex>
   );
};

export default EnterpriseManagement;
