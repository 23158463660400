export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const SHOW_RESET_PASSWORD_MODAL = 'SHOW_RESET_PASSWORD_MODAL';
export const SHOW_REGISTER_MODAL = 'SHOW_REGISTER_MODAL';
export const SHOW_SIGN_UP_MODAL = 'SHOW_SIGN_UP_MODAL';

export const SET_HR_MANAGEMENT_TAB = 'SET_HR_MANAGEMENT_TAB';
export const SET_SPACE_MANAGEMENT_TAB = 'SET_SPACE_MANAGEMENT_TAB';

export const UPDATE_COWORKING_SPACES_SEARCH_TERM = 'UPDATE_COWORKING_SPACES_SEARCH_TERM';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_CUSTOMER_ROLE = 'SET_CUSTOMER_ROLE';
export const SET_IS_CUSTOMER = 'SET_IS_CUSTOMER';
export const SET_ENTERPRISE_ID = 'SET_ENTERPRISE_ID';
export const SET_SPACE_ID = 'SET_SPACE_ID';
export const SET_HR_MANAGEMENT_USER_PROFILE_ACTIVE_ITEM = 'SET_HR_MANAGEMENT_USER_PROFILE_ACTIVE_ITEM';

export const LOGOUT = 'LOGOUT';

export const DIRECT_SIGN_UP = 'DIRECT_SIGN_UP';

export interface IDispatchProps<T> {
   actions: T;
}

export enum HRManagementTabEnum {
   TODAY = 'today',
   INSIGHTS = 'insights',
   SETTINGS = 'settings',
   SPACES = 'spaces',
}

export enum SpaceManagementTabEnum {
   TODAY = 'today',
   SETTINGS = 'settings',
   INSIGHTS = 'insights',
   YOUR_SPACES = 'yourSpaces',
}

export enum ActiveHRManagementUserProfileItemEnum {
   USER_MANAGEMENT = 'userManagement',
   USER_CHECKINS = 'userCheckins',
   REMOVE_USER = 'removeUser',
}

export enum UserRoles {
   DEFAULT_USER = 'default_user',
   ENTERPRISE_MANAGER = 'enterprise_manager',
   SPACE_MANAGER = 'space_manager',
}

export interface ISystemState {
   token: string;
   customerRole: string;
   isCustomer: boolean;
   enterpriseId: string;
   spaceId: string;
   showLoginModal: boolean;
   showResetPasswordModal: boolean;
   showRegisterModal: boolean;
   showSignUpModal: boolean;
   coworkingSpacesSearchTerm: string;
   selectedHRManagementTab: HRManagementTabEnum;
   selectedSpaceManagementTab: SpaceManagementTabEnum;
   activeHRManagementUserProfileItem: ActiveHRManagementUserProfileItemEnum;
}

export interface IGlobalVariables {
   directSignUp: boolean;
}

interface IUpdateCoworkingSpacesSearchTermAction {
   type: typeof UPDATE_COWORKING_SPACES_SEARCH_TERM;
   payload: string;
}

interface IShowLoginModalAction {
   type: typeof SHOW_LOGIN_MODAL;
   payload: boolean;
}

interface IShowSignUpModalAction {
   type: typeof SHOW_SIGN_UP_MODAL;
   payload: boolean;
}

interface IShowResetPasswordModalAction {
   type: typeof SHOW_RESET_PASSWORD_MODAL;
   payload: boolean;
}

interface IShowRegisterModalAction {
   type: typeof SHOW_REGISTER_MODAL;
   payload: boolean;
}

interface ISetHRManagementTabAction {
   type: typeof SET_HR_MANAGEMENT_TAB;
   payload: HRManagementTabEnum;
}

interface ISetHRManagementUserProfileItemAction {
   type: typeof SET_HR_MANAGEMENT_USER_PROFILE_ACTIVE_ITEM;
   payload: ActiveHRManagementUserProfileItemEnum;
}

interface ISetSpaceManagementTabAction {
   type: typeof SET_SPACE_MANAGEMENT_TAB;
   payload: SpaceManagementTabEnum;
}

interface ISetCustomerRoleAction {
   type: typeof SET_CUSTOMER_ROLE;
   payload: UserRoles;
}

interface ISetIsCustomerAction {
   type: typeof SET_IS_CUSTOMER;
   payload: boolean;
}

interface ISetEnterpriseIdAction {
   type: typeof SET_ENTERPRISE_ID;
   payload: string;
}

interface ISetSpaceIdAction {
   type: typeof SET_SPACE_ID;
   payload: string;
}

interface ISetToken {
   type: typeof SET_TOKEN;
   payload: string;
}

interface ILogout {
   type: typeof LOGOUT;
}

export interface IDirectSignUp {
   type: typeof DIRECT_SIGN_UP;
   loading: boolean;
}

export type DirectSignUpType = IDirectSignUp;

export type SystemActionTypes =
   | IUpdateCoworkingSpacesSearchTermAction
   | IShowLoginModalAction
   | IShowResetPasswordModalAction
   | IShowRegisterModalAction
   | IShowSignUpModalAction
   | ISetHRManagementTabAction
   | ISetHRManagementUserProfileItemAction
   | ISetSpaceManagementTabAction
   | ISetCustomerRoleAction
   | ISetIsCustomerAction
   | ISetEnterpriseIdAction
   | ISetSpaceIdAction
   | ISetToken
   | ILogout;
