import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Flex, ProfileInfo, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import {
   SettingsItemTitle,
   SettingsItemIcon,
   SettingsItemContent,
   SettingsItemArrow,
   SettingsItem,
} from '../shared/SharedComponents';

import { IUser } from '../interfaces';
import { RoutesEnum } from 'src/routes/routes';
import {
   UserEditIcon,
   ChartSuccessIcon,
   ChevronRightElement,
   ShieldTickIcon,
   PurpleArrowLeft,
} from 'src/assets/images';
import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';
import { useLocation } from 'react-router';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { useWindowWide } from 'src/utilities/helpers';

const AccountRoot = (): ReactElement => {
   const goBack = () => {
      history.goBack();
   };

   const location = useLocation();
   const history = useHistory();

   const wide = useWindowWide(768);

   const [user, setUser] = useState<IUser>(location.state.user);

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         minHeight={'100vh'}
         height={'100%'}
         width={'100%'}
         position={'relative'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('common.account')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('common.account')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}

         <Flex
            width={'100%'}
            height={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
            borderRight={`1px solid ${theme.colors.lightGray}`}
         >
            <ProfileInfo
               margin={'0 0 56px 0'}
               name={user?.name as string}
               email={user?.email as string}
               image={user?.photo as string}
            />

            <SettingsItem
               onClick={() => {
                  history.push({
                     pathname: RoutesEnum.BASIC_INFO,
                     state: {
                        user: user,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.account.basicInfo.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.account.basicInfo.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  history.push({
                     pathname: RoutesEnum.PROFILE_PHOTO,
                     state: {
                        user: user,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.account.profilePhoto.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.account.profilePhoto.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  history.push({
                     pathname: RoutesEnum.CHANGE_PASSWORD,
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.account.changePw.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.account.changePw.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  history.push({
                     pathname: RoutesEnum.SOCIAL_MEDIA,
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.account.signInMethods.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.account.signInMethods.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  history.push({
                     pathname: RoutesEnum.CHECKIN_CREDITS,
                  });
               }}
            >
               <SettingsItemIcon>
                  <ChartSuccessIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.checkinCredits.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.checkinCredits.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  history.push({
                     pathname: RoutesEnum.CHECKIN_HISTORY,
                  });
               }}
            >
               <SettingsItemIcon>
                  <ShieldTickIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.checkinHistory.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.checkinHistory.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
         </Flex>
      </Flex>
   );
};

export default AccountRoot;
