import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Flex, Input, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { ButtonVariantsType } from 'src/components/Button';

import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { HRManagementTabEnum } from 'src/system/state/types';
import { RoutesEnum } from 'src/routes/routes';
import { parseError } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import { setHRManagementTab } from 'src/system/state/actions';
import NotificationsProvider from 'src/utilities/notifications-provider';

interface IProps {
   userId: string;
   enterpriseId: string;
}

const RemoveFromMember = ({ userId, enterpriseId }: IProps): ReactElement => {
   const dispatch = useDispatch();
   const history = useHistory();

   const [loading, setLoading] = useState(false);
   const [email, setEmail] = useState('');
   const [formFieldsValidity, setFormFieldsValidity] = useState({
      emailValid: false,
   });

   const isFormValid = (): boolean => {
      return formFieldsValidity.emailValid;
   };

   const clearValues = () => {
      setEmail('');
      setFormFieldsValidity({
         emailValid: false,
      });
   };

   const removeUser = async () => {
      try {
         setLoading(true);
         const response: any = await ApiRequests.removeTeamMember({
            payload: {
               enterprise_id: enterpriseId,
               email: email,
               member_uuid: userId,
            },
         });
         if (response.success) {
            NotificationsProvider.success(translate('hrManagement.settings.userProfile.success.removeTeamMember'));
            dispatch(setHRManagementTab(HRManagementTabEnum.SETTINGS));
            history.push(RoutesEnum.ENTERPRISE_SETTINGS);
         } else {
            clearValues();
            NotificationsProvider.error(
               parseError(response) || translate('hrManagement.settings.userProfile.success.removeTeamMember'),
            );
         }
      } catch (err) {
         clearValues();
         NotificationsProvider.error(
            parseError(err) || translate('hrManagement.settings.userProfile.error.removeTeamMember'),
         );
      } finally {
         setLoading(false);
      }
   };

   return (
      <Flex
         width={'68%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('hrManagement.settings.userProfile.removeTeamMember.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 52px 0'}
         />

         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('hrManagement.settings.userProfile.removeTeamMember.inputLabel')}
            variant={StyledTextVariantsType.PARAGRAPH_2}
            margin={'0 0 30px 0'}
         />

         <Input
            width={'100%'}
            type={'email'}
            margin={'0 0 60px 0'}
            placeholder={translate('placeholders.userEmail')}
            onChange={(value) => setEmail(value)}
            validators={['isEmail', 'required']}
            value={email}
            validate={(emailValid) => setFormFieldsValidity({ ...formFieldsValidity, emailValid })}
         />

         <Button
            disabled={!isFormValid()}
            variant={ButtonVariantsType.PURPLE}
            isLoading={loading}
            text={translate('common.confirm')}
            width={'100%'}
            onClick={removeUser}
         />
      </Flex>
   );
};

export default RemoveFromMember;
