import React, { ReactElement } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Flex, ModalComponent, QRCodeComponent, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';

const Item = styled.div`
   width: 95%;
   height: auto;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   padding-bottom: 16px;
   margin-bottom: 24px;
   background-color: ${theme.colors.white};
   border-bottom: 1px solid ${theme.colors.gray};
`;

interface IProps {
   checkInData: any;
   isOpen: boolean;
   setShowModal: (value: boolean) => void;
   setSelectedCheckin: (value: any) => void;
}

const CheckInDetailsModal = ({ checkInData, setShowModal, isOpen, setSelectedCheckin }: IProps): ReactElement => {
   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'514px'}
         closeModal={() => {
            setShowModal(false);
            setSelectedCheckin(null);
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <Flex
               width={'100%'}
               padding={'24px'}
               backgroundColor={'#F9F9F9'}
               borderRadius={'15px'}
               margin={'0 0 40px 0'}
               flexDirectionMobile={'column'}
            >
               <Flex
                  width={'50%'}
                  flexDirection={'column'}
                  alignItems={'flex-start'}
                  justifyContent={'flex-start'}
                  backgroundColor={'#F9F9F9'}
               >
                  <StyledText
                     text={translate('common.checkedInAt')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     margin={'0 0 4px 0'}
                  />
                  <StyledText
                     wordBreak={'break-all'}
                     text={checkInData?.space_name}
                     variant={StyledTextVariantsType.HEADING_1}
                  />
               </Flex>
               <Flex
                  width={'50%'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  justifyContentMobile={'center'}
                  backgroundColor={'#F9F9F9'}
               >
                  <QRCodeComponent width={'90%'} qrCodeUrl={checkInData?.space_name} />
               </Flex>
            </Flex>
            <Item>
               <StyledText
                  text={translate('checkInDetailsModal.checkinTime')}
                  colorVariant={theme.colors.gray}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  margin={'0 0 8px 0'}
                  marginMobile={'16px 0 8px 0'}
               />
               <StyledText
                  text={moment(checkInData?.checkin_time).utc().format('DD-MM-YYYY HH:mm')}
                  variant={StyledTextVariantsType.HEADING_3}
               />
            </Item>

            <Item>
               <StyledText
                  text={translate('checkInDetailsModal.user')}
                  colorVariant={theme.colors.gray}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  margin={'0 0 8px 0'}
               />
               <StyledText text={checkInData.name} variant={StyledTextVariantsType.HEADING_3} />
            </Item>

            <Item>
               <StyledText
                  text={translate('checkInDetailsModal.checkinId')}
                  colorVariant={theme.colors.gray}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  margin={'0 0 8px 0'}
               />
               <StyledText text={checkInData.checkin_id} variant={StyledTextVariantsType.HEADING_3} />
            </Item>

            <Item>
               <StyledText
                  text={translate('checkInDetailsModal.checkinType')}
                  colorVariant={theme.colors.gray}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  margin={'0 0 8px 0'}
               />
               <StyledText text={checkInData.checkin_type} variant={StyledTextVariantsType.HEADING_3} />
            </Item>

            <Item>
               <StyledText
                  text={translate('checkInDetailsModal.checkinTypeName')}
                  colorVariant={theme.colors.gray}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  margin={'0 0 8px 0'}
               />
               <StyledText text={checkInData.checkin_type_name} variant={StyledTextVariantsType.HEADING_3} />
            </Item>
         </Flex>
      </ModalComponent>
   );
};

export default CheckInDetailsModal;
