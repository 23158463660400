import React, { ReactElement, useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { RoutesEnum } from 'src/routes/routes';
import { Button, Flex, StyledText } from 'src/components';
import { useHistory } from 'react-router-dom';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { ButtonVariantsType } from 'src/components/Button';
import { InlineElementsWrapper } from 'src/components/SharedComponents';

import ActivateVoucherModal from './modals/voucher/ActivateVoucherModal';

import {
   SettingsItemTitle,
   SettingsItemIcon,
   SettingsItemContent,
   SettingsItemArrow,
   SettingsItem,
   SettingsCardItem,
} from '../shared/SharedComponents';

import CancelPlanModalModal from './modals/cancel_plan/CancelPlanModalModal';

import ApiRequests from 'src/features/settings/data/ApiRequests';
import { UserEditIcon, ChevronRightElement, ShieldTickIcon, PurpleArrowLeft } from 'src/assets/images';
import { BillingSettingsItemEnum } from '../Settings';
import { translate } from 'src/translations/translations';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { getFirebase } from 'react-redux-firebase';
import { useLocation } from 'react-router';
import HeaderWrapper from 'src/components/HeaderWrapper';

const BillingRoot = (): ReactElement => {
   const history = useHistory();

   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const wide = useWindowWide(768);

   const goBack = () => {
      history.goBack();
   };

   const [cancelPlanLoading, setCancelPlanLoading] = useState(false);
   const [showCancelModal, setShowCancelModal] = useState(false);
   const [showActivateVoucherModal, setShowActivateVoucherModal] = useState(false);

   const [customerData, setCustomerData] = useState<any>(null);
   const [customerLoading, setCustomerLoading] = useState(false);

   const getCustomer = async () => {
      try {
         setCustomerLoading(true);
         const response: any = await ApiRequests.getCustomer(currentUser?.uid as string);
         setCustomerData(response.data);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.getCustomer'));
      } finally {
         setCustomerLoading(false);
      }
   };

   const cancelPlan = async () => {
      try {
         setCancelPlanLoading(true);
         const response: any = await ApiRequests.cancelPlan(currentUser?.uid as string);

         if (response.success) {
            NotificationsProvider.success(response.data?.message);
            await getCustomer();
         } else {
            NotificationsProvider.error(translate('settings.billing.error.cancelPlan'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('settings.billing.error.cancelPlan'));
      } finally {
         setCancelPlanLoading(false);
         setShowCancelModal(false);
      }
   };

   const renderPurchasePlanListItem = () => (
      <SettingsItem
         onClick={() => {
            history.push({
               pathname: RoutesEnum.PURCHASE_PLAN,
            });
         }}
      >
         <SettingsItemIcon>
            <UserEditIcon />
         </SettingsItemIcon>
         <SettingsItemContent>
            <SettingsItemTitle>
               <StyledText
                  width={'100%'}
                  text={translate('settings.billing.purchasePlan.subtitle')}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
                  colorVariant={theme.colors.gray}
                  margin={'0 0 5px 0'}
               />
               <StyledText
                  width={'100%'}
                  text={translate('settings.billing.purchasePlan.title')}
                  variant={StyledTextVariantsType.HEADING_3}
               />
            </SettingsItemTitle>
            <SettingsItemArrow>
               <ChevronRightElement />
            </SettingsItemArrow>
         </SettingsItemContent>
      </SettingsItem>
   );

   const renderActivePlanListItem = () => (
      <SettingsCardItem>
         <CancelPlanModalModal
            loading={cancelPlanLoading}
            setShowModal={setShowCancelModal}
            isOpen={showCancelModal}
            onCancelClickHandler={cancelPlan}
         />
         <Flex
            backgroundColor={theme.colors.grayBackground}
            flexDirection={'column'}
            width={'100%'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
         >
            <StyledText
               width={'100%'}
               text={translate('settings.billing.activePlan.subtitle')}
               variant={StyledTextVariantsType.PARAGRAPH_3}
               margin={'0 0 5px 0'}
            />
            <StyledText
               width={'100%'}
               margin={'0 0 5px 0'}
               text={customerData?.customer?.subscription?.name}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <InlineElementsWrapper margin={'0 0 24px 0'}>
               <StyledText
                  text={translate('settings.billing.activePlan.renewText')}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
               />
               <StyledText
                  text={moment(customerData?.customer?.subscription?.next_billing_cycle).format('DD/MM/YYYY')}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
                  margin={'0 0 0 5px'}
               />
            </InlineElementsWrapper>
            <Flex
               backgroundColor={theme.colors.grayBackground}
               flexDirection={wide ? 'row' : 'column'}
               justifyContent={'space-between'}
               width={'100%'}
            >
               <Button
                  variant={ButtonVariantsType.WHITE}
                  text={translate('settings.billing.activePlan.changeSubscription.button.text')}
                  width={'48%'}
                  mobileWidth={'100%'}
                  onClick={() => {
                     history.push({
                        pathname: RoutesEnum.PURCHASE_PLAN,
                     });
                  }}
               />
               <Button
                  variant={ButtonVariantsType.RED_BORDER}
                  text={translate('settings.billing.activePlan.cancelSubscription.button.text')}
                  width={'48%'}
                  mobileWidth={'100%'}
                  onClick={() => {
                     setShowCancelModal(true);
                  }}
               />
            </Flex>
         </Flex>
      </SettingsCardItem>
   );

   const renderCancelledPlanListItem = () => (
      <SettingsCardItem>
         <Flex
            backgroundColor={theme.colors.grayBackground}
            flexDirection={'column'}
            width={'100%'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
         >
            <StyledText
               width={'100%'}
               text={translate('settings.billing.activePlan.subtitle')}
               variant={StyledTextVariantsType.PARAGRAPH_3}
               colorVariant={theme.colors.black}
               margin={'0 0 5px 0'}
            />
            <StyledText
               width={'100%'}
               margin={'0 0 5px 0'}
               colorVariant={theme.colors.red}
               text={customerData?.customer?.subscription?.name}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <InlineElementsWrapper margin={'0 0 24px 0'}>
               <StyledText
                  text={translate('settings.billing.cancelledPlan.cancelText')}
                  colorVariant={theme.colors.red}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
               />
               <StyledText
                  text={moment(customerData.customer?.subscription?.ends_at).format('DD/MM/YYYY')}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
                  colorVariant={theme.colors.red}
                  margin={'0 0 0 5px'}
               />
            </InlineElementsWrapper>
            <Flex backgroundColor={theme.colors.grayBackground} justifyContent={'center'} width={'100%'}>
               <Button
                  variant={ButtonVariantsType.WHITE}
                  text={translate('settings.billing.renew.subscription.button.text')}
                  width={'100%'}
                  mobileWidth={'100%'}
                  onClick={() => {
                     history.push({
                        pathname: RoutesEnum.PURCHASE_PLAN,
                     });
                  }}
               />
            </Flex>
         </Flex>
      </SettingsCardItem>
   );

   useEffect(() => {
      void getCustomer();
      //eslint-disable-next-line
   }, []);

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         height={'100%'}
         width={'100%'}
         position={'relative'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('common.billing')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('common.billing')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}

         <ActivateVoucherModal isOpen={showActivateVoucherModal} setShowModal={setShowActivateVoucherModal} />

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            height={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
            borderRight={`1px solid ${theme.colors.lightGray}`}
         >
            {customerLoading && <CircularProgress size={40} />}

            {(!customerData ||
               (customerData && !customerData.is_customer) ||
               (customerData &&
                  customerData?.is_customer &&
                  !customerData?.customer.subscription &&
                  !customerLoading) ||
               (customerData &&
                  customerData?.is_customer &&
                  customerData?.customer.subscription &&
                  customerData?.customer.subscription.stripe_status === null)) &&
               !customerLoading &&
               renderPurchasePlanListItem()}

            {customerData &&
               customerData?.customer &&
               customerData?.customer.subscription?.stripe_status === 'active' &&
               !customerLoading &&
               renderActivePlanListItem()}

            {customerData &&
               customerData?.customer &&
               customerData?.customer.subscription?.stripe_status === 'canceled' &&
               customerData?.customer?.subscription?.ends_at &&
               !customerLoading &&
               renderCancelledPlanListItem()}

            <SettingsItem
               onClick={() => {
                  setShowActivateVoucherModal(true);
               }}
            >
               <SettingsItemIcon>
                  <ShieldTickIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.activateVoucher.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.activateVoucher.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  history.push({
                     pathname: RoutesEnum.MANAGE_CARDS,
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.manageCreditCards.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.manageCreditCards.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  history.push({
                     pathname: RoutesEnum.CHANGE_BILLING_ADDRESS,
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.billingAddress.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.billingAddress.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  history.push({
                     pathname: RoutesEnum.INVOICES,
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.invoices.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.invoices.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
         </Flex>
      </Flex>
   );
};

export default BillingRoot;
