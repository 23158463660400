import React, { ReactElement } from 'react';

import PurchasePlan from './purchase_plan/PurchasePlan';
import CreditCards from './credit_cards/CreditCards';
import BillingAddress from './billing_address/BillingAddress';
import Invoices from './invoices/Invoices';

import { ICompany } from '../../interfaces';

export enum BillingSettingsItemEnum {
   EMPTY = '',
   PURCHASE_PLAN = 'purchasePlan',
   MANAGE_CREDIT_CARDS = 'manageCreditCards',
   BILLING_ADDRESS = 'billingAddress',
   INVOICES = 'invoices',
}

interface IProps {
   company: ICompany;
   getCompanyDetails: () => void;
   activeBillingSettingsItem: BillingSettingsItemEnum;
}

const Billing = ({ activeBillingSettingsItem, company, getCompanyDetails }: IProps): ReactElement | null => {
   switch (activeBillingSettingsItem) {
      case BillingSettingsItemEnum.PURCHASE_PLAN:
         return <PurchasePlan />;
      case BillingSettingsItemEnum.MANAGE_CREDIT_CARDS:
         return <CreditCards />;
      case BillingSettingsItemEnum.BILLING_ADDRESS:
         return <BillingAddress />;
      case BillingSettingsItemEnum.INVOICES:
         return <Invoices />;
      default:
         return null;
   }
};

export default Billing;
