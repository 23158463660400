import React, { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFirebase } from 'react-redux-firebase';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Flex, ModalComponent, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { CheckInDetailsIllustration } from 'src/assets/images';
import { RootState } from 'src/app/rootReducer';

import { showSignUpModal } from 'src/system/state/actions';
import { CustomizableImg } from 'src/components/SharedComponents';

const SignUpModal = (): ReactElement => {
   const dispatch = useDispatch();
   const isOpen = useSelector((state: RootState) => state.systemReducer).showSignUpModal;

   const [loading, setLoading] = useState(true);

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'450px'}
         closeModal={() => {
            dispatch(showSignUpModal(false));
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'} padding={'5% 0'}>
            <StyledText
               text={'We are creating your account.'}
               margin={' 0 16px 0'}
               width={'100%'}
               variant={StyledTextVariantsType.HEADING_1}
            />
            <CustomizableImg src={CheckInDetailsIllustration} height={'300px'} width={'auto'} margin={'30px auto'} />

            <Flex flexDirection={'row'} flexDirectionMobile={'column'} width={'100%'} padding={'5% 0 0 0'}>
               <StyledText
                  text={'Please wait.'}
                  margin={'0 15px 15px 0'}
                  width={'100%'}
                  variant={StyledTextVariantsType.HEADING_1}
               />
               <Flex margin={'5px'}>{loading && <CircularProgress size={40} />}</Flex>
            </Flex>
         </Flex>
      </ModalComponent>
   );
};

export default SignUpModal;
