import React, { ReactElement, useEffect, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

import { Button, CountryDropdown, Input, Spinner, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { RootState } from 'src/app/rootReducer';
import ApiRequests from 'src/features/settings/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';
import { useHistory } from 'react-router';
import { RoutesEnum } from 'src/routes/routes';

const INITIAL_STATE = {
   address: '',
   city: '',
   country: '',
   name: '',
   state: '',
   zip_code: '',
};

const BillingAddress = (): ReactElement => {
   const history = useHistory();
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;
   const isCustomer = useSelector((state: RootState) => state.systemReducer).isCustomer;

   const [loading, setLoading] = useState(true);
   const [updateLoading, setUpdateLoading] = useState(false);

   const [customerData, setCustomerData] = useState(INITIAL_STATE);

   const goBack = () => {
      history.goBack();
   };

   const getCustomer = async () => {
      try {
         setLoading(true);
         const response: any = await ApiRequests.getCustomer(currentUser?.uid as string);

         if (response.data?.customer) {
            setCustomerData(response.data.customer);
            history.push(RoutesEnum.SETTINGS);
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.getCustomer'));
      } finally {
         setLoading(false);
      }
   };

   const updateCustomer = async () => {
      if (!isCustomer) {
         return NotificationsProvider.error(translate('settings.billing.billingAddress.error.notCustomer'));
      }

      try {
         setUpdateLoading(true);
         const response: any = await ApiRequests.updateCustomer({
            payload: {
               uuid: currentUser?.uid,
               email: currentUser?.email as string,
               name: currentUser?.displayName as string,
               address: customerData.address,
               city: customerData.city,
               zipCode: customerData.zip_code,
               country: customerData.country,
            },
         });
         if (response.success) {
            NotificationsProvider.success(response.data.message);
            await getCustomer();
         }
      } catch (error) {
         setCustomerData(INITIAL_STATE);
         NotificationsProvider.error(parseError(error) || translate('common.error.createCustomer'));
      } finally {
         setUpdateLoading(false);
      }
   };

   useEffect(() => {
      if (currentUser && isCustomer) {
         void getCustomer();
      } else {
         setLoading(false);
      }
      //eslint-disable-next-line
   }, [currentUser, isCustomer]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <>
         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('settings.billing.billingAddress.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 32px'}
            />
         </HeaderWrapper>
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 24px 0'}
            placeholder={translate('placeholders.billingAddress')}
            onChange={(address) => setCustomerData({ ...customerData, address })}
            validators={['required']}
            value={customerData.address}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 24px 0'}
            placeholder={translate('placeholders.billingCity')}
            onChange={(city) => setCustomerData({ ...customerData, city })}
            validators={['required']}
            value={customerData.city}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 24px 0'}
            placeholder={translate('placeholders.billingZipCode')}
            onChange={(zipCode) => setCustomerData({ ...customerData, zip_code: zipCode })}
            validators={['required']}
            value={customerData.zip_code}
         />
         <CountryDropdown
            width={'100%'}
            margin={'0 0 72px 0'}
            value={customerData.country}
            setValue={(value) => setCustomerData({ ...customerData, country: value })}
         />
         <Button
            isLoading={updateLoading}
            variant={ButtonVariantsType.PURPLE}
            text={translate('common.saveChanges')}
            width={'100%'}
            onClick={updateCustomer}
         />
      </>
   );
};

export default BillingAddress;
