import React from 'react';
import styled from 'styled-components';

const FrameWrapper = styled.div<IProps>`
   max-width: ${(props) => (props.maxWidth ? props.maxWidth : '90vw')};
   max-height: ${(props) => (props.maxHeight ? props.maxHeight : '90vh')};
   display: flex;
   flex-wrap: wrap;
   flex-direction: column;
   overflow: ${({ overflow }) => (overflow ? overflow : 'hidden')};
   align-items: ${(props) => props.alignment};
   justify-content: ${(props) => props.justify};
   background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'white')};
   border-radius: ${({ radius }) => (radius ? radius + 'px' : '')};
   margin: ${(props) =>
      props.margin ? props.margin.map((item) => (isNaN(Number(item)) ? item : item + 'px')).join(' ') : ''};
   border: ${(props) => props.border || '1px solid #C4C4C4'};
   width: ${({ width }) => (width ? (isNaN(Number(width)) ? width : width + 'px') : '75vh')};
   height: ${({ height }) => (height ? (isNaN(Number(height)) ? height : height + 'px') : 'auto')};
   padding: ${(props) =>
      props.padding ? props.padding.map((item) => (isNaN(Number(item)) ? item : item + 'px')).join(' ') : '100px'};
   box-sizing: border-box;
   z-index: ${(props) => (props.zIndex ? props.zIndex : 'initial')};
   position: ${({ position }) => (position ? position : 'initial')};
   filter: drop-shadow(1px 2px 20px rgba(84, 83, 83, 0.1));

   a {
      cursor: pointer !important;

      span,
      div {
         cursor: pointer !important;
      }
   }

   @media only screen and (max-width: 768px) {
      padding: 5% !important;
      width: 100% !important;

      input[type='text'],
      input[type='email'],
      input[type='password'],
      button {
         width: 100%;
      }
   }
`;

interface IProps {
   classNames?: string;
   backgroundColor?: string;
   maxWidth?: string;
   maxHeight?: string;
   border?: string;
   margin?: Array<string | number>;
   padding?: Array<string | number>;
   width?: string | number;
   height?: string | number;
   radius?: number;
   alignment?: string;
   justify?: string;
   [key: string]: any;
   children: any;
   overflow?: string;
   zIndex?: number;
   position?: string;
}

const Frame = (props: IProps) => {
   return (
      <FrameWrapper className={props.classNames} margin={props.margin} padding={props.padding} {...props}>
         {props.children}
      </FrameWrapper>
   );
};

Frame.defaultProps = {
   margin: [],
   classNames: '',
} as Partial<IProps>;

export default Frame;
