import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';

import { Flex, Spinner, StyledText, Table } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn, TableItemAction } from 'src/components/table/interfaces';
import { BorderedContainer, InlineElementsWrapper } from 'src/components/SharedComponents';

import TodayInfoBox from './TodayInfoBox';
import CheckInDetailsModal from './modals/CheckInDetailsModal';

import { replaceLocation, RoutesEnum } from 'src/routes/routes';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { capitalize, parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { config } from 'src/app/config';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/rootReducer';

const BoxesContainer = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
   }
`;

const Today = (): ReactElement => {
   const location = useLocation();
   const history = useHistory();
   const [loading, setLoading] = useState(true);
   const [statistics, setStatistics] = useState<any>(null);
   const [showCheckInDetailsModal, setShowCheckInDetailsModal] = useState(false);
   const [selectedCheckin, setSelectedCheckin] = useState<any>(null);
   const [vauchersCheckIn, setVauchersCheckIn] = useState<number>(0);

   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;

   const getCompanyStatistics = async () => {
      try {
         setLoading(true);
         console.log(enterpriseId);
         const response = await ApiRequests.getCompanyStatistics(enterpriseId, 'daily');
         const data = response.data;
         let count = 0;
         {
            data.vauchers.map((vaucher: any) => {
               count += vaucher?.checkins_left;
            });
         }
         setVauchersCheckIn(count);
         setStatistics(response.data);
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('hrManagement.settings.error.getCompanyStatistics'),
         );
      } finally {
         setLoading(false);
      }
   };

   const getDaysLeftTillNextInvoice = (invoiceObject: any) => {
      if (!invoiceObject) {
         return 'n/a';
      }

      const nextPayment = invoiceObject.next_payment_attempt * 1000;
      const today = Date.now();

      if (today > nextPayment) {
         return 'Plan canceled';
      }

      return moment(nextPayment).diff(moment(today), 'days');
   };

   useEffect(() => {
      void getCompanyStatistics();
      // eslint-disable-next-line
   }, []);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   const columns: Array<TableColumn<any>> = [
      {
         title: '',
         key: 'image',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => `${data.profile_picture ? config.STORAGE_URL + data.profile_picture : ''}`,
      },
      {
         title: 'User',
         key: 'username',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{data.name}</>,
      },
      {
         title: 'Checkin ID',
         key: 'checkinId',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{data.checkin_id}</>,
      },
      {
         title: 'Checkin type',
         key: 'checkinType',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{capitalize(data.checkin_type)}</>,
      },
      {
         title: 'Checkin time',
         key: 'checkinTime',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{moment(checkin.checkin_time).format('DD/MM/YYYY HH:mm')}</>,
      },
   ];

   const itemActions: Array<TableItemAction<any>> = [
      {
         title: 'View ticket',
         clickHandler: (item: any) => {
            setSelectedCheckin(item);
            setShowCheckInDetailsModal(true);
         },
      },
      {
         title: 'View profile',
         clickHandler: (user: any) => {
            history.push(
               replaceLocation(RoutesEnum.ENTERPRISE_MANAGEMENT_USER_PROFILE, {
                  id: user.uuid,
               }),
            );
         },
      },
   ];

   return (
      <Flex
         flexDirection="column"
         alignItems={'flex-start'}
         justifyContent={'flex-start'}
         width={'100%'}
         padding={'0 10% 3% 10%'}
         mobileMinHeight={'100%'}
      >
         {showCheckInDetailsModal && selectedCheckin && (
            <CheckInDetailsModal
               isOpen={showCheckInDetailsModal}
               setShowModal={setShowCheckInDetailsModal}
               checkInData={selectedCheckin}
               setSelectedCheckin={setSelectedCheckin}
            />
         )}

         <InlineElementsWrapper margin={'0 0 38px 0'} width={'100%'}>
            <StyledText
               justifyContent={'flex-start'}
               text={translate('hrManagement.today.title')}
               variant={StyledTextVariantsType.HEADING_1}
            />

            <StyledText
               margin={'0 0 0 21px'}
               justifyContent={'flex-start'}
               text={statistics?.company_name}
               variant={StyledTextVariantsType.HEADING_1}
               colorVariant={theme.colors.mainPurple}
            />
         </InlineElementsWrapper>

         <StyledText
            text={translate('hrManagement.boxes.title')}
            variant={StyledTextVariantsType.HEADING_3}
            margin={'0 0 24px 0'}
         />

         <BoxesContainer>
            <TodayInfoBox title={translate('hrManagement.today.box1.title')} value={statistics?.checked_in?.length} />
            <TodayInfoBox title={translate('hrManagement.today.box2.title')} value={statistics?.checkins_left} />
            <TodayInfoBox title={translate('hrManagement.today.box3.title')} value={vauchersCheckIn} />

            <TodayInfoBox
               title={translate('hrManagement.today.box4.title')}
               value={`${
                  statistics?.upcoming_invoice !== undefined && statistics?.upcoming_invoice !== null
                     ? getDaysLeftTillNextInvoice(statistics?.upcoming_invoice) + ' days'
                     : 'n/a'
               }`}
            />

            <TodayInfoBox title={translate('hrManagement.today.box5.title')} value={statistics?.new_members?.length} />
         </BoxesContainer>

         <StyledText
            text={translate('hrManagement.checkedIn.table.title')}
            variant={StyledTextVariantsType.HEADING_3}
            margin={'40px 0 24px 0'}
         />

         <InlineElementsWrapper margin={'0 0 38px 0'} width={'100%'}>
            <Row>
               <Col>
                  <BorderedContainer>
                     <Table
                        onSelectAll={() => null}
                        columns={columns}
                        data={statistics?.checked_in}
                        itemActions={itemActions}
                        customClass={'w-100'}
                     />
                  </BorderedContainer>
               </Col>
            </Row>
         </InlineElementsWrapper>
      </Flex>
   );
};

export default Today;
