import React, { ReactElement, useEffect, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import moment from 'moment';
import styled from 'styled-components';

import { Flex, NoDataPlaceholderComponent, Spinner, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { InlineElementsWrapper } from 'src/components/SharedComponents';
import { SettingsItem } from '../../shared/SharedComponents';

import ApiRequests from 'src/features/settings/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { capitalize, parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

export const CheckinHistoryItem = styled.div`
   width: 100%;
   height: auto;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
`;

const CheckinHistory = (): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(true);
   const [checkinOptions, setCheckinOptions] = useState<any>([]);

   const goBack = () => {
      history.back();
   };

   const getCheckinOptions = async () => {
      try {
         const response: any = await ApiRequests.getCheckinOptions(currentUser?.uid as string);

         if (response.success) {
            setCheckinOptions(response.data || []);
         } else {
            NotificationsProvider.error(translate('settings.checkinCredits.error.getOptions'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('settings.checkinCredits.error.getOptions'));
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      currentUser && void getCheckinOptions();
      //eslint-disable-next-line
   }, [currentUser]);

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         height={'100%'}
         width={'100%'}
         position={'relative'}
      >
         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('settingsList.checkinCredits.optionsList.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 32px'}
            />
         </HeaderWrapper>

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            height={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
         >
            {loading ? (
               <Spinner height={'100px'} />
            ) : checkinOptions && checkinOptions.length > 0 ? (
               checkinOptions.map((item: any, index: number) => (
                  <SettingsItem key={index} height={'auto'}>
                     <CheckinHistoryItem>
                        <InlineElementsWrapper margin={'0 0 8px 0'}>
                           <StyledText
                              width={'100%'}
                              text={`${translate('common.validUntil')}: `}
                              variant={StyledTextVariantsType.PARAGRAPH_3}
                              colorVariant={theme.colors.gray}
                              margin={'0 5px 0 0'}
                           />
                           <StyledText
                              width={'100%'}
                              text={
                                 item?.ends_at && item?.ends_at !== 'undefined'
                                    ? moment(item?.ends_at).format('DD.MM.YYYY HH:mm')
                                    : 'n/a'
                              }
                              variant={StyledTextVariantsType.PARAGRAPH_3}
                              colorVariant={theme.colors.gray}
                           />
                        </InlineElementsWrapper>
                        <StyledText
                           width={'100%'}
                           text={`${translate('common.checkinOptionType')}: ${item?.type}`}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                           margin={'0 0 4px 0'}
                        />
                        <StyledText
                           width={'100%'}
                           text={`${translate('common.currentCheckins')}: ${item?.current_checkins}`}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                           margin={'0 0 4px 0'}
                        />
                        <StyledText
                           width={'100%'}
                           text={`${translate('common.maxCheckins')}: ${item?.max_checkins}`}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                           margin={'0 0 4px 0'}
                        />
                        <StyledText
                           width={'100%'}
                           text={`${translate('common.checkinOptionName')}: ${capitalize(item.name)}`}
                           variant={StyledTextVariantsType.HEADING_3}
                        />
                     </CheckinHistoryItem>
                  </SettingsItem>
               ))
            ) : (
               <NoDataPlaceholderComponent />
            )}
         </Flex>
      </Flex>
   );
};

export default CheckinHistory;
