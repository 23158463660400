import styled from 'styled-components';
import theme from 'src/styles/theme';

export const InlineElementsWrapper = styled.div<any>`
   display: inline;
   width: ${(props) => props.width || 'auto'};
   text-align: ${(props) => props.textAlign || 'left'};
   justify-content: ${(props) => props.justifyContent || 'unset'};
   margin: ${(props) => props.margin || 0};
   flex-flow: wrap;
   white-space: ${(props) => props.whiteSpace || 'unset'};

   & > section {
      display: inline-block;
   }

   & > section > div {
      display: inline;
   }

   & > div,
   & > input {
      display: inline;
   }

   & > a {
      display: inline-block;
      cursor: pointer !important;

      & > div {
         cursor: pointer !important;
      }
   }

   @media only screen and (max-width: 768px) {
      width: 100%;
      word-break: break-word;
   }
`;

export const CustomizableImg = styled.img<any>`
   height: ${(props) => props.height || 'auto'};
   width: ${(props) => props.width || '100%'};
   max-height: ${(props) => props.maxHeight || 'unset'};
   object-fit: ${(props) => props.objectFit || 'unset'};
   border-radius: ${(props) => props.borderRadius || 'none'};
   align-self: ${(props) => props.alignSelf || 'flex-start'};
   margin: ${(props) => props.margin || 0};
   position: ${(props) => (props.position ? props.position : 'initial')};
   top: ${(props) => props.top || 'unset'};
   bottom: ${(props) => props.bottom || 'unset'};
   left: ${(props) => props.left || 'unset'};
   right: ${(props) => props.right || 'unset'};
   z-index: ${(props) => props.zIndex || 'initial'};
   transform: ${(props) => props.transform || 'unset'};
   onerror: ${(props) => props.transform || 'unset'};

   @media only screen and (max-width: 768px) {
      width: 100%;
      height: auto;
   }
`;

export const HorizontalSeparator = styled.div<any>`
   height: ${(props) => props.height || '0px'};
   width: ${(props) => props.width || '100%'};
   border-bottom: 1px solid ${theme.colors.lightGray};
`;

export const BorderedContainer = styled.div<any>`
   width: 100%;
   justify-content: center;
   align-items: center;
   padding: 30px;
   border-radius: 15px;
   border: 1px solid ${theme.colors.lightGray};
`;
