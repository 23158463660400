import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useMemo, useState } from 'react';
import { config } from 'src/app/config';
import { Flex, Input, StyledText, Button, CountryDropdown } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import theme from 'src/styles/theme';
import { translate } from 'src/translations/translations';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import styled from 'styled-components';

import firebase from 'firebase';
import { CircularProgress } from '@material-ui/core';
import { signOutFromFirebase } from '../auth/authSlice';
import {
   logout,
   setCustomerRole,
   setDirectSignUp,
   setIsCustomer,
   setToken,
   showSignUpModal,
} from 'src/system/state/actions';
import { useDispatch, useSelector } from 'react-redux';
import SettingsApiCalls from 'src/features/settings/data/ApiRequests';
import { UserRoles } from 'src/system/state/types';
import AuthApiCalls from '../auth/data/ApiRequests';
import ApiRequests from 'src/features/settings/data/ApiRequests';
import { RootState } from 'src/app/rootReducer';
import { IDiscountData } from '../settings/billing/purchase_plan/PurchasePlan';
import PlanDetails from './PlanDetails';
import { Container } from '../settings/shared/SharedComponents';
import { useHistory, useParams } from 'react-router-dom';
import { RoutesEnum } from 'src/routes/routes';

const useOptions = () => {
   const options = useMemo(
      () => ({
         hidePostalCode: true,
         style: {
            base: {
               fontSize: '16px',
               color: '#424770',
               letterSpacing: '0.025em',
               fontFamily: ' Helvetica-Neue-Regular, Helvetica, Arial, sans-serif',
               '::placeholder': {
                  color: '#ABABAB',
               },
               fontWeight: '300',
            },
            invalid: {
               color: '#9e2146',
            },
         },
      }),
      [],
   );

   return options;
};

interface ISignUpParams {
   plan_id: string;
   coupon: string;
}

const Register = () => {
   const wide = useWindowWide(768);
   const [signUpModalOpen, setSignUpModalOpen] = useState(false);
   const [formFieldsValidity, setFormFieldsValidity] = useState({
      nameValid: false,
      emailValid: false,
      passwordValid: false,
   });
   const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<any>(null);
   const [paymentMethods, setPaymentMethods] = useState<any>([]);
   const stripe = useStripe();
   const elements = useElements();

   const [loginError, setLoginError] = useState('');
   const dispatch = useDispatch();
   const isCustomer = useSelector((state: RootState) => state.systemReducer).isCustomer;
   const [coupon, setCoupon] = useState('');
   const [discount, setDiscount] = useState<IDiscountData | null>(null);
   const history = useHistory();
   const params = useParams();

   const signUpParams = params as ISignUpParams;

   const isFormValid = () => {
      return (
         name &&
         email &&
         password &&
         country &&
         city &&
         address &&
         zipCode &&
         formFieldsValidity.emailValid &&
         formFieldsValidity.nameValid &&
         formFieldsValidity.passwordValid
      );
   };

   const handleSubmit = async () => {
      dispatch(showSignUpModal(true));
      setSignUpModalOpen(true);
      dispatch(setDirectSignUp(true));
      if (signUpParams.coupon) {
         setCoupon(signUpParams.coupon);
      }
      await registerUserOnFirebase(name, email, password);
   };

   async function registerUserOnFirebase(name: string, email: string, password: string) {
      try {
         const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);

         if (user) {
            await user.updateProfile({ displayName: name, photoURL: '' });

            await registerOnApi(name, email, user);
         } else {
         }
      } catch (err: any) {
         setLoginError(err.message);
      }
   }
   async function registerOnApi(name: string, email: string, firebaseUser: any) {
      try {
         const response: any = await AuthApiCalls.createUser({
            payload: {
               name: name,
               email: email,
               uuid: firebaseUser.uid,
            },
         });

         if (response.success) {
            await firebaseUser?.sendEmailVerification();
            await loginOnApi(firebaseUser);
         } else {
            await clearAuth();
         }
      } catch (err) {
         await clearAuth();
      }
   }

   async function loginOnApi(user: any) {
      try {
         const response: any = await AuthApiCalls.createToken({
            payload: {
               email: user.email,
               uuid: user.uid,
            },
         });

         if (response.success && response.data) {
            dispatch(setToken(response.data.message));
            await loadCustomerData(user);
         } else {
            await clearAuth();
            NotificationsProvider.error(parseError(response) || translate('common.error.register'));
         }
      } catch (err) {
         await clearAuth();
         NotificationsProvider.error(parseError(err) || translate('common.error.register'));
      }
   }

   async function loadCustomerData(user: any) {
      try {
         const response: any = await SettingsApiCalls.getCustomer(user.uid);
         const customer = response?.data?.customer;

         if (customer) {
            dispatch(setIsCustomer(true));
            dispatch(setCustomerRole(customer.role));
         } else {
            dispatch(setIsCustomer(false));
            dispatch(setCustomerRole(UserRoles.DEFAULT_USER));
         }
      } catch (err) {
         dispatch(setIsCustomer(false));
         dispatch(setCustomerRole(UserRoles.DEFAULT_USER));
         NotificationsProvider.error(parseError(err) || translate('common.error.getCustomer'));
      } finally {
         if (!isCustomer) {
            await createCustomer(user.uid);
            await addPaymentMethod(user.uid);
         } else {
            await addPaymentMethod(user.uid);
         }
      }
   }

   const createCustomer = async (user_uid: any) => {
      try {
         const response: any = await ApiRequests.createCustomer({
            payload: {
               uuid: user_uid,
               name,
               email,
               city,
               country,
               zipCode,
               address,
            },
         });
         if (response.success) {
            dispatch(setIsCustomer(true));
            NotificationsProvider.success(response.data.message);
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.createCustomer'));
      }
   };

   const addPaymentMethod = async (user_uid: any) => {
      try {
         const setupIntentData = await getPaymentMethodIntent(user_uid);

         if (!setupIntentData) {
            NotificationsProvider.error(translate('settings.manageCreditCards.error.getPaymentMethodIntent'));
         }

         if (stripe && elements) {
            const { error } = await stripe.confirmCardSetup(setupIntentData.client_secret, {
               payment_method: {
                  // @ts-ignore
                  card: elements.getElement(CardElement),
               },
            });

            if (error) {
               return NotificationsProvider.error(error?.message || translate('addCreditCard.stripe.error'));
            }

            await ApiRequests.addPaymentMethod({
               payload: {
                  uuid: user_uid,
                  is_default: true,
                  setup_intent: setupIntentData.id,
               },
            });

            await getPaymentMethods(user_uid);
         }
      } catch (error) {
         if (error instanceof Error) {
            return NotificationsProvider.error(error?.message || translate('addCreditCard.error'));
         } else {
            return NotificationsProvider.error(translate('addCreditCard.error'));
         }
      }
   };

   const getPaymentMethodIntent = async (user_uid: any) => {
      try {
         const response: any = await ApiRequests.getPaymentMethodIntent(user_uid as string);
         return response;
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('settings.manageCreditCards.error.getPaymentMethodIntent'),
         );
         return null;
      }
   };

   const filterDefaultPaymentMethod = (paymentMethods: any) => {
      const found = paymentMethods.find((item: any) => item?.is_default);
      setDefaultPaymentMethod(found);
   };
   const getPaymentMethods = async (user_uid: any) => {
      try {
         const response = await ApiRequests.getPaymentMethods(user_uid as string);

         if (!(response as any).success) {
            return NotificationsProvider.error(
               response.data?.message || translate('settings.manageCreditCards.error.getPaymentMethods'),
            );
         }
         const filtered = response.data.payment_methods.filter((item: any) => item?.is_default !== true);
         setPaymentMethods(filtered);
         filterDefaultPaymentMethod(response.data.payment_methods);
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('settings.manageCreditCards.error.getPaymentMethods'),
         );
      } finally {
         subscribeToPlan(user_uid);
      }
   };

   const subscribeToPlan = async (user_uid: string) => {
      try {
         const response: any =
            discount && coupon
               ? await ApiRequests.subscribeToPlan(user_uid, {
                    payload: {
                       plan_id: signUpParams.plan_id,
                       coupon,
                    },
                 })
               : await ApiRequests.subscribeToPlan(user_uid, {
                    payload: {
                       plan_id: signUpParams.plan_id,
                    },
                 });

         if (response.success) {
            clearValues();

            NotificationsProvider.success(response.data.message);
         } else {
            NotificationsProvider.error(parseError(response));
         }
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('settings.billing.purchasePlan.error.subscribeToPlan'),
         );
      } finally {
         dispatch(setDirectSignUp(false));
         history.push(RoutesEnum.MANAGE_CARDS);
      }
   };
   const clearValues = () => {
      setCoupon('');
      setDiscount(null);
   };

   const clearAuth = async () => {
      await signOutFromFirebase();
      dispatch(logout());
   };

   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [country, setCountry] = useState('');
   const [city, setCity] = useState('');
   const [address, setAddress] = useState('');
   const [zipCode, setZipCode] = useState('');

   const options = useOptions();
   const goToTermsOfServicePage = () => {
      window.open(config.TERMS_OF_SERVICE_PAGE, '_blank');
   };

   return (
      <Flex
         flexDirection="row"
         width={'100%'}
         padding={'0 10% 10% 10%'}
         justifyContent={'flex-start'}
         flexDirectionMobile={'column'}
      >
         <Flex flexDirection="column" width={'60%'} borderRight={`1px solid ${theme.colors.lightGray}`}>
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('signUp.header.text')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 30px 0'}
            />
            <Flex flexDirection="column">
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('signUp.basicInformations.text')}
                  variant={StyledTextVariantsType.HEADING_3}
                  margin={'0 0 24px 0'}
               />
               <Input
                  width={'90%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('signUp.basicInformations.name')}
                  validators={['required']}
                  onChange={(value) => setName(value)}
                  value={name}
                  validate={(nameValid) => setFormFieldsValidity({ ...formFieldsValidity, nameValid })}
               />
               <Input
                  width={'90%'}
                  type={'email'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('signUp.basicInformations.email')}
                  validators={['isEmail', 'required']}
                  onChange={(value) => setEmail(value)}
                  value={email}
                  validate={(emailValid) => setFormFieldsValidity({ ...formFieldsValidity, emailValid })}
               />
               <Input
                  width={'90%'}
                  type={'password'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('signUp.basicInformations.password')}
                  validators={['password', 'required']}
                  onChange={(value) => setPassword(value)}
                  value={password}
                  validate={(passwordValid) => setFormFieldsValidity({ ...formFieldsValidity, passwordValid })}
               />
               <StyledText
                  text={loginError}
                  colorVariant={theme.colors.red}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
                  margin={'5px 0 0 10px'}
                  width={'100%'}
               />
            </Flex>

            <Flex flexDirection="column" width={'100%'}>
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('signUp.cardInformation.text')}
                  variant={StyledTextVariantsType.HEADING_3}
                  margin={'0 0 24px 0'}
               />

               <Container>
                  <CardElement options={options} />
               </Container>

               <Flex width={'90%'} widthMobile={'100%'} paddingMobile={'0'}>
                  <CountryDropdown
                     width={'100%'}
                     margin={'0 10px 24px 0'}
                     value={country}
                     setValue={(value) => setCountry(value)}
                  />
                  <Input
                     width={'100%'}
                     type={'text'}
                     margin={'0 0 24px 0'}
                     placeholder={translate('signUp.cardInformation.city')}
                     validators={['required']}
                     onChange={(value) => {
                        setCity(value);
                     }}
                  />
               </Flex>

               <Flex width={'90%'} widthMobile={'100%'} paddingMobile={'0'}>
                  <Input
                     width={'100%'}
                     type={'text'}
                     margin={'0 10px 24px 0'}
                     placeholder={translate('placeholders.billingAddress')}
                     onChange={(value) => setAddress(value)}
                     validators={['required']}
                     value={address}
                  />
                  <Input
                     width={'100%'}
                     type={'text'}
                     margin={'0 0 24px 0'}
                     placeholder={translate('placeholders.billingZipCode')}
                     onChange={(value) => setZipCode(value)}
                     validators={['required']}
                     value={zipCode}
                  />
               </Flex>
               <Flex width={'90%'} flexDirection={'column'}>
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={translate('signUp.subscription.text')}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 10px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={translate('signUp.subscription.info')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 24px 0'}
                  />
               </Flex>
               {wide && (
                  <Flex
                     flexDirection="column"
                     justifyContent="center"
                     alignItems="center"
                     alignItemsMobile={'center'}
                     margin="20px 0"
                     width={'100%'}
                  >
                     <Button
                        variant={ButtonVariantsType.PURPLE}
                        text={translate('signUp.subscription.create.account')}
                        disabled={!isFormValid()}
                        onClick={handleSubmit}
                     />

                     <Button
                        variant={ButtonVariantsType.TRANSPARENT}
                        text={translate('signUp.subscription.terms.conditions')}
                        onClick={goToTermsOfServicePage}
                     />
                  </Flex>
               )}
            </Flex>
         </Flex>
         <PlanDetails
            plan_id={signUpParams.plan_id}
            coupon={signUpParams.coupon}
            setCoupon={setCoupon}
            discount={discount}
            setDiscount={setDiscount}
         />
         {!wide && (
            <Flex
               flexDirection="column"
               justifyContent="center"
               alignItems="center"
               alignItemsMobile={'center'}
               margin="20px 0"
               width={'65%'}
            >
               <CircularProgress size={25} />

               <Button
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('signUp.subscription.create.account')}
                  disabled={!isFormValid()}
                  onClick={handleSubmit}
               />

               <Button
                  variant={ButtonVariantsType.TRANSPARENT}
                  text={translate('signUp.subscription.terms.conditions')}
                  onClick={goToTermsOfServicePage}
               />
            </Flex>
         )}
      </Flex>
   );
};

export default Register;
