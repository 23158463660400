import React, { ReactElement, useState, useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';

import { Button, Flex, ModalComponent, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { ButtonVariantsType } from 'src/components/Button';
import { CustomizableImg, InlineElementsWrapper } from 'src/components/SharedComponents';

import { RegisterVerifyEmailIllustration } from 'src/assets/images';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';

const VerifyEmailModal = (): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [user, setUser] = useState(currentUser);

   const [isLoading, setIsLoading] = useState(false);
   const [isOpen, setIsOpen] = useState(true);

   const onVerifyEmailClick = async () => {
      setIsLoading(true);
      setTimeout(async () => {
         await firebase.reloadAuth();
         user && (await user.reload());
         setUser(firebase.auth()?.currentUser);
         if (user?.emailVerified) {
            setIsOpen(false);
            setIsLoading(false);
         } else {
            NotificationsProvider.error(translate('verifyEmailModal.error.notVerified'));
         }
         setIsLoading(false);
      }, 500);
   };

   const resendVerifyEmail = async () => {
      try {
         setIsLoading(true);
         await firebase.reloadAuth();
         user?.sendEmailVerification();

         NotificationsProvider.success(translate('verifyEmailModal.success.resend'));
      } catch (error: any) {
         NotificationsProvider.error(error.message || translate('verifyEmailModal.error.resend'));
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      user?.sendEmailVerification();
   });

   return (
      <ModalComponent size="medium" visible={isOpen} width={'450px'}>
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <StyledText
               text={translate('verifyEmailModal.title')}
               margin={'0 0 16px 0'}
               width={'100%'}
               variant={StyledTextVariantsType.HEADING_1}
            />
            <StyledText
               text={translate('verifyEmailModal.subtitle')}
               margin={'0 0 32px 0'}
               width={'100%'}
               variant={StyledTextVariantsType.PARAGRAPH_1}
            />
            <CustomizableImg src={RegisterVerifyEmailIllustration} height={'200px'} width={'auto'} margin={'0 auto'} />
            <Button
               isLoading={isLoading}
               variant={ButtonVariantsType.PURPLE}
               text={translate('verifyEmailModal.button.text')}
               margin={'32px 0 32px 0'}
               width={'100%'}
               onClick={onVerifyEmailClick}
            />
            <InlineElementsWrapper>
               <StyledText
                  text={translate('verifyEmailModal.note.text')}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  colorVariant={theme.colors.mainPurple}
               />
               <StyledText
                  text={translate('verifyEmailModal.note.link')}
                  variant={StyledTextVariantsType.LINK_TEXT}
                  colorVariant={theme.colors.mainPurple}
                  onClick={resendVerifyEmail}
                  margin={'0 0 0 5px'}
                  cursor={'pointer'}
               />
            </InlineElementsWrapper>
         </Flex>
      </ModalComponent>
   );
};

export default VerifyEmailModal;
