import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFirebase } from 'react-redux-firebase';
import { useIntercom } from 'react-use-intercom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AppLayout from 'src/layout/AppLayout';

import { ScrollToTop } from 'src/components';
import NotFound from 'src/features/pages/not_found/NotFound';

import { RootState } from './rootReducer';
import { RoutesEnum } from 'src/routes/routes';
import { selectFirebaseAuthLoader } from 'src/features/auth/authSlice';
import AccountRoot from 'src/features/settings/account/AccountRoot';
import BillingRoot from 'src/features/settings/billing/BillingRoot';
import LegalInformationItemsList from 'src/features/settings/legals/LegalsRoot';
import BasicInfo from 'src/features/settings/account/user/BasicInfo';
import ProfilePhoto from 'src/features/settings/account/user/ProfilePhoto';
import ChangePassword from 'src/features/settings/account/user/ChangePassword';
import SocialMedia from 'src/features/settings/account/social_media/SocialMedia';
import CheckinCredits from 'src/features/settings/account/checkin-credits/CheckinCredits';

import PurchasePlan from 'src/features/settings/billing/purchase_plan/PurchasePlan';
import CreditCards from 'src/features/settings/billing/credit_cards/CreditCards';
import BillingAddress from 'src/features/settings/billing/billing_address/BillingAddress';
import Invoices from 'src/features/settings/billing/invoices/Invoices';
import CheckinHistory from 'src/features/settings/account/checkin_history/CheckinHistory';

import SpaceManagementSettings from 'src/features/space_management/settings/SpaceSettings';

import SpaceManagementToday from 'src/features/space_management/today/Today';
import SpaceManagementInsights from 'src/features/space_management/insights/Insights';
import SpaceManagementYourSpaces from 'src/features/space_management/your_spaces/YourSpaces';
import EnterpriseInsights from 'src/features/enterprise_management/insights/Insights';
import EnterpriseToday from 'src/features/enterprise_management/today/Today';
import EnterpriseSettings from 'src/features/enterprise_management/settings/EnterpriseSettings';
import EnterpriseSpaces from 'src/features/spaces/SpacesPreview';
const App = () => {
   const firebase = getFirebase();
   const { update } = useIntercom();

   const currentUser = firebase.auth()?.currentUser;
   const firebaseAuthLoaded = useSelector(selectFirebaseAuthLoader);
   const token = useSelector((state: RootState) => state.systemReducer).token;

   useEffect(() => {
      if (firebaseAuthLoaded) {
         if (currentUser && token) {
            update({
               name: currentUser?.displayName as string,
               userId: currentUser.uid as string,
               email: currentUser?.email as string,
            });
         }
      }

      //eslint-disable-next-line
   }, [firebaseAuthLoaded, currentUser, token]);

   return (
      <BrowserRouter>
         <ScrollToTop />
         <Switch>
            <Route exact path={RoutesEnum.ACCEPT_INVITE} component={AppLayout} />
            <Route exact path={RoutesEnum.HOME} component={AppLayout} />
            <Route exact path={RoutesEnum.SPACE_DETAILS} component={AppLayout} />
            <Route exact path={RoutesEnum.MAP_VIEW} component={AppLayout} />
            <Route exact path={RoutesEnum.RESERVE_MEETING_ROOM} component={AppLayout} />
            <Route exact path={RoutesEnum.SIGN_UP} component={AppLayout} />
            <Route
               path={RoutesEnum.SETTINGS}
               render={({ match: { url } }) => (
                  <>
                     <Route path={`${url + RoutesEnum.ACCOUNT}`} component={AccountRoot} />
                     <Route path={`${url + RoutesEnum.BILLING_INFO}`} component={BillingRoot} />
                     <Route path={`${url + RoutesEnum.LEGAL_INFO}`} component={LegalInformationItemsList} />
                  </>
               )}
               component={AppLayout}
            />
            <Route
               path={RoutesEnum.ACCOUNT}
               render={({ match: { url } }) => (
                  <>
                     <Route path={`${url + RoutesEnum.BASIC_INFO}`} component={BasicInfo} />
                     <Route path={`${url + RoutesEnum.PROFILE_PHOTO}`} component={ProfilePhoto} />
                     <Route path={`${url + RoutesEnum.CHANGE_PASSWORD}`} component={ChangePassword} />
                     <Route path={`${url + RoutesEnum.SOCIAL_MEDIA}`} component={SocialMedia} />
                     <Route path={`${url + RoutesEnum.CHECKIN_CREDITS}`} component={CheckinCredits} />
                     <Route path={`${url + RoutesEnum.CHECKIN_HISTORY}`} component={CheckinHistory} />
                  </>
               )}
               component={AppLayout}
            />
            <Route
               path={RoutesEnum.BILLING_INFO}
               render={({ match: { url } }) => (
                  <>
                     <Route path={`${url + RoutesEnum.PURCHASE_PLAN}`} component={PurchasePlan} />
                     <Route path={`${url + RoutesEnum.MANAGE_CARDS}`} component={CreditCards} />
                     <Route path={`${url + RoutesEnum.CHANGE_BILLING_ADDRESS}`} component={BillingAddress} />
                     <Route path={`${url + RoutesEnum.INVOICES}`} component={Invoices} />
                  </>
               )}
               component={AppLayout}
            />

            <Route
               path={RoutesEnum.ENTERPRISE_MANAGEMENT}
               render={({ match: { url } }) => (
                  <>
                     <Route path={`${url + RoutesEnum.ENTERPRISE_INSIGHTS}`} component={EnterpriseInsights} />
                     <Route path={`${url + RoutesEnum.ENTERPRISE_TODAY}`} component={EnterpriseToday} />
                     <Route path={`${url + RoutesEnum.ENTERPRISE_SETTINGS}`} component={EnterpriseSettings} />
                     <Route path={`${url + RoutesEnum.ENTERPRISE_SPACES}`} component={EnterpriseSpaces} />
                  </>
               )}
               component={AppLayout}
            />

            <Route
               path={RoutesEnum.SPACE_MANAGEMENT}
               render={({ match: { url } }) => (
                  <>
                     <Route
                        exact
                        path={`${url + RoutesEnum.SPACE_MANAGEMENT_SETTINGS} `}
                        component={SpaceManagementSettings}
                     />
                     <Route
                        exact
                        path={`${url + RoutesEnum.SPACE_MANAGEMENT_TODAY} `}
                        component={SpaceManagementToday}
                     />
                     <Route
                        exact
                        path={`${url + RoutesEnum.SPACE_MANAGEMENT_INSIGHTS} `}
                        component={SpaceManagementInsights}
                     />
                     <Route
                        exact
                        path={`${url + RoutesEnum.SPACE_MANAGEMENT_YOUR_SPACES} `}
                        component={SpaceManagementYourSpaces}
                     />
                  </>
               )}
               component={AppLayout}
            />

            <Route path={'*'} component={NotFound} />
         </Switch>
      </BrowserRouter>
   );
};

export default App;
