import React, { ReactElement, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import styled from 'styled-components';

import { config } from 'src/app/config';

interface IProps {
   width?: string;
   margin?: string;
   defaultValue?: string;
   onPlaceSelectHandler: (value: any) => void;
}

const Container = styled.div<any>`
   display: flex;
   justify-content: flex-start;
   align-items: center;
   height: auto;
   width: ${({ width }: IProps) => width || '100%'};
   margin: ${({ margin }: IProps) => margin || 0};

   input {
      height: 54px;
      width: 100%;
      border: 1px solid #ababab;
      color: #000000;
      border-radius: 15px;
      padding: 0 20px;
      font-size: 16px;
      font-weight: 400;
      outline: none;
   }
`;

const GoogleAdressAutocomplete = ({ onPlaceSelectHandler, width, margin, defaultValue }: IProps): ReactElement => {
   const [value, setValue] = useState<any>(defaultValue || '');

   return (
      <Container width={width} margin={margin}>
         <Autocomplete
            apiKey={config.GOOGLE_MAPS_API_KEY}
            onPlaceSelected={(value: any) => {
               onPlaceSelectHandler(value);
            }}
            defaultValue={value}
            options={{
               types: ['geocode', 'establishment'],
               fields: ['address_components', 'geometry.location'],
            }}
         />
      </Container>
   );
};

export default GoogleAdressAutocomplete;
