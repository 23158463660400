import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Flex } from 'src/components';

import InsightsRoot from './InsightsRoot';
import SpaceOverview from './space_overview/SpaceOverview';
import CheckinsInfo from './checkins_info/CheckinsInfo';
import EarningsInfo from './earnings_info/EarningsInfo';

import { RootState } from 'src/app/rootReducer';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RoutesEnum, spaceManagementInsightsRoutes } from 'src/routes/routes';

export enum ActiveItemEnum {
   SPACE_OVERVIEW = 'spaceOverview',
   CHECKINS_INFO = 'checkinsInfo',
   EARNINGS_INFO = 'earningsInfo',
}

const Insights = (): ReactElement => {
   const spaceId = useSelector((state: RootState) => state.systemReducer).spaceId;

   const [activeItem, setActiveItem] = useState<ActiveItemEnum>(ActiveItemEnum.SPACE_OVERVIEW);

   const isItemActive = (itemToCheck: ActiveItemEnum) => {
      return itemToCheck === activeItem;
   };
   const [isHidden, setIsHidden] = useState(false);

   const routeMatch = useRouteMatch<RoutesEnum>([
      RoutesEnum.SPACE_MANAGEMENT_SPACE_OVERVIEW,
      RoutesEnum.SPACE_MANAGEMENT_CHECKINS,
      RoutesEnum.SPACE_MANAGEMENT_EARNINGS,
   ]);

   useEffect(() => {
      if (routeMatch?.isExact) {
         setIsHidden(true);
      }
   }, []);

   return (
      <Flex
         justifyContent="flex-start"
         alignItems="stretch"
         flexWrap="nowrap"
         flex={1}
         minHeight={'100%'}
         position={'relative'}
         width={'100%'}
         flexDirectionMobile={'column'}
         padding={'0 10% 3% 10%'}
      >
         <Flex
            width={'32%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
            hide={isHidden}
         >
            <InsightsRoot setActiveItem={setActiveItem} isItemActive={isItemActive} />
         </Flex>
         <Flex
            width={'66%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
         >
            <Switch>
               {spaceManagementInsightsRoutes.map((r) => (
                  <Route exact key={r.name} path={r.path} component={r.component} />
               ))}
            </Switch>
         </Flex>

         {/*  {activeItem === ActiveItemEnum.SPACE_OVERVIEW && <SpaceOverview spaceId={spaceId} />}
         {activeItem === ActiveItemEnum.CHECKINS_INFO && <CheckinsInfo spaceId={spaceId} />}
         {activeItem === ActiveItemEnum.EARNINGS_INFO && <EarningsInfo spaceId={spaceId} />} */}
      </Flex>
   );
};

export default Insights;
