import { pipe } from './pipe';
import { isEmail } from '../helpers';

export enum IValidatorErrorCode {
   MIN_NOT_REACHED = 'MIN_NOT_REACHED',
   MAX_EXCEEDED = 'MAX_EXCEEDED',
   INVALID_EMAIL = 'INVALID_EMAIL',
   NO_LOWERCASE = 'NO_LOWERCASE',
   NO_UPPERCASE = 'NO_UPPERCASE',
   NO_MATCH = 'NO_MATCH',
   NO_NUMBER = 'NO_NUMBER',
   REQUIRED = 'REQUIRED',
}

export const passwordValidationErrors = [
   IValidatorErrorCode.MIN_NOT_REACHED,
   IValidatorErrorCode.NO_LOWERCASE,
   IValidatorErrorCode.NO_NUMBER,
   IValidatorErrorCode.NO_UPPERCASE,
];

export interface IValidatorOptions {
   // eslint-disable-next-line
    value?: any;
   min?: number;
   max?: number;
   limit?: number;
   repeat?: string;
}

export interface IValidationResult {
   value: string;
   error:
      | { key: IValidatorErrorCode; options?: IValidatorOptions }
      | Array<{ key: IValidatorErrorCode; options?: IValidatorOptions }>;
}

export type IValidationFunction = (options: IValidatorOptions) => IValidationResult;

export type IValidationKey =
   | 'range'
   | 'isEmail'
   | 'lowerCase'
   | 'upperCase'
   | 'hasLowercase'
   | 'hasUppercase'
   | 'hasNumber'
   | 'matchValue'
   | 'removeSpaces'
   | 'password'
   | 'keepOnlyLetters'
   | 'keepOnlyNumbers'
   | 'required';

// eslint-disable-next-line
export const validators: { [key in IValidationKey]: IValidationFunction } = {
   // eslint-disable-next-line
    range: ({ value, min = 0, max = Infinity }): any => {
      if (value.length < min) {
         return {
            value,
            error: { key: IValidatorErrorCode.MIN_NOT_REACHED, options: { min } },
         };
      }
      if (value.length > max) {
         return {
            value,
            error: { key: IValidatorErrorCode.MAX_EXCEEDED, options: { max } },
         };
      }
      return { value, error: null };
   },
   // eslint-disable-next-line
    isEmail: ({ value }): any => {
      return {
         value,
         error: isEmail(value) ? null : { key: IValidatorErrorCode.INVALID_EMAIL },
      };
   },

   // eslint-disable-next-line
    hasLowercase: ({ value }): any => {
      return {
         value,
         error: /(?=.*[a-z])/.test(value) ? null : { key: IValidatorErrorCode.NO_LOWERCASE },
      };
   },

   // eslint-disable-next-line
    matchValue: ({ value, repeat }): any => {
      return {
         value,
         error: repeat === value ? null : { key: IValidatorErrorCode.NO_MATCH },
      };
   },

   // eslint-disable-next-line
    hasUppercase: ({ value }): any => {
      return {
         value,
         error: /(?=.*[A-Z])/.test(value) ? null : { key: IValidatorErrorCode.NO_UPPERCASE },
      };
   },

   // eslint-disable-next-line
    hasNumber: ({ value }): any => {
      return {
         value,
         error: /(?=.*\d)/.test(value) ? null : { key: IValidatorErrorCode.NO_NUMBER },
      };
   },

   // eslint-disable-next-line
    lowerCase: ({ value }): any => {
      return { error: null, value: value.toLowerCase() };
   },

   // eslint-disable-next-line
    upperCase: ({ value }): any => {
      return { error: null, value: value.toUpperCase() };
   },

   // eslint-disable-next-line
    removeSpaces: ({ value }): any => {
      return { error: null, value: value.replace(/\s/g, '') };
   },

   password: ({ value }) => {
      const pipeResult = pipe(value, [
         'hasLowercase',
         'hasUppercase',
         'hasNumber',
         { key: 'range', options: { min: 8 } },
      ]);
      return { value: pipeResult.value, error: pipeResult.errors };
   },

   // eslint-disable-next-line
    keepOnlyNumbers: ({ value }): any => {
      return { error: null, value: value.replace(/\D/gi, '') };
   },

   // eslint-disable-next-line
    keepOnlyLetters: ({ value }): any => {
      return { error: null, value: value.replace(/[^A-Za-z]/gi, '') };
   },

   required: ({ value }): any => {
      return {
         value,
         error: value ? null : { key: IValidatorErrorCode.REQUIRED },
      };
   },
};

export { pipe };
