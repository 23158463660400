import React, { ReactElement, useState } from 'react';

import { Button, Flex, Input, ModalComponent, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';

interface IProps {
   isOpen: boolean;
   loading: boolean;
   setShowModal: (value: boolean) => void;
   inviteEmail: string;
   setInviteEmail: (email: string) => void;
   onInviteToEnterpriseHandler: (email: string) => void;
}

const InviteToEnterpriseModal = ({
   loading,
   setShowModal,
   isOpen,
   inviteEmail,
   setInviteEmail,
   onInviteToEnterpriseHandler,
}: IProps): ReactElement => {
   const [formFieldsValidity, setFormFieldsValidity] = useState({
      emailValid: false,
   });

   const clearValues = () => {
      setInviteEmail('');
      setFormFieldsValidity({
         emailValid: false,
      });
   };

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'414px'}
         closeModal={() => {
            clearValues();
            setShowModal(false);
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <StyledText
               text={translate('hrManagement.settings.manageYourTeam.addTeamMemberModal.title')}
               margin={'0 0 19px 0'}
               width={'100%'}
               variant={StyledTextVariantsType.HEADING_1}
            />

            <StyledText
               text={translate('hrManagement.settings.manageYourTeam.addTeamMemberModal.text')}
               margin={'0 0 24px 0'}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               colorVariant={theme.colors.gray}
            />

            <Input
               width={'100%'}
               type={'email'}
               margin={'0 0 95px 0'}
               placeholder={translate('placeholders.email')}
               onChange={(value) => setInviteEmail(value)}
               validators={['isEmail', 'required']}
               value={inviteEmail}
               validate={(emailValid) => setFormFieldsValidity({ ...formFieldsValidity, emailValid })}
            />

            <Button
               isLoading={loading}
               disabled={!formFieldsValidity.emailValid}
               variant={ButtonVariantsType.PURPLE}
               text={translate('common.confirm')}
               width={'100%'}
               onClick={() => {
                  onInviteToEnterpriseHandler(inviteEmail);
               }}
            />
         </Flex>
      </ModalComponent>
   );
};

export default InviteToEnterpriseModal;
