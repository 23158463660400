import React, { ReactElement } from 'react';
import styled from 'styled-components';

import theme from 'src/styles/theme';

export enum StyledTextVariantsType {
   HEADING_1 = 'heading1',
   HEADING_2 = 'heading2',
   HEADING_3 = 'heading3',
   PARAGRAPH_1 = 'paragraph1',
   PARAGRAPH_2 = 'paragraph2',

   PARAGRAPH_3 = 'paragraph3',
   BUTTON_TEXT = 'buttonText',
   LABEL_TEXT = 'labelText',
   LABEL_TEXT_2 = 'labelText2',
   LINK_TEXT = 'linkText',
   NOTE_TEXT = 'noteText',
}

interface IProps {
   text?: string;
   fontWeight?: string;
   variant: StyledTextVariantsType;
   colorVariant?: string;
   justifyContent?: 'center' | 'flex-start' | 'flex-end';
   justifyContentMobile?: 'center' | 'flex-start' | 'flex-end';
   styles?: {};
   width?: string;
   margin?: string;
   marginMobile?: string;
   zIndex?: number;
   overflow?: string;
   onClick?: (e: any) => void;
   classNames?: string;
   whiteSpace?: string;
   wordBreak?: string;
   cursor?: string;
   textDecoration?: string;
   uppercase?: boolean;
   icon?: ReactElement;
}

const styles = {
   heading1: {
      fontFamily: theme.typography.bold,
      fontSize: 40,
   },

   heading2: {
      fontFamily: theme.typography.bold,
      fontSize: 24,
   },
   heading3: {
      fontFamily: theme.typography.bold,
      fontSize: 16,
   },

   paragraph1: {
      fontFamily: theme.typography.regular,
      fontSize: 24,
   },

   paragraph2: {
      fontFamily: theme.typography.regular,
      fontSize: 16,
   },
   paragraph3: {
      fontFamily: theme.typography.regular,
      fontSize: 12,
   },

   buttonText: {
      fontFamily: theme.typography.regular,
      fontSize: 16,
   },

   labelText: {
      fontFamily: theme.typography.medium,
      fontSize: 14,
   },

   labelText2: {
      fontFamily: theme.typography.medium,
      fontSize: 20,
   },

   linkText: {
      fontFamily: theme.typography.bold,
      fontSize: 16,
   },

   noteText: {
      fontFamily: theme.typography.light,
      fontSize: 12,
   },
};

const getTextAlign = (justifyContent: string) => {
   return justifyContent === 'center'
      ? 'center'
      : justifyContent === 'flex-start'
      ? 'left'
      : justifyContent === 'flex-end'
      ? 'right'
      : 'left';
};

const StyledTextWrapper = styled.div<IProps>`
   display: flex;
   align-items: center;
   justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'flex-start')};
   text-align: ${(props) => (props.justifyContent ? getTextAlign(props.justifyContent) : 'left')};
   text-decoration: ${(props) => (props.textDecoration ? props.textDecoration : 'unset')};
   width: ${({ width }) => (width ? width : 'auto')};
   height: auto;
   font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 'regular')};
   line-height: normal;
   overflow: ${({ overflow }) => (overflow ? overflow : 'initial')};
   margin: ${({ margin }) => (margin ? margin : 0)};
   white-space: ${({ whiteSpace }) => (whiteSpace ? whiteSpace : 'unset')};
   word-break: ${({ wordBreak }) => (wordBreak ? wordBreak : 'break-word')};
   z-index: ${(props) => (props.zIndex ? props.zIndex : 'initial')};
   text-transform: ${({ uppercase }: IProps) => (uppercase ? 'uppercase' : '')};
   cursor: ${({ variant, cursor }) => (variant.toLowerCase() === 'linkText' ? 'pointer' : cursor ? cursor : 'initial')};
   color: ${({ variant, colorVariant }) =>
      variant.toLowerCase() === 'linkText'
         ? colorVariant
            ? colorVariant
            : theme.colors.green
         : colorVariant
         ? colorVariant
         : theme.colors.black};

   @media only screen and (max-width: 768px) {
      text-align: ${(props) => (props.justifyContentMobile ? getTextAlign(props.justifyContentMobile) : 'left')};
      justify-content: ${(props) => (props.justifyContentMobile ? props.justifyContentMobile : 'flex-start')};
      margin: ${({ margin, marginMobile }) => (marginMobile ? marginMobile : margin)};
   }
`;

const StyledText = (props: IProps) => {
   return (
      <StyledTextWrapper
         {...props}
         className={`${props.classNames || ''}`}
         onClick={props.onClick}
         style={{ ...styles[props.variant], ...props.styles } as any}
      >
         {props.text}
         {props.icon && <img src={props.icon as any} style={{ marginLeft: 5 }} alt="text-icon" />}
      </StyledTextWrapper>
   );
};

StyledText.defaultProps = {
   text: 'text',
   variant: StyledTextVariantsType.PARAGRAPH_2,
   justifyContent: 'flex-start',
   opacity: 1,
   width: 'auto',
   overflow: 'initial',
   styles: {},
} as Partial<IProps>;

export default StyledText;
