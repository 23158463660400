import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
   TodayTabIcon,
   InsightsTabIcon,
   SpacesTabIcon,
   SettingsTabIcon,
   ArrowLeft,
   TodayTabIconPurple,
   InsightsTabIconPurple,
   SettingsTabIconPurple,
   SpacesTabIconPurple,
} from 'src/assets/images';
import { UserRoles } from 'src/system/state/types';
import { enterpriseManagementRoutes, enterpriseRoutes, RoutesEnum } from 'src/routes/routes';
import { RootState } from 'src/app/rootReducer';
import { ActiveHRManagementUserProfileItemEnum, HRManagementTabEnum } from 'src/system/state/types';
import { translate } from 'src/translations/translations';
import {
   setActiveHRManagementUserProfileItem,
   setEnterpriseId,
   setHRManagementTab,
   updateCoworkingSpacesSearchTerm,
} from 'src/system/state/actions';

import theme from 'src/styles/theme';
import { Input } from '../components';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import { getFirebase } from 'react-redux-firebase';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import NotificationsProvider from 'src/utilities/notifications-provider';

const SubHeaderWrapper = styled.div`
   width: 100%;

   .sub-header {
      display: flex;
      align-items: center;
      padding: 50px 10% 54px 10%;

      > div {
         display: flex;
         justify-content: flex-start;
         align-items: center;
         margin-right: 36px;
         text-decoration: none;
         cursor: pointer;
         padding: 12px;
         font-weight: 500;
         font-size: 16px;
         color: ${theme.colors.gray};

         &.active {
            border-bottom: 2px solid ${theme.colors.black};
            color: ${theme.colors.black};

            > svg {
               stroke: ${theme.colors.black};
            }

            svg > path {
               stroke: ${theme.colors.black};
            }

            &.no-border {
               border: none !important;
            }
         }

         &.disabled {
            border-bottom: none;
            color: ${theme.colors.lightGray};
            opacity: 0.8;
            cursor: not-allowed;

            svg {
               stroke: ${theme.colors.lightGray};
            }

            svg > path {
               stroke: ${theme.colors.lightGray};
            }
         }

         svg {
            margin-right: 10px;
         }
      }

      @media only screen and (max-width: 768px) {
         padding: 5% !important;
         flex-direction: column;

         > div {
            width: 100%;
            margin: 0;
         }
      }
   }
`;

const SubHeaderWrapperMobile = styled.div`
   width: 100%;

   position: fixed;
   box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
   height: 100px;
   background-color: white;
   bottom: 0px;
   z-index: 100;
   display: flex;
   justify-content: 'center';

   .sub-header {
      bottom: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 1% !important;
      flex-direction: row;

      a {
         display: flex;
         justify-content: center;
         margin-right: 36px;
         text-decoration: none;
         cursor: pointer;
         padding: 12px;
         font-weight: 500;
         font-size: 16px;
         color: ${theme.colors.gray};

         &.active {
            color: ${theme.colors.black};

            > svg {
               stroke: ${theme.colors.black};
            }

            svg > path {
               stroke: ${theme.colors.black};
            }

            &.no-border {
               border: none !important;
            }
         }

         img {
            margin-right: 10px;
         }
      }

      > a {
         width: 100% !important;
         margin: 0;
      }

      input {
         margin-top: 40px;
      }
   }
`;

const EnterpriseManagementLayoutSubHeader: React.FunctionComponent<unknown> = () => {
   const history = useHistory();
   const dispatch = useDispatch();

   const wide = useWindowWide(768);

   const coworkingSpacesSearchTerm = useSelector((state: RootState) => state.systemReducer).coworkingSpacesSearchTerm;
   const selectedHRManagementTab = useSelector((state: RootState) => state.systemReducer).selectedHRManagementTab;
   const customerRole = useSelector((state: RootState) => state.systemReducer).customerRole;
   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;
   const activeHRManagementUserProfileItem = useSelector(
      (state: RootState) => state.systemReducer,
   ).activeHRManagementUserProfileItem;

   const isTabActive = (tabToCheck: HRManagementTabEnum) => {
      return selectedHRManagementTab === tabToCheck;
   };

   const setSelectedTab = (tab: HRManagementTabEnum) => {
      dispatch(setHRManagementTab(tab));
   };

   const disableTabs = () => customerRole !== UserRoles.ENTERPRISE_MANAGER && !enterpriseId;

   const isUserProfileRoute = () => {
      const pathname = history.location.pathname;
      return pathname.includes('/hr-management/user/');
   };

   const isSpaceDetailsRoute = () => {
      const pathname = history.location.pathname;

      return pathname.includes('/hr-management/space/') || pathname.includes('/co-working-space');
   };

   const renderSubheaderContent = () => {
      if (isUserProfileRoute()) {
         return (
            <div
               className={'active no-border'}
               onClick={() => {
                  if (activeHRManagementUserProfileItem === ActiveHRManagementUserProfileItemEnum.USER_MANAGEMENT) {
                     return history.push(RoutesEnum.ENTERPRISE_TODAY);
                  }

                  dispatch(setActiveHRManagementUserProfileItem(ActiveHRManagementUserProfileItemEnum.USER_MANAGEMENT));
               }}
            >
               <ArrowLeft />
               {translate('common.back')}
            </div>
         );
      } else {
         return (
            <>
               <div
                  className={disableTabs() ? 'disabled' : isTabActive(HRManagementTabEnum.TODAY) ? 'active' : ''}
                  onClick={() => {
                     if (!disableTabs()) {
                        setSelectedTab(HRManagementTabEnum.TODAY);
                        history.push({
                           pathname: RoutesEnum.ENTERPRISE_TODAY,
                        });
                     }
                  }}
               >
                  {isTabActive(HRManagementTabEnum.TODAY) ? <TodayTabIconPurple /> : <TodayTabIcon />}
                  {wide && translate('hrManagement.tabs.today')}
               </div>

               <div
                  className={disableTabs() ? 'disabled' : isTabActive(HRManagementTabEnum.INSIGHTS) ? 'active' : ''}
                  onClick={() => {
                     if (!disableTabs()) {
                        setSelectedTab(HRManagementTabEnum.INSIGHTS);
                        history.push({
                           pathname: RoutesEnum.ENTERPRISE_INSIGHTS,
                        });
                     }
                  }}
               >
                  {isTabActive(HRManagementTabEnum.INSIGHTS) ? <InsightsTabIconPurple /> : <InsightsTabIcon />}
                  {wide && translate('hrManagement.tabs.insights')}
               </div>

               <div
                  className={isTabActive(HRManagementTabEnum.SETTINGS) ? 'active' : ''}
                  onClick={() => {
                     setSelectedTab(HRManagementTabEnum.SETTINGS);
                     history.push({
                        pathname: RoutesEnum.ENTERPRISE_SETTINGS,
                     });
                  }}
               >
                  {isTabActive(HRManagementTabEnum.SETTINGS) ? <SettingsTabIconPurple /> : <SettingsTabIcon />}
                  {wide && translate('hrManagement.tabs.settings')}
               </div>

               <div
                  className={disableTabs() ? 'disabled' : isTabActive(HRManagementTabEnum.SPACES) ? 'active' : ''}
                  onClick={() => {
                     if (!disableTabs()) {
                        setSelectedTab(HRManagementTabEnum.SPACES);
                        history.push({
                           pathname: RoutesEnum.ENTERPRISE_SPACES,
                        });
                     }
                  }}
               >
                  {isTabActive(HRManagementTabEnum.SPACES) ? <SpacesTabIconPurple /> : <SpacesTabIcon />}
                  {wide && translate('hrManagement.tabs.spaces')}
               </div>
               {!isSpaceDetailsRoute() && wide && (
                  <Input
                     height={'42px'}
                     width={'40%'}
                     type={'text'}
                     margin={'10px 10px 0 auto !important'}
                     placeholder={translate('placeholders.searchCoworkingSpaces')}
                     onChange={(value) => {
                        dispatch(updateCoworkingSpacesSearchTerm(value));
                     }}
                     value={coworkingSpacesSearchTerm}
                  />
               )}
            </>
         );
      }
   };

   return (
      <>
         {wide ? (
            <SubHeaderWrapper>
               <div className={'sub-header'}>{renderSubheaderContent()}</div>
            </SubHeaderWrapper>
         ) : (
            <>
               {!isSpaceDetailsRoute() && isTabActive(HRManagementTabEnum.SPACES) && (
                  <Input
                     height={'42px'}
                     width={'40%'}
                     type={'text'}
                     margin={'10px 10px 0 auto !important'}
                     placeholder={translate('placeholders.searchCoworkingSpaces')}
                     onChange={(value) => {
                        dispatch(updateCoworkingSpacesSearchTerm(value));
                     }}
                     value={coworkingSpacesSearchTerm}
                  />
               )}
               <SubHeaderWrapperMobile>
                  <div className={'sub-header'}>{renderSubheaderContent()}</div>
               </SubHeaderWrapperMobile>
            </>
         )}
      </>
   );
};

export default EnterpriseManagementLayoutSubHeader;
