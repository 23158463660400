import React, { ReactElement, useState, useEffect } from 'react';

import { Button, Input, ModalComponent, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { translate } from 'src/translations/translations';

import { ButtonVariantsType } from 'src/components/Button';
import styled from 'styled-components';
import { ArrowRightModal } from 'src/assets/images';
import theme from 'src/styles/theme';

const MainWrapper = styled.div`
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   overflow: hidden;
   z-index: 10 !important;
`;
const HeaderWrapper = styled.div`
   background-color: white;
   height: 120px;
   position: sticky;
   top: 0;
   padding: 10px;
`;
const SubtitleWrapper = styled.div`
   height: 50px;
   width: 90%;
   text-align: left;
   position: sticky;
   top: 0;
   padding: 10px 0;
`;
const ContentWrapper = styled.div`
   width: 90%;
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
`;
const OneItem = styled.div`
   display: flex;
   flex-direction: columnn;
   justify-content: space-between;
   align-items: center;
`;
const FooterWrapper = styled.div`
   height: 15%;
   position: sticky;
   bottom: 0px;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 10px;
`;
const ArrowWrapper = styled.div`
   width: 10%;
   display: flex;
   justify-content: center;
`;

interface IProps {
   isOpen: boolean;
   setIsOpen: any;
   checkedData: any;
   setCheckedData: any;
}

const DatePickerModal = (props: IProps): ReactElement => {
   const recurringList = ['None', 'Daily', 'Weekly', 'Monthly'];
   const [isRecurringNone, setIsRecurringNone] = useState(false);

   let newCheckedObject = {
      location: props.checkedData.location,
      dateObject: {
         startingDate: props.checkedData.dateObject.startingDate,
         timeFrom: props.checkedData.dateObject.timeFrom,
         timeTo: props.checkedData.dateObject.timeTo,
         recurring: props.checkedData.dateObject.recurring,
         endingDate: props.checkedData.dateObject.endingDate,
      },
      groupSize: props.checkedData.groupSize,
      amenitiesList: props.checkedData.amenitiesList,
   };
   let dateValue = '';

   const today = new Date();
   const date = today.setDate(today.getDate());
   const defaultValue = new Date(date).toISOString().split('T')[0];
   dateValue = defaultValue;
   const handleChange = (event: any, value: string) => {
      let updateStartingDate = props.checkedData.dateObject.startingDate;
      let updateEndingDate = props.checkedData.dateObject.endingDate;
      let updateRecurring = props.checkedData.dateObject.recurring;
      let updateTimeFrom = props.checkedData.dateObject.timeFrom;
      let updateTimeTo = props.checkedData.dateObject.timeTo;

      if (value === 'startingDate') {
         updateStartingDate = event;
         updateEndingDate = event;
      }
      if (value === 'endingDate') {
         updateEndingDate = event;
      }
      if (value === 'recurring') {
         updateRecurring = event.target.value;
         if (updateRecurring === 'None') {
            setIsRecurringNone(true);
         } else {
            setIsRecurringNone(false);
         }
      }
      if (value === 'timeFrom') {
         updateTimeFrom = event;
      }
      if (value === 'timeTo') {
         updateTimeTo = event;
      }
      newCheckedObject = {
         location: props.checkedData.location,
         dateObject: {
            startingDate: updateStartingDate,
            timeFrom: updateTimeFrom,
            timeTo: updateTimeTo,
            recurring: updateRecurring,
            endingDate: updateEndingDate,
         },
         groupSize: props.checkedData.groupSize,
         amenitiesList: props.checkedData.amenitiesList,
      };
      props.setCheckedData(newCheckedObject);
   };

   const isChecked = (item: any) => {
      return newCheckedObject.dateObject.recurring == item;
   };
   const isFormValid = () => {
      return (
         props.checkedData.dateObject.startingDate &&
         props.checkedData.dateObject.timeFrom &&
         props.checkedData.dateObject.timeTo &&
         props.checkedData.dateObject.recurring &&
         props.checkedData.dateObject.endingDate
      );
   };
   return (
      <ModalComponent
         size="medium"
         visible={props.isOpen}
         padding={'0 0 0 0'}
         width={'400px'}
         closeModal={() => {
            props.setIsOpen(false);
         }}
      >
         <HeaderWrapper>
            <StyledText
               margin={'40px 0 10px 10px'}
               text={translate('reserveMeetingRoom.when')}
               variant={StyledTextVariantsType.HEADING_1}
            />
         </HeaderWrapper>
         <MainWrapper>
            <SubtitleWrapper>
               <StyledText
                  margin={'10px 0 0 0'}
                  text={translate('reserveMeetingRoom.startingDate')}
                  variant={StyledTextVariantsType.HEADING_3}
               />
            </SubtitleWrapper>
            <ContentWrapper>
               <Input
                  height={'54px'}
                  width={'100%'}
                  mobileWidth={'100%'}
                  type={'date'}
                  margin={'0 0 10px 0'}
                  value={
                     props.checkedData.dateObject.startingDate ? props.checkedData.dateObject.startingDate : dateValue
                  }
                  onChange={(event) => handleChange(event, 'startingDate')}
                  placeholder={translate('placeholders.date')}
                  min={dateValue}
               />
            </ContentWrapper>
            <SubtitleWrapper>
               <StyledText
                  margin={'10px 0 0 0'}
                  text={translate('reserveMeetingRoom.time')}
                  variant={StyledTextVariantsType.HEADING_2}
               />
            </SubtitleWrapper>
            <ContentWrapper>
               <StyledText
                  margin={'10px 40px 0 0'}
                  width={'100%'}
                  justifyContent={'flex-start'}
                  justifyContentMobile={'flex-start'}
                  text={translate('reserveMeetingRoom.TimeFrom')}
                  colorVariant={theme.colors.gray}
                  variant={StyledTextVariantsType.LABEL_TEXT}
               />
               <StyledText
                  margin={'10px 2px 0 0'}
                  width={'100%'}
                  justifyContent={'flex-start'}
                  justifyContentMobile={'flex-start'}
                  text={translate('reserveMeetingRoom.TimeTo')}
                  colorVariant={theme.colors.gray}
                  variant={StyledTextVariantsType.LABEL_TEXT}
               />
            </ContentWrapper>
            <ContentWrapper>
               <Input
                  height={'54px'}
                  width={'100%'}
                  mobileWidth={'100%'}
                  type={'time'}
                  margin={'0 0 0 0'}
                  placeholder={translate('placeholders.date')}
                  value={props.checkedData.dateObject.timeFrom}
                  onChange={(event) => handleChange(event, 'timeFrom')}
               />
               <ArrowWrapper>
                  <img
                     src={ArrowRightModal}
                     style={{ height: 24, cursor: 'pointer', margin: '15px 28px 0 28px' }}
                     alt="navigate"
                  />
               </ArrowWrapper>
               <Input
                  height={'54px'}
                  width={'100%'}
                  mobileWidth={'100%'}
                  type={'time'}
                  margin={'0 0 0 0'}
                  placeholder={translate('placeholders.date')}
                  value={props.checkedData.dateObject.timeTo}
                  onChange={(event) => handleChange(event, 'timeTo')}
               />
            </ContentWrapper>
            <SubtitleWrapper>
               <StyledText
                  margin={'10px 0 0 0'}
                  text={translate('reserveMeetingRoom.recurring')}
                  variant={StyledTextVariantsType.HEADING_2}
               />
            </SubtitleWrapper>
            <ContentWrapper>
               {recurringList.map((value: any, index: number) => {
                  return (
                     <OneItem key={index}>
                        <input
                           value={value}
                           checked={isChecked(value)}
                           type={'radio'}
                           name={'recurring'}
                           style={{
                              marginLeft: 5,
                              marginTop: 20,
                              marginBottom: 20,
                           }}
                           onChange={(event) => handleChange(event, 'recurring')}
                        />
                        <span
                           style={{
                              marginLeft: 5,
                              marginRight: 15,
                              marginTop: 20,
                              marginBottom: 20,
                           }}
                        >
                           {value}
                        </span>
                     </OneItem>
                  );
               })}
            </ContentWrapper>
            <SubtitleWrapper>
               <StyledText
                  margin={'10px 0 0 0'}
                  text={translate('reserveMeetingRoom.endingDate')}
                  variant={StyledTextVariantsType.HEADING_3}
               />
            </SubtitleWrapper>
            <ContentWrapper>
               <Input
                  height={'54px'}
                  width={'100%'}
                  mobileWidth={'100%'}
                  type={'date'}
                  value={props.checkedData.dateObject.endingDate}
                  margin={'0 0 10px 0'}
                  onChange={(event) => handleChange(event, 'endingDate')}
                  placeholder={translate('placeholders.date')}
                  min={props.checkedData.dateObject.startingDate}
                  max={isRecurringNone ? props.checkedData.dateObject.startingDate : null}
               />
            </ContentWrapper>
            <FooterWrapper>
               <Button
                  disabled={!isFormValid()}
                  variant={ButtonVariantsType.PURPLE}
                  width={'95%'}
                  mobileWidth={'95%'}
                  text={translate('reserveMeetingRoom.apply')}
                  margin={'30px 0 0 0'}
                  onClick={() => props.setIsOpen(false)}
               ></Button>
            </FooterWrapper>
         </MainWrapper>
      </ModalComponent>
   );
};

export default DatePickerModal;
