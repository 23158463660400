import React, { ReactElement, useState, useEffect } from 'react';
import moment from 'moment';

import { StyledText, Flex, NoDataPlaceholderComponent, Spinner } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import {
   SettingsItem,
   SettingsItemArrow,
   SettingsItemContent,
   SettingsItemTitle,
} from 'src/features/settings/shared/SharedComponents';
import { ChevronRightElement, PurpleArrowLeft } from 'src/assets/images';

import ApiRequests from 'src/features/settings/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { getFirebase } from 'react-redux-firebase';
import HeaderWrapper from 'src/components/HeaderWrapper';

const Invoices = (): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const goBack = () => {
      history.back();
   };

   const [loading, setLoading] = useState(true);
   const [invoices, setInvoices] = useState<any>([]);

   const getInvoices = async () => {
      try {
         const response: any = await ApiRequests.getInvoices(localStorage.getItem('enterprise_uuid') as string);

         if (response.success) {
            setInvoices(response.data.invoices);
         } else {
            NotificationsProvider.error(translate('settings.billing.invoices.error.getInvoices'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('settings.billing.invoices.error.getInvoices'));
      } finally {
         setLoading(false);
      }
   };

   const goToInvoicePage = (invoiceUrl: string) => {
      window.open(invoiceUrl, '_blank');
   };

   useEffect(() => {
      void getInvoices();
      //eslint-disable-next-line
   }, []);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <>
         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('common.invoices')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 40px'}
            />
         </HeaderWrapper>

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            height={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 20px'}
         >
            {invoices && invoices.length > 0 ? (
               invoices.map((item: any) => (
                  <SettingsItem
                     key={item.id}
                     onClick={() => {
                        goToInvoicePage(item.hosted_invoice_url);
                     }}
                  >
                     <SettingsItemContent>
                        <SettingsItemTitle>
                           <StyledText
                              width={'100%'}
                              text={`Created at ${new Date(item.created * 1000).toLocaleString() + ''}`}
                              variant={StyledTextVariantsType.PARAGRAPH_3}
                              colorVariant={theme.colors.gray}
                              margin={'0 0 5px 0'}
                           />
                           <StyledText
                              width={'100%'}
                              text={`Invoice NO ${item.number}`}
                              variant={StyledTextVariantsType.HEADING_3}
                              margin={'0 0 4px 0'}
                           />
                        </SettingsItemTitle>
                        <SettingsItemArrow>
                           <ChevronRightElement />
                        </SettingsItemArrow>
                     </SettingsItemContent>
                  </SettingsItem>
               ))
            ) : (
               <NoDataPlaceholderComponent />
            )}
         </Flex>
      </>
   );
};

export default Invoices;
