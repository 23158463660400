import React, { ReactElement, useState, useEffect } from 'react';

import { Flex, Spinner, StyledText, Table } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn, TableItemAction } from 'src/components/table/interfaces';

import CreditNoteModal from '../modals/CreditNoteModal';

import ApiRequests from 'src/features/space_management/data/ApiRequests';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { useLocation } from 'react-router';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

const EarningsInfo = (): ReactElement => {
   const location = useLocation();
   const [loading, setLoading] = useState(true);
   const [spaceId, setSpaceId] = useState(location.state.spaceId);
   const [earningsInfo, setEarningsInfo] = useState<any>(null);
   const [showCreditNoteModal, setShowCreditNoteModal] = useState(false);
   const [selectedEarning, setSelectedEarning] = useState<any>(null);

   const wide = useWindowWide(768);

   const goBack = () => {
      history.back();
   };

   const getSpaceEarnings = async () => {
      try {
         const response = await ApiRequests.getSpaceEarnings(spaceId);
         if (response && response.data) {
            setEarningsInfo(response.data);
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpaceEarnings'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('spaceManagement.error.getSpaceEarnings'));
      } finally {
         setLoading(false);
      }
   };

   const columns: Array<TableColumn<any>> = [
      {
         title: 'Payout Id',
         key: 'payout_id',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (earning: any) => <>{earning.payout_id}</>,
      },
      {
         title: 'Payout date',
         key: 'payout_date',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (earning: any) => <>{earning.payout_date}</>,
      },
      {
         title: 'Pending',
         key: 'pending',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (earning: any) => <>{earning.pending}</>,
      },
      {
         title: 'Total amount',
         key: 'total_amount',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (earning: any) => <>{earning.total_amount}</>,
      },
   ];

   const itemActions: Array<TableItemAction<any>> = [
      {
         title: 'View credit note',
         clickHandler: (earning: any) => {
            setSelectedEarning(earning);
            setShowCreditNoteModal(true);
         },
      },
   ];

   useEffect(() => {
      if (spaceId) {
         void getSpaceEarnings();
      }
      //eslint-disable-next-line
   }, [spaceId]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {showCreditNoteModal && selectedEarning && (
            <CreditNoteModal
               isOpen={showCreditNoteModal}
               setShowModal={setShowCreditNoteModal}
               earning={selectedEarning}
               setSelectedEarning={setSelectedEarning}
            />
         )}

         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               margin={'0 0 32px 0'}
               text={translate('spaceManagement.insights.earningsInfo.title')}
               variant={StyledTextVariantsType.HEADING_1}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  margin={'0 0 0 32px'}
                  text={translate('spaceManagement.insights.earningsInfo.title')}
                  variant={StyledTextVariantsType.HEADING_1}
               />
            </HeaderWrapper>
         )}

         <Flex
            margin={'0 0 40px 0'}
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirectionMobile={'column'}
            alignItemsMobile={'center'}
         >
            <Flex
               width={'48%'}
               flexDirection={'column'}
               alignItems={'center'}
               justifyContent={'center'}
               border={`1px solid ${theme.colors.lightGray}`}
               borderRadius={'15px'}
               minHeight={'184px'}
            >
               <StyledText
                  text={translate('spaceManagement.insights.earningsInfo.totalEarnings.title')}
                  variant={StyledTextVariantsType.NOTE_TEXT}
                  colorVariant={theme.colors.lightGray}
                  margin={'0 0 4px 0'}
               />
               <StyledText
                  text={earningsInfo?.totalEarnings}
                  variant={StyledTextVariantsType.HEADING_1}
                  colorVariant={theme.colors.mainPurple}
               />
            </Flex>

            <Flex
               width={'48%'}
               flexDirection={'column'}
               alignItems={'center'}
               justifyContent={'center'}
               border={`1px solid ${theme.colors.lightGray}`}
               borderRadius={'15px'}
               minHeight={'184px'}
            >
               <StyledText
                  text={translate('spaceManagement.insights.earningsInfo.pendingAmountForPayour.title')}
                  variant={StyledTextVariantsType.NOTE_TEXT}
                  colorVariant={theme.colors.lightGray}
                  margin={'0 0 4px 0'}
               />
               <StyledText
                  text={earningsInfo?.pending}
                  variant={StyledTextVariantsType.HEADING_1}
                  colorVariant={theme.colors.mainPurple}
               />
            </Flex>
         </Flex>

         <Table
            onSelectAll={() => null}
            columns={columns}
            itemActions={itemActions}
            data={earningsInfo?.earnings}
            customClass={'w-100'}
         />
      </Flex>
   );
};

export default EarningsInfo;
