import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { Link, useHistory } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import styled from 'styled-components';

import { StyledText } from './index';
import { StyledTextVariantsType } from './StyledText';

import { LogoutIcon, Home, Locations, Calendar, Settings } from 'src/assets/images';
import { RoutesEnum } from 'src/routes/routes';
import { signOutFromFirebase } from '../features/auth/authSlice';
import { logout, setHRManagementTab, setSpaceanagementTab } from 'src/system/state/actions';
import { translate } from 'src/translations/translations';
import { UserRoles, HRManagementTabEnum, SpaceManagementTabEnum } from 'src/system/state/types';
import theme from 'src/styles/theme';

const HamburgerMenuWrapper = styled.div`
   #hamburger-menu {
      position: fixed !important;
      right: 0 !important;
      left: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
      z-index: 9999 !important;
      transition: all 0.5s ease !important;

      .bm-cross-button {
         right: 20px !important;
         top: 20px !important;
         width: 32px !important;
         height: 32px !important;

         .bm-cross {
            background-color: white !important;
            height: 32px !important;
         }
      }

      .bm-menu {
         background: rgb(137, 125, 253) !important;
         padding: 20px !important;
         font-size: 1.15em !important;
         color: rgb(255, 255, 255) !important;
         position: absolute !important;
         overflow: hidden !important;
         right: 0 !important;
         left: 0 !important;
         top: 0 !important;
         bottom: 0 !important;
      }

      .bm-item-list {
         margin-top: 20%;

         .hamburger-menu-button {
            width: 100%;
            margin: 0 0 20px 0 !important;
         }

         .bm-item {
            display: flex !important;
            justify-content: flex-start;
            text-decoration: none;
            align-items: center;
            width: 100%;
            word-break: break-word;
            margin: 0 0 30px 0;
            border-bottom: 1px solid ${theme.colors.white};
            padding-bottom: 10px;

            &:focus {
               outline: none;
               outline-offset: unset;
            }

            svg,
            img {
               margin-right: 16px;
               width: 32px;
               height: 32px;
            }

            svg > path {
               stroke: ${theme.colors.white};
            }

            & div {
               font-size: 24px;
               color: ${theme.colors.white};
            }
         }
      }
   }
`;

interface IPRops {
   customerRole: string;
   isLoggedIn: boolean;
   currentUser: any;
   firebaseAuthLoaded: boolean;
}

const HamburgerMenu = ({ customerRole, isLoggedIn, currentUser, firebaseAuthLoaded }: IPRops): ReactElement => {
   const history = useHistory();
   const dispatch = useDispatch();
   const { boot, shutdown } = useIntercom();

   const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

   const disableBodyScroll = () => {
      document.body.style.overflow = 'hidden';
   };

   const enableBodyScroll = () => {
      document.body.style.overflow = 'unset';
   };

   const openHamburgerMenu = () => {
      setIsHamburgerMenuOpen(true);
      disableBodyScroll();
   };

   const closeHamburgerMenu = () => {
      setIsHamburgerMenuOpen(false);
      enableBodyScroll();
   };

   const goToHome = () => {
      if (customerRole === UserRoles.ENTERPRISE_MANAGER) {
         dispatch(setHRManagementTab(HRManagementTabEnum.TODAY));
         history.push(RoutesEnum.ENTERPRISE_MANAGEMENT);
      } else if (customerRole === UserRoles.SPACE_MANAGER) {
         dispatch(setSpaceanagementTab(SpaceManagementTabEnum.TODAY));
         history.push(RoutesEnum.SPACE_MANAGEMENT);
      } else {
         history.push(RoutesEnum.HOME);
      }

      closeHamburgerMenu();
   };

   const goToSettingsPage = () => {
      if (customerRole === UserRoles.ENTERPRISE_MANAGER) {
         dispatch(setHRManagementTab(HRManagementTabEnum.SETTINGS));
         history.push(RoutesEnum.ENTERPRISE_MANAGEMENT);
      } else if (customerRole === UserRoles.SPACE_MANAGER) {
         dispatch(setSpaceanagementTab(SpaceManagementTabEnum.SETTINGS));
         history.push(RoutesEnum.SPACE_MANAGEMENT);
      } else {
         history.push(RoutesEnum.SETTINGS);
      }

      closeHamburgerMenu();
   };

   const onLogoutClick = async () => {
      shutdown();
      boot();
      await signOutFromFirebase();
      dispatch(logout());
      closeHamburgerMenu();
   };

   return (
      <HamburgerMenuWrapper>
         <Menu
            id={'hamburger-menu'}
            right
            noOverlay
            width={'100%'}
            isOpen={isHamburgerMenuOpen}
            onOpen={openHamburgerMenu}
            onClose={closeHamburgerMenu}
         >
            <Link to={'#'} onClick={goToHome}>
               <Home />
               <StyledText text={translate('routes.home')} variant={StyledTextVariantsType.PARAGRAPH_1} />
            </Link>
            {customerRole === UserRoles.DEFAULT_USER && (
               <>
                  <Link to={RoutesEnum.MAP_VIEW} className={'bm-item'} onClick={closeHamburgerMenu}>
                     <Locations />
                     <StyledText text={translate('routes.map')} variant={StyledTextVariantsType.PARAGRAPH_1} />
                  </Link>
                  <Link to={RoutesEnum.RESERVE_MEETING_ROOM} className={'bm-item'} onClick={closeHamburgerMenu}>
                     <Calendar />
                     <StyledText
                        text={translate('routes.reserveMeetingRoom')}
                        variant={StyledTextVariantsType.PARAGRAPH_1}
                     />
                  </Link>
               </>
            )}
            {isLoggedIn && (
               <>
                  <Link to={'#'} className={'bm-item'} onClick={goToSettingsPage}>
                     <Settings />
                     <StyledText text={translate('routes.settings')} variant={StyledTextVariantsType.PARAGRAPH_1} />
                  </Link>
                  <Link to={'#'} className={'bm-item'} onClick={onLogoutClick}>
                     <LogoutIcon />
                     <StyledText text={translate('common.logout')} variant={StyledTextVariantsType.PARAGRAPH_1} />
                  </Link>
               </>
            )}
         </Menu>
      </HamburgerMenuWrapper>
   );
};

export default HamburgerMenu;
