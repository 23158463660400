import React, { ReactElement, useState } from 'react';
import { StyledText, EmptyImages as ImagePicker } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { parseError } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import ApiRequests from 'src/features/space_management/data/ApiRequests';
import NotificationsProvider from 'src/utilities/notifications-provider';

interface IProps {
   spaceId: string;
   gallery: any;
   getGallery: () => void;
}

const GalleryAndLogo = ({ spaceId, getGallery, gallery }: IProps): ReactElement => {
   const [filesChangeLoading, setFilesChangeLoading] = useState(false);

   const galleryChangeHandler = async (e: any) => {
      setFilesChangeLoading(true);
      const files = Object.keys(e.target.files).map((key) => {
         return e.target.files[key];
      });

      try {
         // setUploadGalleryLoading(true);
         const formData = new FormData();
         formData.append('space_id', spaceId);

         files.forEach((file: any) => {
            formData.append('images[]', file);
         });

         const response: any = await ApiRequests.uploadSpaceGallery({
            payload: formData,
         });

         if (response && response.data) {
            await getGallery();

            NotificationsProvider.success(
               response.data.message || translate('spaceManagement.success.updateSpaceLogo'),
            );
         } else {
            NotificationsProvider.error(response.error?.message || translate('spaceManagement.error.updateSpaceLogo'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.updateSpaceLogo'));
      } finally {
         setFilesChangeLoading(false);
      }
   };
   const deletePhotoFromGallery = async (payload: any) => {
      try {
         const response: any = await ApiRequests.deleteSpacePhoto({
            payload: payload,
         });

         if (response && response.data) {
            await getGallery();

            NotificationsProvider.success(
               response.data.message || translate('spaceManagement.success.updateSpaceLogo'),
            );
         } else {
            NotificationsProvider.error(response.error?.message || translate('spaceManagement.error.updateSpaceLogo'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.updateSpaceLogo'));
      }
   };

   return (
      <>
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('spaceManagement.settings.galleryAndLogo.gallerySection.title')}
            variant={StyledTextVariantsType.PARAGRAPH_2}
            margin={'64px 0 16px 0'}
         />

         <ImagePicker
            enableAdding={true}
            onImageChange={galleryChangeHandler}
            height={'30%'}
            loading={filesChangeLoading}
            images={gallery ? gallery : []}
            logo={false}
            space_id={spaceId}
            onImageDelete={deletePhotoFromGallery}
         ></ImagePicker>
      </>
   );
};

export default GalleryAndLogo;
