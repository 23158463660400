// @ts-nocheck
import React, { ReactElement } from 'react';
import ReactTooltip from 'react-tooltip';

type Place = 'top' | 'right' | 'bottom' | 'left';
type Type = 'dark' | 'success' | 'warning' | 'error' | 'info' | 'light';
type Effect = 'float' | 'solid';

interface IProps {
   id: string;
   text: string;
   place?: Place;
   type?: Type;
   effect?: Effect;
}

const Tooltip = ({ id, type, effect, place, text }: IProps): ReactElement => {
   return (
      <ReactTooltip id={id} type={type} place={place} effect={effect}>
         <span>{text}</span>
      </ReactTooltip>
   );
};

Tooltip.defaultProps = {
   place: 'top',
   type: 'dark',
   effect: 'solid',
} as Partial<IProps>;

export default Tooltip;
