import React, { ReactElement, useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { Switch, Route, useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { Header, Footer, Spinner, Flex } from 'src/components';
import AppLayoutSubHeader from 'src/layout/AppSubHeader';
import EnterpriseManagementLayoutSubHeader from 'src/layout/EnterpriseManagementSubHeader';
import SpaceManagementLayoutSubHeader from 'src/layout/SpaceManagementSubHeader';

import LoginModal from 'src/features/auth/LoginModal';
import ResetPasswordModal from 'src/features/auth/ResetPasswordModal';
import RegisterModal from 'src/features/auth/RegisterModal';
import VerifyEmailModal from 'src/features/auth/VerifyEmailModal';

import { RootState } from 'src/app/rootReducer';
import { HRManagementTabEnum, SpaceManagementTabEnum } from 'src/system/state/types';
import { selectFirebaseAuthLoader, signOutFromFirebase } from 'src/features/auth/authSlice';
import { setHRManagementTab, setSpaceanagementTab, logout, setEnterpriseId } from 'src/system/state/actions';
import {
   isPublicRoute,
   isHRManagementRoute,
   isSpaceManagementRoute,
   RoutesEnum,
   appRoutes,
   enterpriseManagementRoutes,
   spaceManagementRoutes,
   enterpriseRoutes,
   insightsRoutes,
} from 'src/routes/routes';
import { setPageTitle, getPageTitle } from 'src/utilities/seo.helpers';
import { UserRoles } from 'src/system/state/types';
import Settings from 'src/features/settings/Settings';

import AccountRoot from 'src/features/settings/account/AccountRoot';
import BillingRoot from 'src/features/settings/billing/BillingRoot';

import LegalInformationItemsList from 'src/features/settings/legals/LegalsRoot';
import BasicInfo from 'src/features/settings/account/user/BasicInfo';
import ProfilePhoto from 'src/features/settings/account/user/ProfilePhoto';
import ChangePassword from 'src/features/settings/account/user/ChangePassword';
import SocialMedia from 'src/features/settings/account/social_media/SocialMedia';
import CheckinCredits from 'src/features/settings/account/checkin-credits/CheckinCredits';

import PurchasePlan from 'src/features/settings/billing/purchase_plan/PurchasePlan';
import CreditCards from 'src/features/settings/billing/credit_cards/CreditCards';
import BillingAddress from 'src/features/settings/billing/billing_address/BillingAddress';
import Invoices from 'src/features/settings/billing/invoices/Invoices';
import CheckinHistory from 'src/features/settings/account/checkin_history/CheckinHistory';
import SpaceManagementSettings from 'src/features/space_management/settings/SpaceSettings';
import SpaceManagement from 'src/features/space_management/SpaceManagement';
import SpaceManagementToday from 'src/features/space_management/today/Today';
import SpaceMenagementInsights from 'src/features/space_management/insights/Insights';
import SpaceMenagementYourSpaces from 'src/features/space_management/your_spaces/YourSpaces';
import SpaceMenagementBasicInfo from 'src/features/space_management/settings/basic_details/BasicDetails';
import SpaceMenagementAddress from 'src/features/space_management/settings/address/AddressDetails';
import SpaceMenagementGallery from 'src/features/space_management/settings/gallery_and_logo/GalleryRoot';
import SpaceMenagementManagers from 'src/features/space_management/settings/managers/SpaceManagers';
import SpaceMenagementCapacity from 'src/features/space_management/settings/capacity/AvailableSeats';
import SpaceManagementSpaceOverview from 'src/features/space_management/insights/space_overview/SpaceOverview';
import SpaceManagementSpaceCheckins from 'src/features/space_management/insights/checkins_info/CheckinsInfo';
import SpaceManagementEarnings from 'src/features/space_management/insights/earnings_info/EarningsInfo';
import EnterpriseInsights from 'src/features/enterprise_management/insights/Insights';
import EnterpriseManagement from 'src/features/enterprise_management/EnterpriseManagement';
import EnterpriseToday from 'src/features/space_management/today/Today';
import EnterpriseSettings from 'src/features/enterprise_management/settings/EnterpriseSettings';
import EnterpriseSpaces from 'src/features/spaces/SpacesPreview';
import SpaceOverview from 'src/features/enterprise_management/insights/space_overview/SpaceOverview';
import EnterpriseCheckins from 'src/features/enterprise_management/insights/checkins_info/CheckinsInfo';
import EnterpriseManageTeam from 'src/features/enterprise_management/settings/enterprise/EnterpriseMembers';
import EnterpriseCompanyDetails from 'src/features/enterprise_management/settings/enterprise/CompanyDetails';
import EnterpriseManagers from 'src/features/enterprise_management/settings/enterprise/EnterpriseAdmins';
import EnterpriseBilling from 'src/features/enterprise_management/settings/enterprise/BillingDetails';
import EnterpriseCreditCards from 'src/features/enterprise_management/settings/billing/credit_cards/CreditCards';
import EnterpriseBillingAddress from 'src/features/enterprise_management/settings/billing/billing_address/BillingAddress';
import EnterpriseInvoices from 'src/features/enterprise_management/settings/billing/invoices/Invoices';
import EnterprisePurchasePlan from 'src/features/enterprise_management/settings/billing/purchase_plan/PurchasePlan';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { parseError } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import SignUpModal from 'src/features/auth/SignUpModal';

const LayoutContentWrapper = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   height: auto;
`;

const AppLayout = (props: any): ReactElement | null => {
   const dispatch = useDispatch();
   const history = useHistory();
   const firebase = getFirebase();
   const { boot, shutdown } = useIntercom();
   const currentUser = firebase.auth()?.currentUser;

   const firebaseAuthLoaded = useSelector(selectFirebaseAuthLoader);
   const token = useSelector((state: RootState) => state.systemReducer).token;
   const customerRole = useSelector((state: RootState) => state.systemReducer).customerRole;
   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;
   const isDirectSignUp = useSelector((state: RootState) => state.globalVariablesReduce).directSignUp;
   const renderSubHeader = () => {
      if (isHRManagementRoute(props.match.path) && customerRole === UserRoles.ENTERPRISE_MANAGER) {
         return <EnterpriseManagementLayoutSubHeader />;
      } else if (isSpaceManagementRoute(props.match.path)) {
         return <SpaceManagementLayoutSubHeader />;
      } else {
         return <AppLayoutSubHeader customerRole={customerRole} />;
      }
   };

   const clearAuth = async () => {
      shutdown();
      boot();
      await signOutFromFirebase();
      dispatch(logout());
   };

   const isSpaceDetailsRoute = () => {
      const path = props.match.path;
      return path.includes('/co-working-space') || path.includes('/hr-management/space');
   };

   useEffect(() => {
      // for all pages/routes except /co-working-space, setPageTitle helper method is used
      // for /co-working-space route, title is set on page mount when space details are retrieved from backend
      if (!isSpaceDetailsRoute()) {
         setPageTitle(getPageTitle(props.match.path));
      }
      //eslint-disable-next-line
   }, [props.match.path]);

   const loadUserDetails = async (user: any) => {
      try {
         const response = await ApiRequests.getUserDetails(user.uid);
         if (response.data && response.data.enterprise) {
            localStorage.setItem('enterprise_uuid', response.data.enterprise.owner_uuid); //pass though redux I guess???
            dispatch(setEnterpriseId(response.data.enterprise.enterprise_id));
         } else {
            dispatch(setEnterpriseId(''));
         }
      } catch (err) {
         dispatch(setEnterpriseId(''));
         NotificationsProvider.error(parseError(err) || translate('hrManagement.error.getUserEnterpriseDetails'));
      }
   };

   useEffect(() => {
      if (firebaseAuthLoaded) {
         // logged in
         if (currentUser && token) {
            // render role based UI
            if (customerRole === UserRoles.ENTERPRISE_MANAGER) {
               loadUserDetails(currentUser);
               if (!isHRManagementRoute(props.match.path) && enterpriseId) {
                  dispatch(setHRManagementTab(HRManagementTabEnum.TODAY));
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_TODAY,
                  });
               }
            } else if (customerRole === UserRoles.SPACE_MANAGER) {
               if (!isSpaceManagementRoute(props.match.path)) {
                  dispatch(setSpaceanagementTab(SpaceManagementTabEnum.TODAY));
                  history.push(RoutesEnum.SPACE_MANAGEMENT_TODAY);
               }
            }
         } else {
            if (!isPublicRoute(props.match.path)) {
               history.push(RoutesEnum.HOME);
            }
         }
      }
      //eslint-disable-next-line
   }, [props.match.path, firebaseAuthLoaded, currentUser, customerRole, token, enterpriseId]);

   useEffect(() => {
      window.addEventListener(
         'storage',
         async (event) => {
            if ((event.key === 'token' && event.newValue === null) || (event.key === null && event.newValue === null)) {
               await clearAuth();
            }
         },
         false,
      );
      //eslint-disable-next-line
   }, [window]);

   if (!firebaseAuthLoaded) {
      return <Spinner height={'400px'} />;
   }

   return (
      <div className="app">
         <main className="main">
            <Header customerRole={customerRole} isLoggedIn={!!currentUser && !!token} currentUser={currentUser} />

            <ToastContainer className={'app-toast-notification'} />
            <LoginModal />
            <ResetPasswordModal />
            <RegisterModal />

            {isDirectSignUp && <SignUpModal />}
            {currentUser && token && currentUser.emailVerified === false && !isDirectSignUp && <VerifyEmailModal />}

            {renderSubHeader()}

            <LayoutContentWrapper>
               <Switch>
                  {appRoutes.map((r) => (
                     <Route exact key={r.name} path={r.path} component={r.component} />
                  ))}

                  {enterpriseRoutes.map((r) => (
                     <Route exact key={r.name} path={r.path} component={r.component} />
                  ))}
                  {enterpriseManagementRoutes.map((r) => (
                     <Route exact key={r.name} path={r.path} component={r.component} />
                  ))}

                  {spaceManagementRoutes.map((r) => (
                     <Route exact key={r.name} path={r.path} component={r.component} />
                  ))}

                  <Route
                     path={RoutesEnum.ENTERPRISE_INSIGHTS}
                     render={({ match: { url } }) => (
                        <>
                           <Route path={`${url + RoutesEnum.ENTERPRISE_INSIGHTS_OVERVIEW}`} component={SpaceOverview} />
                           <Route
                              path={`${url + RoutesEnum.ENTERPRISE_INSIGHTS_CHECKINS}`}
                              component={EnterpriseCheckins}
                           />
                        </>
                     )}
                     component={EnterpriseInsights}
                  />
                  <Route
                     path={RoutesEnum.ENTERPRISE_SETTINGS}
                     render={({ match: { url } }) => (
                        <>
                           <Route
                              path={`${url + RoutesEnum.ENTERPRISE_SETTINGS_MANAGE_TEAM}`}
                              component={EnterpriseManageTeam}
                           />
                           <Route
                              path={`${url + RoutesEnum.ENTERPRISE_SETTINGS_COMPANY_DETAILS}`}
                              component={EnterpriseCompanyDetails}
                           />
                           <Route
                              path={`${url + RoutesEnum.ENTERPRISE_SETTINGS_MANAGERS}`}
                              component={EnterpriseManagers}
                           />
                           <Route
                              path={`${url + RoutesEnum.ENTERPRISE_SETTINGS_BILLING}`}
                              component={EnterpriseBilling}
                           />
                        </>
                     )}
                     component={EnterpriseSettings}
                  />
                  <Route
                     path={RoutesEnum.ENTERPRISE_SETTINGS_BILLING}
                     component={EnterpriseBilling}
                     render={({ match: { url } }) => (
                        <>
                           <Route
                              path={`${url + RoutesEnum.ENTERPRISE_SETTINGS_PURCHASE_PLAN}`}
                              component={EnterprisePurchasePlan}
                           />
                           <Route
                              path={`${url + RoutesEnum.ENTERPRISE_SETTINGS_CREDIT_CARDS}`}
                              component={EnterpriseCreditCards}
                           />
                           <Route
                              path={`${url + RoutesEnum.ENTERPRISE_SETTINGS_BILLING_ADDRESS}`}
                              component={EnterpriseBillingAddress}
                           />
                           <Route
                              path={`${url + RoutesEnum.ENTERPRISE_SETTINGS_INVOICES}`}
                              component={EnterpriseInvoices}
                           />
                        </>
                     )}
                  />

                  <Route
                     path={RoutesEnum.SPACE_MANAGEMENT_SETTINGS}
                     render={({ match: { url } }) => (
                        <>
                           <Route
                              path={`${url + RoutesEnum.SPACE_MANAGEMENT_BASIC_INFO}`}
                              component={SpaceMenagementBasicInfo}
                           />
                           <Route
                              path={`${url + RoutesEnum.SPACE_MANAGEMENT_ADDRESS}`}
                              component={SpaceMenagementAddress}
                           />
                           <Route
                              path={`${url + RoutesEnum.SPACE_MANAGEMENT_GALLERY}`}
                              component={SpaceMenagementGallery}
                           />
                           <Route
                              path={`${url + RoutesEnum.SPACE_MANAGEMENT_MANAGERS}`}
                              component={SpaceMenagementManagers}
                           />
                           <Route
                              path={`${url + RoutesEnum.SPACE_MANAGEMENT_CAPACITY}`}
                              component={SpaceMenagementCapacity}
                           />
                        </>
                     )}
                     component={SpaceManagementSettings}
                  />
                  <Route
                     path={RoutesEnum.SPACE_MANAGEMENT_INSIGHTS}
                     render={({ match: { url } }) => (
                        <>
                           <Route
                              path={`${url + RoutesEnum.SPACE_MANAGEMENT_SPACE_OVERVIEW}`}
                              component={SpaceManagementSpaceOverview}
                           />
                           <Route
                              path={`${url + RoutesEnum.SPACE_MANAGEMENT_CHECKINS}`}
                              component={SpaceManagementSpaceCheckins}
                           />
                           <Route
                              path={`${url + RoutesEnum.SPACE_MANAGEMENT_EARNINGS}`}
                              component={SpaceManagementEarnings}
                           />
                        </>
                     )}
                     component={SpaceMenagementInsights}
                  />

                  <Route
                     path={RoutesEnum.SETTINGS}
                     render={({ match: { url } }) => (
                        <>
                           <Route path={`${url + RoutesEnum.ACCOUNT}`} component={AccountRoot} />
                           <Route path={`${url + RoutesEnum.BILLING_INFO}`} component={BillingRoot} />
                           <Route path={`${url + RoutesEnum.LEGAL_INFO}`} component={LegalInformationItemsList} />
                        </>
                     )}
                     component={Settings}
                  />
                  <Route
                     path={RoutesEnum.ACCOUNT}
                     render={({ match: { url } }) => (
                        <>
                           <Route path={`${url + RoutesEnum.BASIC_INFO}`} component={BasicInfo} />
                           <Route path={`${url + RoutesEnum.PROFILE_PHOTO}`} component={ProfilePhoto} />
                           <Route path={`${url + RoutesEnum.CHANGE_PASSWORD}`} component={ChangePassword} />
                           <Route path={`${url + RoutesEnum.SOCIAL_MEDIA}`} component={SocialMedia} />
                           <Route path={`${url + RoutesEnum.CHECKIN_CREDITS}`} component={CheckinCredits} />
                           <Route path={`${url + RoutesEnum.CHECKIN_HISTORY}`} component={CheckinHistory} />
                        </>
                     )}
                     component={AccountRoot}
                  />
                  <Route
                     path={RoutesEnum.BILLING_INFO}
                     render={({ match: { url } }) => (
                        <>
                           <Route path={`${url + RoutesEnum.PURCHASE_PLAN}`} component={PurchasePlan} />
                           <Route path={`${url + RoutesEnum.MANAGE_CARDS}`} component={CreditCards} />
                           <Route path={`${url + RoutesEnum.CHANGE_BILLING_ADDRESS}`} component={BillingAddress} />
                           <Route path={`${url + RoutesEnum.INVOICES}`} component={Invoices} />
                        </>
                     )}
                     component={BillingRoot}
                  />
               </Switch>
            </LayoutContentWrapper>
            <Footer />
         </main>
      </div>
   );
};

export default AppLayout;
