import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Flex } from 'src/components';

import CreateSpace from './CreateSpace';
import SpaceSettingsRoot from './SpaceSettingsRoot';
import BasicDetails from './basic_details/BasicDetails';
import BankDetails from './bank_details/BankDetails';
import GalleryAndLogo from './gallery_and_logo/GalleryRoot';
import SpaceManagers from './managers/SpaceManagers';
import AvailableSeats from './capacity/AvailableSeats';

import { UserRoles } from 'src/system/state/types';
import { RootState } from 'src/app/rootReducer';
import AddressDetails from './address/AddressDetails';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { RoutesEnum, spaceManagementSettingsRoutes } from 'src/routes/routes';

export enum ActiveItemEnum {
   BASIC_DETAILS = 'basicDetails',
   ADDRESS_DETAILS = 'addressDetails',
   GALLERY_AND_LOGO = 'galleryAndLogo',
   BANK_DETAILS = 'bankDetails',
   SPACE_MANAGERS = 'spaceManagers',
   AVAILABLE_SEATS = 'availableSeats',
}

const SpaceSettings = (): ReactElement => {
   const customerRole = useSelector((state: RootState) => state.systemReducer).customerRole;
   const spaceId = useSelector((state: RootState) => state.systemReducer).spaceId;

   const [activeItem, setActiveItem] = useState('');

   const [isHidden, setIsHidden] = useState(false);

   const routeMatch = useRouteMatch<RoutesEnum>([
      RoutesEnum.SPACE_MANAGEMENT_BASIC_INFO,
      RoutesEnum.SPACE_MANAGEMENT_GALLERY,
      RoutesEnum.SPACE_MANAGEMENT_ADDRESS,
      RoutesEnum.SPACE_MANAGEMENT_MANAGERS,
      RoutesEnum.SPACE_MANAGEMENT_CAPACITY,
   ]);

   const isItemActive = (itemToCheck: ActiveItemEnum) => {
      return itemToCheck === activeItem;
   };
   useEffect(() => {
      if (routeMatch?.isExact) {
         setIsHidden(true);
      }
   }, []);

   const renderSettings = () => (
      <Flex
         justifyContent="flex-start"
         alignItems="stretch"
         flexWrap="nowrap"
         flex={1}
         minHeight={'100%'}
         mobileMinHeight={'100%'}
         position={'relative'}
         width={'100%'}
         flexDirectionMobile={'column'}
         padding={'0 10% 3% 10%'}
      >
         <Flex
            width={'32%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
            hide={isHidden}
         >
            <SpaceSettingsRoot setActiveItem={setActiveItem} isItemActive={isItemActive} />
         </Flex>
         <Flex
            width={'66%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
         >
            <Switch>
               {spaceManagementSettingsRoutes.map((r) => (
                  <Route exact key={r.name} path={r.path} component={r.component} />
               ))}
            </Switch>

            {/*  {activeItem === ActiveItemEnum.BASIC_DETAILS && <BasicDetails />}

         {activeItem === ActiveItemEnum.ADDRESS_DETAILS && <AddressDetails spaceId={spaceId} />}

         {activeItem === ActiveItemEnum.GALLERY_AND_LOGO && <GalleryAndLogo spaceId={spaceId} />}

         {activeItem === ActiveItemEnum.BANK_DETAILS && <BankDetails spaceId={spaceId} />}

         {activeItem === ActiveItemEnum.SPACE_MANAGERS && <SpaceManagers spaceId={spaceId} />}

         {activeItem === ActiveItemEnum.AVAILABLE_SEATS && <AvailableSeats spaceId={spaceId} />} */}
         </Flex>
      </Flex>
   );

   return spaceId && customerRole === UserRoles.SPACE_MANAGER ? renderSettings() : <CreateSpace />;
};

export default SpaceSettings;
