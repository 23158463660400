import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import _ from 'underscore';

import { Button, Flex, Input, ModalComponent, NoDataPlaceholderComponent, Spinner, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { RootState } from 'src/app/rootReducer';

import { translate } from 'src/translations/translations';

import { ButtonVariantsType } from 'src/components/Button';
import { ISpace } from 'src/features/space_management/interfaces';
import styled from 'styled-components';

const MainWrapper = styled.div`
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   overflow: hidden;
`;
const HeaderWrapper = styled.div`
   background-color: white;
   height: 180px;
   position: sticky;
   border-bottom: solid;
   border-bottom-color: #e4e4e4;
   top: 0;
   padding: 10px;
`;
const ContentWrapper = styled.div`
   margin-left: 10px;
   margin-bottom: 10px;
   width: 97%;
   flex-grow: 1;
   padding: 10px;
   overflow: auto;
`;
const OneItem = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   border-bottom: solid;
   border-color: rgb(228, 228, 228);
`;
const FooterWrapper = styled.div`
   height: 15%;
   position: sticky;
   bottom: 0px;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 10px;
`;

interface IProps {
   isOpen: boolean;
   setIsOpen: any;
   filtered: any[];
   setFiltered?: any;
   spaces: ISpace[];
   citiesFilter?: boolean;
   amenitiesFilter?: boolean;
   vibesFilter?: boolean;
   filteredSpaces: ISpace[];
   setFilteredSpaces: any;
   checked: any;
   setChecked: any;
}

const ModalFilter = (props: IProps): ReactElement => {
   const [citiesSearchTerm, setCitiesSearchTerm] = useState('');
   let newCheckedObject: any = {
      citiesList: [],
      amenitiesList: [],
      vibesList: [],
   };

   const filterSpaces = () => {
      window.history.replaceState({}, document.title, window.location.pathname);
      const urlParams: any = new URLSearchParams(window.location.search);

      props.setFilteredSpaces(props.spaces);

      if (props.checked.citiesList.length > 0) {
         urlParams.set('cities', checkedCitiesItems);
      }
      if (props.checked.amenitiesList.length > 0) {
         urlParams.set('amenities', checkedAmenitiesItems);
      }
      if (props.checked.vibesList.length > 0) {
         urlParams.set('vibes', checkedVibesItems);
      }
      const checkedCities =
         props.checked.citiesList && props.checked.citiesList.length
            ? props.checked.citiesList.reduce((total: any, item: any) => {
                 return total + ', ' + item;
              })
            : '';

      let list: any = props.spaces;

      if (checkedCitiesItems.length > 0) {
         list = list.filter(
            (space: any) => space.location && space.location.city && checkedCities.includes(space.location.city),
         );
      }

      if (checkedAmenitiesItems.length > 0) {
         list = list.filter(
            (space: any) =>
               space.amenities &&
               props.checked.amenitiesList.every((el: any) => space.amenities.some((el2: any) => el === el2)),
         );
      }

      if (checkedVibesItems.length > 0) {
         list = list.filter(
            (space: any) =>
               space.vibes && props.checked.vibesList.every((el: any) => space.vibes.some((el2: any) => el === el2)),
         );
      }

      props.setFilteredSpaces(list);

      window.history.pushState({}, document.title, `?${urlParams}`);

      props.setIsOpen(false);
   };

   const handleCheck = (event: any) => {
      let updatedCitiesList = [...props.checked.citiesList];
      let updatedAmenitiesList = [...props.checked.amenitiesList];
      let updatedVibesList = [...props.checked.vibesList];

      if (props.citiesFilter) {
         if (event.target.checked) {
            updatedCitiesList = [...props.checked.citiesList, event.target.value];
         } else {
            updatedCitiesList.splice(updatedCitiesList.indexOf(event.target.value), 1);
         }
      }
      if (props.amenitiesFilter) {
         if (event.target.checked) {
            updatedAmenitiesList = [...props.checked.amenitiesList, event.target.value];
         } else {
            updatedAmenitiesList.splice(updatedAmenitiesList.indexOf(event.target.value), 1);
         }
      }
      if (props.vibesFilter) {
         if (event.target.checked) {
            updatedVibesList = [...props.checked.vibesList, event.target.value];
         } else {
            updatedVibesList.splice(updatedVibesList.indexOf(event.target.value), 1);
         }
      }
      newCheckedObject = {
         citiesList: updatedCitiesList,
         amenitiesList: updatedAmenitiesList,
         vibesList: updatedVibesList,
      };

      props.setChecked(newCheckedObject);
   };

   const checkedCitiesItems =
      props.checked.citiesList && props.checked.citiesList.length
         ? props.checked.citiesList.reduce((total: any, item: any) => {
              return total + ', ' + item;
           })
         : '';
   const checkedAmenitiesItems =
      props.checked.amenitiesList && props.checked.amenitiesList.length
         ? props.checked.amenitiesList.reduce((total: any, item: any) => {
              return total + ', ' + item;
           })
         : '';
   const checkedVibesItems =
      props.checked.vibesList && props.checked.vibesList.length
         ? props.checked.vibesList.reduce((total: any, item: any) => {
              return total + ', ' + item;
           })
         : '';

   const isChecked = (item: any) => {
      if (props.amenitiesFilter) {
         return checkedAmenitiesItems.includes(item);
      } else if (props.citiesFilter) {
         return checkedCitiesItems.includes(item);
      } else {
         return checkedVibesItems.includes(item);
      }
   };

   return (
      <ModalComponent
         size="medium"
         visible={props.isOpen}
         padding={'0 0 0 0'}
         width={'400px'}
         height={'700px'}
         closeModal={() => {
            props.setIsOpen(false);
         }}
      >
         <MainWrapper>
            <HeaderWrapper>
               <StyledText
                  margin={'40px 0 10px 10px'}
                  text={translate('common.filters')}
                  variant={StyledTextVariantsType.HEADING_1}
               />

               <Input
                  height={'42px'}
                  width={'95%'}
                  type={'text'}
                  margin={'0 0 10px  10px'}
                  placeholder={translate('placeholders.search')}
                  onChange={(value) => {
                     setCitiesSearchTerm(value);
                  }}
                  value={citiesSearchTerm}
               />
            </HeaderWrapper>
            <ContentWrapper>
               {props.filtered &&
                  props.filtered
                     .filter((s: any) => s && s.toLowerCase().indexOf(citiesSearchTerm.toLowerCase()) > -1)
                     .map((city: any, index: number) => {
                        return (
                           <OneItem key={index}>
                              <span>{city}</span>
                              <input
                                 value={city}
                                 checked={isChecked(city)}
                                 type="checkbox"
                                 onChange={handleCheck}
                                 style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    marginTop: 20,
                                    marginBottom: 20,
                                 }}
                              />
                           </OneItem>
                        );
                     })}
            </ContentWrapper>

            <FooterWrapper>
               <Button
                  variant={ButtonVariantsType.PURPLE}
                  width={'95%'}
                  mobileWidth={'95%'}
                  text={'Apply filter'}
                  onClick={filterSpaces}
               ></Button>
            </FooterWrapper>
         </MainWrapper>
      </ModalComponent>
   );
};

export default ModalFilter;
