import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ArrowDown } from 'src/assets/images';
import { Button, Flex, ModalComponent, Spinner, StyledText } from './index';
import { StyledTextVariantsType } from './StyledText';
import theme from 'src/styles/theme';
import { translate } from '../translations/translations';
import { ButtonVariantsType } from './Button';

const OptionsWrapper = styled.div`
   display: flex;
   flex-direction: column;
   width: 100%;
   height: auto;
   max-height: 100%;
   align-items: center;
   justify-content: center;
   overflow-y: scroll;
   margin-bottom: 154px;
`;

const OptionItem = styled.div<any>`
   display: flex;
   width: 100%;
   height: auto;
   background-color: ${theme.colors.white};
   border: ${(props) => (props.active ? `1px solid ${theme.colors.mainPurple}` : `1px solid ${theme.colors.black}`)};
   color: ${(props) => (props.active ? `${theme.colors.mainPurple}` : `${theme.colors.black}`)};
   align-items: center;
   justify-content: center;
   padding: 16px;
   margin-bottom: 16px;
   border-radius: 15px;

   &:hover {
      cursor: pointer;
   }
`;

const IconContainer = styled.div`
   svg > path {
      stroke: ${(props) => (props.color ? `${props.color}` : `initial`)};
   }
`;

interface ISelectedValue {
   id?: string;
   name?: string;
}

interface IProps {
   title: string;
   options: ISelectedValue[];
   margin?: string;
   selectedValue: ISelectedValue | undefined;
   onSelect: (value: ISelectedValue) => void;
   textVariant?: StyledTextVariantsType;
}

const CustomSelect = ({ title, margin, selectedValue, onSelect, options, textVariant }: IProps): ReactElement => {
   const [showSelectModal, setShowSelectModal] = useState(false);
   const [selectedOption, setSetSelectedOption] = useState<ISelectedValue | null>(null);

   const isSelected = (value: any) => {
      return selectedOption ? selectedOption.id === value : false;
   };

   const renderSelectModal = () => {
      return (
         <ModalComponent
            size="medium"
            visible={showSelectModal}
            width={'414px'}
            closeModal={() => {
               setSetSelectedOption(null);
               setShowSelectModal(false);
            }}
         >
            <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'} width={'100%'}>
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('spaceManagement.today.selectSpace.modal.title')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 16px 0'}
               />

               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('spaceManagement.today.selectSpace.modal.subtitle')}
                  variant={StyledTextVariantsType.PARAGRAPH_1}
                  margin={'0 0 40px 0'}
               />

               <OptionsWrapper>
                  {options.map((o: ISelectedValue, index: number) => {
                     return (
                        <OptionItem
                           key={index}
                           active={isSelected(o.id)}
                           onClick={() => {
                              setSetSelectedOption(o);
                           }}
                        >
                           {o.name}
                        </OptionItem>
                     );
                  })}
               </OptionsWrapper>

               <Button
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('common.confirm')}
                  width={'100%'}
                  onClick={() => {
                     selectedOption && onSelect(selectedOption);
                     setShowSelectModal(false);
                  }}
               />
            </Flex>
         </ModalComponent>
      );
   };

   useEffect(() => {
      if (selectedValue && showSelectModal) {
         setSetSelectedOption(selectedValue);
      }
   }, [selectedValue, showSelectModal]);

   if ((!selectedValue && !options) || (options && options.length < 1)) {
      return <Spinner height={'20px'} />;
   }

   return (
      <Flex margin={margin} marginMobile={margin} alignItems={'center'} justifyContent={'center'} position={'relative'}>
         {showSelectModal && renderSelectModal()}

         <StyledText text={title} variant={textVariant || StyledTextVariantsType.HEADING_3} />
         <StyledText
            text={selectedValue ? selectedValue.name : options[0].name}
            variant={textVariant || StyledTextVariantsType.HEADING_3}
            colorVariant={theme.colors.mainPurple}
            margin={'0 0 0 5px'}
            cursor={'pointer'}
            onClick={() => {
               setShowSelectModal(true);
            }}
         />

         <IconContainer color={theme.colors.mainPurple}>
            <ArrowDown
               style={{
                  cursor: 'pointer',
                  width: '20px',
                  marginLeft: 8,
                  position: 'absolute',
                  top: '60%',
                  right: '-25px',
                  transform: 'translateY(-50%)',
               }}
               onClick={() => {
                  setShowSelectModal(true);
               }}
            />
         </IconContainer>
      </Flex>
   );
};

export default CustomSelect;
