import React, { ReactElement, useEffect, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import moment from 'moment';
import styled from 'styled-components';

import { Flex, NoDataPlaceholderComponent, Spinner, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { InlineElementsWrapper } from 'src/components/SharedComponents';
import { SettingsItem } from '../../shared/SharedComponents';

import ApiRequests from 'src/features/settings/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { capitalize, parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

export const CheckinHistoryItem = styled.div`
   width: 100%;
   height: auto;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
`;

const CheckinHistory = (): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(true);
   const [checkinHistory, setCheckinHistory] = useState<any>([]);

   const goBack = () => {
      history.back();
   };

   const getCheckinHistory = async () => {
      try {
         const response: any = await ApiRequests.getCheckinHistory(currentUser?.uid as string);
         if (response.success) {
            setCheckinHistory(response.data);
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('settings.checkinHistory.error.getHistory'));
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      currentUser && void getCheckinHistory();
      //eslint-disable-next-line
   }, [currentUser]);

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         height={'100%'}
         width={'100%'}
         position={'relative'}
      >
         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('common.checkInHistory')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 32px'}
            />
         </HeaderWrapper>

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            height={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
         >
            {loading ? (
               <Spinner height={'100px'} />
            ) : checkinHistory && checkinHistory.length > 0 ? (
               checkinHistory.map((item: any, index: number) => (
                  <SettingsItem key={index}>
                     <CheckinHistoryItem>
                        <InlineElementsWrapper margin={'0 0 8px 0'}>
                           <StyledText
                              width={'100%'}
                              text={translate('common.checkedInAt')}
                              variant={StyledTextVariantsType.PARAGRAPH_3}
                              colorVariant={theme.colors.gray}
                              margin={'0 5px 0 0'}
                           />
                           <StyledText
                              width={'100%'}
                              text={moment(item.checkin_date).format('DD/MM/YYYY HH:mm')}
                              variant={StyledTextVariantsType.PARAGRAPH_3}
                              colorVariant={theme.colors.gray}
                           />
                        </InlineElementsWrapper>
                        <StyledText
                           width={'100%'}
                           text={item.space_name}
                           variant={StyledTextVariantsType.HEADING_3}
                           margin={'0 0 4px 0'}
                        />
                        <StyledText
                           width={'100%'}
                           text={`Checkin type: ${capitalize(item.checkin_type)}`}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                        />
                     </CheckinHistoryItem>
                  </SettingsItem>
               ))
            ) : (
               <NoDataPlaceholderComponent />
            )}
         </Flex>
      </Flex>
   );
};

export default CheckinHistory;
