import React from 'react';
import Modal from 'react-modal';
import theme from 'src/styles/theme';

type ModalSize = 'small' | 'medium' | 'large';

interface IProps {
   size: ModalSize;
   padding?: string;
   minWidth?: number;
   width?: string;
   height?: string;
   visible: boolean;
   children: any;
   closeModal: () => void;
   shouldCloseOnOverlayClick?: boolean;
   shouldCloseOnEsc?: boolean;
   classNames?: string;
}

const small = (padding: any, width: any, height: any) => {
   return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: `${theme.colors.white}`,
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '15px',
      outline: 'none',
      padding: padding ? padding : '25px',
      width: width ? width : '33vw',
      maxWidth: width ? width : '33vw',
      height: height ? height : 'auto',
      maxHeight: '50vh',
   };
};

const medium = (padding: any, width: any, height: any) => {
   return {
      position: 'absolute',
      top: '53%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: `${theme.colors.white}`,
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '15px',
      outline: 'none',
      padding: padding ? padding : '25px',
      width: width ? width : '50vw',
      maxWidth: width ? width : '50vw',
      height: height ? height : 'auto',
      maxHeight: '90vh',
   };
};

const large = (padding: any, width: any) => {
   return {
      position: 'absolute',
      top: '80px',
      left: '80px',
      right: '80px',
      bottom: '80px',
      background: `${theme.colors.white}`,
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '15px',
      outline: 'none',
      padding: padding ? padding : '25px',
      width: width ? width : 'initial',
   };
};

Modal.setAppElement('#root');

const ModalComponent = (props: IProps) => {
   const modalStyles: any =
      props.size === 'small'
         ? small(props.padding, props.width, props.height)
         : props.size === 'medium'
         ? medium(props.padding, props.width, props.height)
         : props.size === 'large'
         ? large(props.padding, props.width)
         : small(props.padding, props.width, props.height);

   return (
      <Modal
         className={`app-modal-component${props.classNames ? ' ' + props.classNames : ''}`}
         overlayClassName={`${
            props.classNames && (props.classNames === 'error' || props.classNames === 'success') ? props.classNames : ''
         }`}
         isOpen={props.visible}
         onRequestClose={() => {
            props.closeModal && props.closeModal();
         }}
         onAfterClose={() => {
            props.closeModal && props.closeModal();
         }}
         shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
         shouldCloseOnEsc={props.shouldCloseOnEsc}
         style={{
            overlay: {
               position: 'fixed',
               top: 0,
               left: 0,
               right: 0,
               bottom: 0,
               backgroundColor: 'rgba(0,0,0, 0.75)',
            },
            content: modalStyles,
         }}
      >
         {props.children}
      </Modal>
   );
};

ModalComponent.defaultProps = {
   visible: false,
   shouldCloseOnOverlayClick: true,
   shouldCloseOnEsc: false,
} as Partial<IProps>;

export default ModalComponent;
