import styled from 'styled-components';
import theme from 'src/styles/theme';

//* EnterpriseSettings Item

export const SettingsItem = styled.div<any>`
   width: 100%;
   height: ${(props) => (props.height ? props.height : '100px')};
   display: ${(props) => (props.hidden ? 'none' : 'flex')};
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   background-color: ${theme.colors.white};
   padding: ${(props) => (props.padding ? props.padding : '0 0 16px 0')};
   margin: ${(props) => (props.margin ? props.margin : '0 0 32px 0')};
   border-bottom: 1px solid ${theme.colors.lightGray};
   cursor: pointer;

   &.active {
      border-bottom: 1px solid ${theme.colors.mainPurple};

      div {
         color: ${theme.colors.mainPurple} !important;
      }

      svg > path {
         stroke: ${theme.colors.mainPurple} !important;
      }
   }

   div {
      cursor: pointer;
   }
`;

export const SettingsCardItem = styled.div<any>`
   width: 100%;
   margin-bottom: 32px;
   height: 232px;
   display: ${(props) => (props.hidden ? 'none' : 'flex')};
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   border-radius: 15px;
   background-color: ${theme.colors.grayBackground};
   cursor: pointer;
   padding: 32px;

   &.active {
      border-bottom: 1px solid ${theme.colors.mainPurple};

      div {
         color: ${theme.colors.mainPurple} !important;
      }

      svg > path {
         stroke: ${theme.colors.mainPurple} !important;
      }
   }

   div {
      cursor: pointer;
   }

   @media only screen and (max-width: 768px) {
      height: auto;
      padding: 12px;
   }
`;

export const SettingsItemIcon = styled.div`
   width: auto;
   height: auto;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 8px;

   img {
      height: 24px;
      width: auto;
   }
`;

export const SettingsItemContent = styled.div`
   width: 100%;
   height: auto;
   display: flex;
   justify-content: flex-start;
   align-items: stretch;
   cursor: pointer;

   div,
   img {
      cursor: pointer;
   }
`;

export const SettingsItemTitle = styled.div<any>`
   width: ${(props) => (props.width ? props.width : '80%')};
   height: auto;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
`;

export const SettingsItemArrow = styled.div`
   width: 20%;
   height: auto;
   display: flex;
   justify-content: flex-end;
   align-items: center;

   > div {
      justify-content: flex-end;
   }

   .MuiCircularProgress-root {
      width: auto !important;
      height: auto !important;
   }

   svg {
      height: 24px;
      width: auto;
   }
`;
export const Container = styled.div`
   width: 90%;
   display: flex;
   justify-content: center;
   align-items: center;
   @media only screen and (max-width: 768px) {
      width: 100%;
   }
   .StripeElement {
      width: 100%;
      margin-bottom: 24px;
      border-radius: 15px;
      padding: 15px 20px;
      border: 1px solid ${theme.colors.gray};
   }
`;
