import { createSlice } from '@reduxjs/toolkit';
import { getFirebase } from 'react-redux-firebase';

import { RootState } from 'src/app/rootReducer';

export type AuthError = {
   code: string;
   message: string;
   id: string;
};

export type AuthState = {
   loading: 'idle' | 'pending';
   currentRequestId: undefined;
   error: AuthError | undefined;
};

export const signOutFromFirebase = async () => {
   const firebase = getFirebase();

   localStorage.clear();
   await firebase.database().goOffline();
   await firebase.logout();
   await firebase.database().goOnline();
};

const initialState: AuthState = {
   loading: 'idle',
   currentRequestId: undefined,
   error: undefined,
};

export const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {},
});

export const selectFirebaseAuthLoader = (state: RootState) => {
   return state.firebase.auth.isLoaded;
};

export default authSlice.reducer;
