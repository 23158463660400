import React, { ReactElement, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import { Button, Flex, ModalComponent, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { ButtonVariantsType } from 'src/components/Button';
import { CustomizableImg } from 'src/components/SharedComponents';

import { translate } from 'src/translations/translations';
import { CheckInIllustration } from 'src/assets/images';
import theme from 'src/styles/theme';

const CheckinOptionItem = styled.div`
   width: 100%;
   height: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 16px;
   margin-bottom: 16px;
   font-size: 16px;
   font-weight: 400;
   color: ${theme.colors.black};
   border-radius: 15px;
   background-color: ${theme.colors.white};
   border: 1px solid ${theme.colors.gray};
   cursor: pointer;

   &.selected {
      border: 1px solid ${theme.colors.mainPurple};
      background-color: ${theme.colors.mainPurple};
      color: ${theme.colors.white};
   }

   strong {
      font-size: 18px;
      font-weight: 700;
      margin: 0;
      cursor: pointer;
   }
`;

interface IProps {
   isOpen: boolean;
   setShowModal: (value: boolean) => void;
   checkInLoading: boolean;
   checkIn: (selectedCheckinOption?: any) => void;
   checkInOptionsData: any;
}

const CheckInOptionsModal = ({
   setShowModal,
   isOpen,
   checkInLoading,
   checkInOptionsData,
   checkIn,
}: IProps): ReactElement => {
   const [selectedCheckinOption, setSelectedCheckinOption] = useState<any>(null);

   const isOptionSelected = (selectedOption: string, optionToCheck: string) => {
      return selectedOption === optionToCheck;
   };

   const renderCheckinLoader = () => (
      <>
         <StyledText
            text={translate('checkInModal.title')}
            margin={'0 0 16px 0'}
            width={'100%'}
            variant={StyledTextVariantsType.HEADING_1}
         />
         <CustomizableImg src={CheckInIllustration} height={'auto'} width={'75%'} margin={'0 auto'} />
         <StyledText
            text={translate('checkInModal.text')}
            margin={'16px 0 16px 0'}
            width={'100%'}
            variant={StyledTextVariantsType.PARAGRAPH_2}
         />
         <CircularProgress size={25} />
      </>
   );

   const renderCheckinOptions = () => (
      <>
         <StyledText
            width={'100%'}
            text={translate('checkInModal.options.title')}
            variant={StyledTextVariantsType.HEADING_3}
            margin={'0 0 18px 0'}
            justifyContent={'center'}
         />
         <StyledText
            width={'75%'}
            text={translate('checkInModal.options.text')}
            variant={StyledTextVariantsType.PARAGRAPH_2}
            colorVariant={theme.colors.gray}
            margin={'0 0 32px 0'}
            justifyContent={'center'}
         />
         {checkInOptionsData.memberships.map((item: any, index: number) => (
            <CheckinOptionItem
               key={index}
               className={`${
                  isOptionSelected(item?.membership_id, selectedCheckinOption?.membership_id) ? 'selected' : ''
               }`}
               onClick={() => {
                  setSelectedCheckinOption(item);
               }}
            >
               Membership&nbsp;&nbsp;<strong>{item.membership_name}</strong>
            </CheckinOptionItem>
         ))}
         {checkInOptionsData.plans.map((item: any, index: number) => (
            <CheckinOptionItem
               key={index}
               className={`${isOptionSelected(item?.plan_id, selectedCheckinOption?.plan_id) ? 'selected' : ''}`}
               onClick={() => {
                  setSelectedCheckinOption(item);
               }}
            >
               Plan&nbsp;&nbsp;<strong>{item.plan_name}</strong>
            </CheckinOptionItem>
         ))}
         {checkInOptionsData.vouchers.map((item: any, index: number) => (
            <CheckinOptionItem
               key={index}
               className={`${isOptionSelected(item?.voucher_id, selectedCheckinOption?.voucher_id) ? 'selected' : ''}`}
               onClick={() => {
                  setSelectedCheckinOption(item);
               }}
            >
               Voucher&nbsp;&nbsp;<strong>{item.voucher_code}</strong>
            </CheckinOptionItem>
         ))}
         <Button
            disabled={!selectedCheckinOption}
            variant={ButtonVariantsType.PURPLE}
            text={translate('common.checkIn')}
            width={'100%'}
            margin={'40px 0 0 0'}
            onClick={(e: any) => {
               e.preventDefault();
               checkIn(selectedCheckinOption);
            }}
         />
      </>
   );

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'414px'}
         closeModal={() => {
            setShowModal(false);
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            {checkInLoading && checkInOptionsData ? renderCheckinLoader() : renderCheckinOptions()}
         </Flex>
      </ModalComponent>
   );
};

export default CheckInOptionsModal;
