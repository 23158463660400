import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { RoutesEnum } from 'src/routes/routes';
import { RootState } from 'src/app/rootReducer';
import { SpaceManagementTabEnum, UserRoles } from 'src/system/state/types';
import { translate } from 'src/translations/translations';
import { setSpaceanagementTab } from 'src/system/state/actions';
import {
   TodayTabIcon,
   SettingsTabIcon,
   InsightsTabIcon,
   SpacesTabIcon,
   TodayTabIconPurple,
   SettingsTabIconPurple,
   InsightsTabIconPurple,
   SpacesTabIconPurple,
} from 'src/assets/images';
import theme from 'src/styles/theme';
import ApiRequests from 'src/features/space_management/data/ApiRequests';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { parseError, useWindowWide } from 'src/utilities/helpers';

const BannerWrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 30px;
`;
const SubHeaderWrapper = styled.div`
   width: 100%;

   .sub-header {
      display: flex;
      align-items: center;
      padding: 50px 10% 54px 10%;

      > div {
         display: flex;
         justify-content: flex-start;
         margin-right: 36px;
         text-decoration: none;
         cursor: pointer;
         padding: 12px;
         font-weight: 500;
         font-size: 16px;
         color: ${theme.colors.gray};

         &.active {
            border-bottom: 2px solid ${theme.colors.black};
            color: ${theme.colors.black};

            svg {
               stroke: ${theme.colors.black};
            }

            svg > path {
               stroke: ${theme.colors.black};
            }
         }

         &.disabled {
            border-bottom: none;
            color: ${theme.colors.lightGray};
            opacity: 0.8;
            cursor: not-allowed;

            svg > path {
               stroke: ${theme.colors.lightGray};
            }
         }

         svg {
            margin-right: 10px;
         }
      }

      @media only screen and (max-width: 768px) {
         padding: 5% !important;
         flex-direction: column;

         > div {
            width: 100%;
            margin: 0;
         }
      }
   }

   @media only screen and (max-width: 768px) {
      .management-sub-header {
         padding: 10% 5% 0 5%;
      }
   }
`;
const SubHeaderWrapperMobile = styled.div`
   width: 100%;

   position: fixed;
   box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
   height: 100px;
   background-color: white;
   bottom: 0px;
   z-index: 100;
   display: flex;
   justify-content: 'center';

   .sub-header {
      bottom: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 1% !important;
      flex-direction: row;

      a {
         display: flex;
         justify-content: center;
         margin-right: 36px;
         text-decoration: none;
         cursor: pointer;
         padding: 12px;
         font-weight: 500;
         font-size: 16px;
         color: ${theme.colors.gray};

         &.active {
            color: ${theme.colors.black};

            > svg {
               stroke: ${theme.colors.black};
            }

            svg > path {
               stroke: ${theme.colors.black};
            }

            &.no-border {
               border: none !important;
            }
         }

         img {
            margin-right: 10px;
         }
      }

      > a {
         width: 100% !important;
         margin: 0;
      }

      input {
         margin-top: 40px;
      }
   }
`;

const SpaceManagementLayoutSubHeader: React.FunctionComponent<unknown> = () => {
   const history = useHistory();
   const dispatch = useDispatch();
   const selectedSpaceManagementTab = useSelector((state: RootState) => state.systemReducer).selectedSpaceManagementTab;
   const customerRole = useSelector((state: RootState) => state.systemReducer).customerRole;
   const spaceId = useSelector((state: RootState) => state.systemReducer).spaceId;
   const [status, setStatus] = useState('');

   const wide = useWindowWide(768);

   const isTabActive = (tabToCheck: SpaceManagementTabEnum) => {
      return selectedSpaceManagementTab === tabToCheck;
   };
   const getSpace = async () => {
      try {
         const response = await ApiRequests.getSpaceDetails(spaceId as string);
         if (response && response.data) {
            setStatus(response.data.status);
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpaces'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.getSpaces'));
      }
   };

   const setSelectedTab = (tab: SpaceManagementTabEnum) => {
      dispatch(setSpaceanagementTab(tab));
   };

   const disableTabs = () => customerRole !== UserRoles.SPACE_MANAGER && !spaceId;

   useEffect(() => {
      if (spaceId) {
         void getSpace();
      }
      // eslint-disable-next-line
   }, [spaceId]);
   return (
      <>
         {wide ? (
            <SubHeaderWrapper>
               {status === '4' && (
                  <BannerWrapper>
                     <div
                        className="alert alert-primary"
                        role="alert"
                        style={{
                           backgroundColor: '#897DFD',
                           borderColor: '#897DFD',
                           color: 'white',
                           width: '80%',
                           marginBottom: 0,
                        }}
                     >
                        {translate('spaceManagement.bannerText')}
                     </div>
                  </BannerWrapper>
               )}

               <div className={'sub-header'}>
                  <div
                     className={disableTabs() ? 'disabled' : isTabActive(SpaceManagementTabEnum.TODAY) ? 'active' : ''}
                     onClick={() => {
                        if (!disableTabs()) {
                           setSelectedTab(SpaceManagementTabEnum.TODAY);
                           history.push({
                              pathname: RoutesEnum.SPACE_MANAGEMENT_TODAY,
                           });
                        }
                     }}
                  >
                     <TodayTabIcon />
                     {translate('spaceManagement.tabs.today')}
                  </div>

                  <div
                     className={isTabActive(SpaceManagementTabEnum.SETTINGS) ? 'active' : ''}
                     onClick={() => {
                        setSelectedTab(SpaceManagementTabEnum.SETTINGS);
                        history.push({
                           pathname: RoutesEnum.SPACE_MANAGEMENT_SETTINGS,
                        });
                     }}
                  >
                     <SettingsTabIcon />
                     {translate('spaceManagement.tabs.settings')}
                  </div>

                  <div
                     className={
                        disableTabs() ? 'disabled' : isTabActive(SpaceManagementTabEnum.INSIGHTS) ? 'active' : ''
                     }
                     onClick={() => {
                        if (!disableTabs()) {
                           setSelectedTab(SpaceManagementTabEnum.INSIGHTS);
                           history.push({
                              pathname: RoutesEnum.SPACE_MANAGEMENT_INSIGHTS,
                           });
                        }
                     }}
                  >
                     <InsightsTabIcon />
                     {translate('spaceManagement.tabs.insights')}
                  </div>

                  <div
                     className={
                        disableTabs() ? 'disabled' : isTabActive(SpaceManagementTabEnum.YOUR_SPACES) ? 'active' : ''
                     }
                     onClick={() => {
                        if (!disableTabs()) {
                           setSelectedTab(SpaceManagementTabEnum.YOUR_SPACES);
                           history.push({
                              pathname: RoutesEnum.SPACE_MANAGEMENT_YOUR_SPACES,
                           });
                        }
                     }}
                  >
                     <SpacesTabIcon />
                     {translate('spaceManagement.tabs.yourSpaces')}
                  </div>
               </div>
            </SubHeaderWrapper>
         ) : (
            <SubHeaderWrapperMobile>
               {status === '4' && (
                  <BannerWrapper>
                     <div
                        className="alert alert-primary"
                        role="alert"
                        style={{
                           backgroundColor: '#897DFD',
                           borderColor: '#897DFD',
                           color: 'white',
                           width: '80%',
                           marginBottom: 0,
                        }}
                     >
                        {translate('spaceManagement.bannerText')}
                     </div>
                  </BannerWrapper>
               )}

               <div className={'sub-header'}>
                  <div
                     className={disableTabs() ? 'disabled' : isTabActive(SpaceManagementTabEnum.TODAY) ? 'active' : ''}
                     onClick={() => {
                        if (!disableTabs()) {
                           setSelectedTab(SpaceManagementTabEnum.TODAY);
                           history.push({
                              pathname: RoutesEnum.SPACE_MANAGEMENT_TODAY,
                           });
                        }
                     }}
                  >
                     {isTabActive(SpaceManagementTabEnum.TODAY) ? <TodayTabIconPurple /> : <TodayTabIcon />}
                  </div>

                  <div
                     className={isTabActive(SpaceManagementTabEnum.SETTINGS) ? 'active' : ''}
                     onClick={() => {
                        setSelectedTab(SpaceManagementTabEnum.SETTINGS);
                        history.push({
                           pathname: RoutesEnum.SPACE_MANAGEMENT_SETTINGS,
                        });
                     }}
                  >
                     {isTabActive(SpaceManagementTabEnum.SETTINGS) ? <SettingsTabIconPurple /> : <SettingsTabIcon />}
                  </div>

                  <div
                     className={
                        disableTabs() ? 'disabled' : isTabActive(SpaceManagementTabEnum.INSIGHTS) ? 'active' : ''
                     }
                     onClick={() => {
                        if (!disableTabs()) {
                           setSelectedTab(SpaceManagementTabEnum.INSIGHTS);
                           history.push({
                              pathname: RoutesEnum.SPACE_MANAGEMENT_INSIGHTS,
                           });
                        }
                     }}
                  >
                     {isTabActive(SpaceManagementTabEnum.INSIGHTS) ? <InsightsTabIconPurple /> : <InsightsTabIcon />}
                  </div>

                  <div
                     className={
                        disableTabs() ? 'disabled' : isTabActive(SpaceManagementTabEnum.YOUR_SPACES) ? 'active' : ''
                     }
                     onClick={() => {
                        if (!disableTabs()) {
                           setSelectedTab(SpaceManagementTabEnum.YOUR_SPACES);
                           history.push({
                              pathname: RoutesEnum.SPACE_MANAGEMENT_YOUR_SPACES,
                           });
                        }
                     }}
                  >
                     {isTabActive(SpaceManagementTabEnum.YOUR_SPACES) ? <SpacesTabIconPurple /> : <SpacesTabIcon />}
                  </div>
               </div>
            </SubHeaderWrapperMobile>
         )}
      </>
   );
};

export default SpaceManagementLayoutSubHeader;
