import React, { ReactElement, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';

import { Button, GoogleAdressAutocomplete, Flex, Input, StyledText } from 'src/components';
import { LabelWrapper, LabelStar } from 'src/components/Input';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { ButtonVariantsType } from 'src/components/Button';

import { AddSpaceFormIllustration, AddSpaceScreenIllustration } from 'src/assets/images';
import { UserRoles } from 'src/system/state/types';
import ApiRequests from 'src/features/space_management/data/ApiRequests';
import { ICreateSpaceRequestDTO, IGoogleAddressDTO } from 'src/features/space_management/data/RequestDtos';
import { setCustomerRole, setSpaceId } from 'src/system/state/actions';
import { parseError, parseGoogleAutoCompleteResponse, scrollToTop, useWindowWide } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';

const INITIAL_SPACE_STATE: ICreateSpaceRequestDTO = {
   manager_uuid: '',
   name: '',
   country: '',
   city: '',
   zip: '',
   floor: '',
   street_number: '',
   provider: '',
   description: '',
   wifi_text: '',
   coffee_text: '',
   printer_text: '',
   website: '',
   instagram: '',
   facebook: '',
   twitter: '',
   space_capacity: '',
};

const INITIAL_GOOGLE_ADDRESS: IGoogleAddressDTO = {
   address: '',
   country: '',
   city: '',
   zip: '',
   street_number: '',
   longitude: '',
   latitude: '',
};

const CreateSpace = (): ReactElement => {
   const dispatch = useDispatch();
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const wide = useWindowWide(768);

   const [loading, setLoading] = useState(false);
   const [space, setSpace] = useState<ICreateSpaceRequestDTO>(INITIAL_SPACE_STATE);
   const [googleAddress, setGoogleAddress] = useState<IGoogleAddressDTO>(INITIAL_GOOGLE_ADDRESS);
   const [showCreateSpaceForm, setShowCreateSpaceForm] = useState(false);
   const isFormValid = () => {
      return space.name && googleAddress && space.description && space.space_capacity;
   };

   const createSpace = async () => {
      try {
         setLoading(true);
         const response: any = await ApiRequests.createSpace({
            payload: {
               ...space,
               country: googleAddress.country,
               address: googleAddress.address,
               city: googleAddress.city,
               zip: googleAddress.zip,
               latitude: googleAddress.latitude,
               longitude: googleAddress.longitude,
               manager_uuid: currentUser?.uid as string,
            },
         });

         if (response.data) {
            dispatch(setCustomerRole(UserRoles.SPACE_MANAGER));
            dispatch(setSpaceId(response.data.space.space_id));
            NotificationsProvider.success(response.data.message);
         } else {
            NotificationsProvider.error(response.error?.message || translate('spaceManagement.error.createSpace'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('spaceManagement.error.createSpace'));
      } finally {
         setLoading(false);
      }
   };

   const handleGoogleAutocompleteSelect = (value: any) => {
      const { address, street_number, city, zip_code, country, latitude, longitude } =
         parseGoogleAutoCompleteResponse(value);
      setGoogleAddress({
         address,
         street_number,
         city,
         zip: zip_code,
         country,
         longitude,
         latitude,
      });
   };

   const renderAddSpaceScreen = () => (
      <>
         <Flex
            justifyContent="flex-start"
            alignItems="stretch"
            flexWrap="nowrap"
            backgroundColor={'#F9F9F9'}
            borderRadius={'15px'}
            padding={'5% 10%'}
            margin={'5% 10% '}
            flex={1}
            position={'relative'}
            flexDirectionMobile={'column'}
            alignItemsMobile={'center'}
         >
            <Flex
               width={'32%'}
               widthMobile={'32%'}
               flexDirection="column"
               justifyContent="flex-start"
               backgroundColor={'#F9F9F9'}
               alignItems="flex-start"
               position={'relative'}
               overflow={'hidden'}
            >
               <img src={AddSpaceScreenIllustration} alt="add-company" style={{ width: '85%', height: 'auto' }} />
            </Flex>

            <Flex
               width={'68%'}
               flexDirection="column"
               justifyContent="center"
               backgroundColor={'#F9F9F9'}
               alignItems="center"
               position={'relative'}
               padding={'0 0 0 32px'}
               overflow={'hidden'}
            >
               <StyledText
                  width={wide ? '90%' : '100%'}
                  justifyContent={'flex-start'}
                  text={translate('spaceManagement.settings.addSpaceScreen.text')}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  margin={'0 0 32px 0'}
               />

               <Button
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('spaceManagement.settings.addSpaceScreen.button.text')}
                  width={'90%'}
                  mobileWidth={'100%'}
                  paddingMobile={'10'}
                  onClick={() => {
                     setShowCreateSpaceForm(true);
                     scrollToTop();
                  }}
               />
            </Flex>
         </Flex>
      </>
   );

   const renderaddSpaceForm = () => (
      <>
         <StyledText
            width={'80%'}
            justifyContent={'flex-start'}
            text={translate('spaceManagement.settings.addSpaceForm.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 40px 10%'}
         />

         <Flex
            justifyContent="flex-start"
            alignItems="stretch"
            flexWrap="nowrap"
            flex={1}
            mobileMinHeight={'100%'}
            position={'relative'}
            margin={'40px 10% 40px 10%'}
         >
            <Flex
               width={'32%'}
               flexDirection="column"
               justifyContent="flex-start"
               alignItems="flex-start"
               position={'relative'}
               overflow={'hidden'}
            >
               <img src={AddSpaceFormIllustration} alt="add-space" style={{ width: '85%', height: 'auto' }} />
            </Flex>

            <Flex
               width={'68%'}
               flexDirection="column"
               justifyContent="flex-start"
               alignItems="center"
               position={'relative'}
               padding={'0 0 0 64px'}
               overflow={'hidden'}
            >
               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.name')}
                  label={translate('spaceManagement.settings.addSpaceForm.placeholders.name')}
                  onChange={(value) => setSpace({ ...space, name: value })}
                  validators={['required']}
                  value={space.name}
               />

               <LabelWrapper width={'100%'}>
                  <StyledText
                     text={translate('spaceManagement.settings.addSpaceForm.placeholders.address')}
                     variant={StyledTextVariantsType.LABEL_TEXT}
                  />
                  <LabelStar>*</LabelStar>
               </LabelWrapper>
               <GoogleAdressAutocomplete
                  margin={'0 0 24px 0'}
                  onPlaceSelectHandler={(response: any) => {
                     handleGoogleAutocompleteSelect(response);
                  }}
                  defaultValue={''}
               />

               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  label={translate('spaceManagement.settings.addSpaceForm.placeholders.description')}
                  placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.description')}
                  onChange={(value) => setSpace({ ...space, description: value })}
                  validators={['required']}
                  value={space.description}
               />

               <Input
                  width={'100%'}
                  type={'number'}
                  min={0}
                  margin={'0 0 24px 0'}
                  label={translate('spaceManagement.settings.addSpaceForm.placeholders.spaceCapacity')}
                  placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.spaceCapacity')}
                  onKeyDown={(value) => value.preventDefault()}
                  onChange={(value) => setSpace({ ...space, space_capacity: value })}
                  validators={['required']}
                  value={space.space_capacity}
               />

               <Button
                  disabled={!isFormValid()}
                  isLoading={loading}
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('common.continue')}
                  width={'100%'}
                  onClick={createSpace}
               />
            </Flex>
         </Flex>
      </>
   );

   return (
      <Flex
         justifyContent="flex-start"
         flexDirection={'column'}
         alignItems="flex-start"
         position={'relative'}
         width={'100%'}
      >
         {showCreateSpaceForm ? renderaddSpaceForm() : renderAddSpaceScreen()}
      </Flex>
   );
};

export default CreateSpace;
