export default {
   'routes.acceptInvite': 'Accept invite',
   'routes.home': 'Home',
   'routes.map': 'Map',
   'routes.signUp': 'Sign up',
   'routes.space.details': 'Space details',
   'routes.spaces.map': 'Spaces map',
   'routes.reserveMeetingRoom': 'Book a meeting room',
   'routes.settings': 'Settings',
   'routes.enterpriseManagement': 'Enterprise management',
   'routes.enterpriseManagement.userProfile': 'User profile',
   'routes.enterpriseManagement.spaceDetails': 'Space details',
   'routes.spaceManagement': 'Space management',
   'routes.spaceManagement.spaceDetails': 'Space details',
   'routes.account': 'Account',
   'routes.billing': 'Plans Billing',
   'routes.legals': 'Legal information',

   'errors.MIN_NOT_REACHED': 'Minimum chars not reached',
   'errors.MAX_EXCEEDED': 'Maximum chars exceeded',
   'errors.INVALID_EMAIL': 'Email is not valid',
   'errors.NO_LOWERCASE': 'At least one lowercase letter is required',
   'errors.NO_UPPERCASE': 'At least one uppercase letter is required',
   'errors.NO_MATCH': 'Has to be same',
   'errors.NO_NUMBER': 'At least one number is required',
   'errors.REQUIRED': 'This field is required',

   'placeholders.name': 'Enter your name',
   'placeholders.email': 'Enter your email',
   'placeholders.password': 'Enter your password',
   'placeholders.activateVoucher': 'Enter voucher code',
   'placeholders.searchCoworkingSpaces': 'Search for coworking spaces...',
   'placeholders.search': 'Search...',
   'placeholders.billingAddress': 'Billing address',
   'placeholders.billingCity': 'Billing city',
   'placeholders.billingCountry': 'Billing country',
   'placeholders.billingZipCode': 'Billing zip code',
   'placeholders.userEmail': 'User email',
   'placeholders.date': 'Select a date...',

   'common.signIn': 'Sign in',
   'common.filters': 'Filters',
   'common.register': 'Register',
   'common.joinWithSocialMediaAcc': 'Or join with your social media account',
   'common.continue': 'Continue',
   'common.continueWithFb': 'Continue with Facebook',
   'common.continueWithApple': 'Continue with Apple',
   'common.continueWithGoogle': 'Continue with Google',
   'common.logout': 'Logout',
   'common.checkIn': 'Check in now',
   'common.viewTicket': 'View ticket',
   'common.home': 'Home',
   'common.mapView': 'Map view',
   'common.location': 'Location',
   'common.settings': 'Settings',
   'common.reserveMeetingRoom': 'Reserve a meeting room',
   'common.account': 'Account',
   'common.legals': 'Legal information',
   'common.next': 'Next',
   'common.goBack': 'Go back',
   'common.back': 'Back',
   'common.saveChanges': 'Save changes',
   'common.saveDetails': 'Save details',
   'common.billing': 'Plans & Billing',
   'common.upgrade': 'Upgrade',
   'common.purchasePlan': 'Purchase plan',
   'common.noPlans': 'There are no plans added yet!',
   'common.checkout': 'Checkout',
   'common.monthlyMembership': 'Monthly membership',
   'common.needHelp': 'Need help?',
   'common.choosePlan': 'Choose plan',
   'common.checkInHistory': 'Checkin history',
   'common.checkedInAt': 'Checked in at',
   'common.validUntil': 'Ends at',
   'common.checkinOptionName': 'Checkin option name',
   'common.checkinOptionType': 'Checkin option type',
   'common.currentCheckins': 'Used checkins',
   'common.maxCheckins': 'Max checkins',
   'common.checkInType': 'Checkin type',
   'common.currency': 'Currency',
   'common.confirm': 'Confirm',
   'common.cancel': 'Cancel',
   'common.perMonth': 'Per month',
   'common.invoices': 'Invoices',
   'common.welcomeBack': 'Welcome back',
   'common.dismiss': 'Dismiss',
   'common.connected': 'Connected',
   'common.notConnected': 'Not connected',
   'common.selectedFile': 'Selected file:',
   'common.uploadPhoto': 'Upload photo',
   'common.today': 'Today',
   'common.yourSpaces': 'Your spaces',
   'common.insights': 'Insights',
   'common.joinedAt': 'Joined at',

   'common.success.register': 'User signed up successfully.',
   'common.success.activateVoucher': 'Voucher activated successfully.',
   'common.success.resetPassword': 'Reset password link sent successfully.',

   'common.error.signInPlease': 'Sign in and try again.',
   'common.error.signIn': 'Error occurred while signing in.',
   'common.error.resetPassword': 'Error occurred on password reset.',
   'common.error.register': 'Error occurred while registering.',
   'common.error.googleSignIn': 'Error occurred while signing in with Google.',
   'common.error.appleSignIn': 'Error occurred while signing in with Apple ID.',
   'common.error.facebookSignIn': 'Error occurred while signing in with Facebook.',
   'common.error.linkAccount': 'Error occurred while linking account.',
   'common.error.getCustomer': 'Error occurred while loading customer data.',
   'common.error.createCustomer': 'Error occurred while creating customer.',
   'common.error.activateVoucher': 'Error occurred while activating voucher.',
   'common.error.getUserDetails': 'Error occurred on getting user details.',
   'common.error.missingUserPhoto': 'User photo not found.',
   'common.error.updateUser': 'Error occurred on updating user.',

   'notFound.title': 'This page is unknown or does not exist',
   'notFound.text':
      'The link you followed is either outdated or inaccurate, please click on the button below to return to spaces page.',
   'notFound.button.text': 'Back to spaces',

   'noDataPlaceholder.title': 'We’ve got nothing to show...',
   'noDataPlaceholder.text': 'Well this is awkward...',

   'loginModal.title': 'Login',
   'loginModal.subtitle': 'Welcome back! Enter your credentials and start coworking today!',
   'loginModal.resetPassword.text': "Can't login? Click to reset your password",

   'resetPasswordModal.resetPassword.title': 'Reset password',
   'resetPasswordModal.resetPassword.text':
      'Forgot your password? Please enter your email and click button bellow to reset your password.',
   'resetPasswordModal.resetPassword.button.text': 'Reset password',

   'registerModal.title': 'Register',
   'registerModal.subtitle': 'Find the best coworking spaces around you!',

   'acceptInvite.loginWithEmailMessage.text1': 'Please sign in with',
   'acceptInvite.loginWithEmailMessage.text2': 'to accept your invite.',
   'acceptInvite.text':
      'has invated you to join their team. Please accept or decline within the hour before the invitation expires.',
   'acceptInvite.title': 'Company invitation',
   'acceptInvite.button.text': 'Accept invite',
   'acceptInvite.retryButton.text': 'Retry',
   'acceptInvite.declineButton.text': 'Decline',
   'acceptInvite.error.missingUrlToken': 'Missing token in url',
   'acceptInvite.error.getUserInfoFromToken': 'Error occured while getting user info from token',
   'acceptInvite.error.missingInviteToken': 'Missing invite token on accept invitation',
   'acceptInvite.error.uuidMissmatch': 'Current user uuid mismatch',
   'acceptInvite.error.emailMissmatch': 'Current user email mismatch',
   'acceptInvite.error': 'Error occured on accepting invite',

   'verifyEmailModal.title': 'Verify email',
   'verifyEmailModal.subtitle':
      "We've sent a confirmation link to your email. Please click on it to confirm your account.",
   'verifyEmailModal.button.text': 'Verify email',
   'verifyEmailModal.note.text': "Email wasn't sent?",
   'verifyEmailModal.note.link': 'Resend email',
   'verifyEmailModal.success.resend': 'Verification link resent.',
   'verifyEmailModal.error.resend': 'Error occurred while resending verification link.',
   'verifyEmailModal.error.notVerified': 'You did not verify your email address',

   'addCreditCard.title': 'Add credit card info for faster checkin!',
   'addCreditCard.text':
      'Add your credit card details and you have an option to use hourly passes or monthly memberships in specific cities hassle free!',
   'addCreditCard.button.text': 'Add credit card',
   'addCreditCard.stripe.error': 'Error occurred while adding credit card on stripe.',
   'addCreditCard.error': 'Error occurred while adding credit card.',

   'home.title': 'Where to work today...',
   'home.searchResult.title': 'Search results:',
   'home.offices.title': 'Your offices',
   'home.searchResult.emptyState.message': 'No matches found',
   'home.filteredByCity.title': 'All spaces from',
   'home.error.getCoworkingSpaces': 'Error occurred while getting co-working spaces.',

   'mapView.error.getCoworkingSpaces': 'Error occurred while getting co-working spaces locations.',
   'mapView.error.getCurrentLocation': 'Error occurred while getting current location.',

   'coWorkingSpaceDetails.workHours.title': 'Work hours',
   'coWorkingSpaceDetails.communityManagers.title': 'Community managers',
   'coWorkingSpaceDetails.socialMedia.title': 'Social media',
   'coWorkingSpaceDetails.checkInData.title.part1': 'Checked in at:',
   'coWorkingSpaceDetails.checkInData.title.part2': 'View ticket',
   'coWorkingSpaceDetails.checkInData.note': 'View ticket',
   'coWorkingSpaceDetails.onboarding.title': 'Onboarding information',
   'coWorkingSpaceDetails.error.getData': 'Error occurred while getting co-working space details.',
   'coWorkingSpaceDetails.error.missingId': 'Missing co-working space id.',
   'coWorkingSpaceDetails.error.checkIn': 'Error occurred while checking in.',
   'coWorkingSpaceDetails.error.checkIn.noAvailableSeats': 'No available seats.',
   'coWorkingSpaceDetails.error.getCheckInData': 'Error occurred while getting check-in data.',
   'coWorkingSpaceDetails.vibes.amenities.title': 'Vibes & amenities',
   'coWorkingSpaceDetails.favorites.error': 'Error accured while adding to favorites',

   'checkInDetailsModal.checkinId': 'Checkin ID',
   'checkInDetailsModal.checkinTime': 'Checkin Time',
   'checkInDetailsModal.user': 'User',
   'checkInDetailsModal.checkinType': 'Type of checkin',
   'checkInDetailsModal.checkinTypeName': 'Checkin type name',

   'checkInModal.title': 'Checking you in! Please wait...',
   'checkInModal.text':
      'Please wait while we checkin you in. You will receive a digital ticket that you may be asked to show to the communiy manager or at the coworking space reception.',
   'checkInModal.options.title': 'Select checkin option',
   'checkInModal.options.text':
      'We detected more than one checkin option on your account. Please select which one to use for this checkin',
   'checkInModal.options.button1.text': 'Plan Tryout',
   'checkInModal.options.button2.text': 'Voucher 5DAS',

   'coWorkingSpacesCarousel.label': 'Available spaces at the moment:',

   'reserveMeetingRoom.title': 'Request meeting room',
   'reserveMeetingRoom.text':
      'We’re working hard on new features. Ability to reserve a meeting room in a coworking space is coming soon to selected locations. Keep an eye on our mailing campaing once we roll it out in your area!',
   'reserveMeetingRoom.button': 'Coming soon',
   'reserveMeetingRoom.where': 'Where',
   'reserveMeetingRoom.country.city': 'Berlin, Germany',
   'reserveMeetingRoom.when': 'When',
   'reserveMeetingRoom.groupsize': 'Groupsize',
   'reserveMeetingRoom.amenities': 'Amenities',
   'reserveMeetingRoom.startingDate': 'Starting date',
   'reserveMeetingRoom.time': 'Time',
   'reserveMeetingRoom.recurring': 'Recurring',
   'reserveMeetingRoom.endingDate': 'Ending date',
   'reserveMeetingRoom.request': 'Request meeting room',
   'reserveMeetingRoom.requirements': 'Additional requirements',
   'reserveMeetingRoom.select.city': 'Select a city',
   'reserveMeetingRoom.select.group': 'Select group size',
   'reserveMeetingRoom.select.amenities': 'Select amenities',
   'reserveMeetingRoom.custom.amenities': 'Custom',
   'reserveMeetingRoom.apply': 'Apply',
   'reserveMeetingRoom.TimeFrom': 'From',
   'reserveMeetingRoom.TimeTo': 'To',
   'reserveMeetingRoom.error': 'Error occurred while requesting meeting room',
   'reserveMeetingRoomLogIn.text': 'You need to be logged in to use this feature.',
   'reserveMeetingRoomSignIn.button.text': 'Sign in',
   'reserveMeetingRoomRegister.button.text': 'Register',
   'reserveMeetingRoom.success.message': 'Request successfully submitted! We will be in touch soon!',
   'reserveMeetingRoom.new.request': 'Create a new request',
   'reserveMeetingRoom.previous.bookings': 'Previous bookings',
   'reserveMeetingRoom.previous.groupsize.error.max': 'The maximum number of people is 150.',
   'reserveMeetingRoom.previous.groupsize.error.min': 'The minimum number of people is 1.',

   'settingsList.account.title': 'Personal information',
   'settingsList.account.subtitle': 'Review your personal information',
   'settingsList.billing.title': 'Plans & billing',
   'settingsList.billing.subtitle': 'Purchase a plan or edit your payment details',
   'settingsList.checkinHistory.title': 'Checkin history',
   'settingsList.checkinHistory.subtitle': 'View your past checkins',
   'settingsList.activateVoucher.title': 'Activate a voucher',
   'settingsList.activateVoucher.subtitle': 'Get more checkins on your account',
   'settingsList.activity.title': 'Your activity',
   'settingsList.activity.subtitle': 'Review all of your data',
   'settingsList.checkinCredits.title': 'Check-in credits',
   'settingsList.checkinCredits.subtitle': 'View your current checkin options',
   'settingsList.checkinCredits.optionsList.title': 'Checkin options',
   'settingsList.legalInfo.title': 'Legal information',
   'settingsList.legalInfo.subtitle': 'Review your terms with OneCo',
   'settingsList.switchToHRManagement.title': 'Enterprise management',
   'settingsList.switchToHRManagement.subtitle': 'Convert your account to enterprise manager',
   'settingsList.switchToSpaceManagement.title': 'Space management',
   'settingsList.switchToSpaceManagement.subtitle': 'Convert your account to space manager',
   'settingsList.logout.title': 'Logout',
   'settingsList.logout.subtitle': 'We’re sad to see you go',

   'settings.checkinHistory.error.getHistory': 'Error occurred while getting checkin history',
   'settings.checkinCredits.error.getOptions': 'Error occurred while getting checkin options',

   'settings.account.basicInfo.title': 'Basic information',
   'settings.account.basicInfo.subtitle': 'Edit your basic profile info',
   'settings.account.basicInfo.form.title': 'Basic info',
   'settings.account.basicInfo.form.labels.name': 'Name',
   'settings.account.basicInfo.form.labels.biography': 'Biography',
   'settings.account.basicInfo.form.labels.profession': 'Profession / current occupation',
   'settings.account.basicInfo.form.labels.description': 'Short description',

   'settings.account.changePw.title': 'Change password',
   'settings.account.changePw.subtitle': 'Update your account password',
   'settings.account.changePw.form.title': 'New password',
   'settings.account.changePw.form.labels.newPassword': 'New password',
   'settings.account.changePw.form.labels.confirmPassword': 'Confirm password',
   'settings.account.changePw.success': 'Password updated successfully.',
   'settings.account.changePw.error': "Error occurred on updating user's password",

   'settings.account.profilePhoto.title': 'Profile photo',
   'settings.account.profilePhoto.subtitle': 'Upload a stylish profile photo',
   'settings.account.profilePhoto.form.title': 'Profile photo',
   'settings.account.profilePhoto.form.buttonText': 'Select photo',
   'settings.account.profilePhoto.error.missingPhoto': 'Profile photo not selected.',
   'settings.account.profilePhoto.success.uploadPhoto': 'Photo uploaded successfilly.',
   'settings.account.profilePhoto.error.uploadPhoto': 'Error occurred while uploading profile photo.',
   'settings.account.profilePhoto.error.getPhoto': 'Error occurred while getting profile photo.',

   'settings.account.signInMethods.title': 'Social media',
   'settings.account.signInMethods.subtitle': 'Add additional sign in methods',

   'settings.billing.purchasePlan.toggle': 'show / hide purchased plan',
   'settings.billing.purchasePlan.title': 'Purchase plan',
   'settings.billing.purchasePlan.subtitle': 'Choose a plan that works best for you',
   'settings.billing.purchasePlan.error.getPlans': 'Error occurred on getting plans.',
   'settings.billing.purchasePlan.error.subscribeToPlan': 'Error occurred while subscribing to a plan.',

   'settings.billing.activePlan.subtitle': 'Current plan / Upgrade, downgrade or cancel at any time',
   'settings.billing.activePlan.renewText': 'Renews at',
   'settings.billing.activePlan.changeSubscription.button.text': 'Change plan',
   'settings.billing.activePlan.cancelSubscription.button.text': 'Cancel plan',
   'settings.billing.cancelledPlan.cancelText': 'Canceled! Valid until on',
   'settings.billing.renew.subscription.button.text': 'Renew subscription',
   'settings.billing.cancelPlanModal.title': 'Cancel plan',
   'settings.billing.cancelPlanModal.text': 'Are you sure you want to cancel your plan?',
   'settings.billing.cancelPlanModal.confirmButton.text': 'Yes, cancel',
   'settings.billing.cancelPlanModal.cancelButton.text': 'No, do not cancel',
   'settings.billing.error.cancelPlan': 'Error occurred while cancelling plan',

   'settings.billing.activateVoucher.title': 'Activate a voucher',
   'settings.billing.activateVoucher.subtitle': 'Get more checkins on your account',
   'settings.billing.manageCreditCards.title': 'Manage credit cards',
   'settings.billing.manageCreditCards.subtitle': 'Review your personal information',
   'settings.billing.billingAddress.title': 'Change Billing Address',
   'settings.billing.billingAddress.subtitle': 'Address that shows up on your invoices',
   'settings.billing.invoices.title': 'View Your Invoices',
   'settings.billing.invoices.subtitle': 'View all of your subscription invoices',
   'settings.billing.invoices.error.getInvoices': 'Error occurred while getting invoices',
   'settings.billing.billingAddress.error.notCustomer': 'Please add payment method to set customer',

   'settings.activateVoucher.title': 'Activate voucher',
   'settings.activateVoucher.subtitle':
      'Type in your vourcher code. Vouchers provide you with extra checkin credits for a given space.',
   'settings.activateVoucher.button.text': 'Activate voucher',

   'settings.legalInformation.privacy.subtitle': 'Review our privacy',
   'settings.legalInformation.privacy.title': 'Privacy',
   'settings.legalInformation.termsOfService.subtitle': 'Review our terms of service',
   'settings.legalInformation.termsOfService.title': 'Terms of service',
   'settings.legalInformation.imprint.subtitle': 'Review our imprint data',
   'settings.legalInformation.imprint.title': 'Imprint',

   'settings.manageCreditCards.title': 'Credit cards',
   'settings.manageCreditCards.defaultCard.title': 'Default card',
   'settings.manageCreditCards.otherCards.title': 'Other cards',
   'settings.manageCreditCards.addCreditCard.button.text': 'Add a credit card',
   'settings.manageCreditCards.card.expiresOn': 'Expires on',
   'settings.manageCreditCards.editCardModal.title': 'Edit card info',
   'settings.manageCreditCards.editCardModal.text': 'What would you like to do with your credit card ending with',
   'settings.manageCreditCards.editCardModal.setAsDefault.button.text': 'Set as default',
   'settings.manageCreditCards.editCardModal.deleteCard.button.text': 'Delete card',
   'settings.manageCreditCards.error.missingAction': 'Please select an action.',
   'settings.manageCreditCards.error.missingPaymentMethodId': 'Please select payment method.',
   'settings.manageCreditCards.error.setAsDefault': 'Error occurred on setting default payment id.',
   'settings.manageCreditCards.error.cancelNonDefault': 'Cannot cancel non-default payment method.',
   'settings.manageCreditCards.error.cancelDefault': 'Error occurred on cancel default payment method.',
   'settings.manageCreditCards.error.getPaymentMethods': 'Error occurred on getting payment methods.',
   'settings.manageCreditCards.error.deletePaymentMethod': 'Error occurred on deleting payment method.',
   'settings.manageCreditCards.error.deleteDefaultAttempt': 'Cannot delete default payment method.',
   'settings.manageCreditCards.error.getPaymentMethodIntent': 'Error occurred while getting payment method intent.',

   'purchaseSuccessModal.title': 'Purchase successful!',
   'purchaseSuccessModal.text': 'You have successfully subscribed to our plan. Happy coworking!',
   'purchaseSuccessModal.button.text': 'Start coworking!',

   'checkoutModal.promoCodeCard.title': 'Promo code',
   'checkoutModal.promoCodeCard.text': 'Apply a discount coupon or a promo voucher if you have one.',
   'checkoutModal.promoCodeCard.input.placeholder': 'Enter coupon code',
   'checkoutModal.promoCodeCard.button.text': 'Apply code',
   'checkoutModal.paymentInfoCard.title': 'Payment info',
   'checkoutModal.paymentInfoCard.noPayments.text': 'No payment method, click to add.',
   'checkoutModal.paymentInfo.backToCheckout': 'Back to checkout',
   'checkoutModal.orderCard.title': 'Order info',
   'checkoutModal.orderCard.originalAmount.label': 'Original amount',
   'checkoutModal.orderCard.discountAmount.label': 'Discount amount',
   'checkoutModal.orderCard.discountDuration.label': 'Discount duration',
   'checkoutModal.orderCard.totalAmount.label': 'Total amount',
   'checkoutModal.orderCard.vatNote': 'VAT Included',
   'checkoutModal.orderCard.button.text': 'Purchase plan',
   'checkoutModal.subscriptionConditionCard.title': 'Subscription conditions',
   'checkoutModal.subscriptionConditionCard.text':
      'Your plan renews each month on the day of your subscription. Your checkins are valid for 6 months if you keep your subscription, or 30 days from the day you cancel. Please carefully review for which city/global consortium this plan is valid. If you are not sure, contact us.',
   'checkoutModal.error.missingCoupon': 'Please provide a coupon.',
   'checkoutModal.error.validateCoupon': 'Error occurred on validating coupon.',

   'spaceManagement.tabs.today': 'Today',
   'spaceManagement.tabs.settings': 'Settings',
   'spaceManagement.tabs.insights': 'Insights',
   'spaceManagement.tabs.yourSpaces': 'Your spaces',

   'spaceManagement.bannerText':
      'Please wait, Your space management request is being verified, someone from OneCo will contact you.',

   'spaceManagement.today.title': 'Your space today:',
   'spaceManagement.boxes.title': 'Space analytics',
   'spaceManagement.today.box1.title': 'Total checkins today',
   'spaceManagement.today.box2.title': 'Available desks at the moment',
   'spaceManagement.today.box3.title': 'Todays earnings',
   'spaceManagement.today.box4.title': 'Profile visits today',
   'spaceManagement.today.checkedIn.table.title': 'Checked in people',
   'spaceManagement.today.selectSpace.modal.title': 'Choose a space',
   'spaceManagement.today.selectSpace.modal.subtitle':
      'You seem to manage more than one space. Pick a default space for the overview.',

   'spaceManagement.insights.title': 'Insights',
   'spaceManagement.insights.spaceOverview.title': 'Space overview',
   'spaceManagement.insights.spaceOverview.subtitle': 'See how your space is performing',
   'spaceManagement.insights.spaceOverview.chart1.title': 'Weekly checkin overview',
   'spaceManagement.insights.spaceOverview.chart2.title': 'Checkins by type for the week',
   'spaceManagement.insights.spaceOverview.chart3.title': 'Weekly earnings overview',
   'spaceManagement.insights.spaceOverview.chart4.title': 'Profile visits on the platform',

   'spaceManagement.insights.checkinsInfo.title': 'Checkins information',
   'spaceManagement.insights.checkinsInfo.subtitle': 'Review your checkin data',

   'spaceManagement.insights.earningsInfo.title': 'Earnings information',
   'spaceManagement.insights.earningsInfo.subtitle': 'Review your earnings data',
   'spaceManagement.insights.earningsInfo.totalEarnings.title': 'Total earnings from OneCo',
   'spaceManagement.insights.earningsInfo.pendingAmountForPayour.title': 'Pending amount for payout',
   'spaceManagement.insights.earnings.creditNoteModal.payoutDate': 'Payout date',
   'spaceManagement.insights.earnings.creditNoteModal.payoutId': 'Payout ID',
   'spaceManagement.insights.earnings.creditNoteModal.pending': 'Pending',
   'spaceManagement.insights.earnings.creditNoteModal.totalAmount': 'Total amount',

   'spaceManagement.settings.itemsList.title': 'Edit space',
   'spaceManagement.settings.itemsList.logout.title': 'Logout',
   'spaceManagement.settings.itemsList.logout.subtitle': 'We’re sad to see you go',

   'spaceManagement.settings.addSpaceScreen.text':
      'It seems you do not have a coworking space added on One Coworking. If you’re the owner or a community manager of a coworking space, add it to our platform, and join us on a adventure in reshaping the global work industry.',
   'spaceManagement.settings.addSpaceScreen.button.text': 'Add space',

   'spaceManagement.settings.addSpaceForm.title': 'Add space details',
   'spaceManagement.settings.addSpaceForm.placeholders.name': 'Name',
   'spaceManagement.settings.addSpaceForm.placeholders.address': 'Address',
   'spaceManagement.settings.addSpaceForm.placeholders.city': 'City',
   'spaceManagement.settings.addSpaceForm.placeholders.country': 'Country',
   'spaceManagement.settings.addSpaceForm.placeholders.zip': 'ZIP code',
   'spaceManagement.settings.addSpaceForm.placeholders.floor': 'Floor',
   'spaceManagement.settings.addSpaceForm.placeholders.streetNumber': 'Street number',
   'spaceManagement.settings.addSpaceForm.placeholders.longitude': 'Longitude',
   'spaceManagement.settings.addSpaceForm.placeholders.latitude': 'Latitude',
   'spaceManagement.settings.addSpaceForm.placeholders.provider': 'Provider',
   'spaceManagement.settings.addSpaceForm.placeholders.wifiText': 'WiFi details',
   'spaceManagement.settings.addSpaceForm.placeholders.coffeeText': 'Coffee details',
   'spaceManagement.settings.addSpaceForm.placeholders.printerText': 'Printer details',
   'spaceManagement.settings.addSpaceForm.placeholders.website': 'Website',
   'spaceManagement.settings.addSpaceForm.placeholders.instagram': 'Instagram',
   'spaceManagement.settings.addSpaceForm.placeholders.facebook': 'Facebook',
   'spaceManagement.settings.addSpaceForm.placeholders.twitter': 'Twitter',
   'spaceManagement.settings.addSpaceForm.placeholders.spaceCapacity': 'Capacity',
   'spaceManagement.settings.addSpaceForm.placeholders.description': 'Description',

   'spaceManagement.settings.basicDetails.title': 'Basic info',
   'spaceManagement.settings.basicDetails.subtitle': 'Manage basic space details',
   'spaceManagement.settings.addressDetails.title': 'Address details',
   'spaceManagement.settings.addressDetails.subtitle': 'Manage space address details',
   'spaceManagement.settings.addressDetails.placeholders.address': 'Address',
   'spaceManagement.settings.addressDetails.placeholders.city': 'City',
   'spaceManagement.settings.addressDetails.placeholders.area': 'Area',
   'spaceManagement.settings.addressDetails.placeholders.zip': 'Postal code',
   'spaceManagement.settings.addressDetails.placeholders.floor': 'Floor',
   'spaceManagement.settings.addressDetails.placeholders.streetNumber': 'Street number',
   'spaceManagement.settings.addressDetails.placeholders.country': 'Country',
   'spaceManagement.settings.addressDetails.placeholders.latitude': 'Latitude',
   'spaceManagement.settings.addressDetails.placeholders.longitude': 'Longitude',

   'spaceManagement.settings.galleryAndLogo.title': 'Gallery and logo',
   'spaceManagement.settings.galleryAndLogo.subtitle': 'Manage space gallery and logo',
   'spaceManagement.settings.galleryAndLogo.logoSection.title': 'Select & upload space logo',
   'spaceManagement.settings.galleryAndLogo.selectLogo.buttonText': 'Select logo',
   'spaceManagement.settings.galleryAndLogo.uploadLogo.buttonText': 'Upload logo',
   'spaceManagement.settings.galleryAndLogo.gallerySection.title': 'Select & upload gallery photos',
   'spaceManagement.settings.galleryAndLogo.selectGallery.buttonText': 'Select gallery images',
   'spaceManagement.settings.galleryAndLogo.uploadGallery.buttonText': 'Upload gallery images',

   'spaceManagement.settings.bankDetails.title': 'Bank details',
   'spaceManagement.settings.bankDetails.subtitle': 'Manage space bank details',
   'spaceManagement.settings.bankDetails.placeholders.bankName': 'Bank name',
   'spaceManagement.settings.bankDetails.placeholders.iban': 'IBAN',
   'spaceManagement.settings.bankDetails.placeholders.swift': 'SWIFT',
   'spaceManagement.settings.bankDetails.placeholders.legalName': 'Legal name',
   'spaceManagement.settings.bankDetails.placeholders.address': 'Bank address',
   'spaceManagement.settings.bankDetails.placeholders.vat': 'VAT company number',

   'spaceManagement.settings.spaceManagers.title': 'Space managers',
   'spaceManagement.settings.spaceManagers.subtitle': 'Manage space managers',

   'spaceManagement.settings.availableSeats.title': 'Max capacity',
   'spaceManagement.settings.availableSeats.subtitle': 'Manage capacity',
   'spaceManagement.settings.availableSeats.text1': 'There are',
   'spaceManagement.settings.availableSeats.text2': 'seats left.',

   'spaceManagement.error.getSpace': 'Error occurred while getting space data.',
   'spaceManagement.error.getSpaces': 'Error occurred while getting spaces.',
   'spaceManagement.error.getSpaceAddressDetails': 'Error occurred while getting space address details.',
   'spaceManagement.error.updateSpaceBasicDetails': 'Error occurred while updating space details.',
   'spaceManagement.error.updateSpaceAddressDetails': 'Error occurred while updating space address details.',
   'spaceManagement.error.updateSpaceCapacity': 'Error occurred while updating space capacity.',
   'spaceManagement.error.getSpaceStatistics': 'Error occurred while getting space statistics.',
   'spaceManagement.error.getSpaceEarnings': 'Error occurred while getting space earnings.',
   'spaceManagement.error.getSpaceCheckins': 'Error occurred while getting space checkins.',
   'spaceManagement.error.getSpaceInsights': 'Error occurred while getting space insights.',
   'spaceManagement.error.createSpace': 'Error occurred while creating space.',
   'spaceManagement.error.getSpaceBankDetails': 'Error occurred while getting space bank details.',
   'spaceManagement.error.updateSpaceBankDetails': 'Error occurred while updating space bank details.',
   'spaceManagement.error.getSpaceManagers': 'Error occurred while getting space managers.',
   'spaceManagement.error.getSpaceGallery': 'Error occurred while getting space gallery.',
   'spaceManagement.error.getSpaceLogo': 'Error occurred while getting space logo.',
   'spaceManagement.error.updateSpaceGallery': 'Error occurred while updating space gallery.',
   'spaceManagement.error.updateSpaceLogo': 'Error occurred while updating space logo.',
   'spaceManagement.error.checkOutUser': 'Error occurred while trying to check out.',

   'spaceManagement.success.spaceDetailsUpdated': 'Space details updated.',
   'spaceManagement.success.addressDetailsUpdated': 'Address details updated.',
   'spaceManagement.success.updateSpaceBankDetails': 'Bank details updated.',
   'spaceManagement.success.updateSpaceGallery': 'Gallery updated.',
   'spaceManagement.success.updateSpaceLogo': 'Logo updated.',
   'spaceManagement.success.updateSpaceCapacity': 'Space capacity updated.',
   'spaceManagement.success.checkOutUser': 'Check out successfull',

   'hrManagement.tabs.today': 'Today',
   'hrManagement.tabs.insights': 'Insights',
   'hrManagement.tabs.settings': 'Settings',
   'hrManagement.tabs.spaces': 'Spaces',

   'hrManagement.today.title': 'Your company today:',
   'hrManagement.boxes.title': 'Company analytics',
   'hrManagement.today.box1.title': 'Checked in people today',
   'hrManagement.today.box2.title': 'Checkins left available',
   'hrManagement.today.box3.title': 'Checkins left available with vauchers',
   'hrManagement.today.box4.title': 'Next invoice in',
   'hrManagement.today.box5.title': 'New members signed up',
   'hrManagement.checkedIn.table.title': 'Checked in people',

   'hrManagement.insights.title': 'Insights',
   'hrManagement.insights.spaceOverview.title': 'Space overview',
   'hrManagement.insights.spaceOverview.subtitle': 'See how your space is performing',
   'hrManagement.insights.checkinsInfo.title': 'Checkins information',
   'hrManagement.insights.checkinsInfo.subtitle': 'Review your checkin data',
   'hrManagement.insights.checkinsInfo.tabContent.title': 'Checkins info',

   'hrManagement.settings.convertToEnterprise.title': 'Your company',
   'hrManagement.settings.convertToEnterprise.text':
      'If you have a company, or a remote team that you would like to manage, convert your account and unlock our special enterprise features. Onboard your comapny, handle your finances and team members with just few clicks.',
   'hrManagement.settings.convertToEnterprise.button.text': 'Convert to enterprise account',
   'hrManagement.settings.addCompany.title': 'Add company details',

   'hrManagement.settings.yourCompanyToday.title': 'Your company today:',
   'hrManagement.settings.yourCompanyToday.text':
      'Congratulations on creating your company! It seems, howerver, that you haven’t added any payment details or selected a plan for your team. Navigate to settings and fill the rest of the required information and start coworking today!',
   'hrManagement.settings.yourCompanyToday.button.text': 'Go to settings',

   'hrManagement.settings.itemsList.title': 'Edit company',
   'hrManagement.settings.manageYourTeam.title': 'Manage your team',
   'hrManagement.settings.manageYourTeam.subtitle': 'Reveiw your company team members',
   'hrManagement.settings.manageYourTeam.view.title': 'Manage team',
   'hrManagement.settings.manageYourTeam.view.headerButton.text': 'Add team member',
   'hrManagement.settings.manageYourTeam.view.table.action.viewProfile': 'View profile',
   'hrManagement.settings.manageYourTeam.addTeamMemberModal.title': 'Add new team member',
   'hrManagement.settings.manageYourTeam.addTeamMemberModal.text':
      'Invite your team member to the company. If they have a OneCo account they will automatically be added to the team.',

   'hrManagement.settings.companyDetails.title': 'Company details',
   'hrManagement.settings.companyDetails.subtitle': 'Edit your company details',
   'hrManagement.settings.billingDetails.title': 'Plans & Billing',
   'hrManagement.settings.billingDetails.subtitle': 'See your income throughout the month',
   'hrManagement.settings.companyManagers.title': 'Company managers',
   'hrManagement.settings.companyManagers.subtitle': 'Share your admin rights',
   'hrManagement.settings.companyManagers.view.title': 'Manage admins',
   'hrManagement.settings.companyManagers.view.headerButton.text': 'Add new admin',
   'hrManagement.settings.companyManagers.view.table.action.viewProfile': 'View profile',
   'hrManagement.settings.companyManagers.addNewAdmin.modal.title': 'Add new team admin',
   'hrManagement.settings.companyManagers.addNewAdmin.modal.subtitle':
      'Promote one of your coworkers to a admin of a company.',
   'hrManagement.settings.companyManagers.addNewAdmin.modal.actionButton.text': 'Promote to admin',

   'hrManagement.settings.placeholders.companyName': 'Company name',
   'hrManagement.settings.placeholders.companyAddress': 'Company address',
   'hrManagement.settings.placeholders.companyCity': 'Company city',
   'hrManagement.settings.placeholders.companyState': 'Company state',
   'hrManagement.settings.placeholders.companyZipCode': 'Company zip code',
   'hrManagement.settings.placeholders.companyVatNumber': 'Company VAT number',
   'hrManagement.settings.placeholders.companyEmployeesNumber': 'Employees number',
   'hrManagement.settings.placeholders.companyDomain': 'Company domain',

   'hrManagement.settings.error.getCompanyDetails': 'Error occurred while getting company details.',
   'hrManagement.settings.error.getCompanyStatistics': 'Error occurred while getting company statistics.',
   'hrManagement.settings.error.getCompanyCheckins': 'Error occurred while getting company checkins.',
   'hrManagement.settings.error.getTeamMemberCheckins': 'Error occurred while getting user checkins.',
   'hrManagement.settings.error.createCompany': 'Error occurred while creating company.',
   'hrManagement.settings.error.updateCompany': 'Error occurred while updating company.',
   'hrManagement.settings.error.getCompanyTeamMembers': 'Error occurred while getting team members.',
   'hrManagement.settings.error.addTeamMember': 'Error occurred while adding new team member.',
   'hrManagement.settings.error.getCompanyManagers': 'Error occurred while getting company managers.',
   'hrManagement.settings.error.missingCompanyId': 'Missing company id.',
   'hrManagement.error.getUserEnterpriseDetails': 'Error occurred while loading enterprise data.',
   'hrManagement.error.generateTeamMemberReport': 'Error occurred while generating team member report.',

   'hrManagement.settings.userProfile.labels.email': 'Verified email address',
   'hrManagement.settings.userProfile.labels.profession': 'Profession',
   'hrManagement.settings.userProfile.labels.biography': 'Biography',

   'hrManagement.settings.userProfile.userManagement.title': 'User management',
   'hrManagement.settings.userProfile.userManagement.removeTeamMemberItem.subtitle':
      'Control access to team checkins for this user',
   'hrManagement.settings.userProfile.userManagement.removeTeamMemberItem.title': 'Remove user from team',
   'hrManagement.settings.userProfile.userManagement.downloadUserReportItem.subtitle':
      'Generate full report and checkins usage for this user',
   'hrManagement.settings.userProfile.userManagement.downloadUserReportItem.title': 'Download user report',
   'hrManagement.settings.userProfile.userManagement.userCheckinsItem.subtitle':
      'View and analyze checkin usage for this user',
   'hrManagement.settings.userProfile.userManagement.userCheckinsItem.title': 'Review user checkins',

   'hrManagement.settings.userProfile.removeTeamMember.title': 'Remove user from team',
   'hrManagement.settings.userProfile.removeTeamMember.inputLabel': 'Please enter user email to confim this action.',

   'hrManagement.settings.userProfile.userCheckins.title': 'User checkins',

   'hrManagement.settings.userProfile.error.missingId': 'Missing user id in url.',
   'hrManagement.settings.userProfile.error.removeTeamMember': 'Error occurred while removing team member.',
   'hrManagement.settings.userProfile.success.removeTeamMember': 'Team member removed successfully!',

   'footer.label.support': 'Support',
   'footer.label.applications': 'Applications',

   'footer.link.help': 'Help center',
   'footer.link.privacy': 'Privacy policy',
   'footer.link.imprint': 'Imprint',
   'footer.link.terms': 'Terms of service',
   'footer.link.ios': 'iOS application',
   'footer.link.android': 'Android application',

   'signUp.header.text': 'Join One Coworking today!',
   'signUp.basicInformations.text': 'Basic information',
   'signUp.basicInformations.name': 'Name',
   'signUp.basicInformations.email': 'Email',
   'signUp.basicInformations.password': 'Password',
   'signUp.cardInformation.text': 'Card information',
   'signUp.cardInformation.creditCard.number': 'Credit card number',
   'signUp.cardInformation.country': 'Country',
   'signUp.cardInformation.city': 'City',
   'signUp.subscription.text': 'Subscription conditions',
   'signUp.subscription.info':
      'Your plan renews each month on the day of your subscription. Your checkins are valid for 6 months if you keep your subscription, or 30 days from the day you cancel.  Please carefuly review for which city/global consortium this plan is valid. If you are not sure, contact us. ',
   'signUp.subscription.create.account': 'Create account and subscribe',
   'signUp.subscription.terms.conditions': 'Read our Terms and Conditions',
   'signUp.planDetails.title': 'Plan details',
   'signUp.planDetails.city': 'Berlin 10 days',
   'signUp.planDetails.info':
      'Usable for one month, gives you access to all Berlin coworking spaces for 10 times. Renewes every 1st of the month',
   'signUp.planDetails.promo.code': 'Promo code',
   'signUp.planDetails.promo.code.text': 'Apply a discount coupon or a promo voucher if you have one.',
   'signUp.planDetails.promo.code.input.text': 'Enter coupon code',
   'signUp.planDetails.promo.code.apply': 'Apply discount',
   'signUp.planDetails.order.info': 'Order info',
   'signUp.planDetails.order.original.amount': 'Original amount',
   'signUp.planDetails.order.price': '49.90 €',
   'signUp.planDetails.order.discount.amount': 'Discount amount',
   'signUp.planDetails.order.discount.percentage': '50%',
   'signUp.planDetails.order.discount.duration': 'Discount duration',
   'signUp.planDetails.order.discount.months': '6 months',
   'signUp.planDetails.order.discount.total.amount': 'Total amount',
   'signUp.planDetails.order.discount.total.amount.price': '49.90 €',
   'signUp.planDetails.order.VAT': 'VAT included',
};
