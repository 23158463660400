const devFirebaseConfig = {
   apiKey: 'AIzaSyAzaD2eqQui6RaVVv6foxO7VafFY23hxck',
   authDomain: 'onecoworking-dev.firebaseapp.com',
   databaseURL: 'https://onecoworking-dev-default-rtdb.firebaseio.com',
   projectId: 'onecoworking-dev',
   storageBucket: 'onecoworking-dev.appspot.com',
   messagingSenderId: '623192453192',
   appId: '1:623192453192:web:a26f9f90414868b875687d',
   measurementId: 'G-FGPRQ3DXWF',
};

const prodFirebaseConfig = {
   apiKey: 'AIzaSyAXBww9246wkg6LsaCd82_fl-Zw8cZGtZg',
   authDomain: 'one-coworking.firebaseapp.com',
   databaseURL: 'https://one-coworking.firebaseio.com',
   projectId: 'one-coworking',
   storageBucket: 'one-coworking.appspot.com',
   messagingSenderId: '602818215486',
   appId: '1:602818215486:web:5917225079067959f19b6f',
   measurementId: 'G-GMYX5WKYRV',
};

export const firebaseConfig =
   process.env.REACT_APP_BUILD_TARGET === 'PRODUCTION' ? prodFirebaseConfig : devFirebaseConfig;

export const rrfConfig = {
   userProfile: 'users',
};

export const config = {
   DEFAULT_LANGUAGE: 'en',
   API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
   STORAGE_URL: process.env.REACT_APP_STORAGE_URL,
   STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
   IPSTACK_API_KEY: process.env.REACT_APP_IPSTACK_API_KEY,
   GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
   INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
   HELP_CENTER_PAGE: 'http://help.onecoworking.com/en/',
   TERMS_OF_SERVICE_PAGE: 'https://onecoworking.com/terms',
   PRIVACY_POLICY_PAGE: 'https://onecoworking.com/privacy',
   IMPRINT_PAGE: 'https://onecoworking.com/imprint',
   IOS_APP_URL: 'https://apps.apple.com/us/app/one-coworking/id1137381231',
   ANDROID_APP_URL: 'https://play.google.com/store/apps/details?id=com.onecoworking',
   FACEBOOK_URL: 'https://www.facebook.com/onecoworkingapp',
   INSTAGRAM_URL: 'https://www.instagram.com/onecoworking',
   LINKEDIN_URL: 'https://www.linkedin.com/company/onecoworking',
   TWITTER_URL: 'https://twitter.com/onecoworking',
};
