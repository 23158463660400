import React, { ReactElement, useEffect, useState } from 'react';

import ReserveMeetingRoomForm from './ReserveMeetingRoomForm';
import ReserveMeetingRoomTable from './ReserveMeetingRoomTable';

const ReserveMeetingRoom = (): ReactElement => {
   const [newRoomRequest, setNewRoomRequest] = useState(false);

   if (newRoomRequest) return <ReserveMeetingRoomForm />;
   else return <ReserveMeetingRoomTable setCreateNewRequest={setNewRoomRequest} />;
};

export default ReserveMeetingRoom;
