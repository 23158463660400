import React, { ReactElement, useState, useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';
import moment from 'moment';

import { Flex, Spinner, StyledText, Table } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn } from 'src/components/table/interfaces';

import { ISpaceManager } from 'src/features/space_management/interfaces';
import ApiRequests from 'src/features/space_management/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { config } from 'src/app/config';
import { useLocation } from 'react-router';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

const SpaceManagers = (): ReactElement => {
   const location = useLocation();
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(true);
   const [spaceManagers, setSpaceManagers] = useState<ISpaceManager[]>([]);
   const [spaceId, setSpaceId] = useState<any>(location.state.spaceId);

   const wide = useWindowWide(768);

   const goBack = () => {
      history.back();
   };

   const getSpaceManagers = async () => {
      try {
         const response = await ApiRequests.getSpaceManagers(currentUser?.uid as string, spaceId);
         if (response && response.data) {
            setSpaceManagers(response.data);
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpaceManagers'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('spaceManagement.error.getSpaceManagers'));
      } finally {
         setLoading(false);
      }
   };

   const columns: Array<TableColumn<any>> = [
      {
         width: '10%',
         title: '',
         key: 'image',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (member: any) => `${member.photo ? config.STORAGE_URL + member.photo : ''}`,
      },
      {
         width: '30%',
         title: 'Name',
         key: 'name',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (companyManager: any) => <>{companyManager.name}</>,
      },
      {
         width: '40%',
         title: 'Email',
         key: 'email',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (companyManager: any) => <>{companyManager.email}</>,
      },
      {
         width: '20%',
         title: 'Joined At',
         key: 'joined_at',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{moment(checkin.joined_at).format('DD/MM/YYYY HH:mm')}</>,
      },
   ];

   useEffect(() => {
      if (currentUser && spaceId) {
         void getSpaceManagers();
      }
      //eslint-disable-next-line
   }, [currentUser, spaceId]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         <Flex
            margin={'0 0 32px 0'}
            width={'100%'}
            justifyContent={'flex-start'}
            alignItems={'center'}
            flexDirectionMobile={'column'}
            alignItemsMobile={'center'}
         >
            {wide ? (
               <StyledText
                  justifyContent={'flex-start'}
                  text={translate('hrManagement.settings.companyManagers.view.title')}
                  variant={StyledTextVariantsType.HEADING_1}
               />
            ) : (
               <HeaderWrapper>
                  <img
                     src={PurpleArrowLeft}
                     style={{ height: 34, cursor: 'pointer' }}
                     alt="navigate"
                     onClick={goBack}
                  />
                  <StyledText
                     justifyContent={'flex-start'}
                     text={translate('hrManagement.settings.companyManagers.view.title')}
                     variant={StyledTextVariantsType.HEADING_1}
                     margin={'0 0 0 32px'}
                  />
               </HeaderWrapper>
            )}
         </Flex>

         <Table
            onSelectAll={() => null}
            columns={columns}
            data={spaceManagers}
            itemActions={undefined}
            customClass={'w-100'}
         />
      </Flex>
   );
};

export default SpaceManagers;
