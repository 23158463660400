import React, { ReactElement, useEffect, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useHistory, useLocation } from 'react-router';

import { Button, UploadButton, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { CustomizableImg, InlineElementsWrapper } from 'src/components/SharedComponents';

import { AvatarPlaceholder, ProfilePicturePlaceholder, PurpleArrowLeft } from 'src/assets/images';
import ApiRequests from 'src/features/settings/data/ApiRequests';
import { IUser } from '../../interfaces';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { config } from 'src/app/config';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { RoutesEnum } from 'src/routes/routes';

const ProfilePhoto = (): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const history = useHistory();
   const location = useLocation();

   const [user, setUser] = useState<IUser>(location.state.user);
   const [loading, setLoading] = useState(true);

   const [imageChangeLoader, setImageChangeLoader] = useState(false);
   const [uploading, setUploading] = useState(false);

   const [file, setFile] = useState<any>(null);
   const [profilePictureUrl, setProfilePictureUrl] = useState(user?.photo ? user.photo : ProfilePicturePlaceholder);

   const imageChangeHandler = (e: any) => {
      setImageChangeLoader(true);
      const file = e.target.files[0];

      setTimeout(() => {
         setFile(file);
         setImageChangeLoader(false);
      }, 1000);
   };

   const goBack = () => {
      history.goBack();
   };
   const getUser = async () => {
      try {
         const response: any = await ApiRequests.getUserDetails(currentUser?.uid as string);
         if (response.data) {
            setUser(response.data.profile);
            history.push(RoutesEnum.SETTINGS);
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.getUserDetails'));
      } finally {
         setLoading(false);
      }
   };

   const uploadImage = async () => {
      if (!file) {
         return NotificationsProvider.error(translate('settings.account.profilePhoto.error.missingPhoto'));
      }

      try {
         setUploading(true);
         const formData = new FormData();
         formData.append('photo', file);

         const response: any = await ApiRequests.uploadProfilePicture(currentUser?.uid as string, {
            payload: formData,
         });

         if (response.success) {
            await getUser();
            setFile(null);
            NotificationsProvider.success(translate('settings.account.profilePhoto.success.uploadPhoto'));
         } else {
            NotificationsProvider.error(translate('settings.account.profilePhoto.error.uploadPhoto'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('settings.account.profilePhoto.error.uploadPhoto'));
      } finally {
         setUploading(false);
      }
   };

   useEffect(() => {
      if (user && user.photo) {
         setProfilePictureUrl(user?.photo);
      }
   }, [user]);

   function addDefaultSrc(ev: any) {
      ev.target.src = AvatarPlaceholder;
   }

   return (
      <>
         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('settings.account.profilePhoto.form.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 32px'}
            />
         </HeaderWrapper>

         <CustomizableImg
            src={profilePictureUrl}
            height={'auto'}
            width={'100%'}
            margin={'0 auto'}
            borderRadius={'15px'}
            onError={addDefaultSrc}
         />

         <UploadButton
            keyValue={'profile-photo'}
            loading={imageChangeLoader}
            margin={'12px 0 12px 0'}
            width={'100%'}
            color={theme.colors.white}
            backgroundColor={'transparent'}
            onImageChange={imageChangeHandler}
            text={translate('settings.account.profilePhoto.form.buttonText')}
         />

         {file && (
            <InlineElementsWrapper>
               <StyledText
                  text={translate('common.selectedFile')}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  margin={'12px 0 0 0'}
               />
               <StyledText
                  text={file.name}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  colorVariant={theme.colors.gray}
                  margin={'0 0 0 5px'}
               />
            </InlineElementsWrapper>
         )}

         <Button
            isLoading={uploading}
            disabled={!file}
            variant={ButtonVariantsType.PURPLE}
            text={translate('common.uploadPhoto')}
            margin={'72px 0 0 0'}
            width={'100%'}
            onClick={uploadImage}
         />
      </>
   );
};

export default ProfilePhoto;
