import React, { ReactElement, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { Flex, StyledText, Spinner } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { SettingsItemContent, SettingsItemTitle, SettingsItem } from 'src/features/settings/shared/SharedComponents';

import UserProfile from './UserProfile';
import UserCheckins from './checkins/UserCheckins';
import RemoveFromMember from './remove_from_team/RemoveFromMember';

import { AvatarPlaceholder, FacebookUnlinked, GoogleUnlinked, AppleUnlinked } from 'src/assets/images';
import { RootState } from 'src/app/rootReducer';
import { ActiveHRManagementUserProfileItemEnum } from 'src/system/state/types';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { setActiveHRManagementUserProfileItem } from 'src/system/state/actions';
import { parseError } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { config } from 'src/app/config';
import theme from 'src/styles/theme';

interface IRouteParams {
   id: string;
}

const SocialIconsContainer = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: center;
   width: 100%;
   margin-top: 32px;

   svg {
      cursor: pointer;

      &:not(:last-of-type) {
         margin-right: 24px;
      }
   }
`;

const UserProfileRoot = (): ReactElement | null => {
   const dispatch = useDispatch();
   const params = useParams();
   const { id } = params as IRouteParams;
   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;
   const activeHRManagementUserProfileItem = useSelector(
      (state: RootState) => state.systemReducer,
   ).activeHRManagementUserProfileItem;

   const [loading, setLoading] = useState(true);
   const [userProfileData, setUserProfileData] = useState<any>(null);

   const getUserProfileData = async (triggerLoader?: boolean) => {
      if (!id) {
         return NotificationsProvider.error(translate('hrManagement.settings.userProfile.error.missingId'));
      }

      try {
         triggerLoader && setLoading(true);
         const response = await ApiRequests.getUserDetails(id);
         setUserProfileData(response.data);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.getUserDetails'));
      } finally {
         setLoading(false);
      }
   };

   const getUserPhoto = (relativePhotoUrl: string) => {
      return `${relativePhotoUrl ? config.STORAGE_URL + relativePhotoUrl : ''}`;
   };

   const renderUserInfoColumn = () => (
      <>
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={userProfileData?.name}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 8px 0'}
         />

         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={`${translate('common.joinedAt')} ${moment(userProfileData?.created_at).format('DD.MM.YYYY')}`}
            variant={StyledTextVariantsType.NOTE_TEXT}
            colorVariant={theme.colors.gray}
            margin={'0 0 44px 0'}
         />

         <img
            src={getUserPhoto(userProfileData?.photo) || AvatarPlaceholder}
            alt="profile"
            style={{ width: 180, height: 'auto', objectFit: 'cover', borderRadius: 15 }}
         />

         <SettingsItem padding={'0'} height={'auto'} margin={'32px 0 0 0'}>
            <SettingsItemContent>
               <SettingsItemTitle width={'100%'}>
                  <StyledText
                     width={'100%'}
                     text={translate('hrManagement.settings.userProfile.labels.email')}
                     variant={StyledTextVariantsType.PARAGRAPH_3}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 8px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     text={userProfileData?.email}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 8px 0'}
                  />
               </SettingsItemTitle>
            </SettingsItemContent>
         </SettingsItem>

         <SettingsItem padding={'0'} height={'auto'} margin={'24px 0 0 0'}>
            <SettingsItemContent>
               <SettingsItemTitle width={'100%'}>
                  <StyledText
                     width={'100%'}
                     text={translate('hrManagement.settings.userProfile.labels.profession')}
                     variant={StyledTextVariantsType.PARAGRAPH_3}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 8px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     margin={'0 0 8px 0'}
                     text={userProfileData?.profession || 'n/a'}
                     variant={StyledTextVariantsType.HEADING_3}
                  />
               </SettingsItemTitle>
            </SettingsItemContent>
         </SettingsItem>

         <SettingsItem padding={'0'} height={'auto'} margin={'24px 0 0 0'}>
            <SettingsItemContent>
               <SettingsItemTitle width={'100%'}>
                  <StyledText
                     width={'100%'}
                     text={translate('hrManagement.settings.userProfile.labels.biography')}
                     variant={StyledTextVariantsType.PARAGRAPH_3}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 8px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     margin={'0 0 8px 0'}
                     text={userProfileData?.biography || 'n/a'}
                     variant={StyledTextVariantsType.HEADING_3}
                  />
               </SettingsItemTitle>
            </SettingsItemContent>
         </SettingsItem>

         <SocialIconsContainer>
            <FacebookUnlinked />
            <GoogleUnlinked />
            <AppleUnlinked />
         </SocialIconsContainer>
      </>
   );

   useEffect(() => {
      void getUserProfileData(true);

      return () => {
         dispatch(setActiveHRManagementUserProfileItem(ActiveHRManagementUserProfileItemEnum.USER_MANAGEMENT));
      };
      // eslint-disable-next-line
   }, []);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         justifyContent="flex-start"
         alignItems="flex-start"
         flexWrap="nowrap"
         flex={1}
         mobileMinHeight={'100%'}
         position={'relative'}
         padding={'0 10% 3% 10%'}
      >
         <Flex
            justifyContent="flex-start"
            alignItems="stretch"
            flexWrap="nowrap"
            flex={1}
            minHeight={'100%'}
            mobileMinHeight={'100%'}
            position={'relative'}
            width={'100%'}
            flexDirectionMobile={'column'}
         >
            <Flex
               width={'32%'}
               flexDirection="column"
               justifyContent="flex-start"
               alignItems="flex-start"
               position={'relative'}
               overflow={'hidden'}
               padding={'0 20px 0 0'}
               borderRight={`1px solid ${theme.colors.lightGray}`}
            >
               {renderUserInfoColumn()}
            </Flex>

            {activeHRManagementUserProfileItem === ActiveHRManagementUserProfileItemEnum.USER_MANAGEMENT && (
               <UserProfile userId={id} enterpriseId={enterpriseId} />
            )}
            {activeHRManagementUserProfileItem === ActiveHRManagementUserProfileItemEnum.USER_CHECKINS && (
               <UserCheckins userId={id} />
            )}
            {activeHRManagementUserProfileItem === ActiveHRManagementUserProfileItemEnum.REMOVE_USER && (
               <RemoveFromMember userId={id} enterpriseId={enterpriseId} />
            )}
         </Flex>
      </Flex>
   );
};

export default UserProfileRoot;
