import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { Flex, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import {
   SettingsItemArrow,
   SettingsItemContent,
   SettingsItemIcon,
   SettingsItemTitle,
   SettingsItem,
} from 'src/features/settings/shared/SharedComponents';

import { ActiveItemEnum } from './SpaceSettings';
import { UserEditIcon, ChevronRightElement, LogoutIcon } from 'src/assets/images';
import { signOutFromFirebase } from 'src/features/auth/authSlice';
import { logout } from 'src/system/state/actions';
import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';
import { RoutesEnum } from 'src/routes/routes';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/app/rootReducer';

interface IProps {
   setActiveItem: (item: ActiveItemEnum) => void;
   isItemActive: (itemToCheck: ActiveItemEnum) => boolean;
}

const SpaceSettingsRoot = ({ setActiveItem, isItemActive }: IProps): ReactElement => {
   const dispatch = useDispatch();
   const { boot, shutdown } = useIntercom();
   const spaceId = useSelector((state: RootState) => state.systemReducer).spaceId;

   const history = useHistory();

   const onLogoutClick = async () => {
      shutdown();
      boot();
      await signOutFromFirebase();
      dispatch(logout());
   };

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         width={'100%'}
         height={'100%'}
         position={'relative'}
      >
         <StyledText
            width={'100%'}
            text={translate('spaceManagement.settings.itemsList.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 32px 0'}
         />

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
            borderRight={`1px solid ${theme.colors.lightGray}`}
         >
            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.BASIC_DETAILS) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.BASIC_DETAILS);
                  history.push({
                     pathname: RoutesEnum.SPACE_MANAGEMENT_BASIC_INFO,
                     state: {
                        spaceId: spaceId,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.basicDetails.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.basicDetails.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.ADDRESS_DETAILS) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.ADDRESS_DETAILS);
                  history.push({
                     pathname: RoutesEnum.SPACE_MANAGEMENT_ADDRESS,
                     state: {
                        spaceId: spaceId,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.addressDetails.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.addressDetails.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.GALLERY_AND_LOGO) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.GALLERY_AND_LOGO);
                  history.push({
                     pathname: RoutesEnum.SPACE_MANAGEMENT_GALLERY,
                     state: {
                        spaceId: spaceId,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.galleryAndLogo.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.galleryAndLogo.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            {/* <SettingsItem
               className={`${isItemActive(ActiveItemEnum.BANK_DETAILS) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.BANK_DETAILS);
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.bankDetails.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.bankDetails.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem> */}

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.SPACE_MANAGERS) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.SPACE_MANAGERS);
                  history.push({
                     pathname: RoutesEnum.SPACE_MANAGEMENT_MANAGERS,
                     state: {
                        spaceId: spaceId,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.spaceManagers.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.spaceManagers.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.AVAILABLE_SEATS) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.AVAILABLE_SEATS);
                  history.push({
                     pathname: RoutesEnum.SPACE_MANAGEMENT_CAPACITY,
                     state: {
                        spaceId: spaceId,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.availableSeats.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.availableSeats.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem onClick={onLogoutClick}>
               <SettingsItemIcon>
                  <LogoutIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.itemsList.logout.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('spaceManagement.settings.itemsList.logout.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
         </Flex>
      </Flex>
   );
};

export default SpaceSettingsRoot;
