import _ from 'underscore';
import { translate } from 'src/translations/translations';

// default user
import Spaces from 'src/features/spaces/SpacesPreview';
import MapView from 'src/features/pages/map_view/MapView';
import SpaceDetails from 'src/features/spaces/space_details/SpaceDetails';
import ReserveMeetingRoom from 'src/features/pages/reserve_meeting_room/ReserveMeetingRoom';
import Settings from 'src/features/settings/Settings';

// enterprise manager
import EnterpriseManagement from 'src/features/enterprise_management/EnterpriseManagement';
import EnterpriseInvitation from 'src/features/enterprise_management/EnterpriseInvitation';
import UserProfileRoot from 'src/features/enterprise_management/settings/user_profile/UserProfileRoot';

// space manager
import SpaceManagement from 'src/features/space_management/SpaceManagement';

//settings routes
import AccountRoot from 'src/features/settings/account/AccountRoot';
import BillingRoot from 'src/features/settings/billing/BillingRoot';
import LegalInformationItemsList from 'src/features/settings/legals/LegalsRoot';
import BasicInfo from 'src/features/settings/account/user/BasicInfo';
import ProfilePhoto from 'src/features/settings/account/user/ProfilePhoto';
import ChangePassword from 'src/features/settings/account/user/ChangePassword';
import SocialMedia from 'src/features/settings/account/social_media/SocialMedia';
import CheckinCredits from 'src/features/settings/account/checkin-credits/CheckinCredits';
import PurchasePlan from 'src/features/settings/billing/purchase_plan/PurchasePlan';
import CreditCards from 'src/features/settings/billing/credit_cards/CreditCards';
import BillingAddress from 'src/features/settings/billing/billing_address/BillingAddress';
import Invoices from 'src/features/settings/billing/invoices/Invoices';
import CheckinHistory from 'src/features/settings/account/checkin_history/CheckinHistory';
import SpaceManagementSettings from 'src/features/space_management/settings/SpaceSettings';
import SpaceManagementToday from 'src/features/space_management/today/Today';
import SpaceManagementInsights from 'src/features/space_management/insights/Insights';
import SpaceManagementYourSpaces from 'src/features/space_management/your_spaces/YourSpaces';
import SpaceManagementBasicInfo from 'src/features/space_management/settings/basic_details/BasicDetails';
import SpaceManagementAddress from 'src/features/space_management/settings/address/AddressDetails';
import SpaceManagementGallery from 'src/features/space_management/settings/gallery_and_logo/GalleryRoot';
import SpaceManagementManagers from 'src/features/space_management/settings/managers/SpaceManagers';
import SpaceManagementCapacity from 'src/features/space_management/settings/capacity/AvailableSeats';
import SpaceManagementSpaceOverview from 'src/features/space_management/insights/space_overview/SpaceOverview';
import SpaceManagementCheckins from 'src/features/space_management/insights/checkins_info/CheckinsInfo';
import SpaceManagementEarnings from 'src/features/space_management/insights/earnings_info/EarningsInfo';
import EnterpriseInsights from 'src/features/enterprise_management/insights/Insights';
import EnterpriseToday from 'src/features/enterprise_management/today/Today';
import EnterpriseSettings from 'src/features/enterprise_management/settings/EnterpriseSettings';
import EnterpriseSpaces from 'src/features/spaces/SpacesPreview';
import SpaceOverview from 'src/features/enterprise_management/insights/space_overview/SpaceOverview';
import EnterpriseCheckins from 'src/features/enterprise_management/insights/checkins_info/CheckinsInfo';
import EnterpriseManageTeam from 'src/features/enterprise_management/settings/enterprise/EnterpriseMembers';
import EnterpriseCompanyDetails from 'src/features/enterprise_management/settings/enterprise/CompanyDetails';
import EnterpriseManagers from 'src/features/enterprise_management/settings/enterprise/EnterpriseAdmins';
import EnterpriseBilling from 'src/features/enterprise_management/settings/enterprise/BillingDetails';
import EnterpriseCreditCards from 'src/features/enterprise_management/settings/billing/credit_cards/CreditCards';
import EnterpriseBillingAddress from 'src/features/enterprise_management/settings/billing/billing_address/BillingAddress';
import EnterpriseInvoices from 'src/features/enterprise_management/settings/billing/invoices/Invoices';
import EnterprisePurchasePlan from 'src/features/enterprise_management/settings/billing/purchase_plan/PurchasePlan';
import SignUp from 'src/features/direct_sign_up/SignUp';

export enum RoutesEnum {
   HOME = '/',
   MAP_VIEW = '/map',
   RESERVE_MEETING_ROOM = '/book-a-meeting-room',
   SIGN_UP = '/sign-up/:plan_id/:coupon?',
   SETTINGS = '/settings',
   ACCOUNT = '/settings/account',
   BILLING_INFO = '/settings/billing',
   LEGAL_INFO = '/settings/legals',
   BASIC_INFO = '/settings/account/basic_info',
   PROFILE_PHOTO = '/settings/account/profile_photo',
   CHANGE_PASSWORD = '/settings/account/change_password',
   SOCIAL_MEDIA = '/settings/account/social_media',
   CHECKIN_CREDITS = '/settings/account/checkin_credits',
   CHECKIN_HISTORY = '/settings/account/checkin_history',
   PURCHASE_PLAN = '/settings/billing/purchase_plan',
   ACTIVATE_VOUCHER = '/settings/billing/activate_voucher',
   MANAGE_CARDS = '/settings/billing/manage_cards',
   CHANGE_BILLING_ADDRESS = '/settings/billing/change_address',
   INVOICES = '/settings/billing/invoices',
   SPACE_DETAILS = '/space/:id/:name',
   ENTERPRISE_MANAGEMENT = '/enterprise-management',
   ENTERPRISE_MANAGEMENT_USER_PROFILE = '/enterprise-management/user/:id',
   ENTERPRISE_MANAGEMENT_SPACE_DETAILS = '/space-management/space/:id/:name',
   SPACE_MANAGEMENT = '/space-management',
   SPACE_MANAGEMENT_SETTINGS = '/space-management/settings',
   SPACE_MANAGEMENT_TODAY = '/space-management/today',
   SPACE_MANAGEMENT_INSIGHTS = '/space-management/insights',
   SPACE_MANAGEMENT_YOUR_SPACES = '/space-management/your-spaces',
   SPACE_MANAGEMENT_BASIC_INFO = '/space-management/settings/basic-info',
   SPACE_MANAGEMENT_ADDRESS = '/space-management/settings/address',
   SPACE_MANAGEMENT_GALLERY = '/space-management/settings/gallery',
   SPACE_MANAGEMENT_MANAGERS = '/space-management/settings/managers',
   SPACE_MANAGEMENT_CAPACITY = '/space-management/settings/capacity',
   SPACE_MANAGEMENT_SPACE_OVERVIEW = '/space-management/insights/space-overview',
   SPACE_MANAGEMENT_CHECKINS = '/space-management/insights/checkins',
   SPACE_MANAGEMENT_EARNINGS = '/space-management/insights/earnings',
   SPACE_MANAGEMENT_SPACE_DETAILS = '/space-management/space/:id/:name',
   ACCEPT_INVITE = '/invite',
   ENTERPRISE_INSIGHTS = '/enterprise-management/insights',
   ENTERPRISE_TODAY = '/enterprise-management/today',
   ENTERPRISE_SETTINGS = '/enterprise-management/settings',
   ENTERPRISE_SPACES = '/enterprise-management/spaces',
   ENTERPRISE_INSIGHTS_OVERVIEW = '/enterprise-management/insights/overview',
   ENTERPRISE_INSIGHTS_CHECKINS = '/enterprise-management/insights/checkins',
   ENTERPRISE_SETTINGS_MANAGE_TEAM = '/enterprise-management/settings/members',
   ENTERPRISE_SETTINGS_COMPANY_DETAILS = '/enterprise-management/settings/company-details',
   ENTERPRISE_SETTINGS_MANAGERS = '/enterprise-management/settings/managers',
   ENTERPRISE_SETTINGS_BILLING = '/enterprise-management/settings/billing',
   ENTERPRISE_SETTINGS_CREDIT_CARDS = '/enterprise-management/settings/billing/credit-cards',
   ENTERPRISE_SETTINGS_BILLING_ADDRESS = '/enterprise-management/settings/billing/billing-address',
   ENTERPRISE_SETTINGS_INVOICES = '/enterprise-management/settings/billing/invoices',
   ENTERPRISE_SETTINGS_PURCHASE_PLAN = '/enterprise-management/settings/billing/purchase-plan',
}

export interface IRoute {
   name: string;
   path: string;
   component: any;
}

export const settingsRoutes: IRoute[] = [
   {
      name: translate('routes.account'),
      path: RoutesEnum.ACCOUNT,
      component: AccountRoot,
   },
   {
      name: translate('routes.billing'),
      path: RoutesEnum.BILLING_INFO,
      component: BillingRoot,
   },
   {
      name: translate('routes.legals'),
      path: RoutesEnum.LEGAL_INFO,
      component: LegalInformationItemsList,
   },
];
export const accountRoutes: IRoute[] = [
   {
      name: translate('routes.account'),
      path: RoutesEnum.BASIC_INFO,
      component: BasicInfo,
   },
   {
      name: translate('routes.account'),
      path: RoutesEnum.PROFILE_PHOTO,
      component: ProfilePhoto,
   },
   {
      name: translate('routes.account'),
      path: RoutesEnum.CHANGE_PASSWORD,
      component: ChangePassword,
   },
   {
      name: translate('routes.account'),
      path: RoutesEnum.SOCIAL_MEDIA,
      component: SocialMedia,
   },
   {
      name: translate('routes.account'),
      path: RoutesEnum.CHECKIN_CREDITS,
      component: CheckinCredits,
   },
   {
      name: translate('routes.account'),
      path: RoutesEnum.CHECKIN_HISTORY,
      component: CheckinHistory,
   },
];
export const billingRoutes: IRoute[] = [
   {
      name: translate('routes.account'),
      path: RoutesEnum.PURCHASE_PLAN,
      component: PurchasePlan,
   },
   {
      name: translate('routes.account'),
      path: RoutesEnum.MANAGE_CARDS,
      component: CreditCards,
   },
   {
      name: translate('routes.account'),
      path: RoutesEnum.CHANGE_BILLING_ADDRESS,
      component: BillingAddress,
   },
   {
      name: translate('routes.account'),
      path: RoutesEnum.INVOICES,
      component: Invoices,
   },
];
export const appRoutes: IRoute[] = [
   {
      name: translate('routes.home'),
      path: RoutesEnum.HOME,
      component: Spaces,
   },
   {
      name: translate('routes.map'),
      path: RoutesEnum.MAP_VIEW,
      component: MapView,
   },
   {
      name: translate('routes.signUp'),
      path: RoutesEnum.SIGN_UP,
      component: SignUp,
   },
   {
      name: translate('routes.space.details'),
      path: RoutesEnum.SPACE_DETAILS,
      component: SpaceDetails,
   },
   {
      name: translate('routes.reserveMeetingRoom'),
      path: RoutesEnum.RESERVE_MEETING_ROOM,
      component: ReserveMeetingRoom,
   },
   {
      name: translate('routes.settings'),
      path: RoutesEnum.SETTINGS,
      component: Settings,
   },
   {
      name: translate('routes.acceptInvite'),
      path: RoutesEnum.ACCEPT_INVITE,
      component: EnterpriseInvitation,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT,
      component: SpaceManagement,
   },
];

export const enterpriseManagementRoutes: IRoute[] = [
   {
      name: translate('routes.enterpriseManagement.userProfile'),
      path: RoutesEnum.ENTERPRISE_MANAGEMENT_USER_PROFILE,
      component: UserProfileRoot,
   },
   {
      name: translate('routes.enterpriseManagement.spaceDetails'),
      path: RoutesEnum.ENTERPRISE_MANAGEMENT_SPACE_DETAILS,
      component: SpaceDetails,
   },
   {
      name: translate('routes.enterpriseManagement'),
      path: RoutesEnum.ENTERPRISE_MANAGEMENT,
      component: EnterpriseManagement,
   },
];
export const enterpriseRoutes: IRoute[] = [
   {
      name: translate('routes.enterpriseManagement'),
      path: RoutesEnum.ENTERPRISE_INSIGHTS,
      component: EnterpriseInsights,
   },
   {
      name: translate('routes.enterpriseManagement'),
      path: RoutesEnum.ENTERPRISE_TODAY,
      component: EnterpriseToday,
   },
   {
      name: translate('routes.enterpriseManagement'),
      path: RoutesEnum.ENTERPRISE_SETTINGS,
      component: EnterpriseSettings,
   },
   {
      name: translate('routes.enterpriseManagement'),
      path: RoutesEnum.ENTERPRISE_SPACES,
      component: EnterpriseSpaces,
   },
];
export const insightsRoutes: IRoute[] = [
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_INSIGHTS_OVERVIEW,
      component: SpaceOverview,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_INSIGHTS_CHECKINS,
      component: EnterpriseCheckins,
   },
];
export const enterpriseSettingsRoutes: IRoute[] = [
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_SETTINGS_MANAGE_TEAM,
      component: EnterpriseManageTeam,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_SETTINGS_COMPANY_DETAILS,
      component: EnterpriseCompanyDetails,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_SETTINGS_MANAGERS,
      component: EnterpriseManagers,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_SETTINGS_BILLING,
      component: EnterpriseBilling,
   },
];
export const enterpriseBillingRoutes: IRoute[] = [
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_SETTINGS_PURCHASE_PLAN,
      component: EnterprisePurchasePlan,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_SETTINGS_CREDIT_CARDS,
      component: EnterpriseCreditCards,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_SETTINGS_BILLING_ADDRESS,
      component: EnterpriseBillingAddress,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.ENTERPRISE_SETTINGS_INVOICES,
      component: EnterpriseInvoices,
   },
];

export const spaceManagementSettingsRoutes: IRoute[] = [
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_BASIC_INFO,
      component: SpaceManagementBasicInfo,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_ADDRESS,
      component: SpaceManagementAddress,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_GALLERY,
      component: SpaceManagementGallery,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_MANAGERS,
      component: SpaceManagementManagers,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_CAPACITY,
      component: SpaceManagementCapacity,
   },
];
export const spaceManagementInsightsRoutes: IRoute[] = [
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_SPACE_OVERVIEW,
      component: SpaceManagementSpaceOverview,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_CHECKINS,
      component: SpaceManagementCheckins,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_EARNINGS,
      component: SpaceManagementEarnings,
   },
];

export const spaceManagementRoutes: IRoute[] = [
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_SETTINGS,
      component: SpaceManagementSettings,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_TODAY,
      component: SpaceManagementToday,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_INSIGHTS,
      component: SpaceManagementInsights,
   },
   {
      name: translate('routes.spaceManagement'),
      path: RoutesEnum.SPACE_MANAGEMENT_YOUR_SPACES,
      component: SpaceManagementYourSpaces,
   },
   {
      name: translate('routes.spaceManagement.spaceDetails'),
      path: RoutesEnum.SPACE_MANAGEMENT_SPACE_DETAILS,
      component: SpaceDetails,
   },
];

const publicRoutesArray = [
   RoutesEnum.HOME,
   RoutesEnum.SPACE_DETAILS,
   RoutesEnum.MAP_VIEW,
   RoutesEnum.RESERVE_MEETING_ROOM,
   RoutesEnum.ACCEPT_INVITE,
   RoutesEnum.SIGN_UP,
];

const enterpriseManagementRoutesArray = [
   RoutesEnum.ENTERPRISE_MANAGEMENT,
   RoutesEnum.ENTERPRISE_TODAY,
   RoutesEnum.ENTERPRISE_MANAGEMENT_USER_PROFILE,
   RoutesEnum.SPACE_DETAILS,
];

const spaceManagementRoutesArray = [
   RoutesEnum.SPACE_MANAGEMENT,
   RoutesEnum.SPACE_MANAGEMENT_TODAY,
   RoutesEnum.SPACE_MANAGEMENT_SETTINGS,
   RoutesEnum.SPACE_MANAGEMENT_INSIGHTS,
   RoutesEnum.SPACE_MANAGEMENT_YOUR_SPACES,
   RoutesEnum.SPACE_MANAGEMENT_SPACE_DETAILS,
];

export const isPublicRoute = (currentPath: string) => {
   return _.contains(publicRoutesArray, currentPath);
};

export const isHRManagementRoute = (currentPath: string) => {
   return _.contains(enterpriseManagementRoutesArray, currentPath);
};

export const isSpaceManagementRoute = (currentPath: string) => {
   return _.contains(spaceManagementRoutesArray, currentPath);
};

interface IOptions {
   id?: number;
   name?: string;
}

export const replaceLocation = (route: RoutesEnum, options: IOptions | any): RoutesEnum => {
   Object.keys(options).forEach((element: IOptions | any) => {
      route = route.replace(`:${element}`, options[element]) as RoutesEnum;
   });
   return route as RoutesEnum;
};
