import { IValidationKey, IValidatorOptions, IValidationResult, validators, IValidatorErrorCode } from '.';
import { isNotNullOrUndefined } from '../helpers';

export interface IPipeResult {
   value: string;
   isValid: boolean;
   errors: Array<{ key: IValidatorErrorCode; options?: IValidatorOptions }>;
}

export function pipe(
   value: string,
   validations: Array<IValidationKey | { key: IValidationKey; options: IValidatorOptions }> = [],
): IPipeResult {
   return validations.reduce(
      (prev: IPipeResult, curr) => {
         let result: IValidationResult;

         if (typeof curr === 'string') {
            result = validators[curr]({ value: prev.value });
         } else {
            result = validators[curr.key]({ value: prev.value, ...curr.options });
         }

         if (isNotNullOrUndefined(result.error)) {
            Array.isArray(result.error) ? prev.errors.push(...result.error) : prev.errors.push(result.error);
         }

         prev.isValid = prev.errors.length === 0;
         prev.value = result.value;
         return prev;
      },
      { isValid: true, value, errors: [] },
   );
}
