import React, { ReactElement, useState, useEffect } from 'react';

import { Flex, Spinner } from 'src/components';

import EnterpriseSettingsRoot from './EnterpriseSettingsRoot';
import CreateCompany from './enterprise/CreateCompany';
import EnterpriseMembers from './enterprise/EnterpriseMembers';
import CompanyDetails from './enterprise/CompanyDetails';
import BillingDetails from './enterprise/BillingDetails';
import EnterpriseAdmins from './enterprise/EnterpriseAdmins';
import ConvertedToEnterpriseModal from './modals/ConvertedToEnterpriseModal';

import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { UserRoles } from 'src/system/state/types';
import { ICompany } from '../interfaces';
import { parseError } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/rootReducer';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { enterpriseBillingRoutes, enterpriseSettingsRoutes, RoutesEnum } from 'src/routes/routes';

export enum ActiveItemEnum {
   MANAGE_YOUR_TEAM = 'manageYourTeam',
   COMPANY_DETAILS = 'companyDetails',
   BILLING_DETAILS = 'billingDetails',
   COMPANY_MANAGERS = 'companyManagers',
   EMPTY = '',
}

const EnterpriseSettings = (): ReactElement | null => {
   const [loading, setLoading] = useState(true);
   const [company, setCompany] = useState<ICompany | null>(null);
   const [companyManagers, setCompanyManagers] = useState<any>([]);

   const [showCreatedCompanyDetailsModal, setShowCreatedCompanyDetailsModal] = useState(false);
   const [activeItem, setActiveItem] = useState(ActiveItemEnum.EMPTY);
   const [updateLoading, setUpdateLoading] = useState(false);

   const customerRole = useSelector((state: RootState) => state.systemReducer).customerRole;
   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;

   const [isHidden, setIsHidden] = useState(false);

   const routeMatch = useRouteMatch<RoutesEnum>([
      RoutesEnum.ENTERPRISE_SETTINGS_BILLING,
      RoutesEnum.ENTERPRISE_SETTINGS_COMPANY_DETAILS,
      RoutesEnum.ENTERPRISE_SETTINGS_MANAGERS,
      RoutesEnum.ENTERPRISE_SETTINGS_MANAGE_TEAM,
   ]);

   const getCompanyDetails = async (triggerLoader?: boolean) => {
      try {
         triggerLoader && setLoading(true);
         const response = await ApiRequests.getCompanyDetails(enterpriseId);
         setCompany(response.data?.company);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.getCompanyDetails'));
      } finally {
         setLoading(false);
      }
   };

   const getCompanyManagers = async () => {
      if (company && company.enterprise_id) {
         try {
            const response = await ApiRequests.getCompanyManagers(company.enterprise_id);
            setCompanyManagers(response.data);
         } catch (error) {
            NotificationsProvider.error(
               parseError(error) || translate('hrManagement.settings.error.getCompanyManagers'),
            );
         }
      } else {
         NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }
   };

   const updateCompany = async () => {
      setUpdateLoading(true);

      if (company && company.enterprise_id) {
         try {
            const response: any = await ApiRequests.updateCompany({
               payload: {
                  enterprise_id: company.enterprise_id,
                  company_name: company.company_name,
                  address: company.address.name,
                  city: company.address.city,
                  state: company.address.state,
                  zip_code: company.address.zip_code,
                  country: company.address.country,
                  domain: company.domain,
                  number_of_employees: company.number_of_employees,
                  vat: company.vat,
               },
            });

            if (response.success) {
               NotificationsProvider.success(response.data.message);
               await getCompanyDetails();
            } else {
               NotificationsProvider.error(translate('hrManagement.settings.error.updateCompany'));
            }
         } catch (error) {
            NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.updateCompany'));
         } finally {
            setUpdateLoading(false);
         }
      } else {
         NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }
   };

   const isItemActive = (itemToCheck: ActiveItemEnum) => {
      return itemToCheck === activeItem;
   };

   useEffect(() => {
      if (routeMatch?.isExact) {
         setIsHidden(true);
      }
   }, []);

   const renderSettings = () => (
      <Flex
         justifyContent="flex-start"
         alignItems="stretch"
         flexWrap="nowrap"
         flex={1}
         minHeight={'100%'}
         mobileMinHeight={'100%'}
         position={'relative'}
         width={'100%'}
         flexDirectionMobile={'column'}
         padding={'0 10% 3% 10%'}
      >
         <Flex
            width={'32%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
            hide={isHidden}
         >
            <EnterpriseSettingsRoot setActiveItem={setActiveItem} isItemActive={isItemActive} />
         </Flex>

         <Flex
            width={'66%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
         >
            <Switch>
               {enterpriseSettingsRoutes.map((r) => (
                  <Route exact key={r.name} path={r.path} component={r.component} />
               ))}

               {enterpriseBillingRoutes.map((r) => (
                  <Route exact key={r.name} path={r.path} component={r.component} />
               ))}
            </Switch>
         </Flex>

         {showCreatedCompanyDetailsModal && company && (
            <ConvertedToEnterpriseModal
               isOpen={showCreatedCompanyDetailsModal}
               setShowModal={setShowCreatedCompanyDetailsModal}
               company={company as ICompany}
            />
         )}
      </Flex>
   );

   useEffect(() => {
      if (customerRole === UserRoles.ENTERPRISE_MANAGER && enterpriseId) {
         void getCompanyDetails(true);
      } else {
         setLoading(false);
      }
      // eslint-disable-next-line
   }, [customerRole, enterpriseId]);

   useEffect(() => {
      if (company) {
         void getCompanyManagers();
      }
      // eslint-disable-next-line
   }, [company]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return customerRole !== UserRoles.ENTERPRISE_MANAGER && !enterpriseId ? (
      <CreateCompany setShowCreatedCompanyDetailsModal={setShowCreatedCompanyDetailsModal} />
   ) : company ? (
      renderSettings()
   ) : null;
};

export default EnterpriseSettings;
