import React, { ReactElement, useState, useEffect } from 'react';

import { Flex, Spinner } from 'src/components';

import InsightsRoot from './InsightsRoot';
import SpaceOverview from './space_overview/SpaceOverview';
import CheckinsInfo from './checkins_info/CheckinsInfo';

import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { ICompany } from '../interfaces';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';

import { useSelector } from 'react-redux';
import { RootState } from 'src/app/rootReducer';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { insightsRoutes, RoutesEnum } from 'src/routes/routes';

export enum ActiveItemEnum {
   SPACE_OVERVIEW = 'spaceOverview',
   CHECKINS_INFO = 'checkinsInfo',
   EMPTY = '',
}

const Insights = (): ReactElement => {
   const [loading, setLoading] = useState(true);
   const [company, setCompany] = useState<ICompany | null>(null);
   const [activeItem, setActiveItem] = useState<ActiveItemEnum>(ActiveItemEnum.EMPTY);

   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;

   const [isHidden, setIsHidden] = useState(false);

   const routeMatch = useRouteMatch<RoutesEnum>([
      RoutesEnum.ENTERPRISE_INSIGHTS_OVERVIEW,
      RoutesEnum.ENTERPRISE_INSIGHTS_CHECKINS,
   ]);

   const getCompanyDetails = async () => {
      try {
         setLoading(true);
         const response = await ApiRequests.getCompanyDetails(enterpriseId);
         setCompany(response.data?.company);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.getCompanyDetails'));
      } finally {
         setLoading(false);
      }
   };

   const isItemActive = (itemToCheck: ActiveItemEnum) => {
      return itemToCheck === activeItem;
   };

   useEffect(() => {
      void getCompanyDetails();
      // eslint-disable-next-line

      if (routeMatch?.isExact) {
         setIsHidden(true);
      }
   }, []);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         justifyContent="flex-start"
         flexWrap="nowrap"
         flex={1}
         minHeight={'100%'}
         position={'relative'}
         flexDirectionMobile={'column'}
         padding={'0 10% 3% 10%'}
         alignItems="stretch"
         width={'100%'}
      >
         <Flex
            width={'32%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
            hide={isHidden}
         >
            <InsightsRoot setActiveItem={setActiveItem} isItemActive={isItemActive} />
         </Flex>

         <Switch>
            {insightsRoutes.map((r) => (
               <Route exact key={r.name} path={r.path} component={r.component} />
            ))}
         </Switch>
      </Flex>
   );
};

export default Insights;
