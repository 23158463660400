import firebase from 'firebase';
import React, { ReactElement, useEffect, useState } from 'react';
import { Flex, StyledText, Table } from 'src/components';
import Button, { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn } from 'src/components/table/interfaces';
import { translate } from 'src/translations/translations';
import { useDispatch } from 'react-redux';
import { showLoginModal, showRegisterModal } from 'src/system/state/actions';
import theme from 'src/styles/theme';
import { ReserveMeetingRoomIllustration } from 'src/assets/images';
import { useWindowWide } from 'src/utilities/helpers';
import ApiRequests from 'src/features/settings/data/ApiRequests';
import moment from 'moment';
const columns: Array<TableColumn<any>> = [
   {
      width: '15%',
      title: 'City',
      key: 'city',
      enableSort: false,
      // eslint-disable-next-line react/display-name
   },
   {
      width: '15%',
      title: 'Date',
      key: 'date',
      enableSort: false,
      // eslint-disable-next-line react/display-name
   },
   {
      width: '15%',
      title: 'Group size',
      key: 'group_size',
      enableSort: false,
      // eslint-disable-next-line react/display-name
   },
   {
      width: '15%',
      title: 'Status',
      key: 'status',
      enableSort: false,
      // eslint-disable-next-line react/display-name
   },
   {
      width: '20%',
      title: 'Amenities',
      key: 'amenities',
      enableSort: false,
      // eslint-disable-next-line react/display-name
   },
   {
      width: '25%',
      title: 'Updated At',
      key: 'updated_at',
      enableSort: false,
      // eslint-disable-next-line react/display-name
   },
];
interface IProps {
   setCreateNewRequest: any;
}

const ReserveMeetingRoomTable = (props: IProps): ReactElement => {
   const [previousBookings, setPreviousBookings] = useState<any>([]);
   const currentUser = firebase.auth()?.currentUser;
   const dispatch = useDispatch();
   const wide = useWindowWide(768);
   const getPreviousBooking = async () => {
      const preBookings: any = await ApiRequests.getPreviousBookings(currentUser?.uid as string);

      preBookings.data.bookings.map((booking: any) => {
         booking.updated_at = moment(booking.updated_at).format('DD-MM-YYYY hh:mm');
         booking.date = moment(booking.start_date).format('DD.MM') + ' - ' + moment(booking.end_date).format('DD.MM');
      });

      setPreviousBookings(preBookings.data.bookings);
   };

   useEffect(() => {
      getPreviousBooking();
   }, []);

   if (!currentUser) {
      return (
         <Flex
            flexDirection="column"
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            padding={'0 10% 3% 10%'}
            paddingMobile={'5%'}
         >
            <Flex
               width={'100%'}
               flexDirection="column"
               alignItems={'flex-start'}
               justifyContent={'flex-start'}
               padding={'0 0 3% 0'}
               paddingMobile={'5%'}
            >
               <Flex
                  width={'100%'}
                  justifyContent="flex-start"
                  alignItems="stretch"
                  flexDirectionMobile={'column'}
                  flexWrap="nowrap"
                  backgroundColor={`${theme.colors.white}`}
                  borderRadius={'15px'}
                  padding={'5% 10% 5% 10%'}
                  flex={1}
                  position={'relative'}
                  border={`1px solid ${theme.colors.lightGray}`}
               >
                  <Flex
                     width={'35%'}
                     flexDirection="column"
                     justifyContent="flex-start"
                     backgroundColor={`${theme.colors.white}`}
                     alignItems="flex-start"
                     position={'relative'}
                     overflow={'hidden'}
                  >
                     <img
                        src={ReserveMeetingRoomIllustration}
                        alt="add-company"
                        style={{
                           width: '100%',
                           height: 'auto',
                           marginTop: '70px',
                           display: wide ? 'flex' : 'none',
                        }}
                     />
                  </Flex>

                  <Flex
                     width={'68%'}
                     flexDirection="column"
                     justifyContent="center"
                     backgroundColor={'#FFFFF'}
                     alignItems="center"
                     position={'relative'}
                     padding={'0 0 0 32px'}
                     overflow={'hidden'}
                  >
                     <StyledText
                        width={'100%'}
                        justifyContent={'center'}
                        text={translate('reserveMeetingRoomLogIn.text')}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                        margin={'0 0 32px 0'}
                     />

                     <Button
                        variant={ButtonVariantsType.PURPLE}
                        text={translate('reserveMeetingRoomSignIn.button.text')}
                        width={'100%'}
                        margin={'5px 10px'}
                        paddingMobile={'10'}
                        onClick={() => {
                           dispatch(showLoginModal(true));
                        }}
                     />
                     <Button
                        variant={ButtonVariantsType.PURPLE}
                        text={translate('reserveMeetingRoomRegister.button.text')}
                        width={'100%'}
                        margin={'5px 10px'}
                        paddingMobile={'10'}
                        onClick={() => {
                           dispatch(showRegisterModal(true));
                        }}
                     />
                  </Flex>
               </Flex>
            </Flex>
         </Flex>
      );
   } else {
      return (
         <Flex
            flexDirection="column"
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            padding={'0 0 5% 10%'}
            paddingMobile={'5%'}
         >
            <Flex
               flexDirection={wide ? 'row' : 'column'}
               width={'100%'}
               alignItems={'flex-start'}
               justifyContent={'flex-start'}
               padding={'0 10% 0 0'}
               paddingMobile={'5%'}
            >
               <StyledText
                  width={'80%'}
                  justifyContent={'flex-start'}
                  text={translate('reserveMeetingRoom.previous.bookings')}
                  variant={StyledTextVariantsType.HEADING_1}
               />
               <Button
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('reserveMeetingRoom.new.request')}
                  width={'50%'}
                  mobileWidth={'100%'}
                  margin={'0 0 5% 30%'}
                  onClick={() => {
                     props.setCreateNewRequest(true);
                  }}
               />
            </Flex>
            <Flex
               width={'90%'}
               justifyContent="flex-start"
               alignItems="stretch"
               flexDirectionMobile={'row'}
               flexWrap="nowrap"
               backgroundColor={`${theme.colors.white}`}
               borderRadius={'15px'}
               padding={'5%'}
               flex={1}
               position={'relative'}
               border={`1px solid ${theme.colors.lightGray}`}
            >
               <Flex
                  width={'100%'}
                  flexDirection="column"
                  justifyContent="flex-start"
                  backgroundColor={'#FFFFF'}
                  alignItems="flex-start"
                  position={'relative'}
                  padding={'0 0 0 32px'}
                  overflow={'hidden'}
               >
                  <Table
                     onSelectAll={() => null}
                     columns={columns}
                     data={previousBookings}
                     itemActions={undefined}
                     customClass={'w-100'}
                  />
               </Flex>
            </Flex>
         </Flex>
      );
   }
};

export default ReserveMeetingRoomTable;
