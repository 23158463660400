import React, { ReactElement, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';

import { Button, Flex, Input, ModalComponent, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import ApiRequests from 'src/features/settings/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';

interface IProps {
   isOpen: boolean;
   setShowModal: (value: boolean) => void;
}

const ActivateVoucherModal = ({ setShowModal, isOpen }: IProps): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(false);
   const [voucher, setVoucher] = useState('');

   const resetVaules = () => {
      setVoucher('');
      setShowModal(false);
   };

   const activateVoucher = async () => {
      try {
         setLoading(true);
         const response: any = await ApiRequests.activateVoucher({
            payload: {
               uuid: currentUser?.uid,
               code: voucher,
            },
         });

         if (response.success) {
            NotificationsProvider.success(translate('common.success.activateVoucher'));
         } else {
            NotificationsProvider.error(translate('common.error.activateVoucher'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.activateVoucher'));
      } finally {
         setLoading(false);
         resetVaules();
      }
   };

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'414px'}
         closeModal={() => {
            resetVaules();
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'} width={'100%'}>
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('settings.activateVoucher.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />

            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('settings.activateVoucher.subtitle')}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               margin={'0 0 48px 0'}
            />

            <Input
               width={'100%'}
               type={'text'}
               margin={'0 0 72px 0'}
               placeholder={translate('placeholders.activateVoucher')}
               onChange={(value) => setVoucher(value)}
               validators={['required']}
               value={voucher}
            />

            <Button
               disabled={!voucher}
               isLoading={loading}
               variant={ButtonVariantsType.PURPLE}
               text={translate('settings.activateVoucher.button.text')}
               width={'100%'}
               onClick={activateVoucher}
            />
         </Flex>
      </ModalComponent>
   );
};

export default ActivateVoucherModal;
