import {
   ActiveHRManagementUserProfileItemEnum,
   DirectSignUpType,
   DIRECT_SIGN_UP,
   HRManagementTabEnum,
   IGlobalVariables,
   ISystemState,
   LOGOUT,
   SET_CUSTOMER_ROLE,
   SET_ENTERPRISE_ID,
   SET_HR_MANAGEMENT_TAB,
   SET_HR_MANAGEMENT_USER_PROFILE_ACTIVE_ITEM,
   SET_IS_CUSTOMER,
   SET_SPACE_ID,
   SET_SPACE_MANAGEMENT_TAB,
   SET_TOKEN,
   SHOW_LOGIN_MODAL,
   SHOW_REGISTER_MODAL,
   SHOW_RESET_PASSWORD_MODAL,
   SHOW_SIGN_UP_MODAL,
   SpaceManagementTabEnum,
   SystemActionTypes,
   UPDATE_COWORKING_SPACES_SEARCH_TERM,
   UserRoles,
} from './types';

export const systemInitialState: ISystemState = {
   token: localStorage.getItem('token') || '',
   customerRole: localStorage.getItem('customer_role') || UserRoles.DEFAULT_USER,
   isCustomer: !!(localStorage.getItem('is_customer') && localStorage.getItem('is_customer') === 'true'),
   enterpriseId: localStorage.getItem('enterprise_id') || '',
   spaceId: localStorage.getItem('space_id') || '',
   showLoginModal: false,
   showResetPasswordModal: false,
   showRegisterModal: false,
   showSignUpModal: false,
   coworkingSpacesSearchTerm: '',
   selectedHRManagementTab:
      (localStorage.getItem('selected_hr_management_tab') as HRManagementTabEnum) || HRManagementTabEnum.TODAY,
   activeHRManagementUserProfileItem:
      (localStorage.getItem('active_hr_management_user_profile_item') as ActiveHRManagementUserProfileItemEnum) ||
      ActiveHRManagementUserProfileItemEnum.USER_MANAGEMENT,
   selectedSpaceManagementTab:
      (localStorage.getItem('selected_space_management_tab') as SpaceManagementTabEnum) || SpaceManagementTabEnum.TODAY,
};

export const globalVariablesInitialState: IGlobalVariables = {
   directSignUp: false,
};

export function globalVariablesReducer(
   state = globalVariablesInitialState,
   action: DirectSignUpType,
): IGlobalVariables {
   switch (action.type) {
      case DIRECT_SIGN_UP: {
         return {
            ...state,
            directSignUp: action.loading,
         };
      }
      default:
         return state;
   }
}

export function systemReducer(state = systemInitialState, action: SystemActionTypes): ISystemState {
   switch (action.type) {
      case UPDATE_COWORKING_SPACES_SEARCH_TERM:
         return {
            ...state,
            coworkingSpacesSearchTerm: action.payload,
         };
      case SHOW_LOGIN_MODAL:
         return {
            ...state,
            showLoginModal: action.payload,
         };
      case SHOW_RESET_PASSWORD_MODAL:
         return {
            ...state,
            showResetPasswordModal: action.payload,
         };
      case SHOW_SIGN_UP_MODAL:
         return {
            ...state,
            showSignUpModal: action.payload,
         };
      case SHOW_REGISTER_MODAL:
         return {
            ...state,
            showRegisterModal: action.payload,
         };
      case SET_HR_MANAGEMENT_TAB: {
         return {
            ...state,
            selectedHRManagementTab: action.payload,
         };
      }
      case SET_HR_MANAGEMENT_USER_PROFILE_ACTIVE_ITEM: {
         return {
            ...state,
            activeHRManagementUserProfileItem: action.payload,
         };
      }
      case SET_SPACE_MANAGEMENT_TAB: {
         return {
            ...state,
            selectedSpaceManagementTab: action.payload,
         };
      }
      case SET_CUSTOMER_ROLE: {
         return {
            ...state,
            customerRole: action.payload,
         };
      }
      case SET_IS_CUSTOMER: {
         return {
            ...state,
            isCustomer: action.payload,
         };
      }
      case SET_ENTERPRISE_ID: {
         return {
            ...state,
            enterpriseId: action.payload,
         };
      }
      case SET_SPACE_ID: {
         return {
            ...state,
            spaceId: action.payload,
         };
      }
      case SET_TOKEN: {
         return {
            ...state,
            token: action.payload,
         };
      }
      case LOGOUT: {
         return {
            ...state,
            token: '',
            customerRole: '',
            isCustomer: false,
            enterpriseId: '',
            spaceId: '',
         };
      }

      default:
         return state;
   }
}
