import React, { ReactElement } from 'react';
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(PointElement, Title, Tooltip, Legend, LineElement);

const getOptions = (data: any) => {
   return {
      plugins: {
         legend: {
            display: false,
         },
         title: {
            display: false,
         },
      },
      responsive: false,
      maintainAspectRatio: false,
      fullWidth: true,
      tooltipFontSize: 10,
      scales: {
         x: {
            display: true,
            ticks: {
               color: '#A2A2AC',
            },
            grid: {
               drawBorder: false,
               display: false,
            },
         },
         y: {
            beginAtZero: true,
            display: true,
            grid: {
               display: true,
            },
            ticks: {
               beginAtZero: true,
               max: Math.max.apply(null, data.datasets[0].data),
               display: true,
               padding: 5,
            },
         },
      },
   };
};

const getLabels = (statistics: any) => {
   // eslint-disable-next-line
   let labels: any[] = [];

   Object.keys(statistics).forEach((key: any) => {
      labels.push(key.substring(0, 2));
   });

   return labels;
};

const getData = (statistics: any) => {
   // eslint-disable-next-line
   let dataArray: any[] = [];

   Object.keys(statistics).forEach((key: any) => {
      dataArray.push(statistics[key]);
   });

   return dataArray;
};

const getDataObject = (statistics: any) => {
   return {
      labels: getLabels(statistics),
      datasets: [
         {
            data: getData(statistics),
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'transparent',
            pointBorderColor: '#bf3947',
            pointBackgroundColor: '#bf3947',
            pointBorderWidth: 4,
            pointHoverBorderColor: '#bf3947',
            pointHoverBackgroundColor: '#bf3947',
            pointHoverBorderWidth: 4,
            pointHoverRadius: 8,
            /*     pointRadius: function (context: any) {
               if (context.dataset.data.length === 0) return 0;
               if (context.dataset.data[context.dataIndex] === 0) return 0;

               return context.dataIndex === context.dataset.data.length - 1 ? 8 : 0;
            },*/
            pointRadius: 4,
            borderColor: '#bf3947',
            borderWidth: 3,
         },
      ],
   };
};

interface IProps {
   data: any;
}

const VerticalBarChart = ({ data }: IProps): ReactElement => {
   return <Line options={getOptions(getDataObject(data))} data={getDataObject(data)} height={200} />;
};

export default VerticalBarChart;
