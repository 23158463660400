import React, { ReactElement, useState, useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import moment from 'moment';

import { Flex, StyledText, Spinner, Button } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { InlineElementsWrapper } from 'src/components/SharedComponents';

import InfoModal from './modals/InfoModal';
import CheckInOptionsModal from './modals/check_in/CheckInOptionsModal';
import CheckInDetailsModal from './modals/check_in/CheckInDetailsModal';

import { AvatarPlaceholder, ArrowRightElement } from 'src/assets/images';
import { TwitterIcon, InstagramIcon, GlobeIcon, FacebookIcon } from 'src/assets/images/social-media';

import ApiRequests from 'src/features/spaces/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { capitalize, getTimeFromTimestamp, parseError, useWindowWide } from 'src/utilities/helpers';
import { setPageTitle } from 'src/utilities/seo.helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';

import theme from 'src/styles/theme';

import { config } from '../../../app/config';
import GoogleMap from '../../../components/googleMap/GoogleMap';
import { GoogleApiWrapper, GoogleAPI } from 'google-maps-react';

import SpaceCarousel from './SpaceCarousel';

const ManagerItem = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
   margin-bottom: 24px;

   img {
      height: 120px !important;
      width: 120px !important;
      object-fit: cover;
      border-radius: 10px;
      text-align: center;
   }

   > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      padding: 20px;
   }

   @media only screen and (max-width: 768px) {
      width: 100%;
   }
`;

const IconsWrapper = styled.div<any>`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   margin: ${(props) => props.margin || 0};

   @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
   }
`;

const IconItem = styled.div<any>`
   width: ${(props) => props.width || 'auto'};
   height: ${(props) => props.height || 'auto'};
   display: flex;
   padding: 12px;
   align-items: center;
   justify-content: center;
   margin-right: 16px;
   background-color: #ffffff;
   border-radius: 15px;
   border: 1px solid rgba(171, 171, 171, 0.5);
   cursor: pointer;

   img {
      height: 32px;
      width: auto;
   }

   span {
      margin-left: 10px;
      color: ${theme.colors.gray};
      font-family: Helvetica-Neue-Light;
   }

   @media only screen and (max-width: 768px) {
      margin-bottom: 16px;
   }
`;

const CoWorkingSpaceImagePreview = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   width: 100%;
   margin: 20px 0;

   @media only screen and (max-width: 768px) {
      margin: 0;
   }
`;

const CoWorkingSpaceImagePreviewCarouselWrapper = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 20%;
   height: 100%;
`;

const CoWorkingSpaceImage = styled.div<any>`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 80%;
   padding: 10px;
   height: 100%;
   position: relative;
   opacity: ${(props) => (props.dimmed ? 0.5 : 1)};

   img {
      max-height: 500px;
   }
`;

const WorkingHourCardsWrapper = styled.div`
   display: flex;
   flex-wrap: wrap;
   align-items: flex-start;
   justify-content: flex-start;
   width: 100%;

   @media only screen and (max-width: 768px) {
      justify-content: space-between !important;
   }
`;

const WorkingHourCard = styled.div`
   width: 25% !important;
   margin: 0 16px 16px 0;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   height: 75px;
   outline: none;
   border: 1px solid rgba(171, 171, 171, 0.5);
   background-color: ${theme.colors.white};
   padding: 16px;
   border-radius: 15px;

   @media only screen and (max-width: 768px) {
      width: 48% !important;
      margin: 0 0 16px 0 !important;
   }
`;

const SlideImgZoomed = styled.img`
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 10px;
`;
const ButtonsWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   flex-wrap: wrap;
`;
const GalleryWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: flex-start;
   flex-wrap: wrap;
   @media (max-width: 1070px) {
      flex-direction: column;
`;
interface IRouteParams {
   id: string;
}

const SpaceDetails = ({ google }: { google: GoogleAPI }): ReactElement | null => {
   const medium = useWindowWide(1200);
   const small = useWindowWide(1070);

   const firebase = getFirebase();
   const params = useParams();
   const { show } = useIntercom();

   const currentUser = firebase.auth()?.currentUser;
   const { id } = params as IRouteParams;

   const [loading, setLoading] = useState(true);
   const [showCoffeeInfoModal, setShowCoffeeInfoModal] = useState(false);
   const [showPrinterInfoModal, setShowPrinterInfoModal] = useState(false);
   const [showWifiInfoModal, setShowWifiInfoModal] = useState(false);

   const [space, setCoWorkingSpaceData] = useState<any>();

   const [selectedSpaceImageIndex, setSelectedCoworkingSpaceImageIndex] = useState(0);
   const [showSpinnerOnSelectedCoworkingSpaceImageChange, setShowSpinnerOnSelectedCoworkingSpaceImageChange] =
      useState(false);

   const [checkInLoading, setCheckInLoading] = useState(false);
   const [checkInData, setCheckInData] = useState<any>(null);
   const [checkInOptionsData, setCheckInOptionsData] = useState<any>(null);
   const [showCheckInOptionsModal, setShowCheckInOptionsModal] = useState(false);
   const [showCheckInDetailsModal, setShowCheckInDetailsModal] = useState(false);
   const [user, setUser] = useState<any>(currentUser);

   const getSpace = async () => {
      try {
         const response: any = await ApiRequests.getSpaceDetails(id);
         if (response && response.data) {
            setCoWorkingSpaceData(response.data);
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpace'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.getSpace'));
      } finally {
         setLoading(false);
      }
   };

   const getCheckInData = () => {
      if (!id) {
         return NotificationsProvider.error(translate('coWorkingSpaceDetails.error.missingId'));
      }

      const todayUTCDate = moment().utc().format('YYYY-MM-DD');
      const checkinsRef = firebase.database().ref(`checkins/${todayUTCDate}/records/users/${currentUser?.uid}`);

      checkinsRef.once(
         'value',
         (snapshot) => {
            if (!snapshot.val()) {
               return;
            }

            const data = snapshot.val();
            //eslint-disable-next-line
            let checkinDataObject: any = null;

            Object.keys(data).forEach((key) => {
               if (key === id) {
                  checkinDataObject = data[key];
               }
               return checkinDataObject;
            });

            setCheckInData(checkinDataObject);
         },
         (error) => {
            NotificationsProvider.error(error.message || 'coWorkingSpaceDetails.error.getCheckInData');
         },
      );
   };

   const checkIn = async (selectedCheckinOption?: any) => {
      if (!currentUser) {
         return NotificationsProvider.error(translate('common.error.signInPlease'));
      }

      if (space.availableSeats == 1) {
         return NotificationsProvider.error(translate('coWorkingSpaceDetails.error.checkIn.noAvailableSeats'));
      }

      const checkinMethod = selectedCheckinOption
         ? selectedCheckinOption.hasOwnProperty('plan_id')
            ? 'plan'
            : selectedCheckinOption.hasOwnProperty('voucher_id')
            ? 'voucher'
            : selectedCheckinOption.hasOwnProperty('membership_id')
            ? 'membership'
            : null
         : null;
      const checkinId = selectedCheckinOption
         ? selectedCheckinOption.hasOwnProperty('plan_id')
            ? selectedCheckinOption.plan_id
            : selectedCheckinOption.hasOwnProperty('voucher_id')
            ? selectedCheckinOption.voucher_id
            : selectedCheckinOption.hasOwnProperty('membership_id')
            ? selectedCheckinOption.membership_id
            : null
         : null;

      try {
         setCheckInLoading(true);
         const response = await ApiRequests.checkIn({
            payload: {
               uuid: currentUser?.uid,
               space_id: id.toString(),
               checkin_method: checkinMethod,
               checkin_id: checkinId,
            },
         });

         if (!(response as any).success) {
            setCheckInLoading(false);
            return NotificationsProvider.error(
               response.data?.message || translate('coWorkingSpaceDetails.error.checkIn'),
            );
         }

         if (response.data.hasOwnProperty('message')) {
            await getCheckInData();
            setShowCheckInOptionsModal(false);
            setShowCheckInDetailsModal(true);
         } else {
            setCheckInOptionsData(response.data);
            setShowCheckInOptionsModal(true);
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('coWorkingSpaceDetails.error.checkIn'));
      } finally {
         setCheckInLoading(false);
      }
   };

   const onViewTicketClick = () => {
      setShowCheckInDetailsModal(true);
   };

   const onSliderImageChange = (index: number) => {
      if (selectedSpaceImageIndex === index) {
         return;
      }

      if (space && space.images?.gallery) {
         setShowSpinnerOnSelectedCoworkingSpaceImageChange(true);

         setTimeout(() => {
            setSelectedCoworkingSpaceImageIndex(index);
            setShowSpinnerOnSelectedCoworkingSpaceImageChange(false);
         }, 500);
      }
   };

   const goToPage = (url: string) => {
      window.open(url, '_blank');
   };

   const sortDaysOfWeek = (workingHoursObject: any) => {
      if (!workingHoursObject) {
         return {};
      }

      let sorted: any;
      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

      days.forEach((day: any) => {
         sorted = { ...sorted, [`${day}`]: workingHoursObject[day] };
      });

      return sorted;
   };

   const generateMarkers = (space: any) => {
      if (space && space.location) {
         //eslint-disable-next-line
         let adjusted: any = {};
         adjusted = space;
         adjusted.location = space.location;
         return [adjusted];
      } else {
         return [];
      }
   };

   const hasCheckinOptions = () => {
      return (
         checkInOptionsData &&
         ((checkInOptionsData.memberships && checkInOptionsData.memberships.length > 0) ||
            (checkInOptionsData.plans && checkInOptionsData.plans.length > 0) ||
            (checkInOptionsData.vouchers && checkInOptionsData.vouchers.length > 0))
      );
   };

   useEffect(() => {
      if (id) {
         void getSpace();
      }
      //eslint-disable-next-line
   }, [id]);

   useEffect(() => {
      if (space) {
         setPageTitle(`OneCo - ${space?.name}`);
      }
   }, [space]);

   useEffect(() => {
      if (id && currentUser) {
         getCheckInData();
      }
      //eslint-disable-next-line
   }, [id, currentUser]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <>
         <Flex
            flexDirection="column"
            minHeight={'100%'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            padding={'0 10% 3% 10%'}
            paddingMobile={'0 20px 3% 20px'}
         >
            {showCheckInOptionsModal && hasCheckinOptions() && (
               <CheckInOptionsModal
                  isOpen={showCheckInOptionsModal}
                  setShowModal={setShowCheckInOptionsModal}
                  checkInLoading={checkInLoading}
                  checkInOptionsData={checkInOptionsData}
                  checkIn={checkIn}
               />
            )}

            {showCheckInDetailsModal && checkInData && (
               <CheckInDetailsModal
                  isOpen={showCheckInDetailsModal}
                  setShowModal={setShowCheckInDetailsModal}
                  checkInData={checkInData}
               />
            )}

            <StyledText text={space?.name} variant={StyledTextVariantsType.HEADING_1} margin={'0 0 8px 0'} />
            {space.location && (
               <StyledText
                  text={`${space?.location?.city}, ${space?.location?.country}`}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  margin={'0 0 32px 0'}
               />
            )}

            <GalleryWrapper>
               <SpaceCarousel data={space?.images?.gallery} />
               <Flex
                  width={'35%'}
                  justifyContent={'center'}
                  height={'100%'}
                  alignItems={'flex-start'}
                  positionMobile={'relative'}
               >
                  <Sticky enabled={false} top={20}>
                     {checkInData ? (
                        <Button
                           variant={ButtonVariantsType.GREEN}
                           width={'95%'}
                           mobileWidth={'100%'}
                           onClick={onViewTicketClick}
                           margin={'0 0 0 auto'}
                           paddingMobile={'0px'}
                        >
                           <Flex
                              width={'100%'}
                              flexDirectionMobile={'column'}
                              backgroundColor={theme.colors.green}
                              paddingMobile={'5px'}
                           >
                              <Flex
                                 width={'60%'}
                                 flexDirection={'column'}
                                 justifyContent={'flex-start'}
                                 alignItems={'flex-start'}
                                 backgroundColor={theme.colors.green}
                              >
                                 <StyledText
                                    text={translate('coWorkingSpaceDetails.checkInData.title.part1')}
                                    variant={StyledTextVariantsType.PARAGRAPH_1}
                                    colorVariant={theme.colors.white}
                                    margin={'0 0 8px 0'}
                                 />
                                 <Flex alignItems={'center'} width={'100%'} backgroundColor={theme.colors.green}>
                                    <StyledText
                                       text={translate('coWorkingSpaceDetails.checkInData.title.part2')}
                                       variant={StyledTextVariantsType.PARAGRAPH_2}
                                       colorVariant={theme.colors.white}
                                       margin={'0 5px 0 0'}
                                    />
                                    <ArrowRightElement />
                                 </Flex>
                              </Flex>
                              <Flex
                                 width={'40%'}
                                 justifyContent={'center'}
                                 alignItems={'center'}
                                 backgroundColor={theme.colors.green}
                              >
                                 <StyledText
                                    text={getTimeFromTimestamp(checkInData?.timestamp)}
                                    variant={StyledTextVariantsType.HEADING_1}
                                    colorVariant={theme.colors.white}
                                 />
                              </Flex>
                           </Flex>
                        </Button>
                     ) : (
                        <Flex
                           width={small ? '80%' : '100%'}
                           flexDirection="column"
                           alignItems={'center'}
                           justifyContent={'center'}
                           backgroundColor={'#F9F9F9'}
                           borderRadius={'15px'}
                           padding={'32px'}
                           margin={'0 50px 0 auto'}
                        >
                           <InlineElementsWrapper
                              margin={medium ? '140px 0 16px 0' : small ? '105px 0 15px 0' : '10px 0 0 0'}
                           >
                              <StyledText
                                 text={`${space?.availableSeats}`}
                                 variant={StyledTextVariantsType.HEADING_2}
                              />
                              <StyledText
                                 text={'available seats'}
                                 variant={StyledTextVariantsType.PARAGRAPH_2}
                                 margin={'0 0 0 8px'}
                              />
                           </InlineElementsWrapper>
                           <Button
                              isLoading={checkInLoading}
                              disabled={space?.availableSeats == 0 || !currentUser}
                              variant={ButtonVariantsType.PURPLE}
                              text={translate('common.checkIn')}
                              margin={'0 0 16px 0'}
                              width={'100%'}
                              onClick={checkIn}
                           />
                           <InlineElementsWrapper margin={medium ? '157px 0 0 0' : small ? '95px 0 0 0' : '10px 0 0 0'}>
                              <StyledText
                                 text={translate('common.needHelp')}
                                 variant={StyledTextVariantsType.PARAGRAPH_2}
                                 colorVariant={theme.colors.mainPurple}
                              />
                              <StyledText
                                 text={'Contact us'}
                                 variant={StyledTextVariantsType.HEADING_3}
                                 margin={'0 0 0 5px'}
                                 colorVariant={theme.colors.mainPurple}
                                 cursor={'pointer'}
                                 onClick={show}
                              />
                           </InlineElementsWrapper>
                        </Flex>
                     )}
                  </Sticky>
               </Flex>
            </GalleryWrapper>

            {checkInData && (
               <>
                  <StyledText
                     text={translate('coWorkingSpaceDetails.onboarding.title')}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 16px 0'}
                     marginMobile={'16px 0 16px 0'}
                  />
                  <IconsWrapper>
                     {space?.wifiText && (
                        <IconItem
                           width={'120px'}
                           height={'75px'}
                           onClick={() => {
                              setShowWifiInfoModal(true);
                           }}
                        >
                           <i className={'fa fa-wifi'} />
                           <span>Wi-Fi</span>
                        </IconItem>
                     )}
                     {space?.coffeeText && (
                        <IconItem
                           width={'120px'}
                           height={'75px'}
                           onClick={() => {
                              setShowCoffeeInfoModal(true);
                           }}
                        >
                           <i className={'fa fa-coffee'} />
                           <span>Coffee</span>
                        </IconItem>
                     )}
                     {space?.printerText && (
                        <IconItem
                           width={'120px'}
                           height={'75px'}
                           onClick={() => {
                              setShowPrinterInfoModal(true);
                           }}
                        >
                           <i className={'fa fa-print'} />
                           <span>Printer</span>
                        </IconItem>
                     )}
                  </IconsWrapper>

                  {showWifiInfoModal && (
                     <InfoModal
                        isOpen={showWifiInfoModal}
                        setShowModal={setShowWifiInfoModal}
                        infoText={space?.wifiText}
                     />
                  )}

                  {showCoffeeInfoModal && (
                     <InfoModal
                        isOpen={showCoffeeInfoModal}
                        setShowModal={setShowCoffeeInfoModal}
                        infoText={space?.coffeeText}
                     />
                  )}

                  {showPrinterInfoModal && (
                     <InfoModal
                        isOpen={showPrinterInfoModal}
                        setShowModal={setShowPrinterInfoModal}
                        infoText={space?.printerText}
                     />
                  )}
               </>
            )}

            <Flex
               width={'100%'}
               alignItems={'flex-start'}
               flexDirectionMobile={'column'}
               justifyContent={'flex-start'}
               paddingMobile={'0px'}
               margin={'50px 0 50px 0'}
            >
               <Flex width={'65%'} flexDirection="column" alignItems={'flex-start'} justifyContent={'flex-start'}>
                  <StyledText
                     text={space?.description}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 32px 0'}
                  />

                  {space?.vibes.length > 0 && (
                     <StyledText
                        text={translate('coWorkingSpaceDetails.vibes.amenities.title')}
                        variant={StyledTextVariantsType.HEADING_2}
                        margin={'16px 0 16px 0'}
                     />
                  )}

                  {space?.vibes && (
                     <ButtonsWrapper>
                        {space.vibes.map((vibe: string, index: number) => {
                           return (
                              <Button
                                 text={vibe}
                                 variant={ButtonVariantsType.SECONDARYWHITE}
                                 reduceHeight={true}
                                 margin={'5px 5px 0 0'}
                                 marginMobile={'5px 5px 0 0'}
                                 mobileWidth={'fit-content'}
                                 paddingMobile={'10px'}
                                 key={`${vibe}_${index}`}
                              />
                           );
                        })}
                     </ButtonsWrapper>
                  )}
                  {space?.amenities && (
                     <ButtonsWrapper>
                        {space.amenities.map((amenitie: string, index: number) => {
                           return (
                              <Button
                                 text={amenitie}
                                 variant={ButtonVariantsType.SECONDARYWHITE}
                                 reduceHeight={true}
                                 margin={'5px 5px 0 0'}
                                 marginMobile={'5px 5px 0 0'}
                                 mobileWidth={'fit-content'}
                                 paddingMobile={'10px'}
                                 key={`${amenitie}_${index}`}
                              />
                           );
                        })}
                     </ButtonsWrapper>
                  )}

                  <StyledText
                     text={translate('coWorkingSpaceDetails.workHours.title')}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'16px 0 16px 0'}
                  />

                  <WorkingHourCardsWrapper>
                     {space?.workingHours &&
                        Object.keys(sortDaysOfWeek(space.workingHours)).map((key: any, index: number) => (
                           <WorkingHourCard key={index}>
                              <StyledText
                                 text={capitalize(key)}
                                 variant={StyledTextVariantsType.PARAGRAPH_3}
                                 colorVariant={theme.colors.gray}
                                 margin={'0 0 5px 0'}
                              />
                              <StyledText text={space?.workingHours[key]} variant={StyledTextVariantsType.HEADING_3} />
                           </WorkingHourCard>
                        ))}
                  </WorkingHourCardsWrapper>
                  <StyledText
                     text={translate('coWorkingSpaceDetails.communityManagers.title')}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'42px 0 24px 0'}
                  />
                  <Flex width={'100%'} flexWrap={'wrap'} paddingMobile={'unset'}>
                     {space?.managers &&
                        Object.keys(space.managers).map((key: any, index: number) => {
                           return (
                              <ManagerItem key={index}>
                                 <img
                                    src={space.managers[key].profileImage || AvatarPlaceholder}
                                    alt="Community manager"
                                    onError={({ currentTarget }) => {
                                       currentTarget.src = AvatarPlaceholder;
                                    }}
                                 />
                                 <div>
                                    <StyledText
                                       text={space.managers[key].name}
                                       variant={StyledTextVariantsType.PARAGRAPH_2}
                                       margin={'0 0 4px 0'}
                                    />
                                    {user && space.managers[key].email && (
                                       <StyledText
                                          text={space.managers[key].email}
                                          variant={StyledTextVariantsType.PARAGRAPH_3}
                                          colorVariant={theme.colors.gray}
                                       />
                                    )}
                                    {user && space.managers[key].phoneNumber && (
                                       <StyledText
                                          text={space.managers[key].phoneNumber}
                                          variant={StyledTextVariantsType.PARAGRAPH_3}
                                          colorVariant={theme.colors.gray}
                                       />
                                    )}
                                 </div>
                              </ManagerItem>
                           );
                        })}
                  </Flex>
                  <StyledText
                     text={translate('common.location')}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'52px 0 4px 0'}
                  />
                  <StyledText
                     text={`${space?.location?.city}, ${space?.location?.country}`}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 16px 0'}
                  />
                  <GoogleMap
                     google={google}
                     markers={generateMarkers(space)}
                     height={'500px'}
                     width={'80%'}
                     zoom={18}
                  />
                  <StyledText
                     text={translate('coWorkingSpaceDetails.socialMedia.title')}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'32px 0 16px 0'}
                  />
                  <IconsWrapper>
                     {space?.facebook && (
                        <IconItem
                           width={'85px'}
                           height={'85px'}
                           onClick={() => {
                              goToPage(space.facebook);
                           }}
                        >
                           <img src={FacebookIcon} alt={'facebook'} />
                        </IconItem>
                     )}
                     {space?.instagram && (
                        <IconItem
                           width={'85px'}
                           height={'85px'}
                           onClick={() => {
                              goToPage(space.instagram);
                           }}
                        >
                           <img src={InstagramIcon} alt={'instagram'} />
                        </IconItem>
                     )}
                     {space?.twitter && (
                        <IconItem
                           width={'85px'}
                           height={'85px'}
                           onClick={() => {
                              goToPage(space.twitter);
                           }}
                        >
                           <img src={TwitterIcon} alt={'twitter'} />
                        </IconItem>
                     )}
                     {space?.website && (
                        <IconItem
                           width={'85px'}
                           height={'85px'}
                           onClick={() => {
                              goToPage(space.website);
                           }}
                        >
                           <img src={GlobeIcon} alt={'website'} />
                        </IconItem>
                     )}
                  </IconsWrapper>
               </Flex>
            </Flex>
         </Flex>
      </>
   );
};

const loadingContainer = () => <div />;
export default GoogleApiWrapper({
   apiKey: config.GOOGLE_MAPS_API_KEY as string,
   LoadingContainer: loadingContainer,
})(SpaceDetails);
