import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Flex, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { RoutesEnum } from 'src/routes/routes';
import { NotFound404, Logo } from 'src/assets/images';
import { translate } from 'src/translations/translations';

const LogoContainer = styled.div`
   display: flex;
   width: 100%;
   align-items: center;
   justify-content: flex-start;
   padding: 20px;

   img {
      height: 60px;
   }
`;

const ContentContainer = styled.div`
   display: flex;
   width: 100%;
   height: 85vh;
   align-items: center;
   justify-content: center;
`;

const NotFoundBox = styled.div`
   display: flex;
   flex-direction: column;
   width: auto;
   height: auto;
   align-items: center;
   justify-content: center;
   padding: 30px;

   i {
      height: 100px;
      width: 100px;
      margin-bottom: 40px;
   }
`;

const NotFound = (): ReactElement => {
   const history = useHistory();

   return (
      <Flex flex={1} flexDirection="column" alignItems={'center'} justifyContent={'center'} height={'100%'}>
         <LogoContainer>
            <Link to={RoutesEnum.HOME}>
               <img src={Logo} alt={'logo'} />
            </Link>
         </LogoContainer>
         <ContentContainer>
            <NotFoundBox>
               <img src={NotFound404} alt={'logo green'} />
               <StyledText
                  text={translate('notFound.title')}
                  justifyContent={'center'}
                  variant={StyledTextVariantsType.LABEL_TEXT}
                  margin={'16px 0 24px 0'}
               />
               <StyledText
                  text={translate('notFound.text')}
                  justifyContent={'center'}
                  variant={StyledTextVariantsType.HEADING_3}
                  width={'75%'}
               />
               <Button
                  margin={'36px 0 0 0'}
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('notFound.button.text')}
                  width={'33%'}
                  onClick={() => {
                     history.push(RoutesEnum.HOME);
                  }}
               />
            </NotFoundBox>
         </ContentContainer>
      </Flex>
   );
};

export default NotFound;
