import {
   ActiveHRManagementUserProfileItemEnum,
   HRManagementTabEnum,
   LOGOUT,
   SET_CUSTOMER_ROLE,
   SET_ENTERPRISE_ID,
   SET_HR_MANAGEMENT_TAB,
   SET_HR_MANAGEMENT_USER_PROFILE_ACTIVE_ITEM,
   SET_IS_CUSTOMER,
   SET_SPACE_ID,
   SET_SPACE_MANAGEMENT_TAB,
   SET_TOKEN,
   SHOW_LOGIN_MODAL,
   SHOW_REGISTER_MODAL,
   SHOW_RESET_PASSWORD_MODAL,
   SHOW_SIGN_UP_MODAL,
   SpaceManagementTabEnum,
   SystemActionTypes,
   UPDATE_COWORKING_SPACES_SEARCH_TERM,
   UserRoles,
   DIRECT_SIGN_UP,
   DirectSignUpType,
} from './types';

export function updateCoworkingSpacesSearchTerm(searchTerm: string): SystemActionTypes {
   return {
      type: UPDATE_COWORKING_SPACES_SEARCH_TERM,
      payload: searchTerm,
   };
}

export function showLoginModal(flag: boolean): SystemActionTypes {
   return {
      type: SHOW_LOGIN_MODAL,
      payload: flag,
   };
}

export function showResetPasswordModal(flag: boolean): SystemActionTypes {
   return {
      type: SHOW_RESET_PASSWORD_MODAL,
      payload: flag,
   };
}

export function showRegisterModal(flag: boolean): SystemActionTypes {
   return {
      type: SHOW_REGISTER_MODAL,
      payload: flag,
   };
}

export function showSignUpModal(flag: boolean): SystemActionTypes {
   return {
      type: SHOW_SIGN_UP_MODAL,
      payload: flag,
   };
}

export function setHRManagementTab(tab: HRManagementTabEnum): SystemActionTypes {
   localStorage.setItem('selected_hr_management_tab', tab);

   return {
      type: SET_HR_MANAGEMENT_TAB,
      payload: tab,
   };
}

export function setActiveHRManagementUserProfileItem(item: ActiveHRManagementUserProfileItemEnum): SystemActionTypes {
   localStorage.setItem('active_hr_management_user_profile_item', item);

   return {
      type: SET_HR_MANAGEMENT_USER_PROFILE_ACTIVE_ITEM,
      payload: item,
   };
}

export function setSpaceanagementTab(tab: SpaceManagementTabEnum): SystemActionTypes {
   localStorage.setItem('selected_space_management_tab', tab);

   return {
      type: SET_SPACE_MANAGEMENT_TAB,
      payload: tab,
   };
}

export function setCustomerRole(role: UserRoles): SystemActionTypes {
   localStorage.setItem('customer_role', role);

   return {
      type: SET_CUSTOMER_ROLE,
      payload: role,
   };
}

export function setIsCustomer(value: boolean): SystemActionTypes {
   localStorage.setItem('is_customer', JSON.stringify(value));

   return {
      type: SET_IS_CUSTOMER,
      payload: value,
   };
}

export function setEnterpriseId(value: string): SystemActionTypes {
   localStorage.setItem('enterprise_id', value);

   return {
      type: SET_ENTERPRISE_ID,
      payload: value,
   };
}

export function setSpaceId(value: string): SystemActionTypes {
   localStorage.setItem('space_id', value);

   return {
      type: SET_SPACE_ID,
      payload: value,
   };
}

export function setToken(token: string): SystemActionTypes {
   localStorage.setItem('token', token);

   return {
      type: SET_TOKEN,
      payload: token,
   };
}

export function logout() {
   localStorage.clear();
   return {
      type: LOGOUT,
   };
}

export function setDirectSignUp(value: boolean): DirectSignUpType {
   return {
      type: DIRECT_SIGN_UP,
      loading: value,
   };
}
