import React from 'react';

interface Props {
   isChecked: boolean;
   handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
   label: string;
   list?: boolean;
}

const Checkbox = (props: Props) => {
   return (
      <>
         {props.list ? (
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: 'solid',

                  borderColor: 'rgb(228, 228, 228)',
               }}
            >
               <label htmlFor={props.label}>{props.label}</label>
               <input
                  style={{
                     marginLeft: 10,
                     marginRight: 10,
                     marginTop: props.list ? 20 : 0,
                     marginBottom: props.list ? 20 : 0,
                  }}
                  type="checkbox"
                  id={props.label}
                  checked={props.isChecked}
                  onChange={props.handleChange}
               />
            </div>
         ) : (
            <div>
               <label htmlFor={props.label}>{props.label}</label>
               <input
                  style={{
                     marginLeft: 10,
                     marginRight: 10,
                     marginTop: props.list ? 20 : 0,
                     marginBottom: props.list ? 20 : 0,
                  }}
                  type="checkbox"
                  id={props.label}
                  checked={props.isChecked}
                  onChange={props.handleChange}
               />
            </div>
         )}
      </>
   );
};
export default Checkbox;
