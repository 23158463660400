import { ajaxService } from 'src/services/AjaxService';
import { IRequest, IResponse } from 'src/system/interfaces';
import { ICreateUserRequestDTO, ICreateTokenRequestDTO, IAcceptInviteRequestDTO } from './RequestDtos';
import { ICreateTokenResponseDTO } from './ResponseDtos';

export default class ApiCalls {
   public static createUser(request: IRequest<ICreateUserRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/users/create', request.payload, {
         noAuthentication: true,
      });
   }

   public static createToken(request: IRequest<ICreateTokenRequestDTO>) {
      return ajaxService.post<IResponse<ICreateTokenResponseDTO>>('/tokens/create', request.payload, {
         noAuthentication: true,
      });
   }

   public static getInvitation(token: string) {
      return ajaxService.get<IResponse<any>>(`/enterprise/getUserInfoFromToken/${token}`, {
         noAuthentication: true,
      });
   }

   public static acceptInvite(request: IRequest<IAcceptInviteRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/enterprise/accept-invite', request.payload, {
         noAuthentication: true,
      });
   }
}
