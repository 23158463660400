import React, { useRef, useEffect, useState } from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import { CrossIcon, CloseIcon, UpdateIcon } from 'src/assets/images';
import { ModalComponent, Spinner } from 'src/components';
import { CustomizableImg } from 'src/components/SharedComponents';

interface IProps {
   loading?: boolean;
   height?: string;
   enableAdding: boolean;
   onImageChange?: (event: any) => void;
   images: string[];
   logo?: boolean;
   space_id?: string;
   onImageDelete?: (payload: any) => void;
}

const OneImage = styled.div`
   position: relative;
   background-color: white;
   border: 1px solid #dadada;
   border-radius: 20px;
   width: 30%;
   height: 190px;
   margin: 5px;
   display: flex;
   padding: 0;
   justify-content: center;
   align-items: center;
`;
const ImagesWrapper = styled.div`
   display: flex;
   flex-wrap: wrap;
   width: 100%;
   height: ${({ height }: IProps) => height};
`;
const CloseImage = styled.div`
   position: absolute;
   top: -10px;
   right: -10px;
   background-color: red;
   width: 25px;
   height: 25px;
   border-radius: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const ImagePicker = (props: IProps) => {
   let newArray: any = [];
   let all = [];

   if (!props.logo) {
      newArray = props.images.map((item: any) => item.photo);
      all = [...newArray];
   } else {
      all = [...props.images];
   }

   const fileInput = useRef(null);
   const refUpdate = useRef(null);
   const refDelete = useRef(null);
   const [isOpen, setIsOpen] = useState(false);
   const [selectedItem, setIsSelected] = useState('');

   const handleFileChange = (e: any) => {
      props.onImageChange && props.onImageChange(e);
   };

   const imagePreviewClicked = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
      if (event.target == refUpdate.current || event.target == refDelete.current) {
         event.stopPropagation();
         return;
      } else {
         if (index >= props.images.length) {
            // @ts-ignore
            fileInput.current.click();
         } else {
            let newPreviewObject;

            if (!props.logo) {
               newPreviewObject = newArray[index];
            } else {
               newPreviewObject = props.images[index];
            }
            setIsSelected(newPreviewObject);
            setIsOpen(true);
         }
      }
   };
   const updateButtonClicked = (event: any) => {
      // @ts-ignore
      fileInput.current.click();

      event?.stopPropagation();
   };

   const removeButtonClciked = async (index: number) => {
      //todo
      const onePhoto: any = props.images[index];

      const payload = {
         photo_id: onePhoto.id.toString(),
         space_id: props.space_id,
      };
      props.onImageDelete(payload);
   };

   useEffect(() => {
      // @ts-ignore
      if (!props.loading && fileInput?.current?.files) {
         // @ts-ignore
         fileInput.current.files = null;
      }
   }, [props.loading]);

   return (
      <ImagesWrapper>
         {all.map((item, index) => (
            <>
               <OneImage key={index} onClick={() => imagePreviewClicked(event, index)}>
                  {props.loading ? (
                     <Spinner />
                  ) : (
                     <CustomizableImg
                        src={item}
                        style={{ borderRadius: 18, height: '100%', width: '100%' }}
                        objectFit={'cover'}
                     />
                  )}
                  {props.logo ? (
                     <CloseImage onClick={() => updateButtonClicked(event)}>
                        <img ref={refUpdate} src={UpdateIcon} alt="Update" width={20} height={20} />
                     </CloseImage>
                  ) : (
                     props.images.length > 1 && (
                        <CloseImage onClick={() => removeButtonClciked(index)}>
                           <img ref={refDelete} src={CloseIcon} alt="Close" width={20} height={20} />
                        </CloseImage>
                     )
                  )}
               </OneImage>
            </>
         ))}
         {props.enableAdding && (
            <OneImage key={all.length + 1} onClick={() => imagePreviewClicked(event, all.length + 1)}>
               <>{props.loading ? <Spinner /> : <img src={CrossIcon} alt="Cross" />}</>
            </OneImage>
         )}
         <ModalComponent
            size="medium"
            visible={isOpen}
            width={'500px'}
            height={'400px'}
            padding={'0'}
            closeModal={() => {
               setIsOpen(false);
            }}
         >
            <CustomizableImg src={selectedItem} height={'100%'} width={'100%'} />
         </ModalComponent>
         <input ref={fileInput} type="file" onChange={handleFileChange} multiple={false} style={{ display: 'none' }} />
      </ImagesWrapper>
   );
};

export default ImagePicker;
