import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { StyledText } from './index';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { NoDataPlaceholderIllustration } from 'src/assets/images';
import { translate } from '../translations/translations';

interface IProps {
   width?: string;
   height?: string;
   position?: string;
   margin?: string;
}

const Container = styled.div<any>`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: ${({ height }: IProps) => height || 'auto'};
   width: ${({ width }: IProps) => width || '100%'};
   position: ${({ position }: IProps) => position || 'initial'};
   margin: ${({ margin }: IProps) => margin || 0};

   img {
      height: 150px;
      width: auto;
   }
`;

const NoDataPlaceholderComponent = ({ height, position, width, margin }: IProps): ReactElement => {
   return (
      <Container position={position} height={height} width={width} margin={margin}>
         <img src={NoDataPlaceholderIllustration} alt={'no data'} />

         <StyledText
            width={'100%'}
            text={translate('noDataPlaceholder.text')}
            variant={StyledTextVariantsType.PARAGRAPH_2}
            margin={'32px 0 6px 0'}
            justifyContent={'center'}
         />

         <StyledText
            width={'100%'}
            text={translate('noDataPlaceholder.title')}
            variant={StyledTextVariantsType.HEADING_2}
            justifyContent={'center'}
         />
      </Container>
   );
};

export default NoDataPlaceholderComponent;
