import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Button, Flex, Input, ModalComponent, NoDataPlaceholderComponent, Spinner, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { translate } from 'src/translations/translations';

import { ButtonVariantsType } from 'src/components/Button';
import styled from 'styled-components';

const MainWrapper = styled.div`
   height: 100%;
   width: 100%;
   display: flex;
   flex-direction: column;
   overflow: hidden;
`;
const HeaderWrapper = styled.div`
   background-color: white;
   height: 180px;
   position: sticky;
   border-bottom: solid;
   border-bottom-color: #e4e4e4;
   top: 0;
   padding: 10px;
`;
const ContentWrapper = styled.div`
   margin-left: 10px;
   margin-bottom: 10px;
   width: 97%;
   flex-grow: 1;
   padding: 10px;
   overflow: auto;
`;
const OneItem = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   border-bottom: solid;
   border-color: rgb(228, 228, 228);
`;
const FooterWrapper = styled.div`
   height: 20%;
   position: sticky;
   bottom: 0px;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 10px;
`;

interface IProps {
   isOpen: boolean;
   setIsOpen: any;
   data: any;
   checkedData: any;
   setCheckedData: any;
   isLocation?: boolean;
   isGroupSize?: boolean;
   isAmenitiesList?: boolean;
   text?: string;
}

const MeetingRoomModal = (props: IProps): ReactElement => {
   const [citiesSearchTerm, setCitiesSearchTerm] = useState('');
   const [customAmenities, setCustomAmenities] = useState('');

   let newCheckedObject = {
      location: props.checkedData.location,
      dateObject: props.checkedData.dateObject,
      groupSize: props.checkedData.groupSize,
      amenitiesList: props.checkedData.amenitiesList,
   };

   const handleChange = (event: any) => {
      let updatedLocation = props.checkedData.location;
      let updatedGroupSize = props.checkedData.groupSize;
      let updatedAmenitiesList = [...props.checkedData.amenitiesList];
      if (props.isLocation) {
         updatedLocation = event.target.value;
      }
      if (props.isGroupSize) {
         updatedGroupSize = event.target.value;
      }
      if (props.isAmenitiesList) {
         if (event.target.checked) {
            updatedAmenitiesList = [...props.checkedData.amenitiesList, event.target.value];
         } else {
            updatedAmenitiesList.splice(updatedAmenitiesList.indexOf(event.target.value), 1);
         }
      }
      newCheckedObject = {
         location: updatedLocation,
         dateObject: props.checkedData.dateObject,
         groupSize: updatedGroupSize,
         amenitiesList: updatedAmenitiesList,
      };
      props.setCheckedData(newCheckedObject);
   };

   const isChecked = (item: any) => {
      if (props.isLocation) {
         return newCheckedObject.location == item;
      } else if (props.isGroupSize) {
         return newCheckedObject.groupSize == item;
      } else if (props.isAmenitiesList) {
         return newCheckedObject.amenitiesList.includes(item);
      }
   };

   return (
      <ModalComponent
         size="medium"
         visible={props.isOpen}
         padding={'0 0 0 0'}
         width={'400px'}
         height={'700px'}
         closeModal={() => {
            props.setIsOpen(false);
         }}
      >
         <MainWrapper>
            <HeaderWrapper>
               <StyledText margin={'40px 0 10px 10px'} text={props.text} variant={StyledTextVariantsType.HEADING_1} />
               <Input
                  height={'42px'}
                  width={'95%'}
                  type={'text'}
                  margin={'0 0 10px  10px'}
                  placeholder={translate('placeholders.search')}
                  onChange={(value) => {
                     setCitiesSearchTerm(value);
                  }}
                  value={citiesSearchTerm}
               />
            </HeaderWrapper>
            <ContentWrapper>
               {props.data.length === 0 && <Spinner height={'400px'} width={'25px'} />}
               {props.data &&
                  props.data
                     .filter((s: any) => s && s.toLowerCase().indexOf(citiesSearchTerm.toLowerCase()) > -1)
                     .map((value: any, index: number) => {
                        return (
                           <OneItem key={index}>
                              <span>{value}</span>
                              <input
                                 value={value}
                                 checked={isChecked(value)}
                                 type={props.isAmenitiesList ? 'checkbox' : 'radio'}
                                 name={props.isAmenitiesList ? '' : 'city'}
                                 onChange={handleChange}
                                 style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    marginTop: 20,
                                    marginBottom: 20,
                                 }}
                              />
                           </OneItem>
                        );
                     })}
            </ContentWrapper>
            <FooterWrapper>
               <Button
                  variant={ButtonVariantsType.PURPLE}
                  width={'95%'}
                  mobileWidth={'95%'}
                  text={translate('reserveMeetingRoom.apply')}
                  onClick={() => props.setIsOpen(false)}
               ></Button>
            </FooterWrapper>
         </MainWrapper>
      </ModalComponent>
   );
};

export default MeetingRoomModal;
