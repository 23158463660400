import React, { ReactElement, useEffect, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';

import { Button, Flex, Spinner, Input, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { parseError, useWindowWide } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import { IUpdateSpaceCapacity } from '../../interfaces';

import ApiRequests from 'src/features/space_management/data/ApiRequests';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { useLocation } from 'react-router';
import { PurpleArrowLeft } from 'src/assets/images';
import HeaderWrapper from 'src/components/HeaderWrapper';

const INITIAL_STATE: IUpdateSpaceCapacity = {
   space_id: '',
   capacity: '5',
};

const AvailableSeats = (): ReactElement => {
   const location = useLocation();
   const [loading, setLoading] = useState(true);
   const [updateLoading, setUpdateLoading] = useState(false);
   const [spaceId, setSpaceId] = useState(location.state.spaceId);

   const [spaceCapacity, setSpaceCapacity] = useState<IUpdateSpaceCapacity>({
      ...INITIAL_STATE,
      space_id: spaceId,
   });

   const wide = useWindowWide(768);

   const goBack = () => {
      history.back();
   };

   const getSpaceDetails = async () => {
      try {
         setLoading(true);
         const response = await ApiRequests.getSpaceDetails(spaceId);
         if (response && response.data) {
            setSpaceCapacity({
               space_id: spaceId,
               capacity: response.data.maxCapacity,
            });
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpace'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.getSpace'));
      } finally {
         setLoading(false);
      }
   };

   const isFormValid = () => {
      return spaceId && spaceCapacity;
   };

   const updateSpaceDetails = async () => {
      console.log({ ...spaceCapacity });
      setUpdateLoading(true);
      try {
         const response = await ApiRequests.updateSpaceCapacity({
            payload: {
               space_id: spaceId,
               capacity: Number(spaceCapacity.capacity),
            },
         });
         if (response && response.data) {
            NotificationsProvider.success(translate('spaceManagement.success.updateSpaceCapacity'));
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.updateSpaceCapacity'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('spaceManagement.error.updateSpaceCapacity'));
      } finally {
         setUpdateLoading(false);
      }
   };

   useEffect(() => {
      if (spaceId) {
         void getSpaceDetails();
      }
      //eslint-disable-next-line
   }, [spaceId]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('spaceManagement.settings.availableSeats.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('spaceManagement.settings.availableSeats.title')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}
         <Input
            width={'100%'}
            type={'number'}
            margin={'48px 0 24px 0'}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.spaceCapacity')}
            placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.spaceCapacity')}
            onKeyDown={(value) => value.preventDefault()}
            onChange={(value) => setSpaceCapacity({ ...spaceCapacity, capacity: value })}
            validators={['required']}
            value={spaceCapacity.capacity}
         />

         <Button
            disabled={!isFormValid()}
            isLoading={updateLoading}
            variant={ButtonVariantsType.PURPLE}
            text={translate('common.saveChanges')}
            width={'100%'}
            onClick={updateSpaceDetails}
         />
      </Flex>
   );
};

export default AvailableSeats;
