import React, { ReactElement } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { Flex, ModalComponent, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import theme from 'src/styles/theme';
import { translate } from '../../../../translations/translations';

const Item = styled.div`
   width: 95%;
   height: auto;
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   padding: 16px;
   margin-bottom: 24px;
   background-color: ${theme.colors.white};
   border-bottom: 1px solid ${theme.colors.gray};
`;

interface IProps {
   earning: any;
   setSelectedEarning: (value: any) => void;
   isOpen: boolean;
   setShowModal: (value: boolean) => void;
}

const CreditNoteModal = ({ earning, setSelectedEarning, setShowModal, isOpen }: IProps): ReactElement => {
   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'514px'}
         closeModal={() => {
            setShowModal(false);
            setSelectedEarning(null);
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <Flex
               width={'100%'}
               padding={'24px'}
               backgroundColor={'#F9F9F9'}
               borderRadius={'15px'}
               flexDirectionMobile={'column'}
               flexDirection="column"
               alignItems="center"
            >
               <Item>
                  <StyledText
                     text={translate('spaceManagement.insights.earnings.creditNoteModal.payoutDate')}
                     colorVariant={theme.colors.gray}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     margin={'0 0 8px 0'}
                     marginMobile={'16px 0 8px 0'}
                  />
                  <StyledText
                     text={moment(earning?.payout_date).utc().format('DD-MM-YYYY HH:mm')}
                     variant={StyledTextVariantsType.HEADING_3}
                  />
               </Item>

               <Item>
                  <StyledText
                     text={translate('spaceManagement.insights.earnings.creditNoteModal.payoutId')}
                     colorVariant={theme.colors.gray}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     margin={'0 0 8px 0'}
                  />
                  <StyledText text={earning?.payout_id} variant={StyledTextVariantsType.HEADING_3} />
               </Item>

               <Item>
                  <StyledText
                     text={translate('spaceManagement.insights.earnings.creditNoteModal.pending')}
                     colorVariant={theme.colors.gray}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     margin={'0 0 8px 0'}
                  />
                  <StyledText text={earning.pending.toString()} variant={StyledTextVariantsType.HEADING_3} />
               </Item>

               <Item>
                  <StyledText
                     text={translate('spaceManagement.insights.earnings.creditNoteModal.totalAmount')}
                     colorVariant={theme.colors.gray}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     margin={'0 0 8px 0'}
                  />
                  <StyledText text={earning.total_amount} variant={StyledTextVariantsType.HEADING_3} />
               </Item>
            </Flex>
         </Flex>
      </ModalComponent>
   );
};

export default CreditNoteModal;
