import { combineReducers } from '@reduxjs/toolkit';
import { firebaseReducer } from 'react-redux-firebase';

// Reducers
import { globalVariablesReducer, systemReducer } from 'src/system/state/reducer';

const rootReducer = combineReducers({
   firebase: firebaseReducer,
   systemReducer: systemReducer,
   globalVariablesReduce: globalVariablesReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
