import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Flex, ModalComponent, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { CustomizableImg } from 'src/components/SharedComponents';

import { translate } from 'src/translations/translations';
import { RoutesEnum } from 'src/routes/routes';
import { PurchasedPageIllustration } from 'src/assets/images';

interface IProps {
   isOpen: boolean;
   setShowModal: (value: boolean) => void;
}

const StartCoworkingModal = ({ setShowModal, isOpen }: IProps): ReactElement => {
   const history = useHistory();

   const onStartCoworkingClick = () => {
      setShowModal(false);
      history.push(RoutesEnum.HOME);
   };

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'500px'}
         closeModal={() => {
            setShowModal(false);
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <StyledText
               text={translate('purchaseSuccessModal.title')}
               margin={'0 0 24px 0'}
               width={'100%'}
               variant={StyledTextVariantsType.HEADING_1}
            />
            <StyledText
               text={translate('purchaseSuccessModal.text')}
               margin={'0 0 42px 0'}
               width={'100%'}
               variant={StyledTextVariantsType.PARAGRAPH_2}
            />
            <CustomizableImg src={PurchasedPageIllustration} height={'auto'} width={'75%'} margin={'0 auto'} />
            <Button
               variant={ButtonVariantsType.PURPLE}
               text={translate('purchaseSuccessModal.button.text')}
               margin={'80px 0 0 0'}
               width={'100%'}
               onClick={onStartCoworkingClick}
            />
         </Flex>
      </ModalComponent>
   );
};

export default StartCoworkingModal;
