import { configureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { getFirebase } from 'react-redux-firebase';

import firebase from 'src/app/firebase';
import { rrfConfig } from 'src/app/config';
import rootReducer, { RootState } from 'src/app/rootReducer';

const middleware = getDefaultMiddleware({
   serializableCheck: false,
   thunk: {
      extraArgument: { getFirebase },
   },
});

const store = configureStore({
   reducer: rootReducer,
   middleware,
});

export const rrfProps = {
   firebase,
   config: rrfConfig,
   dispatch: store.dispatch,
};

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
