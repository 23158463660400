import { ajaxService } from 'src/services/AjaxService';
import { IRequest, IResponse } from 'src/system/interfaces';
import {
   ICreateCustomerRequestDTO,
   ISubscribeToPlanRequestDTO,
   IValidateCouponRequestDTO,
   IAddPaymentMethodRequestDTO,
   IActivateVoucherRequestDTO,
   IRequestMeetingRoomDTO,
   // IAddToFavoritesDTO,
} from './RequestDtos';
import { IUser } from '../interfaces';

export default class ApiRequests {
   public static getUserDetails(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/user/details?uuid=${uuid}`);
   }

   public static updateUser(request: IRequest<IUser>) {
      return ajaxService.post<IResponse<any>>('/users/update', request.payload);
   }

   public static uploadProfilePicture(uuid: string, request: IRequest<any>) {
      return ajaxService.post<IResponse<any>>(`/users/upload/photo?uuid=${uuid}`, request.payload, {
         headers: {
            'Content-Type': 'multipart/form-data',
         },
      });
   }

   public static getPaymentMethodIntent(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/payment-method/setup-intent?uuid=${uuid}`);
   }

   public static addPaymentMethod(request: IRequest<IAddPaymentMethodRequestDTO>) {
      return ajaxService.post<IResponse<any>>(`/payment-method/add`, request.payload);
   }

   public static getPaymentMethods(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/payment-methods?uuid=${uuid}`);
   }

   public static setDefaultPaymentMethod(uuid: string, paymentMethodId: string) {
      return ajaxService.post<IResponse<any>>(
         `/payment-method/set-default?uuid=${uuid}&payment_method_id=${paymentMethodId}`,
      );
   }

   public static deletePaymentMethod(uuid: string, paymentMethodId: string) {
      return ajaxService.post<IResponse<any>>(
         `/payment-method/remove?uuid=${uuid}&payment_method_id=${paymentMethodId}`,
      );
   }

   public static getCustomer(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/customer?uuid=${uuid}`);
   }

   public static createCustomer(request: IRequest<ICreateCustomerRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/customer/create', request.payload);
   }

   public static updateCustomer(request: IRequest<ICreateCustomerRequestDTO>) {
      return ajaxService.post<IResponse<any>>(`/customer/update`, request.payload);
   }

   public static activateVoucher(request: IRequest<IActivateVoucherRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/vouchers/activate', request.payload);
   }

   public static getPlan(plan_id: string) {
      return ajaxService.get<IResponse<any>>(`/plan?plan_id=${plan_id}`);
   }

   public static getPlans(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/plans?uuid=${uuid}`);
   }

   public static subscribeToPlan(uuid: string, request: IRequest<ISubscribeToPlanRequestDTO>) {
      return ajaxService.post<IResponse<any>>(`/plan/subscribe?uuid=${uuid}`, request.payload);
   }

   public static cancelPlan(uuid: string) {
      return ajaxService.post<IResponse<any>>(`/plan/cancel?uuid=${uuid}`);
   }

   public static validateCoupon(request: IRequest<IValidateCouponRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/coupons/validate', request.payload);
   }

   public static getInvoices(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/invoices?uuid=${uuid}`);
   }

   public static getCheckinHistory(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/checkins/users?uuid=${uuid}`);
   }

   public static getCheckinOptions(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/checkin/options?uuid=${uuid}`);
   }
   public static getCitiesList() {
      return ajaxService.get<IResponse<any>>(`/spaces/filter/cities`);
   }
   public static getVibesList() {
      return ajaxService.get<IResponse<any>>(`/spaces/filter/vibes`);
   }
   public static getAmenitiesList() {
      return ajaxService.get<IResponse<any>>(`/spaces/filter/amenities`);
   }
   public static getRoomAmenitiesList() {
      return ajaxService.get<IResponse<any>>(`/room/amenities`);
   }
   // public static addToFavorites(request: IRequest<IAddToFavoritesDTO>) {
   //    console.log(request.payload, 'payload');
   //    return ajaxService.post<IResponse<any>>('/favorite/spaces/add', request.payload);
   // }
   public static requestMeetingRoom(request: IRequest<IRequestMeetingRoomDTO>) {
      return ajaxService.post<IResponse<any>>('/room/book', request.payload);
   }
   public static getPreviousBookings(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/room/bookings?uuid=${uuid}`);
   }
}
