import React, { ReactElement } from 'react';

import { Flex, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import {
   SettingsItemTitle,
   SettingsItemIcon,
   SettingsItemContent,
   SettingsItemArrow,
   SettingsItem,
} from '../shared/SharedComponents';
import { ChevronRightElement, BriefcaseIcon, BarCodeIcon, ShieldTickIcon, PurpleArrowLeft } from 'src/assets/images';
import { translate } from 'src/translations/translations';
import { config } from 'src/app/config';
import theme from 'src/styles/theme';
import { useWindowWide } from 'src/utilities/helpers';
import HeaderWrapper from 'src/components/HeaderWrapper';

const LegalInformationItemsList = (): ReactElement => {
   const goToUrl = (url: string) => {
      window.open(url, '_blank');
   };
   const wide = useWindowWide(768);

   const goBack = () => {
      history.back();
   };

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         minHeight={'100vh'}
         height={'100%'}
         width={'100%'}
         position={'relative'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('common.legals')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('common.legals')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}

         <Flex
            width={'100%'}
            height={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
            borderRight={`1px solid ${theme.colors.lightGray}`}
         >
            <SettingsItem
               onClick={() => {
                  goToUrl(config.PRIVACY_POLICY_PAGE);
               }}
            >
               <SettingsItemIcon>
                  <ShieldTickIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.legalInformation.privacy.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.legalInformation.privacy.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  goToUrl(config.TERMS_OF_SERVICE_PAGE);
               }}
            >
               <SettingsItemIcon>
                  <BarCodeIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.legalInformation.termsOfService.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.legalInformation.termsOfService.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               onClick={() => {
                  goToUrl(config.IMPRINT_PAGE);
               }}
            >
               <SettingsItemIcon>
                  <BriefcaseIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.legalInformation.imprint.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.legalInformation.imprint.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
         </Flex>
      </Flex>
   );
};

export default LegalInformationItemsList;
