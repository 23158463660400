import axios, { AxiosRequestConfig } from 'axios';

import { config } from 'src/app/config';

export interface IInvokeOptions {
   noAuthentication?: boolean;
   headers?: any;
   responseType?: any;
}

class AjaxService {
   private accessToken?: string | null;

   constructor() {
      this.init();

      axios.interceptors.response.use(
         (response) => {
            if (response.data?.success) {
               return response.data;
            } else {
               if (response.status === 200) {
                  return response.data;
               }
               if (response.data?.error) {
                  if (typeof response.data?.error === 'string') {
                     return Promise.reject(response.data?.error);
                  }

                  if (response.data?.error.message) {
                     return Promise.reject(response.data?.error.message);
                  }
               }

               return Promise.reject(new Error('Something went wrong.'));
            }
         },
         async function (error) {
            return Promise.reject(error);
         },
      );
   }

   init(): void {
      this.accessToken = localStorage.getItem('token') || null;
   }

   setAuthToken(newAuthToken: string | null): void {
      this.accessToken = newAuthToken;
   }

   public get endpoint(): string {
      return this.basePath;
   }

   private get basePath(): string {
      return config.API_BASE_URL as string;
   }

   private get defaultHeaders() {
      const token = localStorage.getItem('token') || '';

      return {
         Accept: 'application/json',
         Authorization: `Bearer ${token}`,
      };
   }

   // eslint-disable-next-line
    private invoke<T>(method: string, url: string, data?: any, options?: IInvokeOptions) {
      let defaultHeaders = this.defaultHeaders;

      if (options && options.noAuthentication) {
         delete defaultHeaders['Authorization'];
      }

      if (options && options.headers) {
         defaultHeaders = { ...defaultHeaders, ...options.headers };
      }

      let axiosObject: any = {
         method,
         url: `${this.basePath}${url}`,
         headers: defaultHeaders,
         data,
      };

      if (options && options.responseType) {
         axiosObject = { ...axiosObject, responseType: options.responseType };
      }

      return axios(axiosObject as AxiosRequestConfig) as Promise<any>;
   }

   // eslint-disable-next-line
    public get<T = any>(url: string, options?: IInvokeOptions): Promise<T> {
      return this.invoke<T>('GET', url, null, options);
   }

   // eslint-disable-next-line
    public post<T = any>(url: string, data: any = null, options?: IInvokeOptions): Promise<T> {
      return this.invoke<T>('POST', url, data, options);
   }

   // eslint-disable-next-line
    public put<T = any>(url: string, data?: any, options?: IInvokeOptions): Promise<T> {
      return this.invoke<T>('PUT', url, data, options);
   }

   // eslint-disable-next-line
    public patch<T = any>(url: string, data?: any, options?: IInvokeOptions): Promise<T> {
      return this.invoke<T>('PATCH', url, data, options);
   }

   // eslint-disable-next-line
    public delete<T = any>(url: string, options?: IInvokeOptions): Promise<T> {
      return this.invoke('DELETE', url, null, options);
   }
}

export const ajaxService = new AjaxService();
