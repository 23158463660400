import React, { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Flex, Spinner, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import {
   SettingsItem,
   SettingsItemArrow,
   SettingsItemContent,
   SettingsItemTitle,
} from 'src/features/settings/shared/SharedComponents';

import { ChevronRightElement } from 'src/assets/images';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { ActiveHRManagementUserProfileItemEnum } from 'src/system/state/types';
import { setActiveHRManagementUserProfileItem } from 'src/system/state/actions';
import { translate } from 'src/translations/translations';
import { blobToJsonObject, parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';

interface IProps {
   userId: string;
   enterpriseId: string;
}

const UserProfile = ({ userId, enterpriseId }: IProps): ReactElement => {
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);

   const getUserReportUrl = async () => {
      if (!userId) {
         return NotificationsProvider.error(translate('hrManagement.settings.userProfile.error.missingId'));
      }

      if (!enterpriseId) {
         return NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }

      try {
         setLoading(true);
         const response: any = await ApiRequests.getCompanyTeamMemberReport(enterpriseId, userId, 1);
         const file = new Blob([response], { type: 'application/pdf' });
         const fileURL = URL.createObjectURL(file);
         window.open(fileURL, '_blank');
      } catch (error) {
         if (error.response?.data) {
            const blob = new Blob([error.response.data], { type: 'application/json' });
            NotificationsProvider.error(
               parseError(blobToJsonObject(blob)) || translate('hrManagement.error.generateTeamMemberReport'),
            );
         } else {
            NotificationsProvider.error(translate('hrManagement.error.generateTeamMemberReport'));
         }
      } finally {
         setLoading(false);
      }
   };

   return (
      <Flex
         width={'68%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('hrManagement.settings.userProfile.userManagement.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 52px 0'}
         />

         <SettingsItem
            padding={'0'}
            height={'auto'}
            onClick={() => {
               dispatch(setActiveHRManagementUserProfileItem(ActiveHRManagementUserProfileItemEnum.REMOVE_USER));
            }}
         >
            <SettingsItemContent>
               <SettingsItemTitle>
                  <StyledText
                     width={'100%'}
                     text={translate('hrManagement.settings.userProfile.userManagement.removeTeamMemberItem.subtitle')}
                     variant={StyledTextVariantsType.PARAGRAPH_3}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 5px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     text={translate('hrManagement.settings.userProfile.userManagement.removeTeamMemberItem.title')}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 16px 0'}
                  />
               </SettingsItemTitle>
               <SettingsItemArrow>
                  <ChevronRightElement />
               </SettingsItemArrow>
            </SettingsItemContent>
         </SettingsItem>

         <SettingsItem padding={'0'} height={'auto'} onClick={getUserReportUrl}>
            <SettingsItemContent>
               <SettingsItemTitle>
                  <StyledText
                     width={'100%'}
                     text={translate(
                        'hrManagement.settings.userProfile.userManagement.downloadUserReportItem.subtitle',
                     )}
                     variant={StyledTextVariantsType.PARAGRAPH_3}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 5px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     text={translate('hrManagement.settings.userProfile.userManagement.downloadUserReportItem.title')}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 16px 0'}
                  />
               </SettingsItemTitle>
               <SettingsItemArrow>{loading ? <Spinner /> : <ChevronRightElement />}</SettingsItemArrow>
            </SettingsItemContent>
         </SettingsItem>

         <SettingsItem
            padding={'0'}
            height={'auto'}
            onClick={() => {
               dispatch(setActiveHRManagementUserProfileItem(ActiveHRManagementUserProfileItemEnum.USER_CHECKINS));
            }}
         >
            <SettingsItemContent>
               <SettingsItemTitle>
                  <StyledText
                     width={'100%'}
                     text={translate('hrManagement.settings.userProfile.userManagement.userCheckinsItem.subtitle')}
                     variant={StyledTextVariantsType.PARAGRAPH_3}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 5px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     text={translate('hrManagement.settings.userProfile.userManagement.userCheckinsItem.title')}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 16px 0'}
                  />
               </SettingsItemTitle>
               <SettingsItemArrow>
                  <ChevronRightElement />
               </SettingsItemArrow>
            </SettingsItemContent>
         </SettingsItem>
      </Flex>
   );
};

export default UserProfile;
