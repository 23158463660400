import { ajaxService } from 'src/services/AjaxService';
import { IRequest, IResponse } from 'src/system/interfaces';
import { ICheckInRequestDTO } from './RequestDtos';

export default class ApiRequests {
   public static getSpaceDetails(space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space?space_id=${space_id}`, {
         noAuthentication: true,
      });
   }

   public static checkIn(request: IRequest<ICheckInRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/checkin', request.payload);
   }
}
