import React, { ReactElement, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getFirebase } from 'react-redux-firebase';

import { Flex, Spinner } from 'src/components';
import { replaceLocation, RoutesEnum } from 'src/routes/routes';
import { getCurrentLocation } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { IUpdateSpaceAddress } from 'src/features/space_management/interfaces';

import './MapView.scss';
import { GoogleApiWrapper, GoogleAPI } from 'google-maps-react';
import { config } from '../../../app/config';
import GoogleMapAddressDetails from 'src/components/googleMap/GoogleMapAddressDetails';

const MapViewAddressDetails = ({
   google,
   spaceAddress,
   setSpaceAddress,
}: {
   google: GoogleAPI;
   spaceAddress: any;
   setSpaceAddress: React.Dispatch<React.SetStateAction<IUpdateSpaceAddress>>;
}): ReactElement => {
   const history = useHistory();
   const firebase = getFirebase();
   const [loading, setLoading] = useState(true);
   const [location, setLocation] = useState<any>(null);
   const [currentLocation, setCurrentLocation] = useState<any>(null);

   const checkCurrentLocation = async () => {
      try {
         const location = {
            latitude: spaceAddress.lattitude,
            longitude: spaceAddress.longitude,
         };
         setCurrentLocation(location);
         setLoading(false);
      } catch (err) {
         setCurrentLocation(null);
         setLoading(false);
      }
   };

   const getCoworkingSpaces = async () => {
      await checkCurrentLocation();

      const coworkingSpacesRef = firebase.database().ref(`spaces-list/data/${spaceAddress.space_id}`);

      coworkingSpacesRef.once(
         'value',
         (snapshot) => {
            if (!snapshot.val()) {
               setLoading(false);
               return;
            }

            const data = snapshot.val();

            setLocation(data);
            setLoading(false);
         },
         (error) => {
            NotificationsProvider.error(error.message || 'mapView.error.getCoworkingSpaces');
            setLoading(false);
         },
      );
   };

   const goToCoworkingSpaceDetailsPage = (id: string) => {
      history.push(
         replaceLocation(RoutesEnum.SPACE_DETAILS, {
            id,
         }),
      );
   };

   useEffect(() => {
      void getCoworkingSpaces();
      //eslint-disable-next-line
   }, []);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         flexDirection="column"
         minHeight={'100vh'}
         alignItems={'flex-start'}
         justifyContent={'flex-start'}
         classNames={'locations-page-map-wrapper'}
         padding={'0% 0% 0% 0%'}
         paddingMobile={'unset'}
      >
         <GoogleMapAddressDetails
            google={google}
            marker={location}
            height={'100%'}
            width={'100%'}
            zoom={13}
            infoWindowActionHandler={goToCoworkingSpaceDetailsPage}
            currentLocation={currentLocation}
            spaceAddress={spaceAddress}
            setSpaceAddress={setSpaceAddress}
            onClick={(lat: any, lon: any) => {
               setLocation((previousLocation: any) => ({
                  ...previousLocation,
                  location: { ...previousLocation.location, lattitude: lat, longitude: lon },
               }));
            }}
         />
      </Flex>
   );
};
const loadingContainer = () => <div />;
// export default MapView;
export default GoogleApiWrapper({
   apiKey: config.GOOGLE_MAPS_API_KEY as string,
   LoadingContainer: loadingContainer,
})(MapViewAddressDetails);
