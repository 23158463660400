import { RoutesEnum } from 'src/routes/routes';

enum PagesTitles {
   HOME = 'OneCo',
   MAP = 'OneCo - Map',
   RESERVE_MEETING_ROOM = 'OneCo - Reserve a meeting room',
   SETTINGS = 'OneCo - Settings',
   SPACE_DETAILS = 'OneCo - Space details',
   ENTERPRISE_MANAGEMENT = 'OneCo - Enterprise management',
   ENTERPRISE_MANAGEMENT_USER_PROFILE = 'OneCo - User profile',
   SPACE_MANAGEMENT = 'OneCo - Space management',
   ACCEPT_INVITE = 'OneCo - Accept invite',
}

export const setPageTitle = (title?: string) => {
   title = title || 'OneCo';
   document.title = title;
};

export const getPageTitle = (path: string) => {
   switch (path) {
      case RoutesEnum.HOME:
         return PagesTitles.HOME;
      case RoutesEnum.RESERVE_MEETING_ROOM:
         return PagesTitles.RESERVE_MEETING_ROOM;
      case RoutesEnum.MAP_VIEW:
         return PagesTitles.MAP;
      case RoutesEnum.SETTINGS:
         return PagesTitles.SETTINGS;
      case RoutesEnum.SPACE_DETAILS:
         return PagesTitles.SPACE_DETAILS;
      case RoutesEnum.ENTERPRISE_MANAGEMENT:
         return PagesTitles.ENTERPRISE_MANAGEMENT;
      case RoutesEnum.ENTERPRISE_MANAGEMENT_USER_PROFILE:
         return PagesTitles.ENTERPRISE_MANAGEMENT_USER_PROFILE;
      case RoutesEnum.SPACE_MANAGEMENT:
         return PagesTitles.SPACE_MANAGEMENT;
      case RoutesEnum.ACCEPT_INVITE:
         return PagesTitles.ACCEPT_INVITE;
      default:
         return PagesTitles.HOME;
   }
};
