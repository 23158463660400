const chartColors = [
   '#ff0029',
   '#377eb8',
   '#ffed6f',
   '#66a61e',
   '#984ea3',
   '#00d2d5',
   '#ff7f00',
   '#af8d00',
   '#7f80cd',
   '#b3e900',
   '#c42e60',
   '#a65628',
   '#f781bf',
   '#8dd3c7',
   '#bebada',
   '#fb8072',
   '#80b1d3',
   '#fdb462',
   '#fccde5',
   '#bc80bd',
   '#c4eaff',
   '#cf8c00',
   '#1b9e77',
   '#d95f02',
   '#e7298a',
   '#e6ab02',
   '#a6761d',
   '#0097ff',
   '#00d067',
   '#000000',
   '#252525',
   '#525252',
   '#737373',
   '#969696',
   '#bdbdbd',
   '#f43600',
   '#4ba93b',
   '#5779bb',
   '#927acc',
   '#97ee3f',
   '#bf3947',
   '#9f5b00',
   '#f48758',
   '#8caed6',
   '#f2b94f',
   '#eff26e',
   '#e43872',
   '#d9b100',
   '#9d7a00',
   '#698cff',
];

export default chartColors;
