import React, { ReactElement, useEffect, useState } from 'react';

import { Flex, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import {
   SettingsItem,
   SettingsItemArrow,
   SettingsItemContent,
   SettingsItemIcon,
   SettingsItemTitle,
} from 'src/features/settings/shared/SharedComponents';
import { ActiveItemEnum } from './Insights';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';

import { ChevronRightElement, UserEditIcon } from 'src/assets/images';
import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';
import { RoutesEnum } from 'src/routes/routes';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/rootReducer';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { parseError } from 'src/utilities/helpers';
import { ICompany } from '../interfaces';

interface IProps {
   setActiveItem: (item: ActiveItemEnum) => void;
   isItemActive: (itemToCheck: ActiveItemEnum) => boolean;
}

const InsightsRoot = ({ setActiveItem, isItemActive }: IProps): ReactElement => {
   const history = useHistory();

   const [company, setCompany] = useState<ICompany | null>(null);
   const [loading, setLoading] = useState(true);
   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;

   const getCompanyDetails = async () => {
      try {
         setLoading(true);
         const response = await ApiRequests.getCompanyDetails(enterpriseId);
         setCompany(response.data?.company);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.getCompanyDetails'));
      } finally {
         setLoading(false);
      }
   };
   useEffect(() => {
      void getCompanyDetails();
      // eslint-disable-next-line
   }, []);

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         width={'100%'}
         height={'100%'}
         position={'relative'}
      >
         <StyledText
            width={'100%'}
            text={translate('hrManagement.insights.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 32px 0'}
         />

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
            borderRight={`1px solid ${theme.colors.lightGray}`}
         >
            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.SPACE_OVERVIEW) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.SPACE_OVERVIEW);
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_INSIGHTS_OVERVIEW,
                     state: {
                        company: company,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.insights.spaceOverview.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.insights.spaceOverview.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.CHECKINS_INFO) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.CHECKINS_INFO);
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_INSIGHTS_CHECKINS,
                     state: {
                        company: company,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.insights.checkinsInfo.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.insights.checkinsInfo.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
         </Flex>
      </Flex>
   );
};

export default InsightsRoot;
