import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Tooltip } from 'src/components';

interface IProps {
   data: any;
}

const Wrapper = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const BarsAndLabelsContainer = styled.div`
   width: 100%;
   height: auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
`;

const BarsWrapper = styled.div`
   width: 100%;
   height: auto;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 11px;
`;

const BeforeNoonBar = styled.div<any>`
   width: ${(props) => props.width || '0%'};
   height: 30px;
   background: linear-gradient(180deg, #ff9364 0%, #f25f33 100%);
   border-radius: 10px;
   cursor: pointer;
`;

const AfterNoonBar = styled.div<any>`
   width: ${(props) => props.width || '0%'};
   height: 29px;
   background: #ffc54a;
   border-radius: 10px;
   opacity: 0.3;
   cursor: pointer;
`;

const LabelsWrapper = styled.div`
   width: 100%;
   height: auto;
   display: flex;
   justify-content: space-between;
   align-items: center;
`;

const CheckinBar = ({ data }: IProps): ReactElement => {
   const beforeNoon = data['AM checkins'];
   const afterNoon = data['PM checkins'];
   const total = beforeNoon + afterNoon;
   const beforePercent = beforeNoon && total ? (beforeNoon / total) * 100 : 0;
   const afterPercent = afterNoon && total ? (afterNoon / total) * 100 : 0;

   return (
      <Wrapper>
         <BarsAndLabelsContainer>
            <BarsWrapper>
               <BeforeNoonBar data-tip data-for={'before-noon-bar'} width={`${beforePercent}%`} />
               <AfterNoonBar data-tip data-for={'after-noon-bar'} width={`${afterPercent}%`} />
            </BarsWrapper>
            <LabelsWrapper>
               <p>Before noon</p>
               <p>After noon</p>
            </LabelsWrapper>
         </BarsAndLabelsContainer>

         <Tooltip id="before-noon-bar" text={`${beforeNoon} checkin${beforeNoon === 1 ? '' : 's'} before noon`} />
         <Tooltip id="after-noon-bar" text={`${afterNoon} checkin${afterNoon === 1 ? '' : 's'} after noon`} />
      </Wrapper>
   );
};

export default CheckinBar;
