import React, { ReactElement } from 'react';

import { Button, Flex, ModalComponent, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';

interface IProps {
   isOpen: boolean;
   loading: boolean;
   setShowModal: (value: boolean) => void;
   onCancelClickHandler: () => void;
}

const CancelPlanModal = ({ loading, setShowModal, isOpen, onCancelClickHandler }: IProps): ReactElement => {
   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'414px'}
         closeModal={() => {
            setShowModal(false);
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <StyledText
               text={translate('settings.billing.cancelPlanModal.title')}
               margin={'0 0 22px 0'}
               width={'100%'}
               justifyContent={'center'}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <StyledText
               text={translate('settings.billing.cancelPlanModal.text')}
               margin={'0 0 32px 0'}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               colorVariant={theme.colors.gray}
            />
            <Button
               isLoading={loading}
               variant={ButtonVariantsType.WHITE}
               text={translate('settings.billing.cancelPlanModal.confirmButton.text')}
               margin={'0 0 16px 0'}
               width={'90%'}
               onClick={onCancelClickHandler}
            />
            <Button
               variant={ButtonVariantsType.PURPLE}
               text={translate('settings.billing.cancelPlanModal.cancelButton.text')}
               width={'90%'}
               onClick={() => {
                  setShowModal(false);
               }}
            />
         </Flex>
      </ModalComponent>
   );
};

export default CancelPlanModal;
