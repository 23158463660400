import React, { ReactElement, useMemo, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';

import { Button, CountryDropdown, Input } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';

import { RootState } from 'src/app/rootReducer';
import ApiRequests from 'src/features/settings/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import { setIsCustomer } from 'src/system/state/actions';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { Container } from 'src/features/settings/shared/SharedComponents';

const useOptions = () => {
   return useMemo(
      () => ({
         hidePostalCode: true,
         style: {
            base: {
               fontSize: '16px',
               color: '#424770',
               letterSpacing: '0.025em',
               fontFamily: ' Helvetica-Neue-Regular, Helvetica, Arial, sans-serif',
               '::placeholder': {
                  color: '#aab7c4',
               },
            },
            invalid: {
               color: '#9e2146',
            },
         },
      }),
      [],
   );
};

interface IProps {
   setShowModal: (value: boolean) => void;
   getPaymentMethods: () => void;
}

const AddPaymentMethodForm = ({ setShowModal, getPaymentMethods }: IProps): ReactElement => {
   const firebase = getFirebase();
   const dispatch = useDispatch();
   const currentUser = firebase.auth()?.currentUser;
   const isCustomer = useSelector((state: RootState) => state.systemReducer).isCustomer;

   const stripe = useStripe();
   const elements = useElements();
   const options = useOptions();

   const [name, setName] = useState('');
   const [address, setAddress] = useState('');
   const [city, setCity] = useState('');
   const [country, setCountry] = useState('United States'); //default country
   const [zipCode, setZipCode] = useState('');
   const [addPaymentMethodLoading, setAddPaymentMethodLoading] = useState(false);

   const isFormValid = (): boolean => {
      return (!isCustomer && !!address && !!name && !!city && !!country && !!zipCode) || isCustomer;
   };

   const createCustomer = async () => {
      try {
         const response: any = await ApiRequests.createCustomer({
            payload: {
               uuid: currentUser?.uid,
               name,
               address,
               city,
               zipCode,
               country,
               email: currentUser?.email as string,
            },
         });
         if (response.success) {
            dispatch(setIsCustomer(true));
            NotificationsProvider.success(response.data.message);
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.createCustomer'));
      }
   };

   const getPaymentMethodIntent = async () => {
      try {
         const response: any = await ApiRequests.getPaymentMethodIntent(currentUser?.uid as string);
         return response;
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('settings.manageCreditCards.error.getPaymentMethodIntent'),
         );
         return null;
      }
   };

   const addPaymentMethod = async () => {
      setAddPaymentMethodLoading(true);

      try {
         const setupIntentData = await getPaymentMethodIntent();

         if (!setupIntentData) {
            NotificationsProvider.error(translate('settings.manageCreditCards.error.getPaymentMethodIntent'));
         }

         if (stripe && elements) {
            const { error } = await stripe.confirmCardSetup(setupIntentData.client_secret, {
               payment_method: {
                  // @ts-ignore
                  card: elements.getElement(CardElement),
               },
            });

            if (error) {
               return NotificationsProvider.error(error?.message || translate('addCreditCard.stripe.error'));
            }

            await ApiRequests.addPaymentMethod({
               payload: {
                  uuid: currentUser?.uid,
                  is_default: true,
                  setup_intent: setupIntentData.id,
               },
            });

            setShowModal(false);
            await getPaymentMethods();
         }
      } catch (error) {
         if (error instanceof Error) {
            return NotificationsProvider.error(error?.message || translate('addCreditCard.error'));
         } else {
            return NotificationsProvider.error(translate('addCreditCard.error'));
         }
      } finally {
         setAddPaymentMethodLoading(false);
      }
   };

   const handleSubmit = async (event: any) => {
      event.preventDefault();

      try {
         if (isCustomer) {
            await addPaymentMethod();
         } else {
            await createCustomer();
            await addPaymentMethod();
         }
         setShowModal(false);
      } catch {
         setShowModal(false);
      }
   };

   return (
      <>
         {!isCustomer && (
            <>
               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('placeholders.name')}
                  onChange={(value) => setName(value)}
                  validators={['required']}
                  value={name}
               />
               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('placeholders.billingAddress')}
                  onChange={(value) => setAddress(value)}
                  validators={['required']}
                  value={address}
               />
               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('placeholders.billingCity')}
                  onChange={(value) => setCity(value)}
                  validators={['required']}
                  value={city}
               />
               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('placeholders.billingZipCode')}
                  onChange={(value) => setZipCode(value)}
                  validators={['required']}
                  value={zipCode}
               />
               <CountryDropdown
                  width={'100%'}
                  margin={'0 0 24px 0'}
                  value={country}
                  setValue={(value) => setCountry(value)}
               />
            </>
         )}
         <Container>
            <CardElement options={options} />
         </Container>
         <Button
            disabled={!isFormValid()}
            isLoading={addPaymentMethodLoading}
            variant={ButtonVariantsType.PURPLE}
            text={translate('addCreditCard.button.text')}
            margin={'48px 0 0 0'}
            width={'100%'}
            onClick={handleSubmit}
         />
      </>
   );
};

export default AddPaymentMethodForm;
