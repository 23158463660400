import React, { ReactElement, useState } from 'react';

import { Button, Flex, ModalComponent, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { InlineElementsWrapper } from 'src/components/SharedComponents';

import { ActionType } from '../../credit_cards/CreditCards';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';

interface IProps {
   isOpen: boolean;
   loading: boolean;
   selectedPaymentMethod: any;
   setShowModal: (value: boolean) => void;
   onConfirmClickHandler: (paymentMethodId: string, action: ActionType) => void;
}

const EditCreditCardModal = ({
   loading,
   selectedPaymentMethod,
   setShowModal,
   isOpen,
   onConfirmClickHandler,
}: IProps): ReactElement => {
   const [action, setAction] = useState<'' | ActionType>('');

   const onConfirmClick = () => {
      if (!selectedPaymentMethod.id) {
         return NotificationsProvider.error(translate('settings.manageCreditCards.error.missingPaymentMethodId'));
      }

      if (!action) {
         return NotificationsProvider.error(translate('settings.manageCreditCards.error.missingAction'));
      }

      onConfirmClickHandler(selectedPaymentMethod, action);
   };

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'414px'}
         closeModal={() => {
            setShowModal(false);
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <StyledText
               text={translate('settings.manageCreditCards.editCardModal.title')}
               margin={'0 0 22px 0'}
               width={'100%'}
               justifyContent={'center'}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <InlineElementsWrapper margin={'0 0 32px 0'} textAlign={'center'}>
               <StyledText
                  text={translate('settings.manageCreditCards.editCardModal.text')}
                  margin={'0 5px 0 0'}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
               />
               <StyledText text={selectedPaymentMethod.card.last4} variant={StyledTextVariantsType.PARAGRAPH_2} />
            </InlineElementsWrapper>
            {!selectedPaymentMethod.is_default && (
               <Button
                  variant={action === 'setAsDefault' ? ButtonVariantsType.PURPLE : ButtonVariantsType.WHITE}
                  text={translate('settings.manageCreditCards.editCardModal.setAsDefault.button.text')}
                  margin={'0 0 16px 0'}
                  width={'100%'}
                  onClick={() => setAction('setAsDefault')}
               />
            )}
            <Button
               variant={action === 'deleteCard' ? ButtonVariantsType.PURPLE : ButtonVariantsType.WHITE}
               text={translate('settings.manageCreditCards.editCardModal.deleteCard.button.text')}
               margin={'0 0 40px 0'}
               width={'100%'}
               onClick={() => setAction('deleteCard')}
            />
            <Button
               disabled={!action || loading}
               isLoading={loading}
               variant={ButtonVariantsType.PURPLE}
               text={translate('common.confirm')}
               width={'100%'}
               onClick={onConfirmClick}
            />
         </Flex>
      </ModalComponent>
   );
};

export default EditCreditCardModal;
