import React, { useState, useEffect } from 'react';
import theme from '../styles/theme';
import styled from 'styled-components';

import { StyledText } from './index';
import { StyledTextVariantsType } from './StyledText';

import { translate } from 'src/translations/translations';
import { isNotNullOrUndefined } from 'src/utilities/helpers';
import { IPipeResult, pipe } from 'src/utilities/validators/pipe';
import { IValidationKey, IValidatorErrorCode, IValidatorOptions } from 'src/utilities/validators';
import { MeetingRoomCalendarIcon, Time } from 'src/assets/images';

type InputType = 'text' | 'email' | 'password' | 'time' | 'number' | 'date';

interface IProps {
   value?: number | string;
   defaultValue?: number | string;
   label?: string;
   placeholder?: string;
   onChange?: (value: string) => void;
   validate?: (value: boolean) => void;
   onKeyDown?: (value: React.KeyboardEvent<HTMLInputElement>) => void;
   disabled?: boolean;
   width?: string;
   mobileWidth?: string;
   margin?: string;
   validators?: Array<IValidationKey | { key: IValidationKey; options: IValidatorOptions }>;
   type?: InputType;
   fullWidthLabel?: boolean;
   height?: string;
   readonly?: boolean;
   min?: any;
   max?: any;
   border?: string;
   transparent?: boolean;
}

const InputComponentContainer = styled.div<IProps>`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   position: relative;
   width: ${(props) => (props.width ? props.width : '100%')} !important;
   margin: ${(props) => (props.margin ? props.margin : 0)};
   height: auto;
   input {
      height: ${(props) => (props.height ? props.height : '54px')};
      width: 100%;
      border: ${(props) => (props.border ? `${props.border} !important` : `1px solid ${theme.colors.gray}!important`)};
      color: ${theme.colors.black};
      border-radius: 15px;
      background-color: ${theme.colors.white};
      padding: ${(props) => (props.transparent ? '0' : '0 20px')};
      font-size: 16px;
      font-family: ${(props) => (props.transparent ? `${theme.typography.bold}` : `${theme.typography.regular}`)};

      &:disabled {
         opacity: 0.5;
      }

      &::placeholder {
         color: ${theme.colors.gray};
         font-family: ${(props) => (props.transparent ? `${theme.typography.bold}` : `${theme.typography.regular}`)};
         font-size: 16px;
      }
   }

   input[type='date'] {
      &::-webkit-calendar-picker-indicator {
         background: url(${MeetingRoomCalendarIcon}) no-repeat;
         cursor: pointer;
      }
   }
   input[type='time'] {
      &::-webkit-calendar-picker-indicator {
         background: url(${Time}) no-repeat;
         cursor: pointer;
      }
   }

   input:focus {
      outline: none;
   }

   @media only screen and (max-width: 768px) {
      margin-bottom: 12px !important;
      width: ${(props) => (props.mobileWidth ? props.mobileWidth : '100%')} !important;
   }
`;

const InputWrapper = styled.div`
   position: relative;
   width: 100%;
   height: auto;

   i {
      position: absolute;
      top: 50%;
      right: 5%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      color: ${theme.colors.gray};
      cursor: pointer;
   }
`;

export const LabelWrapper = styled.div<{
   width?: string;
}>`
   display: inline;
   margin: 0 0 5px 0;
   width: ${(props) => (props.width ? props.width : '')};

   & > div,
   span {
      display: inline;
   }
`;

export const LabelStar = styled.span`
   display: inline;
   color: red;
   margin-left: 5px;
   font-size: 18px;
   font-weight: bold;
`;

const Input = (props: IProps) => {
   const [value, setValue] = useState<string | number | null>('');
   const [passwordShown, setPasswordShown] = useState(false);
   const [isValid, setIsValid] = useState<boolean>(true);
   const [validationError, setValidationError] = useState<
      Array<{
         key: IValidatorErrorCode;
         options?: IValidatorOptions;
      }>
   >();

   const validateValue = (validationResult: IPipeResult) => {
      if (props.validate) {
         props.validate(validationResult.isValid);
      }
      if (props.onChange) {
         props.onChange(validationResult.value);
      }
      setValidationError(validationResult.errors);
      setIsValid(validationResult.isValid);
   };

   const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const exceptThisSymbols = ['e', 'E', '+', '-', '.'];
      if (props.type === 'number' && exceptThisSymbols.includes(e.key)) {
         e.preventDefault();
      }
   };
   const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const { validators } = props;
      setValue(evt.target.value);
      const validationResult = pipe(evt.target.value, validators);
      validateValue(validationResult);
   };

   const hasError = () => {
      return validationError && !isValid;
   };

   const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
   };

   useEffect(() => {
      setValue(props.value || '');
   }, [props.value]);

   return (
      <InputComponentContainer
         width={props.width}
         height={props.height}
         margin={props.margin}
         mobileWidth={props.mobileWidth}
         transparent={props.transparent}
         border={props.border}
      >
         {isNotNullOrUndefined(props.label) && (
            <LabelWrapper width={props.fullWidthLabel ? '100%' : props.width}>
               <StyledText text={props.label} variant={StyledTextVariantsType.LABEL_TEXT} />
               {props.validators && props.validators.includes('required') && <LabelStar>*</LabelStar>}
            </LabelWrapper>
         )}
         <InputWrapper>
            <input
               value={value || ''}
               defaultValue={props?.defaultValue}
               placeholder={props.placeholder}
               disabled={props.disabled}
               readOnly={props.readonly}
               onChange={onChange}
               onKeyDown={onKeyDown}
               type={passwordShown ? 'text' : props.type}
               style={{
                  borderColor: !isValid ? theme.colors.red : theme.colors.black,
               }}
               min={props.min ? props.min : 0}
               max={props.max}
            />
            {props.type === 'password' && (
               <i className={passwordShown ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={togglePasswordVisibility} />
            )}
         </InputWrapper>
         {hasError() && (
            <StyledText
               text={translate(`errors.${(validationError as any)[0].key}`)}
               colorVariant={theme.colors.red}
               variant={StyledTextVariantsType.PARAGRAPH_3}
               margin={'5px 0 0 10px'}
               width={'100%'}
            />
         )}
      </InputComponentContainer>
   );
};

export default Input;
