import firebase from 'firebase';
import React from 'react';
import { config } from 'src/app/config';
import { Flex } from 'src/components';

import Register from './Register';

import { Elements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';

const SignUp = () => {
   const stripePromise = loadStripe(config.STRIPE_KEY as string);

   return (
      <>
         <Flex flexDirection="row" width={'100%'} justifyContent={'flex-start'} flexDirectionMobile={'column'}>
            <Elements stripe={stripePromise}>
               <Register />
            </Elements>
         </Flex>
      </>
   );
};

export default SignUp;
