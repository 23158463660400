import React, { ReactElement, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';

import { Button, CountryDropdown, Flex, Input, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { ButtonVariantsType } from 'src/components/Button';

import { AddCompanyIllustration, ConvertToEnterpriseIllustration } from 'src/assets/images';
import { UserRoles } from 'src/system/state/types';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { ICreateCompanyRequestDTO } from 'src/features/enterprise_management/data/RequestDtos';
import { setCustomerRole, setEnterpriseId, setIsCustomer } from 'src/system/state/actions';
import { parseError, scrollToTop } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';

const INITIAL_COMPANY_STATE: ICreateCompanyRequestDTO = {
   owner_uuid: '',
   email: '',
   company_name: '',
   address: '',
   city: '',
   state: '',
   zip_code: '',
   country: 'United States',
   domain: '',
   number_of_employees: 0,
   vat: '',
};

interface IProps {
   setShowCreatedCompanyDetailsModal: (value: boolean) => void;
}

const CreateCompany = ({ setShowCreatedCompanyDetailsModal }: IProps): ReactElement => {
   const dispatch = useDispatch();
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(false);
   const [company, setCompany] = useState<ICreateCompanyRequestDTO>(INITIAL_COMPANY_STATE);
   const [showCreateCompanyForm, setShowCreateCompanyForm] = useState(false);

   const isFormValid = () => {
      return company.company_name && company.domain;
   };

   const loadEnterpriseDetails = async (user: any) => {
      try {
         const response = await ApiRequests.getUserDetails(user.uid);

         if (response.data && response.data.enterprise) {
            localStorage.setItem('enterprise_uuid', response.data.enterprise.owner_uuid); //pass though redux I guess???
            dispatch(setEnterpriseId(response.data.enterprise.enterprise_id));
            dispatch(setCustomerRole(UserRoles.ENTERPRISE_MANAGER));
         } else {
            dispatch(setEnterpriseId(''));
         }
      } catch (err) {
         dispatch(setEnterpriseId(''));
         NotificationsProvider.error(parseError(err) || translate('hrManagement.error.getUserEnterpriseDetails'));
      }
   };

   const createCompany = async () => {
      try {
         setLoading(true);
         const response: any = await ApiRequests.createCompany({
            payload: {
               ...company,
               owner_uuid: currentUser?.uid,
               email: currentUser?.email as string,
            },
         });

         if (response.data) {
            setShowCreatedCompanyDetailsModal(true);
            dispatch(setIsCustomer(true));
            dispatch(setCustomerRole(UserRoles.ENTERPRISE_MANAGER));
            await loadEnterpriseDetails(currentUser);
            NotificationsProvider.success(response.data.message);
         } else {
            NotificationsProvider.error(response.error?.message || translate('common.error.createCompany'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('common.error.createCompany'));
      } finally {
         setLoading(false);
      }
   };

   const renderConvertToEnterpriseScreen = () => (
      <>
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('hrManagement.settings.convertToEnterprise.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 38px 10%'}
            marginMobile={'0 0 38px 0'}
         />

         <Flex
            justifyContent="center"
            alignItems="stretch"
            flexWrap="nowrap"
            backgroundColor={'#F9F9F9'}
            borderRadius={'15px'}
            padding={'5% 10% 5% 10%'}
            margin={'5% 10% 5% 10%'}
            flex={1}
            position={'relative'}
            flexDirectionMobile={'column'}
            alignItemsMobile={'center'}
         >
            <Flex
               width={'32%'}
               widthMobile={'32%'}
               flexDirection="column"
               justifyContent="flex-start"
               backgroundColor={'#F9F9F9'}
               alignItems="flex-start"
               position={'relative'}
               overflow={'hidden'}
            >
               <img
                  src={ConvertToEnterpriseIllustration}
                  alt="add-company"
                  style={{
                     width: '85%',
                     height: 'auto',
                  }}
               />
            </Flex>

            <Flex
               width={'68%'}
               flexDirection="column"
               justifyContent="center"
               backgroundColor={'#F9F9F9'}
               alignItems="center"
               position={'relative'}
               padding={'0 0 0 32px'}
               overflow={'hidden'}
            >
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('hrManagement.settings.convertToEnterprise.text')}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
                  margin={'0 0 32px 0'}
               />

               <Button
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('hrManagement.settings.convertToEnterprise.button.text')}
                  width={'100%'}
                  paddingMobile={'10'}
                  onClick={() => {
                     setShowCreateCompanyForm(true);
                     scrollToTop();
                  }}
               />
            </Flex>
         </Flex>
      </>
   );

   const renderCreateCompanyForm = () => (
      <>
         <StyledText
            width={'80%'}
            justifyContent={'flex-start'}
            text={translate('hrManagement.settings.addCompany.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 40px 10%'}
         />

         <Flex
            justifyContent="flex-start"
            alignItems="stretch"
            flexWrap="nowrap"
            flex={1}
            mobileMinHeight={'100%'}
            position={'relative'}
            margin={'40px 10% 40px 10%'}
         >
            <Flex
               width={'32%'}
               flexDirection="column"
               justifyContent="flex-start"
               alignItems="flex-start"
               position={'relative'}
               overflow={'hidden'}
            >
               <img src={AddCompanyIllustration} alt="add-company" style={{ width: '85%', height: 'auto' }} />
            </Flex>

            <Flex
               width={'68%'}
               flexDirection="column"
               justifyContent="flex-start"
               alignItems="center"
               position={'relative'}
               padding={'0 0 0 32px'}
               overflow={'hidden'}
            >
               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('hrManagement.settings.placeholders.companyName')}
                  onChange={(value) => setCompany({ ...company, company_name: value })}
                  validators={['required']}
                  value={company.company_name}
               />

               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('hrManagement.settings.placeholders.companyAddress')}
                  onChange={(value) => setCompany({ ...company, address: value })}
                  value={company.address}
               />

               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('hrManagement.settings.placeholders.companyCity')}
                  onChange={(value) => setCompany({ ...company, city: value })}
                  value={company.city}
               />

               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('hrManagement.settings.placeholders.companyState')}
                  onChange={(value) => setCompany({ ...company, state: value })}
                  value={company.state}
               />

               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('hrManagement.settings.placeholders.companyZipCode')}
                  onChange={(value) => setCompany({ ...company, zip_code: value })}
                  value={company.zip_code}
               />

               <CountryDropdown
                  width={'100%'}
                  margin={'0 0 24px 0'}
                  value={company.country}
                  setValue={(value) => setCompany({ ...company, country: value })}
               />

               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('hrManagement.settings.placeholders.companyVatNumber')}
                  onChange={(value) => setCompany({ ...company, vat: value })}
                  value={company.vat}
               />

               <Input
                  width={'100%'}
                  type={'number'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('hrManagement.settings.placeholders.companyEmployeesNumber')}
                  onChange={(value) => setCompany({ ...company, number_of_employees: Number(value) })}
                  value={company.number_of_employees}
               />

               <Input
                  width={'100%'}
                  type={'text'}
                  margin={'0 0 24px 0'}
                  placeholder={translate('hrManagement.settings.placeholders.companyDomain')}
                  onChange={(value) => setCompany({ ...company, domain: value })}
                  value={company.domain}
               />

               <Button
                  disabled={!isFormValid()}
                  isLoading={loading}
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('common.continue')}
                  width={'100%'}
                  onClick={createCompany}
               />
            </Flex>
         </Flex>
      </>
   );

   return (
      <Flex
         justifyContent="flex-start"
         flexDirection={'column'}
         alignItems="flex-start"
         position={'relative'}
         width={'100%'}
      >
         {showCreateCompanyForm ? renderCreateCompanyForm() : renderConvertToEnterpriseScreen()}
      </Flex>
   );
};

export default CreateCompany;
