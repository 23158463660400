import React, { ReactElement, useState, useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';
import styled from 'styled-components';

import { Button, Flex, Input, Spinner, StyledText, CountryDropdown } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { parseError, useWindowWide } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import { config } from 'src/app/config';
import { GoogleApiWrapper, GoogleAPI, IProvidedProps, Omit } from 'google-maps-react';

import NotificationsProvider from 'src/utilities/notifications-provider';
import ApiRequests from 'src/features/space_management/data/ApiRequests';
import { IUpdateSpaceAddressRequestDTO } from '../../data/RequestDtos';
import MapViewAddressDetails from 'src/features/pages/map_view/MapViewAddressDetails';
import { useLocation } from 'react-router';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

interface IProps {
   google: GoogleAPI;
}

const INITIAL_STATE: IUpdateSpaceAddressRequestDTO = {
   uuid: '',
   space_id: '',
   address: '',
   country: '',
   city: '',
   area: '',
   zip: '',
   floor: '',
   street_number: '',
   longitude: '',
   lattitude: '',
};

export const LabelWrapper = styled.div<{
   width?: string;
}>`
   display: inline;
   width: ${(props) => (props.width ? props.width : '')};

   & > div,
   span {
      display: inline;
   }
`;
export const WrapperDiv = styled.div`
   width: 100%;
   display: flex;
   flex-direction: row;

   @media only screen and (max-width: 768px) {
      flex-direction: column;
      padding: 0;
   }
`;
export const FieldsDiv = styled.div`
   width: 50%;
   @media only screen and (max-width: 768px) {
      width: 100%;
   }
`;
export const MapDiv = styled.div`
   position: relative;
   width: 50%;
   margin-top: 27px;
   margin-left: 20px;

   @media only screen and (max-width: 768px) {
      margin-left: 0px;
      width: 100%;
      height: 50vh;
   }
`;
export const LabelStar = styled.span`
   display: inline;
   color: red;
   margin-left: 5px;
   font-size: 18px;
   font-weight: bold;
`;

const AddressDetails = ({ google }: IProps): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const location = useLocation();

   const [spaceId, setSpaceId] = useState(location.state.spaceId);

   const [loading, setLoading] = useState(true);
   const [updateLoading, setUpdateLoading] = useState(false);

   const wide = useWindowWide(768);

   const goBack = () => {
      history.back();
   };

   const [spaceAddress, setSpaceAddress] = useState<IUpdateSpaceAddressRequestDTO>({
      ...INITIAL_STATE,
      uuid: currentUser?.uid as string,
      space_id: spaceId,
   });

   const isFormValid = () => {
      return (
         spaceAddress.uuid &&
         spaceAddress.address &&
         spaceAddress.country &&
         spaceAddress.city &&
         spaceAddress.address &&
         spaceAddress.zip
      );
   };

   const getSpaceAddress = async () => {
      try {
         setLoading(true);
         const response = await ApiRequests.getSpaceDetails(spaceId);
         if (response && response.data) {
            setSpaceAddress({
               address: response.data.location.address,
               city: response.data.location.city,
               country: response.data.location.country,
               floor: response.data.location.floor,
               zip: response.data.location.postalCode,
               street_number: response.data.location.streetNumber,
               lattitude: response.data.location.lattitude,
               longitude: response.data.location.longitude,
               area: response.data.area,
               uuid: currentUser?.uid as string,
               space_id: spaceId,
            });
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpaceAddressDetails'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.getSpaceAddressDetails'));
      } finally {
         setLoading(false);
      }
   };

   const updateSpaceAddressDetails = async () => {
      console.log(spaceAddress);
      setUpdateLoading(true);
      try {
         const response: any = await ApiRequests.updateSpaceAddress({
            payload: { ...spaceAddress },
         });
         if (response.success) {
            NotificationsProvider.success(translate('spaceManagement.success.addressDetailsUpdated'));
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.updateSpaceAddressDetails'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('spaceManagement.error.updateSpaceAddressDetails'));
      } finally {
         setUpdateLoading(false);
      }
   };

   useEffect(() => {
      if (currentUser && spaceId) {
         void getSpaceAddress();
      }
      //eslint-disable-next-line
   }, [currentUser, spaceId]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('spaceManagement.settings.addressDetails.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('spaceManagement.settings.addressDetails.title')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}
         <WrapperDiv>
            <FieldsDiv>
               <div style={{ width: '100%' }}>
                  <LabelWrapper width={'100%'}>
                     <StyledText
                        text={translate('spaceManagement.settings.addressDetails.placeholders.country')}
                        variant={StyledTextVariantsType.LABEL_TEXT}
                     />
                     <LabelStar>*</LabelStar>
                  </LabelWrapper>
                  <CountryDropdown
                     width={'100%'}
                     margin={'5px 0 16px 0'}
                     setValue={(value) => setSpaceAddress({ ...spaceAddress, country: value })}
                     value={spaceAddress.country}
                  />
               </div>
               <Input
                  width={'100%'}
                  fullWidthLabel={true}
                  type={'text'}
                  margin={'0 0 16px 0'}
                  label={translate('spaceManagement.settings.addressDetails.placeholders.city')}
                  placeholder={translate('spaceManagement.settings.addressDetails.placeholders.city')}
                  onChange={(value) => setSpaceAddress({ ...spaceAddress, city: value })}
                  validators={['required']}
                  value={spaceAddress.city}
               />
               <Input
                  width={'100%'}
                  fullWidthLabel={true}
                  type={'text'}
                  margin={'0 0 16px 0'}
                  label={translate('spaceManagement.settings.addressDetails.placeholders.area')}
                  placeholder={translate('spaceManagement.settings.addressDetails.placeholders.area')}
                  onChange={(value) => setSpaceAddress({ ...spaceAddress, area: value })}
                  validators={['required']}
                  value={spaceAddress.area}
               />
               <Input
                  width={'100%'}
                  fullWidthLabel={true}
                  type={'text'}
                  margin={'0 0 16px 0'}
                  label={translate('spaceManagement.settings.addressDetails.placeholders.zip')}
                  placeholder={translate('spaceManagement.settings.addressDetails.placeholders.zip')}
                  onChange={(value) => setSpaceAddress({ ...spaceAddress, zip: value })}
                  validators={['required']}
                  value={spaceAddress.zip}
               />
               <Input
                  width={'100%'}
                  fullWidthLabel={true}
                  type={'text'}
                  margin={'0 0 16px 0'}
                  label={translate('spaceManagement.settings.addressDetails.placeholders.address')}
                  placeholder={translate('spaceManagement.settings.addressDetails.placeholders.address')}
                  onChange={(value) => setSpaceAddress({ ...spaceAddress, address: value })}
                  validators={['required']}
                  value={spaceAddress.address}
               />
               <Input
                  width={'100%'}
                  fullWidthLabel={true}
                  type={'text'}
                  margin={'0 0 16px 0'}
                  label={translate('spaceManagement.settings.addressDetails.placeholders.streetNumber')}
                  placeholder={translate('spaceManagement.settings.addressDetails.placeholders.streetNumber')}
                  onChange={(value) => setSpaceAddress({ ...spaceAddress, street_number: value })}
                  value={spaceAddress.street_number}
               />
               <Input
                  width={'100%'}
                  fullWidthLabel={true}
                  type={'text'}
                  margin={'0 0 16px 0'}
                  placeholder={translate('spaceManagement.settings.addressDetails.placeholders.floor')}
                  label={translate('spaceManagement.settings.addressDetails.placeholders.floor')}
                  onChange={(value) => setSpaceAddress({ ...spaceAddress, floor: value })}
                  value={spaceAddress.floor}
               />
               <Input
                  width={'100%'}
                  fullWidthLabel={true}
                  type={'text'}
                  margin={'0 0 16px 0'}
                  readonly={true}
                  label={translate('spaceManagement.settings.addressDetails.placeholders.longitude')}
                  placeholder={translate('spaceManagement.settings.addressDetails.placeholders.longitude')}
                  onChange={(value) => setSpaceAddress({ ...spaceAddress, longitude: value })}
                  validators={['required']}
                  value={spaceAddress.longitude}
               />
               <Input
                  width={'100%'}
                  fullWidthLabel={true}
                  type={'text'}
                  margin={'0 0 60px 0'}
                  readonly={true}
                  label={translate('spaceManagement.settings.addressDetails.placeholders.latitude')}
                  placeholder={translate('spaceManagement.settings.addressDetails.placeholders.latitude')}
                  onChange={(value) => setSpaceAddress({ ...spaceAddress, lattitude: value })}
                  validators={['required']}
                  value={spaceAddress.lattitude}
               />
               <Button
                  disabled={!isFormValid()}
                  isLoading={updateLoading}
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('common.saveChanges')}
                  width={'100%'}
                  onClick={updateSpaceAddressDetails}
               />
            </FieldsDiv>
            <MapDiv>
               <MapViewAddressDetails
                  google={google}
                  spaceAddress={spaceAddress}
                  setSpaceAddress={(value) => setSpaceAddress({ ...value })}
               ></MapViewAddressDetails>
            </MapDiv>
         </WrapperDiv>
      </Flex>
   );
};

const Wrapped = GoogleApiWrapper({
   apiKey: config.GOOGLE_MAPS_API_KEY as string,
})(AddressDetails);

// eslint-disable-next-line
export default (props: JSX.IntrinsicAttributes & Omit<IProps, IProvidedProps> & { children?: React.ReactNode }) => (
   <section style={{ width: '100%' }}>
      <Wrapped {...props} />
   </section>
);
