import React from 'react';
import styled from 'styled-components';

interface IProps {
   initials: string;
}
const MainWrapper = styled.div`
   background-color: #897dfd;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 20px;
   width: 40px;
   height: 40px;
`;
const Text = styled.div`
   color: white;
   font-size: 25;
`;

const InitialIcon = (props: IProps) => {
   return (
      <MainWrapper>
         <Text>{props.initials}</Text>
      </MainWrapper>
   );
};

export default InitialIcon;
