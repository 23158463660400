import React, { ReactElement } from 'react';

import { Flex, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { CheckoutModalContentType } from './CheckoutModal';
import CreditCards from '../../credit_cards/CreditCards';

import { translate } from 'src/translations/translations';

interface IProps {
   setCheckoutModalContent: (content: CheckoutModalContentType) => void;
}

const CheckoutCreditCardsModal = ({ setCheckoutModalContent }: IProps): ReactElement => {
   return (
      <Flex flexDirection="column" alignItems="center" height="auto" backgroundColor={'white'}>
         <StyledText
            width={'100%'}
            text={translate('checkoutModal.paymentInfo.backToCheckout')}
            variant={StyledTextVariantsType.PARAGRAPH_2}
            cursor={'pointer'}
            margin={'0 0 16px 0'}
            onClick={() => {
               setCheckoutModalContent('checkout');
            }}
         />

         <CreditCards minHeight={'unset'} />
      </Flex>
   );
};

export default CheckoutCreditCardsModal;
