import React, { ReactElement } from 'react';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import styled from 'styled-components';
import './SpaceCarousel.css';

interface IProps {
   data: ReactElement[] | undefined;
}

const CarouselWrapper = styled.div<any>`
   width: 65%;
   @media (max-width: 1070px) {
      width: 100%;
   }
`;

const SpaceCarousel = ({ data }: IProps): ReactElement | null => {
   return (
      <CarouselWrapper>
         <Carousel showThumbs={true} autoPlay={true} useKeyboardArrows={true}>
            {data?.map((item: any, index: number) => (
               <img src={item as any} alt="Space image" key={`${item}_${index}`} />
            ))}
         </Carousel>
      </CarouselWrapper>
   );
};

export default SpaceCarousel;
