import React from 'react';

import { TableColumn, TableItemAction } from './interfaces';

import InitialIcon from '../InitialIcon';

export interface TableBodyProps<T> {
   data: Array<T>;
   columns: Array<TableColumn<T>>;
   itemActions?: Array<TableItemAction<T>>;
   selectedRows?: Array<number>;
}

export interface EditCellProps {
   toggleEdit: boolean;
   key: string;
   index: number;
}

export interface ColOptions {
   inputType?: string;
   defaultValue?: string;
   onChange?: (e: React.SyntheticEvent<any>, record: Record<any, any>, index: any) => void;
   onToggleEdit?: (key: string, index: number) => void;
   hidden?: boolean;
   customContent?: boolean;
   toggleEditCell?: EditCellProps;
}

const TableBody = <T,>({ data, columns, itemActions }: TableBodyProps<T>): React.ReactElement => {
   const getInitials = (data: any): string => {
      let names;
      if (data.user_name) {
         names = data.user_name.split(' ');
      } else {
         names = data.name.split(' ');
      }
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
         initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
   };
   const renderCells = (record: Record<string, unknown>, index: number, options: Record<string, ColOptions>) => {
      return Object.keys(record).map((key) => {
         const value = record[key] ? record[key] : options[key].defaultValue;
         // eslint-disable-next-line
         const { inputType, hidden, customContent } = options[key];
         // eslint-disable-next-line
         const callback = options[key]?.onChange;

         if (hidden) {
            return undefined;
         }
         // if cell needs to be customized
         if (customContent) {
            switch (key) {
               case 'type':
                  return (
                     <td key={`${key}-${index}`} className="table-body-value">
                        {value !== 'N/A' ? (
                           <div
                              className={` ${
                                 // eslint-disable-next-line no-nested-ternary
                                 value === 'error'
                                    ? 'table-error-cell'
                                    : value === 'warning' || value === 'information'
                                    ? 'table-warning-cell'
                                    : ''
                              }`}
                           >
                              <span className="text-capitalize">{value as string}</span>
                           </div>
                        ) : (
                           <div className="gray-placeholder-cell studentTags" />
                        )}
                     </td>
                  );
               default:
                  break;
            }
         }

         if (key === 'image') {
            return (
               <td key={`${key}-${index}`} className="px-1 table-body-value">
                  {value ? (
                     <img
                        src={value as string}
                        alt=""
                        style={{ height: 30, width: 30, borderRadius: '50%', marginRight: 10 }}
                     />
                  ) : (
                     <InitialIcon initials={getInitials(data[index])}></InitialIcon>
                  )}
               </td>
            );
         }

         return (
            <td key={`${key}-${index}`} className="py-3 px-1 table-body-value">
               {value as React.ReactElement}
            </td>
         );
      });
   };

   const isDisabled = (action: any, item: any): boolean => {
      if (action.disabled !== undefined && item.did_checkout) {
         return true;
      }
      return false;
   };

   const actionHandler = (action: any, item: any) => {
      action.clickHandler(item);
   };

   const renderActions = (item: T | any, actions: Array<TableItemAction<T>>) => {
      let isItemSelected: any;

      return (
         <td className="text-right px-1 text-nowrap d-flex align-items-center justify-content-end w-100">
            {actions.map((action, idx) => (
               <button
                  disabled={isDisabled(action, item)}
                  key={idx}
                  type="button"
                  className={`btn btn-sm btn-secondary text-primary mx-1 action-cta
              ${action.customClass || ''}`}
                  onClick={() => actionHandler(action, item)}
               >
                  {action.showCheckbox && (
                     <input
                        disabled={action.disabled}
                        type="checkbox"
                        className="mr-2"
                        checked={isItemSelected !== undefined && isItemSelected > -1}
                     />
                  )}
                  {action.title}
               </button>
            ))}
         </td>
      );
   };

   return (
      <tbody>
         {data &&
            data?.map((item, idx) => {
               const record = item as Record<string, unknown>;
               const dataObj: Record<string, unknown> = {};
               const options: Record<string, ColOptions> = {};

               columns
                  .filter((c) => c.key !== 'itemActions')
                  .forEach((k) => {
                     const val = record[k.key];
                     options[k.key] = {
                        inputType: undefined,
                        defaultValue: undefined,
                        onChange: undefined,
                        onToggleEdit: undefined,
                        hidden: undefined,
                        customContent: undefined,
                        toggleEditCell: undefined,
                     };
                     options[k.key].inputType = k.inputType;
                     options[k.key].defaultValue = k.defaultValue;
                     options[k.key].onChange = k.onChange;
                     options[k.key].onToggleEdit = k.onToggleEdit;
                     options[k.key].hidden = k.hidden;
                     options[k.key].customContent = k.customContent;
                     options[k.key].toggleEditCell = k.toggleEditCell;
                     if (k.rowParser) {
                        dataObj[k.key] = k.rowParser(item);
                     } else if (val !== undefined) {
                        dataObj[k.key] = val;
                     } else {
                        dataObj[k.key] = 'N/A';
                     }
                  });

               return (
                  <tr
                     key={`row-${idx}`}
                     className="border-bottom"
                     style={record.did_checkout ? { backgroundColor: '#dee2e6' } : {}}
                  >
                     {renderCells(dataObj, idx, options)}
                     {itemActions && renderActions(item, itemActions)}
                  </tr>
               );
            })}
      </tbody>
   );
};

export default TableBody;
