import React, { ReactElement, useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import { Button, Flex, Input, Spinner, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { SettingsItemArrow, SettingsItemContent } from '../../../shared/SharedComponents';
import { ChevronRightElement, CardIcon } from 'src/assets/images';
import { CheckoutModalContentType } from './CheckoutModal';
import { IDiscountData } from '../../purchase_plan/PurchasePlan';

import ApiRequests from 'src/features/settings/data/ApiRequests';
import { IPlan } from 'src/features/settings/interfaces';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { getFirebase } from 'react-redux-firebase';

const Card = styled.div`
   width: 100%;
   margin-bottom: 32px;
   height: auto;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: flex-start;
   padding: 0 0 16px 0;
   border-bottom: 1px solid ${theme.colors.gray};
`;

const CardInlineElements = styled.div<any>`
   width: 100%;
   height: auto;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: ${(props) => props.margin || 0};

   input,
   button {
      height: 42px !important;
   }
`;

interface IProps {
   selectedPlan: IPlan;
   onPurchaseClick: () => void;
   purchasePlanLoading: boolean;
   setCheckoutModalContent: (content: CheckoutModalContentType) => void;
   discount: IDiscountData | null;
   setDiscount: (value: IDiscountData) => void;
   coupon: string;
   setCoupon: (value: string) => void;
}

const CheckoutForm = ({
   selectedPlan,
   onPurchaseClick,
   purchasePlanLoading,
   setCheckoutModalContent,
   setDiscount,
   discount,
   coupon,
   setCoupon,
}: IProps): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(true);
   const [couponLoading, setCouponLoading] = useState(false);
   const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<any>(null);

   const filterDefaultPaymentMethod = (paymentMethods: any) => {
      return paymentMethods.find((item: any) => item?.is_default);
   };

   const getDefaultPaymentMethod = async () => {
      try {
         const response = await ApiRequests.getPaymentMethods(currentUser?.uid as string);

         if (response.data?.payment_methods) {
            const filteredDefaultPaymentMethod = filterDefaultPaymentMethod(response.data.payment_methods);
            setDefaultPaymentMethod(filteredDefaultPaymentMethod);
         }
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('settings.manageCreditCards.error.getPaymentMethods'),
         );
      } finally {
         setLoading(false);
      }
   };

   const onValidateCouponClick = async () => {
      if (!coupon) {
         return NotificationsProvider.error(translate('checkoutModal.error.missingCoupon'));
      }

      try {
         setCouponLoading(true);

         const response = await ApiRequests.validateCoupon({
            payload: {
               plan_id: selectedPlan.plan_id,
               coupon_id: coupon,
            },
         });

         if (!(response as any).success) {
            return NotificationsProvider.error(
               response.data?.message || translate('checkoutModal.error.validateCoupon'),
            );
         }

         setDiscount(response.data);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('checkoutModal.error.validateCoupon'));
      } finally {
         setCouponLoading(false);
      }
   };

   const getPercent = (discount: string) => {
      const discountPercent = parseFloat(discount);
      return `${Number(discountPercent.toFixed(2))}%`;
   };

   const getMonths = (monthsDuration: number) => {
      return `${monthsDuration} month${monthsDuration > 1 ? 's' : ''}`;
   };

   const getTotalAmount = (price: number, discount: string) => {
      const discountPercent = parseFloat(discount) / 100;
      const discountAmount = price * Number(discountPercent.toFixed(2));
      return price - discountAmount;
   };

   useEffect(() => {
      currentUser && void getDefaultPaymentMethod();
      //eslint-disable-next-line
   }, [currentUser]);

   useEffect(() => {
      if (selectedPlan) {
         setTimeout(() => {
            setLoading(false);
         }, 500);
      }
   }, [selectedPlan]);

   return (
      <Flex flexDirection="column" alignItems="center" height="auto" backgroundColor={'white'}>
         <StyledText
            width={'100%'}
            text={translate('common.checkout')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 48px 0'}
         />

         {loading ? (
            <Spinner height={'400px'} />
         ) : (
            <>
               <Card>
                  <StyledText
                     width={'100%'}
                     text={translate('common.monthlyMembership')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 4px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     text={selectedPlan?.plan_name}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'0 0 8px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     text={selectedPlan?.plan_description}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                  />
               </Card>

               <Card>
                  <StyledText
                     width={'100%'}
                     text={translate('checkoutModal.subscriptionConditionCard.title')}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'0 0 16px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     text={translate('checkoutModal.subscriptionConditionCard.text')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                  />
               </Card>

               <Card>
                  <StyledText
                     width={'100%'}
                     text={translate('checkoutModal.promoCodeCard.title')}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'0 0 16px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     margin={'0 0 16px 0'}
                     text={translate('checkoutModal.promoCodeCard.text')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                  />
                  <Input
                     width={'100%'}
                     type={'text'}
                     margin={'0 0 16px 0'}
                     placeholder={translate('checkoutModal.promoCodeCard.input.placeholder')}
                     onChange={(value) => setCoupon(value)}
                     value={coupon}
                  />
                  <StyledText
                     width={'100%'}
                     justifyContent={'center'}
                     text={translate('checkoutModal.promoCodeCard.button.text')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.mainPurple}
                     onClick={onValidateCouponClick}
                     cursor={'pointer'}
                  />
               </Card>

               <Card>
                  <StyledText
                     width={'100%'}
                     text={translate('checkoutModal.paymentInfoCard.title')}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'0 0 32px 0'}
                  />
                  {defaultPaymentMethod && (
                     <SettingsItemContent
                        onClick={() => {
                           setCheckoutModalContent('payment-methods');
                        }}
                     >
                        <Flex width={'80%'}>
                           <CardIcon height={24} />
                           <StyledText
                              width={'100%'}
                              margin={'0 0 0 10px'}
                              text={`**** ${defaultPaymentMethod.card.last4}`}
                              variant={StyledTextVariantsType.LABEL_TEXT}
                           />
                        </Flex>
                        <SettingsItemArrow>
                           <ChevronRightElement />
                        </SettingsItemArrow>
                     </SettingsItemContent>
                  )}
                  {!defaultPaymentMethod && (
                     <SettingsItemContent
                        onClick={() => {
                           setCheckoutModalContent('payment-methods');
                        }}
                     >
                        <Flex width={'80%'}>
                           <StyledText
                              width={'100%'}
                              text={translate('checkoutModal.paymentInfoCard.noPayments.text')}
                              variant={StyledTextVariantsType.LABEL_TEXT}
                           />
                        </Flex>
                        <SettingsItemArrow>
                           <ChevronRightElement />
                        </SettingsItemArrow>
                     </SettingsItemContent>
                  )}
               </Card>

               {!discount && (
                  <Card>
                     <StyledText
                        width={'100%'}
                        text={translate('checkoutModal.orderCard.title')}
                        variant={StyledTextVariantsType.HEADING_2}
                        margin={'0 0 16px 0'}
                     />
                     <CardInlineElements margin={'0 0 8px 0'}>
                        <StyledText
                           width={'60%'}
                           text={translate('checkoutModal.orderCard.originalAmount.label')}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                           colorVariant={theme.colors.gray}
                        />
                        <StyledText
                           width={'35%'}
                           text={`${selectedPlan.plan_price} ${selectedPlan.currency}`}
                           variant={StyledTextVariantsType.HEADING_3}
                           justifyContent={'flex-end'}
                        />
                     </CardInlineElements>

                     <CardInlineElements>
                        <StyledText
                           width={'60%'}
                           text={translate('checkoutModal.orderCard.totalAmount.label')}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                           colorVariant={theme.colors.gray}
                        />
                        <StyledText
                           width={'35%'}
                           text={`${selectedPlan.plan_price} ${selectedPlan.currency}`}
                           variant={StyledTextVariantsType.HEADING_2}
                           justifyContent={'flex-end'}
                        />
                     </CardInlineElements>

                     <StyledText
                        width={'100%'}
                        text={translate('checkoutModal.orderCard.vatNote')}
                        variant={StyledTextVariantsType.NOTE_TEXT}
                        justifyContent={'flex-end'}
                        colorVariant={theme.colors.gray}
                     />
                  </Card>
               )}

               {discount && couponLoading && <CircularProgress size={25} />}

               {discount && !couponLoading && (
                  <Card>
                     <StyledText
                        width={'100%'}
                        text={translate('checkoutModal.orderCard.title')}
                        variant={StyledTextVariantsType.HEADING_2}
                        margin={'0 0 16px 0'}
                     />
                     <CardInlineElements margin={'0 0 8px 0'}>
                        <StyledText
                           width={'60%'}
                           text={translate('checkoutModal.orderCard.originalAmount.label')}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                           colorVariant={theme.colors.gray}
                        />
                        <StyledText
                           width={'35%'}
                           text={`${selectedPlan.plan_price} ${selectedPlan.currency}`}
                           variant={StyledTextVariantsType.HEADING_3}
                           justifyContent={'flex-end'}
                        />
                     </CardInlineElements>

                     <CardInlineElements margin={'0 0 8px 0'}>
                        <StyledText
                           width={'60%'}
                           text={translate('checkoutModal.orderCard.discountAmount.label')}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                           colorVariant={theme.colors.gray}
                        />
                        <StyledText
                           width={'35%'}
                           text={`${getPercent(discount.percent_off)}`}
                           variant={StyledTextVariantsType.HEADING_3}
                           justifyContent={'flex-end'}
                        />
                     </CardInlineElements>

                     <CardInlineElements margin={'0 0 24px 0'}>
                        <StyledText
                           width={'60%'}
                           text={translate('checkoutModal.orderCard.discountDuration.label')}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                           colorVariant={theme.colors.gray}
                        />
                        <StyledText
                           width={'35%'}
                           text={`${getMonths(discount.duration_in_months)}`}
                           variant={StyledTextVariantsType.HEADING_3}
                           justifyContent={'flex-end'}
                        />
                     </CardInlineElements>

                     <CardInlineElements>
                        <StyledText
                           width={'60%'}
                           text={translate('checkoutModal.orderCard.totalAmount.label')}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                           colorVariant={theme.colors.gray}
                        />
                        <StyledText
                           width={'35%'}
                           text={`${getTotalAmount(selectedPlan.plan_price, discount.percent_off)} ${
                              selectedPlan.currency
                           }`}
                           variant={StyledTextVariantsType.HEADING_2}
                           justifyContent={'flex-end'}
                        />
                     </CardInlineElements>

                     <StyledText
                        width={'100%'}
                        text={translate('checkoutModal.orderCard.vatNote')}
                        variant={StyledTextVariantsType.NOTE_TEXT}
                        justifyContent={'flex-end'}
                        colorVariant={theme.colors.gray}
                     />
                  </Card>
               )}

               <Button
                  disabled={!selectedPlan || purchasePlanLoading || !defaultPaymentMethod}
                  isLoading={purchasePlanLoading}
                  variant={ButtonVariantsType.PURPLE}
                  text={translate('checkoutModal.orderCard.button.text')}
                  margin={'66px 0 0 0'}
                  width={'100%'}
                  onClick={onPurchaseClick}
               />
            </>
         )}
      </Flex>
   );
};

export default CheckoutForm;
