import React, { ReactElement, useEffect, useState } from 'react';
import moment from 'moment';

import { Flex, StyledText, Spinner, Table } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn } from 'src/components/table/interfaces';

import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { ICompany } from 'src/features/enterprise_management/interfaces';
import { translate } from 'src/translations/translations';
import { capitalize, parseError, useWindowWide } from '../../../../utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { config } from 'src/app/config';
import { useLocation } from 'react-router-dom';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

interface IProps {
   company: ICompany;
}

enum PeriodFilter {
   DAILY = 'daily',
   WEEKLY = 'weekly',
   MONTHLY = 'monthly',
}

enum LimitFilter {
   TEN = 10,
   TWENTY = 20,
   THIRTY = 30,
   FOURTY = 40,
}

enum SortFilter {
   CREATED_AT = 'created_at',
   UPDATED_AT = 'updated_at',
}

enum OrderFilter {
   DESC = 'desc',
   ASC = 'asc',
}

const CheckinsInfo = (): ReactElement => {
   const [loading, setLoading] = useState(false);
   const [checkinsInfo, setCheckinsInfo] = useState<any>([]);

   const location = useLocation();
   const company = location.state.company;

   const wide = useWindowWide(768);
   const goBack = () => {
      history.back();
   };

   const [periodFilter, setPeriodFilter] = useState<PeriodFilter>(PeriodFilter.WEEKLY);
   const [limitFilter, setLimitFilter] = useState<LimitFilter>(LimitFilter.TWENTY);
   const [sortFilter, setSortFilter] = useState<SortFilter>(SortFilter.CREATED_AT);
   const [orderFilter, setOrderFilter] = useState<OrderFilter>(OrderFilter.DESC);

   const getCompanyCheckins = async () => {
      if (company && company.enterprise_id) {
         try {
            setLoading(true);
            const response = await ApiRequests.getCompanyCheckins(
               company.enterprise_id,
               periodFilter,
               limitFilter,
               sortFilter,
               orderFilter,
            );

            if (response && response.data) {
               setCheckinsInfo(response.data);
            } else {
               NotificationsProvider.error(translate('hrManagement.settings.error.getCompanyCheckins'));
            }
         } catch (error) {
            NotificationsProvider.error(
               parseError(error) || translate('hrManagement.settings.error.getCompanyCheckins'),
            );
         } finally {
            setLoading(false);
         }
      } else {
         setLoading(false);
         NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }
   };

   const columns: Array<TableColumn<any>> = [
      {
         title: '',
         key: 'image',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => `${data.user_photo ? config.STORAGE_URL + data.user_photo : ''}`,
      },
      {
         title: 'User',
         key: 'user_name',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{checkin.user_name}</>,
      },
      {
         title: 'Space',
         key: 'space',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{checkin.space}</>,
      },
      {
         title: 'Checkin ID',
         key: 'checkin_id',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{checkin.checkin_id}</>,
      },
      {
         title: 'Checkin type',
         key: 'checkin_type',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{capitalize(checkin.checkin_type)}</>,
      },
      {
         title: 'Checkin date',
         key: 'date',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{checkin.checkin_date}</>,
      },
   ];

   useEffect(() => {
      //TODO remove this once filters are set
      setPeriodFilter(PeriodFilter.WEEKLY);
      setLimitFilter(LimitFilter.TWENTY);
      setSortFilter(SortFilter.CREATED_AT);
      setOrderFilter(OrderFilter.DESC);

      void getCompanyCheckins();
      //eslint-disable-next-line
   }, []);

   return (
      <Flex
         width={'68%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               margin={'0 0 32px 0'}
               text={translate('hrManagement.insights.checkinsInfo.tabContent.title')}
               variant={StyledTextVariantsType.HEADING_1}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  margin={'0 0 0 32px'}
                  text={translate('hrManagement.insights.checkinsInfo.tabContent.title')}
                  variant={StyledTextVariantsType.HEADING_1}
               />
            </HeaderWrapper>
         )}
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            margin={'0 0 32px 0'}
            text={'Showing last 50 records'}
            variant={StyledTextVariantsType.PARAGRAPH_2}
         />
         {loading ? (
            <Spinner height={'400px'} />
         ) : (
            <Table onSelectAll={() => null} columns={columns} data={checkinsInfo} customClass={'w-100'} />
         )}
      </Flex>
   );
};

export default CheckinsInfo;
