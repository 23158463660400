import React, { ReactElement } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Flex, HamburgerMenu, StyledText } from './index';
import { StyledTextVariantsType } from './StyledText';
import { InlineElementsWrapper } from './SharedComponents';

import { RoutesEnum } from 'src/routes/routes';
import { Logo, SettingsIcon } from 'src/assets/images';
import { HRManagementTabEnum, SpaceManagementTabEnum } from 'src/system/state/types';
import { UserRoles } from 'src/system/state/types';
import { setHRManagementTab, setSpaceanagementTab } from 'src/system/state/actions';
import { selectFirebaseAuthLoader } from 'src/features/auth/authSlice';
import { translate } from 'src/translations/translations';
import { showLoginModal, showRegisterModal } from 'src/system/state/actions';
import theme from 'src/styles/theme';

const HeaderWrapper = styled.div`
   width: 100%;

   header {
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      padding: 12px 10%;
      height: auto;
      box-shadow: 1px 2px 15px -5px rgba(0, 0, 0, 0.15);

      section:nth-of-type(1) {
         position: relative;
      }

      .bm-burger-button,
      #hamburger-menu {
         display: none;
      }

      .navigation-item {
         width: 100%;
         min-width: 200px;
      }
   }

   @media only screen and (max-width: 768px) {
      header {
         padding: 5%;
         flex-direction: column;
         height: auto;

         .header-settings-link {
            display: none;
         }

         .header-navbar {
            display: none;
         }

         .bm-burger-button {
            z-index: 1000;
            position: absolute;
            width: 40px;
            height: 32px;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            .bm-burger-bars {
               background-color: ${theme.colors.mainPurple};
            }
         }

         .bm-burger-button,
         #hamburger-menu {
            display: block;
         }
      }
   }
`;

interface IPRops {
   customerRole: string;
   isLoggedIn: boolean;
   currentUser: any;
}

const Header = ({ customerRole, isLoggedIn, currentUser }: IPRops): ReactElement => {
   const history = useHistory();
   const dispatch = useDispatch();
   const firebaseAuthLoaded = useSelector(selectFirebaseAuthLoader);

   const goToHome = () => {
      if (customerRole === UserRoles.ENTERPRISE_MANAGER) {
         dispatch(setHRManagementTab(HRManagementTabEnum.TODAY));
         history.push(RoutesEnum.ENTERPRISE_TODAY);
      } else {
         history.push(RoutesEnum.HOME);
      }
   };

   const goToSettingsPage = () => {
      if (customerRole === UserRoles.ENTERPRISE_MANAGER) {
         dispatch(setHRManagementTab(HRManagementTabEnum.SETTINGS));
         history.push(RoutesEnum.ENTERPRISE_SETTINGS);
      } else if (customerRole === UserRoles.SPACE_MANAGER) {
         dispatch(setSpaceanagementTab(SpaceManagementTabEnum.SETTINGS));
         history.push(RoutesEnum.SPACE_MANAGEMENT_SETTINGS);
      } else {
         history.push(RoutesEnum.SETTINGS);
      }
   };

   return (
      <HeaderWrapper>
         <header>
            <Flex
               width={'20%'}
               alignItems={'center'}
               paddingMobile={'unset'}
               justifyContent={'flex-start'}
               classNames={'logo-wrapper'}
            >
               <Link to={'#'} onClick={goToHome}>
                  <img src={Logo} height={35} alt={'logo green'} />
               </Link>
            </Flex>
            <Flex
               classNames={'header-navbar'}
               width={'55%'}
               flexDirection="row"
               alignItems="center"
               justifyContent={'center'}
            />
            <Flex
               width={'25%'}
               alignItems={'center'}
               justifyContent={'flex-end'}
               marginMobile={'20px 0 0 0'}
               paddingMobile={'unset'}
            >
               {!firebaseAuthLoaded ? null : isLoggedIn ? (
                  <Flex paddingMobile={'unset'}>
                     <InlineElementsWrapper margin={'0 10px 0 0'}>
                        <StyledText
                           text={translate('common.welcomeBack')}
                           variant={StyledTextVariantsType.PARAGRAPH_2}
                        />
                        <StyledText
                           text={`${currentUser.displayName}`}
                           variant={StyledTextVariantsType.HEADING_3}
                           colorVariant={theme.colors.mainPurple}
                           margin={'0 0 0 5px'}
                        />
                        <StyledText text={'!'} variant={StyledTextVariantsType.PARAGRAPH_2} />
                     </InlineElementsWrapper>
                     <Link
                        to={'#'}
                        style={{ textDecoration: 'none' }}
                        className={'header-settings-link'}
                        onClick={goToSettingsPage}
                     >
                        <img src={SettingsIcon} height={20} alt={'settings'} style={{ marginLeft: 4 }} />
                     </Link>
                  </Flex>
               ) : (
                  <Flex>
                     <StyledText
                        text={translate('common.signIn')}
                        variant={StyledTextVariantsType.HEADING_3}
                        colorVariant={theme.colors.mainPurple}
                        margin={'0 20px 0 0'}
                        marginMobile={'0 20px 0 0'}
                        cursor={'pointer'}
                        onClick={() => {
                           dispatch(showLoginModal(true));
                        }}
                     />
                     <StyledText
                        text={translate('common.register')}
                        variant={StyledTextVariantsType.HEADING_3}
                        colorVariant={theme.colors.mainPurple}
                        cursor={'pointer'}
                        onClick={() => {
                           dispatch(showRegisterModal(true));
                        }}
                     />
                  </Flex>
               )}
            </Flex>
         </header>
      </HeaderWrapper>
   );
};

export default Header;
