import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { Flex, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import {
   SettingsItemArrow,
   SettingsItemContent,
   SettingsItemIcon,
   SettingsItemTitle,
   SettingsItem,
} from './shared/SharedComponents';

import {
   UserEditIcon,
   BriefcaseIcon,
   FolderCloudIcon,
   BarCodeIcon,
   ChevronRightElement,
   HRManagementSettingsTabIcon,
   InsightsTabIcon,
   LogoutIcon,
} from 'src/assets/images';

import { SettingsItemEnum, AccountSettingsItemEnum, BillingSettingsItemEnum } from './Settings';
import { RoutesEnum } from 'src/routes/routes';
import { signOutFromFirebase } from 'src/features/auth/authSlice';
import { logout } from 'src/system/state/actions';
import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';
import { IUser } from './interfaces';

interface IProps {
   user: IUser;
   activeSettingsItem: SettingsItemEnum;
   setActiveSettingsItem: (item: SettingsItemEnum) => void;
   isSettingItemSelected: (selectedItem: SettingsItemEnum, item: SettingsItemEnum) => boolean;
   setActiveAccountSettingsItem: (item: AccountSettingsItemEnum) => void;
   setActiveBillingSettingsItem: (item: BillingSettingsItemEnum) => void;
}

const SettingsRoot = ({
   user,
   activeSettingsItem,
   setActiveSettingsItem,
   isSettingItemSelected,
   setActiveAccountSettingsItem,
   setActiveBillingSettingsItem,
}: IProps): ReactElement => {
   const history = useHistory();
   const dispatch = useDispatch();
   const { boot, shutdown } = useIntercom();

   const onSettingsItemClick = (item: SettingsItemEnum) => {
      setActiveSettingsItem(item);
      setActiveAccountSettingsItem(AccountSettingsItemEnum.EMPTY);
      setActiveBillingSettingsItem(BillingSettingsItemEnum.EMPTY);
   };

   const onLogoutClick = async () => {
      shutdown();
      boot();
      await signOutFromFirebase();
      dispatch(logout());
   };

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         width={'100%'}
         height={'100%'}
         position={'relative'}
      >
         <StyledText
            width={'100%'}
            text={translate('common.settings')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 32px 0'}
         />

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
            borderRight={`1px solid ${theme.colors.lightGray}`}
            height={'inherit'}
         >
            <SettingsItem
               className={`${isSettingItemSelected(activeSettingsItem, SettingsItemEnum.ACCOUNT_INFO) ? 'active' : ''}`}
               onClick={() => {
                  onSettingsItemClick(SettingsItemEnum.ACCOUNT_INFO);
                  history.push({
                     pathname: RoutesEnum.ACCOUNT,
                     state: {
                        user: user,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.account.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.account.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
            <SettingsItem
               className={`${isSettingItemSelected(activeSettingsItem, SettingsItemEnum.BILLING_INFO) ? 'active' : ''}`}
               onClick={() => {
                  onSettingsItemClick(SettingsItemEnum.BILLING_INFO);
                  history.push({
                     pathname: RoutesEnum.BILLING_INFO,
                  });
               }}
            >
               <SettingsItemIcon>
                  <BarCodeIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.billing.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.billing.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
            {/* TODO Remove hidden prop */}
            <SettingsItem
               hidden={true}
               className={`${isSettingItemSelected(activeSettingsItem, SettingsItemEnum.ACTIVITY) ? 'active' : ''}`}
               onClick={() => {
                  return;
                  // onSettingsItemClick(SettingsItemEnum.ACTIVITY);
               }}
            >
               <SettingsItemIcon>
                  <FolderCloudIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.activity.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.activity.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
            <SettingsItem
               className={`${isSettingItemSelected(activeSettingsItem, SettingsItemEnum.LEGAL_INFO) ? 'active' : ''}`}
               onClick={() => {
                  onSettingsItemClick(SettingsItemEnum.LEGAL_INFO);
                  history.push({
                     pathname: RoutesEnum.LEGAL_INFO,
                  });
               }}
            >
               <SettingsItemIcon>
                  <BriefcaseIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.legalInfo.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.legalInfo.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
            {/* TODO Remove hidden prop */}
            <SettingsItem
               hidden={true}
               onClick={() => {
                  history.push(RoutesEnum.SPACE_MANAGEMENT_SETTINGS);
               }}
            >
               <SettingsItemIcon>
                  <InsightsTabIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.switchToSpaceManagement.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.switchToSpaceManagement.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
            <SettingsItem
               onClick={() => {
                  history.push(RoutesEnum.ENTERPRISE_SETTINGS);
               }}
            >
               <SettingsItemIcon>
                  <HRManagementSettingsTabIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.switchToHRManagement.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.switchToHRManagement.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
            {/* <SettingsItem
               onClick={() => {
                  history.push(RoutesEnum.SPACE_MANAGEMENT_SETTINGS);
               }}
            >
               <SettingsItemIcon>
                  <HRManagementSettingsTabIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.switchToSpaceManagement.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.switchToSpaceManagement.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem> */}
            <SettingsItem onClick={onLogoutClick}>
               <SettingsItemIcon>
                  <LogoutIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.logout.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.logout.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
         </Flex>
      </Flex>
   );
};

export default SettingsRoot;
