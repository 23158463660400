import React, { ReactElement } from 'react';

import { HRCompanyDetailsIllustration } from 'src/assets/images';
import { Button, Flex, ModalComponent, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { InlineElementsWrapper } from 'src/components/SharedComponents';

import { ICompany } from '../../interfaces';
import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';

interface IProps {
   isOpen: boolean;
   setShowModal: (value: boolean) => void;
   company: ICompany;
}

const ConvertedToEnterpriseModal = ({ setShowModal, isOpen, company }: IProps): ReactElement => {
   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'75%'}
         closeModal={() => {
            setShowModal(false);
         }}
      >
         <Flex flexDirection="column" alignItems="center" backgroundColor={'white'}>
            <Flex
               justifyContent="flex-start"
               flexDirection={'column'}
               alignItems="flex-start"
               position={'relative'}
               width={'100%'}
            >
               <InlineElementsWrapper margin={'0 0 38px 0'} width={'100%'}>
                  <StyledText
                     justifyContent={'flex-start'}
                     text={translate('hrManagement.settings.yourCompanyToday.title')}
                     variant={StyledTextVariantsType.HEADING_1}
                  />

                  <StyledText
                     margin={'0 0 0 21px'}
                     justifyContent={'flex-start'}
                     text={company.company_name}
                     variant={StyledTextVariantsType.HEADING_1}
                     colorVariant={theme.colors.mainPurple}
                  />
               </InlineElementsWrapper>

               <Flex
                  justifyContent="flex-start"
                  alignItems="stretch"
                  flexWrap="nowrap"
                  backgroundColor={'#F9F9F9'}
                  borderRadius={'15px'}
                  padding={'5% 10% 5% 10%'}
                  flex={1}
                  position={'relative'}
               >
                  <Flex
                     width={'32%'}
                     flexDirection="column"
                     justifyContent="flex-start"
                     backgroundColor={'#F9F9F9'}
                     alignItems="flex-start"
                     position={'relative'}
                     overflow={'hidden'}
                  >
                     <img
                        src={HRCompanyDetailsIllustration}
                        alt="add-company"
                        style={{ width: '85%', height: 'auto' }}
                     />
                  </Flex>

                  <Flex
                     width={'68%'}
                     flexDirection="column"
                     justifyContent="center"
                     backgroundColor={'#F9F9F9'}
                     alignItems="center"
                     position={'relative'}
                     padding={'0 0 0 32px'}
                     overflow={'hidden'}
                  >
                     <StyledText
                        width={'100%'}
                        justifyContent={'flex-start'}
                        text={translate('hrManagement.settings.yourCompanyToday.text')}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                        margin={'0 0 32px 0'}
                     />

                     <Button
                        variant={ButtonVariantsType.PURPLE}
                        text={translate('hrManagement.settings.yourCompanyToday.button.text')}
                        width={'100%'}
                        onClick={() => {
                           setShowModal(false);
                        }}
                     />
                  </Flex>
               </Flex>
            </Flex>
         </Flex>
      </ModalComponent>
   );
};

export default ConvertedToEnterpriseModal;
