import React, { ReactElement, useState } from 'react';

import { ModalComponent } from 'src/components';
import CheckoutForm from 'src/features/settings/billing/modals/checkout/CheckoutForm';
import CheckoutCreditCardsModal from 'src/features/settings/billing/modals/checkout/CheckoutCreditCardsModal';
import { IPlan } from 'src/features/settings/interfaces';
import { IDiscountData } from '../../purchase_plan/PurchasePlan';

export type CheckoutModalContentType = 'checkout' | 'payment-methods';

interface IProps {
   selectedPlan: IPlan;
   isOpen: boolean;
   loading: boolean;
   discount: IDiscountData | null;
   setDiscount: (value: IDiscountData | null) => void;
   coupon: string;
   setCoupon: (value: string) => void;
   setShowModal: (value: boolean) => void;
   onPurchaseClick: () => void;
}

const CheckoutModal = ({
   selectedPlan,
   setShowModal,
   isOpen,
   loading,
   onPurchaseClick,
   discount,
   setDiscount,
   setCoupon,
   coupon,
}: IProps): ReactElement => {
   const [checkoutModalContent, setCheckoutModalContent] = useState<CheckoutModalContentType>('checkout');

   const clearValues = () => {
      setDiscount(null);
      setCoupon('');
   };

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'500px'}
         height={'90vh'}
         closeModal={() => {
            clearValues();
            setShowModal(false);
         }}
      >
         {checkoutModalContent === 'checkout' && (
            <CheckoutForm
               selectedPlan={selectedPlan}
               onPurchaseClick={onPurchaseClick}
               setCheckoutModalContent={setCheckoutModalContent}
               discount={discount}
               setDiscount={setDiscount}
               coupon={coupon}
               setCoupon={setCoupon}
               purchasePlanLoading={loading}
            />
         )}

         {checkoutModalContent === 'payment-methods' && (
            <CheckoutCreditCardsModal setCheckoutModalContent={setCheckoutModalContent} />
         )}
      </ModalComponent>
   );
};

export default CheckoutModal;
