import React, { ReactElement } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

import theme from 'src/styles/theme';

interface IProps {
   width?: string;
   height?: string;
   position?: string;
}

const SpinnerContainer = styled.div<any>`
   display: flex;
   justify-content: center;
   align-items: center;
   height: ${({ height }: IProps) => height || '100%'};
   width: ${({ width }: IProps) => width || '100%'};
   position: ${({ position }: IProps) => position || 'initial'};
`;

const Spinner = ({ height, position }: IProps): ReactElement => {
   return (
      <SpinnerContainer position={position} height={height}>
         <CircularProgress size={40} style={{ color: theme.colors.mainPurple }} />
      </SpinnerContainer>
   );
};

export default Spinner;
