import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Input } from 'src/components';

import { RootState } from 'src/app/rootReducer';
import { RoutesEnum } from 'src/routes/routes';
import { translate } from 'src/translations/translations';
import { updateCoworkingSpacesSearchTerm } from 'src/system/state/actions';
import { setHRManagementTab, setSpaceanagementTab } from 'src/system/state/actions';
import {
   HomeIcon,
   CalendarIcon,
   LocationsIcon,
   HomeIconGray,
   LocationsIconGray,
   CalendarIconGray,
   SettingsIconGray,
   LocationsIconPurple,
   CalendarIconPurple,
   SettingsIconPurple,
   HomeIconPurple,
} from 'src/assets/images';
import theme from 'src/styles/theme';
import { useWindowWide } from 'src/utilities/helpers';
import { HRManagementTabEnum, SpaceManagementTabEnum, UserRoles } from 'src/system/state/types';
import { selectFirebaseAuthLoader } from 'src/features/auth/authSlice';
import { getFirebase } from 'react-redux-firebase';

const SubHeaderWrapper = styled.div`
   width: 100%;

   .sub-header {
      display: flex;
      align-items: center;
      padding: 50px 10% 54px 10%;

      a {
         display: flex;
         justify-content: flex-start;
         margin-right: 36px;
         text-decoration: none;
         cursor: pointer;
         padding: 12px;
         font-weight: 500;
         font-size: 16px;
         color: ${theme.colors.gray};

         &.active {
            border-bottom: 2px solid ${theme.colors.black};
            color: ${theme.colors.black};

            > svg {
               stroke: ${theme.colors.black};
            }

            svg > path {
               stroke: ${theme.colors.black};
            }

            &.no-border {
               border: none !important;
            }
         }

         img {
            margin-right: 10px;
         }
      }

      @media only screen and (max-width: 768px) {
         padding: 5% !important;
         flex-direction: column;

         > a {
            width: 100% !important;
            margin: 0;
         }

         input {
            margin-top: 40px;
         }
      }
   }
`;

const SubHeaderWrapperMobile = styled.div`
   width: 100%;

   position: fixed;
   box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.08);
   height: 100px;
   background-color: white;
   bottom: 0px;
   z-index: 100;
   display: flex;
   justify-content: 'center';

   .sub-header {
      bottom: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 1% !important;
      flex-direction: row;

      a {
         display: flex;
         justify-content: center;
         margin-right: 36px;
         text-decoration: none;
         cursor: pointer;
         padding: 12px;
         font-weight: 500;
         font-size: 16px;
         color: ${theme.colors.gray};

         &.active {
            color: ${theme.colors.black};

            > svg {
               stroke: ${theme.colors.black};
            }

            svg > path {
               stroke: ${theme.colors.black};
            }

            &.no-border {
               border: none !important;
            }
         }

         img {
            margin-right: 10px;
         }
      }

      > a {
         width: 100% !important;
         margin: 0;
      }

      input {
         margin-top: 40px;
      }
   }
`;
interface IPRops {
   customerRole: string;
}

const AppLayoutSubHeader = ({ customerRole }: IPRops) => {
   const location = useLocation();
   const history = useHistory();
   const dispatch = useDispatch();
   const firebase = getFirebase();
   const firebaseAuthLoaded = useSelector(selectFirebaseAuthLoader);
   const currentUser = firebase.auth()?.currentUser;
   const token = useSelector((state: RootState) => state.systemReducer).token;

   const isLoggedIn = !!currentUser && !!token;

   const wide = useWindowWide(768);

   const goToSettingsPage = () => {
      if (customerRole === UserRoles.ENTERPRISE_MANAGER) {
         dispatch(setHRManagementTab(HRManagementTabEnum.SETTINGS));
         history.push(RoutesEnum.ENTERPRISE_SETTINGS);
      } else if (customerRole === UserRoles.SPACE_MANAGER) {
         dispatch(setSpaceanagementTab(SpaceManagementTabEnum.SETTINGS));
         history.push(RoutesEnum.SPACE_MANAGEMENT_SETTINGS);
      } else {
         history.push(RoutesEnum.SETTINGS);
      }
   };

   const coworkingSpacesSearchTerm = useSelector((state: RootState) => state.systemReducer).coworkingSpacesSearchTerm;

   const isActive = (pathname: string) => {
      return pathname === location.pathname;
   };

   return (
      <>
         {wide ? (
            <SubHeaderWrapper>
               <div className={'sub-header'}>
                  <Link to={RoutesEnum.HOME} className={isActive(RoutesEnum.HOME) ? 'active' : ''}>
                     <img src={isActive(RoutesEnum.HOME) ? HomeIcon : HomeIconGray} height={20} alt={'spaces'} />
                     {translate('common.home')}
                  </Link>
                  <Link to={RoutesEnum.MAP_VIEW} className={isActive(RoutesEnum.MAP_VIEW) ? 'active' : ''}>
                     <img
                        src={isActive(RoutesEnum.MAP_VIEW) ? LocationsIcon : LocationsIconGray}
                        height={20}
                        alt={'locations'}
                     />
                     {translate('common.mapView')}
                  </Link>
                  <Link
                     to={RoutesEnum.RESERVE_MEETING_ROOM}
                     className={isActive(RoutesEnum.RESERVE_MEETING_ROOM) ? 'active' : ''}
                  >
                     <img
                        src={isActive(RoutesEnum.RESERVE_MEETING_ROOM) ? CalendarIcon : CalendarIconGray}
                        height={20}
                        alt={'reserve a meeting room'}
                     />
                     {translate('common.reserveMeetingRoom')}
                  </Link>

                  {isActive(RoutesEnum.HOME) && (
                     <Input
                        height={'42px'}
                        width={'40%'}
                        type={'text'}
                        margin={'0 0 0 auto !important'}
                        placeholder={translate('placeholders.searchCoworkingSpaces')}
                        onChange={(value) => {
                           dispatch(updateCoworkingSpacesSearchTerm(value));
                        }}
                        value={coworkingSpacesSearchTerm}
                     />
                  )}
               </div>
            </SubHeaderWrapper>
         ) : (
            <>
               {isActive(RoutesEnum.HOME) && (
                  <Input
                     height={'42px'}
                     width={'40%'}
                     mobileWidth={'95%'}
                     type={'text'}
                     margin={'10px 10px 0 auto !important'}
                     placeholder={translate('placeholders.searchCoworkingSpaces')}
                     onChange={(value) => {
                        dispatch(updateCoworkingSpacesSearchTerm(value));
                     }}
                     value={coworkingSpacesSearchTerm}
                  />
               )}
               <SubHeaderWrapperMobile>
                  <div className={'sub-header'}>
                     <Link to={RoutesEnum.HOME} className={isActive(RoutesEnum.HOME) ? 'active' : ''}>
                        <img
                           src={isActive(RoutesEnum.HOME) ? HomeIconPurple : HomeIconGray}
                           height={25}
                           alt={'spaces'}
                        />
                     </Link>
                     <Link to={RoutesEnum.MAP_VIEW} className={isActive(RoutesEnum.MAP_VIEW) ? 'active' : ''}>
                        <img
                           src={isActive(RoutesEnum.MAP_VIEW) ? LocationsIconPurple : LocationsIconGray}
                           height={25}
                           alt={'locations'}
                        />
                     </Link>
                     <Link
                        to={RoutesEnum.RESERVE_MEETING_ROOM}
                        className={isActive(RoutesEnum.RESERVE_MEETING_ROOM) ? 'active' : ''}
                     >
                        <img
                           src={isActive(RoutesEnum.RESERVE_MEETING_ROOM) ? CalendarIconPurple : CalendarIconGray}
                           height={25}
                           alt={'reserve a meeting room'}
                        />
                     </Link>

                     {!firebaseAuthLoaded
                        ? null
                        : isLoggedIn && (
                             <Link
                                to={'#'}
                                style={{ textDecoration: 'none' }}
                                className={'header-settings-link'}
                                onClick={goToSettingsPage}
                             >
                                <img
                                   src={isActive(RoutesEnum.SETTINGS) ? SettingsIconPurple : SettingsIconGray}
                                   height={25}
                                   alt={'settings'}
                                   style={{ marginLeft: 4 }}
                                />
                             </Link>
                          )}
                  </div>
               </SubHeaderWrapperMobile>
            </>
         )}
      </>
   );
};

export default AppLayoutSubHeader;
