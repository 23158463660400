import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Spinner, StyledText } from './index';
import { StyledTextVariantsType } from './StyledText';
import theme from 'src/styles/theme';
import { ArrowDown, ArrowDownWhite, CloseIcon } from 'src/assets/images';

export enum ButtonVariantsType {
   PURPLE = 'purple',
   PURPLE_BORDER = 'purpleBorder',
   GREEN = 'green',
   WHITE = 'white',
   SECONDARYWHITE = 'secondaryWhite',
   RED_BORDER = 'redBorder',
   GRAY = 'gray',
   TRANSPARENT = 'transparent',
}

type ButtonType = 'button' | 'submit' | 'reset';

interface IProps {
   text?: string;
   textColor?: string;
   onClick?: (event: any) => void;
   variant: ButtonVariantsType;
   disabled?: boolean;
   width?: string;
   minWidth?: boolean;
   mobileWidth?: string;
   paddingMobile?: string;
   margin?: string;
   marginMobile?: string;
   zIndex?: number | string;
   transparent?: boolean;
   type?: ButtonType;
   classNames?: string;
   icon?: string;
   children?: ReactElement;
   hasChildren?: boolean;
   isLoading?: boolean;
   isFilterButton?: boolean;
   isClearButton?: boolean;
   reduceHeight?: boolean;
}

const styles = {
   purple: {
      backgroundColor: theme.colors.mainPurple,
      border: 'none',
      color: theme.colors.white,
   },
   purpleBorder: {
      backgroundColor: theme.colors.white,
      border: ` 1px solid  ${theme.colors.mainPurple}`,
      color: theme.colors.mainPurple,
   },
   green: {
      backgroundColor: theme.colors.green,
      border: 'none',
      color: theme.colors.white,
   },
   white: {
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray}`,
      color: theme.colors.black,
   },
   secondaryWhite: {
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray}`,
      color: theme.colors.gray,
   },
   redBorder: {
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.red}`,
      color: theme.colors.red,
   },
   gray: {
      backgroundColor: '#ABABAB',
      border: 'none',
      color: theme.colors.white,
   },
   transparent: {
      backgroundColor: 'transparent',
      border: 'none',
      color: theme.colors.mainPurple,
   },
};

const ButtonWrapper = styled.button<Omit<IProps, 'children'>>`
   background-color: ${(props: IProps) => styles[props.variant].backgroundColor};
   border: ${(props: IProps) => styles[props.variant].border};
   width: ${({ width }: IProps) => width};
   height: ${({ hasChildren, reduceHeight }: IProps) => (hasChildren ? 'auto' : reduceHeight ? '35px' : '54px')};
   margin: ${({ margin }: IProps) => margin};
   z-index: ${({ zIndex }: IProps) => zIndex};
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 15px;
   white-space: nowrap;
   overflow: hidden;
   min-width: ${({ minWidth }: IProps) => (minWidth ? '150px' : '')};
   cursor: ${({ disabled, isLoading }: IProps) => (disabled || isLoading ? 'not-allowed' : 'pointer')};
   padding: ${({ hasChildren }: IProps) => (hasChildren ? '20px' : '0 20px')};
   opacity: ${({ disabled, isLoading }: IProps) => (disabled || isLoading ? 0.5 : 1)};
   position: relative;

   :focus {
      outline: none;
   }

   div {
      cursor: ${({ disabled, isLoading }: IProps) => (disabled || isLoading ? 'not-allowed' : 'pointer')};
   }

   .MuiCircularProgress-root {
      height: 25px !important;
      width: 25px !important;
      color: ${({ variant }: IProps) =>
         variant === ButtonVariantsType.WHITE || variant === ButtonVariantsType.RED_BORDER
            ? 'black'
            : 'white'} !important;
   }

   @media only screen and (max-width: 768px) {
      width: ${(props) => (props.mobileWidth ? `${props.mobileWidth} !important` : 'initial')};
      padding: ${(props) => (props.paddingMobile ? `${props.paddingMobile} !important` : 'initial')};
      margin: ${(props) => (props.marginMobile ? `${props.marginMobile} !important` : 'initial')};
   }
`;

const Button = (props: IProps) => {
   return (
      <ButtonWrapper
         {...props}
         hasChildren={!!props.children}
         onClick={!props.disabled && !props.isLoading && props.onClick ? props.onClick : undefined}
         className={props.classNames}
         isLoading={props.isLoading}
         variant={props.variant}
      >
         {props.isLoading && <Spinner height={'25px'} width={'25px'} />}
         {!props.isLoading && (
            <>
               {props.children || (
                  <>
                     <StyledText
                        width={'auto'}
                        justifyContent={'center'}
                        text={props.text}
                        styles={{ whiteSpace: 'pre-line' }}
                        variant={StyledTextVariantsType.BUTTON_TEXT}
                        colorVariant={styles[props.variant].color}
                     />
                     {props.isFilterButton && <ArrowDown height={8} style={{ marginLeft: 10 }}></ArrowDown>}
                     {props.isClearButton && (
                        <img src={CloseIcon} alt="Close" width={20} height={20} style={{ marginLeft: 10 }} />
                     )}
                  </>
               )}
               {props.icon && <img src={props.icon as any} style={{ marginLeft: 10, height: 24 }} alt="button-icon" />}
            </>
         )}
      </ButtonWrapper>
   );
};

Button.defaultProps = {
   isLoading: false,
   type: 'button',
   text: 'button',
   classNames: '',
   variant: ButtonVariantsType.PURPLE,
   disabled: false,
   margin: 'unset',
   width: 'fit-content',
   onClick: undefined,
} as Partial<IProps>;

export default Button;
