import React, { ReactElement } from 'react';

import { Button, Flex, ModalComponent, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { translate } from 'src/translations/translations';

interface IProps {
   isOpen: boolean;
   infoText: string;
   setShowModal: (value: boolean) => void;
}

const InfoModal = ({ infoText, setShowModal, isOpen }: IProps): ReactElement => {
   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'314px'}
         closeModal={() => {
            setShowModal(false);
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <StyledText
               width={'100%'}
               text={infoText}
               variant={StyledTextVariantsType.HEADING_3}
               margin={'0 0 18px 0'}
               justifyContent={'center'}
            />
            <Button
               variant={ButtonVariantsType.PURPLE}
               text={translate('common.dismiss')}
               width={'100%'}
               margin={'32px 0 0 0'}
               onClick={() => {
                  setShowModal(false);
               }}
            />
         </Flex>
      </ModalComponent>
   );
};

export default InfoModal;
