import React, { ReactElement, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Flex, Input, ModalComponent, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { RootState } from 'src/app/rootReducer';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import { showLoginModal, showResetPasswordModal } from 'src/system/state/actions';
import NotificationsProvider from 'src/utilities/notifications-provider';

const ResetPasswordModal = (): ReactElement => {
   const firebase = getFirebase();
   const dispatch = useDispatch();
   const isOpen = useSelector((state: RootState) => state.systemReducer).showResetPasswordModal;

   const [loading, setLoading] = useState(false);
   const [email, setEmail] = useState('');

   const [formFieldsValidity, setFormFieldsValidity] = useState({
      emailValid: false,
   });

   const resetVaules = () => {
      setEmail('');
      showResetPasswordModal(false);
   };

   async function resetPassword() {
      try {
         setLoading(true);
         await firebase.auth().sendPasswordResetEmail(email);
         dispatch(showResetPasswordModal(false));
         dispatch(showLoginModal(true));
         NotificationsProvider.success(translate('common.success.resetPassword'));
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('common.error.resetPassword'));
      } finally {
         setLoading(false);
      }
   }

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'414px'}
         closeModal={() => {
            resetVaules();
            dispatch(showResetPasswordModal(false));
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'} width={'100%'}>
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('resetPasswordModal.resetPassword.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />

            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('resetPasswordModal.resetPassword.text')}
               variant={StyledTextVariantsType.PARAGRAPH_2}
               margin={'0 0 48px 0'}
            />

            <Input
               width={'100%'}
               type={'text'}
               margin={'0 0 72px 0'}
               placeholder={translate('placeholders.email')}
               onChange={(value) => setEmail(value)}
               validators={['required', 'isEmail']}
               value={email}
               validate={(emailValid) => setFormFieldsValidity({ ...formFieldsValidity, emailValid })}
            />

            <Button
               disabled={!formFieldsValidity.emailValid}
               isLoading={loading}
               variant={ButtonVariantsType.PURPLE}
               text={translate('resetPasswordModal.resetPassword.button.text')}
               width={'100%'}
               onClick={resetPassword}
            />
         </Flex>
      </ModalComponent>
   );
};

export default ResetPasswordModal;
