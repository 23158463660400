import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { Flex, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import {
   SettingsItemArrow,
   SettingsItemContent,
   SettingsItemIcon,
   SettingsItemTitle,
   SettingsItem,
} from 'src/features/settings/shared/SharedComponents';

import { ActiveItemEnum } from './EnterpriseSettings';
import { UserEditIcon, ChevronRightElement, LogoutIcon } from 'src/assets/images';
import { signOutFromFirebase } from 'src/features/auth/authSlice';
import { logout } from 'src/system/state/actions';
import { translate } from 'src/translations/translations';
import theme from 'src/styles/theme';
import { useHistory } from 'react-router';
import { RoutesEnum } from 'src/routes/routes';
import { ICompany } from '../interfaces';
import { RootState } from 'src/app/rootReducer';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { parseError } from 'src/utilities/helpers';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';

interface IProps {
   setActiveItem: (item: ActiveItemEnum) => void;
   isItemActive: (itemToCheck: ActiveItemEnum) => boolean;
}

const EnterpriseSettingsRoot = ({ setActiveItem, isItemActive }: IProps): ReactElement => {
   const history = useHistory();
   const dispatch = useDispatch();
   const { boot, shutdown } = useIntercom();

   const [company, setCompany] = useState<ICompany | null>(null);
   const [loading, setLoading] = useState(true);
   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;

   const getCompanyDetails = async () => {
      try {
         setLoading(true);
         const response = await ApiRequests.getCompanyDetails(enterpriseId);
         setCompany(response.data?.company);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.getCompanyDetails'));
      } finally {
         setLoading(false);
      }
   };
   useEffect(() => {
      void getCompanyDetails();
      // eslint-disable-next-line
   }, []);

   const onLogoutClick = async () => {
      shutdown();
      boot();
      await signOutFromFirebase();
      dispatch(logout());
   };

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         width={'100%'}
         height={'100%'}
         position={'relative'}
      >
         <StyledText
            width={'100%'}
            text={translate('hrManagement.settings.itemsList.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 32px 0'}
         />

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
            borderRight={`1px solid ${theme.colors.lightGray}`}
         >
            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.MANAGE_YOUR_TEAM) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.MANAGE_YOUR_TEAM);
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_SETTINGS_MANAGE_TEAM,
                     state: {
                        company: company,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.settings.manageYourTeam.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.settings.manageYourTeam.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.COMPANY_DETAILS) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.COMPANY_DETAILS);
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_SETTINGS_COMPANY_DETAILS,
                     state: {
                        company: company,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.settings.companyDetails.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.settings.companyDetails.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.BILLING_DETAILS) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.BILLING_DETAILS);
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_SETTINGS_BILLING,
                     state: {
                        company: company,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.settings.billingDetails.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.settings.billingDetails.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${isItemActive(ActiveItemEnum.COMPANY_MANAGERS) ? 'active' : ''}`}
               onClick={() => {
                  setActiveItem(ActiveItemEnum.COMPANY_MANAGERS);
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_SETTINGS_MANAGERS,
                     state: {
                        company: company,
                     },
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.settings.companyManagers.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('hrManagement.settings.companyManagers.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem onClick={onLogoutClick}>
               <SettingsItemIcon>
                  <LogoutIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.logout.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settingsList.logout.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
         </Flex>
      </Flex>
   );
};

export default EnterpriseSettingsRoot;
