import React from 'react';

import { NoDataPlaceholderComponent } from '../index';
import { TableColumn, TableItemAction } from './interfaces';
import TableBody from './TableBody';
import TableHeader from './TableHeader';

export interface TableProps<T> {
   data: Array<T>;
   customClass: string;
   itemActions?: Array<TableItemAction<T>>;
   columns: Array<TableColumn<T>>;
   actionsColumnName?: string;
   actionsSelectable?: boolean;
   selectedRows?: Array<number>;
   onSelectAll?: (val: any) => void;
}

const Table = <T,>({
   data,
   itemActions,
   columns,
   actionsColumnName,
   actionsSelectable,
   customClass,
   selectedRows,
   onSelectAll,
}: TableProps<T>): React.ReactElement => {
   return (
      <div className={`table-responsive overflow-y-visible ${customClass}`}>
         {!data || data.length === 0 ? (
            <NoDataPlaceholderComponent />
         ) : (
            <table className="table table-borderless">
               <TableHeader
                  onSelectAll={onSelectAll}
                  actionsSelectable={actionsSelectable}
                  allSelected={selectedRows?.length === data?.length}
                  columns={columns}
                  actionsColumnName={actionsColumnName}
                  itemActions={itemActions}
                  stickyHeader
                  renderActions
               />
               <TableBody selectedRows={selectedRows} data={data} columns={columns} itemActions={itemActions} />
            </table>
         )}
      </div>
   );
};

export default Table;

Table.defaultProps = {
   customClass: '',
};
