import React, { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RootState } from 'src/app/rootReducer';
import { PurpleArrowLeft } from 'src/assets/images';

import { Button, CountryDropdown, Flex, Input, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { StyledTextVariantsType } from 'src/components/StyledText';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';

import { ICompany } from 'src/features/enterprise_management/interfaces';
import { RoutesEnum } from 'src/routes/routes';
import { translate } from 'src/translations/translations';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';

const CompanyDetails = (): ReactElement => {
   const history = useHistory();
   const location = useLocation();
   const [company, setCompany] = useState<ICompany>(location.state.company);
   const [loading, setLoading] = useState(true);
   const [updateLoading, setUpdateLoading] = useState(false);

   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;

   const wide = useWindowWide(768);

   const goBack = () => {
      history.goBack();
   };

   const isFormValid = () => {
      return company.company_name && company.domain;
   };
   const getCompanyDetails = async (triggerLoader?: boolean) => {
      try {
         triggerLoader && setLoading(true);
         const response = await ApiRequests.getCompanyDetails(enterpriseId);
         setCompany(response.data?.company);
         history.push(RoutesEnum.ENTERPRISE_SETTINGS);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.getCompanyDetails'));
      } finally {
         setLoading(false);
      }
   };

   const updateCompanyDetails = async () => {
      setUpdateLoading(true);

      if (company && company.enterprise_id) {
         try {
            const response: any = await ApiRequests.updateCompany({
               payload: {
                  enterprise_id: company.enterprise_id,
                  company_name: company.company_name,
                  address: company.address.name,
                  city: company.address.city,
                  state: company.address.state,
                  zip_code: company.address.zip_code,
                  country: company.address.country,
                  domain: company.domain,
                  number_of_employees: company.number_of_employees,
                  vat: company.vat,
               },
            });

            if (response.success) {
               NotificationsProvider.success(response.data.message);
               await getCompanyDetails();
            } else {
               NotificationsProvider.error(translate('hrManagement.settings.error.updateCompany'));
            }
         } catch (error) {
            NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.updateCompany'));
         } finally {
            setUpdateLoading(false);
         }
      } else {
         NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }
   };

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('hrManagement.settings.companyDetails.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('hrManagement.settings.companyDetails.title')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}

         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 24px 0'}
            placeholder={translate('hrManagement.settings.placeholders.companyName')}
            onChange={(value) => setCompany({ ...company, company_name: value })}
            validators={['required']}
            value={company.company_name}
         />

         {company.address && (
            <Input
               width={'100%'}
               type={'text'}
               margin={'0 0 24px 0'}
               placeholder={translate('hrManagement.settings.placeholders.companyAddress')}
               onChange={(value) => setCompany({ ...company, address: { ...company.address, name: value } })}
               value={company.address.name}
            />
         )}

         {company.address && (
            <Input
               width={'100%'}
               type={'text'}
               margin={'0 0 24px 0'}
               placeholder={translate('hrManagement.settings.placeholders.companyCity')}
               onChange={(value) => setCompany({ ...company, address: { ...company.address, city: value } })}
               value={company.address.city}
            />
         )}

         {company.address && (
            <Input
               width={'100%'}
               type={'text'}
               margin={'0 0 24px 0'}
               placeholder={translate('hrManagement.settings.placeholders.companyState')}
               onChange={(value) => setCompany({ ...company, address: { ...company.address, state: value } })}
               value={company.address.state}
            />
         )}

         {company.address && (
            <Input
               width={'100%'}
               type={'text'}
               margin={'0 0 24px 0'}
               placeholder={translate('hrManagement.settings.placeholders.companyZipCode')}
               onChange={(value) => setCompany({ ...company, address: { ...company.address, zip_code: value } })}
               value={company.address.zip_code}
            />
         )}

         {company.address && (
            <CountryDropdown
               width={'100%'}
               margin={'0 0 24px 0'}
               setValue={(value) => setCompany({ ...company, address: { ...company.address, country: value } })}
               value={company.address.country}
            />
         )}

         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 24px 0'}
            placeholder={translate('hrManagement.settings.placeholders.companyVatNumber')}
            onChange={(value) => setCompany({ ...company, vat: value })}
            value={company.vat}
         />

         <Input
            width={'100%'}
            type={'number'}
            margin={'0 0 24px 0'}
            placeholder={translate('hrManagement.settings.placeholders.companyEmployeesNumber')}
            onChange={(value) => setCompany({ ...company, number_of_employees: Number(value) })}
            value={company?.number_of_employees}
         />

         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 72px 0'}
            placeholder={translate('hrManagement.settings.placeholders.companyDomain')}
            onChange={(value) => setCompany({ ...company, domain: value })}
            value={company?.domain}
         />

         <Button
            disabled={!isFormValid()}
            isLoading={updateLoading}
            variant={ButtonVariantsType.PURPLE}
            text={translate('common.saveDetails')}
            width={'100%'}
            onClick={updateCompanyDetails}
         />
      </Flex>
   );
};

export default CompanyDetails;
