import React, { ReactElement, useState } from 'react';

import { Flex } from 'src/components';
import EnterpriseBilling, {
   BillingSettingsItemEnum,
} from 'src/features/enterprise_management/settings/billing/EnterpriseBilling';
import EnterpriseBillingRoot from 'src/features/enterprise_management/settings/billing/EnterpriseBillingRoot';
import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';

import { ICompany } from '../../interfaces';
import { useSelector } from 'react-redux';
import { RootState } from 'src/app/rootReducer';
import { useLocation } from 'react-router';

const BillingDetails = (): ReactElement => {
   const location = useLocation();
   const [loading, setLoading] = useState(true);
   const [company, setCompany] = useState<ICompany>(location.state.company);
   const [activeBillingSettingsItem, setActiveBillingSettingsItem] = useState(BillingSettingsItemEnum.EMPTY);

   const enterpriseId = useSelector((state: RootState) => state.systemReducer).enterpriseId;

   const getCompanyDetails = async (triggerLoader?: boolean) => {
      try {
         triggerLoader && setLoading(true);
         const response = await ApiRequests.getCompanyDetails(enterpriseId);
         setCompany(response.data?.company);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.getCompanyDetails'));
      } finally {
         setLoading(false);
      }
   };

   const isSettingItemSelected = (selectedItem: BillingSettingsItemEnum, itemToCheck: BillingSettingsItemEnum) => {
      return selectedItem === itemToCheck;
   };

   return (
      <>
         <Flex
            width={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
            margin={'0 0 0 40px'}
         >
            <EnterpriseBillingRoot
               company={company}
               getCompanyDetails={getCompanyDetails}
               setActiveBillingSettingsItem={setActiveBillingSettingsItem}
               activeBillingSettingsItem={activeBillingSettingsItem}
               isSettingItemSelected={isSettingItemSelected}
            />
         </Flex>

         <Flex
            width={'32%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            overflow={'hidden'}
            margin={'0 0 0 32px'}
         >
            <EnterpriseBilling
               company={company}
               getCompanyDetails={getCompanyDetails}
               activeBillingSettingsItem={activeBillingSettingsItem}
            />
         </Flex>
      </>
   );
};

export default BillingDetails;
