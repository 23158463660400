import React, { ReactElement } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
   responsive: true,
   scales: {
      x: {
         display: true,
         ticks: {
            color: '#A2A2AC',
         },
         grid: {
            drawBorder: false,
            display: false,
         },
      },
      y: {
         beginAtZero: true,
         display: true,
         grid: {
            display: true,
         },
      },
   },
   plugins: {
      legend: {
         display: false,
      },
      title: {
         display: false,
      },
   },
};

const getLabels = (statistics: any) => {
   // eslint-disable-next-line
   let labels: any[] = [];

   Object.keys(statistics).forEach((key: any) => {
      labels.push(key.substring(0, 2));
   });

   return labels;
};

const getData = (statistics: any) => {
   // eslint-disable-next-line
   let dataArray: any[] = [];

   Object.keys(statistics).forEach((key: any) => {
      dataArray.push(statistics[key]);
   });

   return dataArray;
};

const getDataObject = (statistics: any) => {
   return {
      labels: getLabels(statistics),
      datasets: [
         {
            label: '',
            data: getData(statistics),
            backgroundColor: 'rgba(141, 121, 246, 1)',
            barThickness: 15,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: 'rgb(255, 255, 255)',
         },
      ],
   };
};

interface IProps {
   data: any;
}

const VerticalBarChart = ({ data }: IProps): ReactElement => {
   return <Bar options={options} data={getDataObject(data)} height={200} />;
};

export default VerticalBarChart;
