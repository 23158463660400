import React, { ReactElement, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import styled from 'styled-components';

const Wrapper = styled.div<any>`
   display: flex;
   width: ${(props) => props.width || 'auto'};
   height: 54px;
   align-items: center;
   justify-content: center;
   margin: ${(props) => props.margin || '0'};
   background-color: #fff;

   > div {
      width: 100%;
      background-color: #ffffff;

      .css-1pahdxg-control:hover {
         border: 1px solid #ababab;
      }

      > div {
         width: 100%;
         background-color: #ffffff;
         border: 1px solid #ababab;
         outline: none;
         box-shadow: none;
         border-radius: 15px;

         .css-g1d714-ValueContainer {
            width: 100%;
            height: 54px;
            font-size: 16px;
            font-weight: 400;
            padding: 0 20px;
         }
      }

      div[class*='MenuList'] {
         > div {
            width: 98%;
            margin: 0 auto;
         }
      }
   }

   @media only screen and (max-width: 768px) {
      margin-bottom: 12px !important;
      width: ${(props) => props.width || 'auto'};
   }
`;

interface IProps {
   margin?: string;
   width?: string;

   value: any;
   setValue: (value: string) => void;
}

const CountryDropdownInput = ({ value, margin, width, setValue }: IProps): ReactElement => {
   const options = useMemo(() => countryList().getData(), []);

   const defaultCountry = {
      label: 'United States',
      value: 'US',
   };

   const handleSelect = (valueObject: any) => {
      setValue(valueObject.label as string);
   };

   const getSelectedValue = (countryName: string) => {
      return {
         label: countryName,
         value: countryList().getValue(countryName),
      };
   };

   return (
      <Wrapper margin={margin} width={width}>
         <Select options={options} value={value ? getSelectedValue(value) : defaultCountry} onChange={handleSelect} />
      </Wrapper>
   );
};

export default CountryDropdownInput;
