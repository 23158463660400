import React, { ReactElement } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Flex, ModalComponent, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import AddCreditCard from './AddCreditCard';

import { translate } from 'src/translations/translations';
import { config } from 'src/app/config';

const stripePromise = loadStripe(config.STRIPE_KEY as string);

interface IProps {
   isOpen: boolean;
   setShowModal: (value: boolean) => void;
   getPaymentMethods: () => void;
}

const AddCreditCardModal = ({ isOpen, setShowModal, getPaymentMethods }: IProps): ReactElement => {
   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'500px'}
         closeModal={() => {
            setShowModal(false);
         }}
      >
         <Flex flexDirection="column" alignItems="center" height="100%" backgroundColor={'white'}>
            <StyledText
               text={translate('addCreditCard.title')}
               justifyContent={'center'}
               margin={'0 0 48px 0'}
               width={'100%'}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <Elements stripe={stripePromise}>
               <AddCreditCard getPaymentMethods={getPaymentMethods} setShowModal={setShowModal} />
            </Elements>
         </Flex>
      </ModalComponent>
   );
};

export default AddCreditCardModal;
