import React, { useEffect, useState, ReactElement } from 'react';
import { GoogleAPI, Map, Marker } from 'google-maps-react';
import styled from 'styled-components';
import { Wrapper } from '@googlemaps/react-wrapper';

import { InfoWindowWithEventHandlers as InfoWindow } from 'src/components/index';

import { MapMarker, PurpleArrowRight } from 'src/assets/images';
import theme from 'src/styles/theme';
import { config } from 'src/app/config';

import mapStyle from './google_map_style.json';

const MapWrapper = styled.div<any>`
   margin-bottom: ${(props) => props.marginBottom || 0};
   @media only screen and (max-width: 768px) {
      > div {
         width: 90% !important;
      }
   }
`;

const InfoWindowContent = styled.div`
   padding: 10px 20px;
   display: flex;
   align-items: center;
   background-color: ${theme.colors.white};
   border-radius: 15px;
   z-index: 1;
`;

const InfoWindowContentText = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   h2 {
      margin: 0 0 10px 0;
      font-weight: 500;
      font-size: 16px;
   }
   p {
      margin: 0;
      font-weight: 300;
      font-size: 14px;
      color: ${theme.colors.gray};
   }
`;

const InfoWindowContentAction = styled.div<any>`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 32px;
   z-index: 2;
`;

interface IProps {
   google: GoogleAPI;
   markers: any[];
   height: string;
   width?: string;
   zoom?: number;
   currentLocation?: any;
   infoWindowActionHandler?: (id: string) => void;
}

function GoogleMap({
   google,
   markers,
   height,
   width,
   zoom,
   currentLocation,
   infoWindowActionHandler,
}: IProps): ReactElement {
   const centered = currentLocation
      ? { lat: currentLocation.latitude, lng: currentLocation.longitude }
      : markers && markers.length > 0 && markers[0]?.location
      ? {
           lat: Number(markers[0]?.location?.lattitude),
           lng: Number(markers[0]?.location?.longitude),
        }
      : { lat: 43.8563, lng: 18.4131 };

   const [initialCenter, setInitialCenter] = useState<any>(centered);
   const [activeMarker, setActiveMarker] = useState<any>();
   const [selectedPlace, setSelectedPlace] = useState<any>();
   const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

   const onMarkerClick = (markerData: any, props: any, marker: any) => {
      setActiveMarker(marker);
      setSelectedPlace({ ...props, ...markerData });
      setIsInfoWindowOpen(true);
   };

   const onInfoWindowClose = () => {
      setActiveMarker(undefined);
      setIsInfoWindowOpen(false);
   };

   const onMapClicked = () => {
      if (isInfoWindowOpen) {
         setActiveMarker(undefined);
         setIsInfoWindowOpen(false);
      }
   };

   const _mapLoaded = (mapProps: any, map: any) => {
      map.setOptions({
         styles: mapStyle,
      });
   };

   useEffect(() => {
      if (currentLocation) {
         setInitialCenter({ lat: currentLocation.latitude, lng: currentLocation.longitude });
      }
   }, [currentLocation]);

   return (
      <Wrapper apiKey={config.GOOGLE_MAPS_API_KEY as string}>
         <MapWrapper marginBottom={height}>
            <Map
               google={google}
               // @ts-ignore
               zoom={zoom || 15}
               zoomControl={true}
               mapTypeControl={false}
               scaleControl={false}
               streetViewControl={false}
               rotateControl={false}
               fullscreenControl={false}
               onClick={onMapClicked}
               showsUserLocation={true}
               initialCenter={initialCenter}
               resetBoundsOnResize={true}
               containerStyle={{
                  height: height,
                  width: width || '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
               }}
               style={{ borderRadius: 15, marginBottom: 32 }}
               onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
            >
               {markers &&
                  markers.length &&
                  markers[0]?.location &&
                  markers.map((m: any, index: number) => {
                     return (
                        <Marker
                           key={index}
                           // @ts-ignore
                           position={{ lat: Number(m.location?.lattitude), lng: Number(m.location?.longitude) }}
                           icon={{
                              url: MapMarker,
                              scaledSize: new google.maps.Size(28, 28),
                           }}
                           defaultAnimation={1}
                           onClick={(props, marker) => {
                              onMarkerClick(m, props, marker);
                           }}
                        />
                     );
                  })}
               <InfoWindow
                  google={google}
                  map={google.map}
                  marker={activeMarker}
                  // @ts-ignore
                  onClose={onInfoWindowClose}
                  visible={isInfoWindowOpen}
               >
                  <InfoWindowContent>
                     <InfoWindowContentText>
                        {selectedPlace?.name && <h2>{selectedPlace.name}</h2>}
                        {selectedPlace?.location.city && selectedPlace?.location.country && (
                           <p>{`${selectedPlace.location.city}, ${selectedPlace.location.country}`}</p>
                        )}
                     </InfoWindowContentText>
                     {infoWindowActionHandler && (
                        <InfoWindowContentAction
                           onClick={(e: any) => {
                              e.preventDefault();
                              infoWindowActionHandler(selectedPlace?.uid);
                           }}
                        >
                           <img src={PurpleArrowRight} style={{ height: 24, cursor: 'pointer' }} alt="navigate" />
                        </InfoWindowContentAction>
                     )}
                  </InfoWindowContent>
               </InfoWindow>
            </Map>
         </MapWrapper>
      </Wrapper>
   );
}

export default GoogleMap;
