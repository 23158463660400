import React, { ReactElement, useState, useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Flex, Spinner, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { SpacePlaceholder } from 'src/assets/images';
import ApiRequests from 'src/features/space_management/data/ApiRequests';
import { ISpace } from 'src/features/space_management/interfaces';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { replaceLocation, RoutesEnum } from '../../../routes/routes';

const CarouselImg = styled.img<any>`
   width: 100%;
   height: 200px;
   object-fit: cover;
   border-radius: 10px;

   svg > path {
      stroke: ${theme.colors.mainPurple};
   }
`;

const YourSpaces = (): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;
   const history = useHistory();

   const [loading, setLoading] = useState(true);
   const [spaces, setSpaces] = useState<ISpace[]>([]);

   const getAllSpaces = async () => {
      try {
         const response = await ApiRequests.getAllSpaces(currentUser?.uid as string);
         if (response && response.data) {
            setSpaces(response.data);
            console.log(response);
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpaces'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.getSpaces'));
      } finally {
         setLoading(false);
      }
   };

   const onSpaceClick = (id: number, name: string) => {
      const nameLowercased = name.replaceAll(' ', '-').toLocaleLowerCase();
      history.push(
         replaceLocation(RoutesEnum.SPACE_MANAGEMENT_SPACE_DETAILS, {
            id,
            name: nameLowercased,
         }),
      );
   };

   const renderSpaces = (spaces: any) => {
      if (!spaces) {
         return null;
      }
      return (
         <div className={'d-flex w-100 flex-column flex-md-row align-items-start justify-content-start flex-wrap'}>
            {spaces.map((space: any, index: number) => (
               <div
                  key={index}
                  onClick={() => {
                     onSpaceClick(space.uid, space.name);
                  }}
                  className={
                     'd-flex col col-12 col-md-4 flex-column align-items-start justify-content-start px-0 px-md-3 mb-4 cursor-pointer'
                  }
               >
                  <CarouselImg
                     src={
                        space?.images?.gallery && space?.images?.gallery[0]
                           ? space?.images?.gallery[0]
                           : SpacePlaceholder
                     }
                     alt={space?.name}
                  />

                  <StyledText
                     width={'100%'}
                     text={space?.name}
                     variant={StyledTextVariantsType.LABEL_TEXT}
                     margin={'16px 0 4px 0'}
                  />

                  <StyledText
                     variant={StyledTextVariantsType.NOTE_TEXT}
                     text={`${space?.location?.city}, ${space?.location?.country}`}
                     colorVariant={theme.colors.gray}
                  />
               </div>
            ))}
         </div>
      );
   };

   useEffect(() => {
      if (currentUser) {
         void getAllSpaces();
      } else {
         setLoading(false);
      }
      //eslint-disable-next-line
   }, [currentUser]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         flexDirection="column"
         alignItems={'flex-start'}
         justifyContent={'flex-start'}
         paddingMobile={'0%'}
         width={'100%'}
         padding={'0 10% 3% 10%'}
      >
         {renderSpaces(spaces)}
      </Flex>
   );
};

export default YourSpaces;
