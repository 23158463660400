import React, { ReactElement, useState, useEffect } from 'react';
import moment from 'moment';

import { Flex, Spinner, StyledText, Table } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn } from 'src/components/table/interfaces';

import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { capitalize, parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';

interface IProps {
   userId: string;
}

const UserCheckins = ({ userId }: IProps): ReactElement => {
   const [loading, setLoading] = useState(true);
   const [checkins, setCheckins] = useState<any>([]);

   const getUserCheckins = async () => {
      try {
         const response = await ApiRequests.getTeamMemberCheckins(userId);
         if (response.data) {
            setCheckins(response.data);
         }
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('hrManagement.settings.error.getTeamMemberCheckins'),
         );
      } finally {
         setLoading(false);
      }
   };

   const columns: Array<TableColumn<any>> = [
      {
         title: 'Space',
         key: 'space',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{data.space_name}</>,
      },
      {
         title: 'Checkin ID',
         key: 'checkin_id',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{data.checkin_id}</>,
      },
      {
         title: 'Checkin type',
         key: 'checkin_type',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{capitalize(data.checkin_type)}</>,
      },
      {
         title: 'Checkin date',
         key: 'checkin_date',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (data: any) => <>{moment(data.checkin_date).format('DD/MM/YYYY HH:mm')}</>,
      },
   ];

   useEffect(() => {
      void getUserCheckins();
      // eslint-disable-next-line
   }, []);

   return (
      <Flex
         width={'68%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('hrManagement.settings.userProfile.userCheckins.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 32px 0'}
         />

         {loading ? (
            <Spinner height={'400px'} />
         ) : (
            <Table onSelectAll={() => null} columns={columns} data={checkins} customClass={'w-100'} />
         )}
      </Flex>
   );
};

export default UserCheckins;
