import React, { ReactElement, useEffect, useState } from 'react';

import { Button, Flex, Input, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { getFirebase } from 'react-redux-firebase';
import styled from 'styled-components';
import theme from 'src/styles/theme';
import { translate } from 'src/translations/translations';

import {
   AddSquareIcon,
   Calendar,
   GroupIcon,
   LocationIcon,
   PencilIcon,
   RelaxedIcon,
   RequirementsIcon,
   ReserveMeetingRoomIllustration,
} from 'src/assets/images';
import {
   SettingsItem,
   SettingsItemArrow,
   SettingsItemContent,
   SettingsItemIcon,
   SettingsItemTitle,
} from 'src/features/settings/shared/SharedComponents';
import ApiRequests from 'src/features/settings/data/ApiRequests';
import MeetingRoomModal from 'src/components/MeetingRoomModal';
import DatePickerModal from 'src/components/DatePickerModal';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { ButtonVariantsType } from 'src/components/Button';
import { showLoginModal } from 'src/system/state/actions';
import { useDispatch } from 'react-redux';
import ReserveMeetingRoom from './ReserveMeetingRoom';

const FormWraper = styled.div`
   width: 100%;
   height: 100%;
   position: relative;
`;
const AddIconWrapper = styled.div`
   position: absolute;
   right: 50px;
`;

const ReserveMeetingRoomForm = (): ReactElement => {
   const firebase = getFirebase();
   const dispatch = useDispatch();
   const currentUser = firebase.auth()?.currentUser;
   let dateValue = '';

   const today = new Date();
   const date = today.setDate(today.getDate());
   const defaultValue = new Date(date).toISOString().split('T')[0];
   dateValue = defaultValue;

   const wide = useWindowWide(768);
   const [cities, setCities] = useState<any>([]);
   const [amenities, setAmenities] = useState<any>([]);
   const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
   const [isGroupSizeModalOpen, setIsGroupSizeModalOpen] = useState(false);
   const [isAmenitiesModalOpen, setIsAmenitiesModalOpen] = useState(false);
   const [isDateModalOpen, setIsDateModalOpen] = useState(false);
   const [requestSent, setRequestSent] = useState(false);
   const [additionalInfo, setAdditionalInfo] = useState('');
   const [filledGroupSize, setFilledGroupSize] = useState('');

   const [checkedData, setCheckedData] = useState<any>({
      location: null,
      dateObject: {},
      groupSize: filledGroupSize,
      amenitiesList: [],
      additonalInfo: additionalInfo,
   });

   const openCitiesModal = async () => {
      setIsLocationModalOpen(true);
      const cities = await ApiRequests.getCitiesList();
      setCities(cities.data);
   };

   const openAmenitiesModal = async () => {
      setIsAmenitiesModalOpen(true);
      const amenities = await ApiRequests.getRoomAmenitiesList();
      setAmenities(amenities.data);
   };
   const openDataModal = () => {
      setIsDateModalOpen(true);
   };

   useEffect(() => {
      const newCheckedObject = {
         location: null,
         dateObject: { startingDate: dateValue, timeFrom: '', timeTo: '', recurring: '', endingDate: dateValue },
         groupSize: '',
         amenitiesList: [],
         additionalInfo: '',
      };
      setCheckedData(newCheckedObject);
   }, []);

   const requestMeetingRoom = async () => {
      try {
         const response = await ApiRequests.requestMeetingRoom({
            payload: {
               uuid: currentUser?.uid,
               city: checkedData.location,
               start_date: checkedData.dateObject.startingDate,
               start_time: checkedData.dateObject.timeFrom,
               end_time: checkedData.dateObject.timeTo,
               end_date: checkedData.dateObject.endingDate,
               recurring: checkedData.dateObject.recurring,
               group_size: filledGroupSize,
               amenities: checkedData.amenitiesList,
               additional_info: additionalInfo,
            },
         });
         if (!(response as any).success) {
            return NotificationsProvider.error(response.data?.message || translate('reserveMeetingRoom.error'));
         }
         setRequestSent(!requestSent);
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('reserveMeetingRoom.error'));
      } finally {
         NotificationsProvider.success('Request successfully submitted! We will be in touch soon!');
      }
   };

   const setGroupSize = (event: any) => {
      if (event > 150) {
         NotificationsProvider.error(translate('reserveMeetingRoom.previous.groupsize.error.max'));
      } else if (event == 0) {
         NotificationsProvider.error(translate('reserveMeetingRoom.previous.groupsize.error.min'));
      } else {
         setFilledGroupSize(event);
      }
   };

   const isFormValid = () => {
      return (
         checkedData.location &&
         checkedData.dateObject.startingDate &&
         checkedData.dateObject.timeFrom &&
         checkedData.dateObject.timeTo &&
         checkedData.dateObject.recurring &&
         checkedData.dateObject.endingDate &&
         filledGroupSize
      );
   };

   const createAnotherRequest = () => {
      setRequestSent(!requestSent);
      checkedData.location = null;
      checkedData.dateObject.startingDate = dateValue;
      checkedData.dateObject.timeFrom = '';
      checkedData.dateObject.timeTo = '';
      checkedData.dateObject.recurring = '';
      checkedData.dateObject.endingDate = checkedData.dateObject.startingDate;
      setFilledGroupSize('');
      checkedData.groupSize = filledGroupSize;
      checkedData.amenitiesList = [];
      setAdditionalInfo('');
      checkedData.additionalInfo = additionalInfo;
   };

   const renderReserveMeetingRoomPlaceholder = () => (
      <>
         <MeetingRoomModal
            isOpen={isLocationModalOpen}
            setIsOpen={setIsLocationModalOpen}
            data={cities}
            checkedData={checkedData}
            setCheckedData={setCheckedData}
            isLocation={true}
            text={translate('reserveMeetingRoom.select.city')}
         ></MeetingRoomModal>
         <MeetingRoomModal
            isOpen={isAmenitiesModalOpen}
            setIsOpen={setIsAmenitiesModalOpen}
            data={amenities}
            checkedData={checkedData}
            setCheckedData={setCheckedData}
            isAmenitiesList={true}
            text={translate('reserveMeetingRoom.select.amenities')}
         ></MeetingRoomModal>
         <DatePickerModal
            isOpen={isDateModalOpen}
            setIsOpen={setIsDateModalOpen}
            checkedData={checkedData}
            setCheckedData={setCheckedData}
         ></DatePickerModal>
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('reserveMeetingRoom.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 38px 0'}
         />
         <Flex
            width={'100%'}
            justifyContent="flex-start"
            alignItems="stretch"
            flexDirectionMobile={'column'}
            flexWrap="nowrap"
            backgroundColor={`${theme.colors.white}`}
            borderRadius={'15px'}
            padding={'5% 0 5% 10%'}
            flex={1}
            position={'relative'}
            border={`1px solid ${theme.colors.lightGray}`}
         >
            <Flex
               width={'35%'}
               flexDirection="column"
               justifyContent="flex-start"
               backgroundColor={`${theme.colors.white}`}
               alignItems="flex-start"
               position={'relative'}
               overflow={'hidden'}
            >
               <img
                  src={ReserveMeetingRoomIllustration}
                  alt="add-company"
                  style={{
                     width: '95%',
                     height: 'auto',
                     marginTop: '80px',
                     display: wide ? 'flex' : 'none',
                  }}
               />
            </Flex>

            <FormWraper>
               <Flex
                  width={'100%'}
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  position={'relative'}
                  padding={'0 20px 0 0'}
               >
                  <SettingsItem
                     margin={'10px 0 0 0'}
                     padding={'20px 0 20px 20px'}
                     height="100%"
                     onClick={openCitiesModal}
                  >
                     <SettingsItemIcon>
                        <LocationIcon />
                     </SettingsItemIcon>
                     <SettingsItemContent>
                        {checkedData.location && (
                           <SettingsItemTitle>
                              <StyledText
                                 width={'100%'}
                                 text={translate('reserveMeetingRoom.where')}
                                 variant={StyledTextVariantsType.PARAGRAPH_3}
                                 colorVariant={theme.colors.gray}
                                 margin={'0 0 5px 0'}
                              />
                              <StyledText
                                 width={'100%'}
                                 text={checkedData.location}
                                 variant={StyledTextVariantsType.HEADING_3}
                              />
                           </SettingsItemTitle>
                        )}
                        {checkedData.location === null && (
                           <SettingsItemTitle>
                              <StyledText
                                 width={'100%'}
                                 text={translate('reserveMeetingRoom.where')}
                                 variant={StyledTextVariantsType.HEADING_3}
                                 colorVariant={theme.colors.gray}
                              />
                           </SettingsItemTitle>
                        )}
                     </SettingsItemContent>
                     <AddIconWrapper>
                        <AddSquareIcon />
                     </AddIconWrapper>
                  </SettingsItem>
                  <SettingsItem
                     margin={'10px 0 0 0'}
                     padding={'20px 0 20px 20px'}
                     height="100%"
                     onClick={openDataModal}
                  >
                     <SettingsItemIcon>
                        <Calendar />
                     </SettingsItemIcon>
                     <SettingsItemContent>
                        {checkedData.dateObject.startingDate &&
                           checkedData.dateObject.timeFrom &&
                           checkedData.dateObject.timeTo &&
                           checkedData.dateObject.recurring && (
                              <SettingsItemTitle>
                                 <StyledText
                                    width={'100%'}
                                    text={translate('reserveMeetingRoom.when')}
                                    variant={StyledTextVariantsType.PARAGRAPH_3}
                                    colorVariant={theme.colors.gray}
                                    margin={'0 0 5px 0'}
                                 />
                                 <StyledText
                                    width={'100%'}
                                    text={`${checkedData.dateObject.startingDate}, ${checkedData.dateObject.timeFrom}-${checkedData.dateObject.timeTo}, recurring ${checkedData.dateObject.recurring}`}
                                    variant={StyledTextVariantsType.HEADING_3}
                                 />
                              </SettingsItemTitle>
                           )}
                        {checkedData.dateObject.recurring === '' && (
                           <SettingsItemTitle>
                              <StyledText
                                 width={'100%'}
                                 text={translate('reserveMeetingRoom.when')}
                                 colorVariant={theme.colors.gray}
                                 variant={StyledTextVariantsType.HEADING_3}
                              />
                           </SettingsItemTitle>
                        )}
                     </SettingsItemContent>
                     <AddIconWrapper>
                        <AddSquareIcon />
                     </AddIconWrapper>
                  </SettingsItem>
                  <SettingsItem margin={'10px 0 0 0'} padding={'20px 0 20px 20px'} height="100%">
                     <SettingsItemIcon>
                        <GroupIcon />
                     </SettingsItemIcon>
                     <SettingsItemContent>
                        <SettingsItemTitle>
                           {filledGroupSize && (
                              <StyledText
                                 width={'100%'}
                                 text={translate('reserveMeetingRoom.groupsize')}
                                 variant={StyledTextVariantsType.PARAGRAPH_3}
                                 colorVariant={theme.colors.gray}
                              />
                           )}
                           <Input
                              height={'30px'}
                              width={'100%'}
                              type={'number'}
                              min={1}
                              max={150}
                              onChange={(event) => {
                                 setGroupSize(event);
                              }}
                              border={'1px solid transparent'}
                              transparent={true}
                              placeholder={'Group size'}
                              value={filledGroupSize}
                           />
                        </SettingsItemTitle>
                     </SettingsItemContent>
                     <AddIconWrapper>
                        <PencilIcon />
                     </AddIconWrapper>
                  </SettingsItem>
                  <SettingsItem
                     margin={'10px 0 0 0'}
                     padding={'20px 0 20px 20px'}
                     height="100%"
                     onClick={openAmenitiesModal}
                  >
                     <SettingsItemIcon>
                        <RelaxedIcon />
                     </SettingsItemIcon>
                     <SettingsItemContent>
                        {checkedData.amenitiesList.length > 0 && (
                           <SettingsItemTitle>
                              <StyledText
                                 width={'100%'}
                                 text={translate('reserveMeetingRoom.amenities')}
                                 variant={StyledTextVariantsType.PARAGRAPH_3}
                                 colorVariant={theme.colors.gray}
                                 margin={'0 0 5px 0'}
                              />
                              <StyledText
                                 width={'100%'}
                                 text={
                                    checkedData.amenitiesList.length > 1
                                       ? `${checkedData.amenitiesList[0]} + ${checkedData.amenitiesList.length - 1}`
                                       : checkedData.amenitiesList.length > 0
                                       ? `${checkedData.amenitiesList[0]} `
                                       : 'Vibes'
                                 }
                                 variant={StyledTextVariantsType.HEADING_3}
                              />
                           </SettingsItemTitle>
                        )}
                        {checkedData.amenitiesList.length === 0 && (
                           <StyledText
                              width={'100%'}
                              text={translate('reserveMeetingRoom.amenities')}
                              variant={StyledTextVariantsType.HEADING_3}
                              colorVariant={theme.colors.gray}
                           />
                        )}
                     </SettingsItemContent>
                     <AddIconWrapper>
                        <AddSquareIcon />
                     </AddIconWrapper>
                  </SettingsItem>
                  <SettingsItem margin={'10px 0 0 0'} padding={'20px 0 20px 20px'} height="100%">
                     <SettingsItemIcon>
                        <RequirementsIcon />
                     </SettingsItemIcon>
                     <SettingsItemContent>
                        <SettingsItemTitle>
                           {additionalInfo && (
                              <StyledText
                                 width={'100%'}
                                 text={translate('reserveMeetingRoom.requirements')}
                                 variant={StyledTextVariantsType.PARAGRAPH_3}
                                 colorVariant={theme.colors.gray}
                              />
                           )}
                           <Input
                              height={'35px'}
                              width={'100%'}
                              type={'text'}
                              onChange={(event) => {
                                 setAdditionalInfo(event);
                              }}
                              border={'1px solid transparent'}
                              transparent={true}
                              placeholder={'Additional requirements'}
                              value={additionalInfo}
                           />
                        </SettingsItemTitle>
                     </SettingsItemContent>
                     <AddIconWrapper>
                        <PencilIcon />
                     </AddIconWrapper>
                  </SettingsItem>
               </Flex>

               <Button
                  disabled={!isFormValid()}
                  text={translate('reserveMeetingRoom.request')}
                  width={'95%'}
                  margin={'20px 0 0 10px'}
                  onClick={requestMeetingRoom}
               ></Button>
            </FormWraper>
         </Flex>
      </>
   );

   if (requestSent) {
      return <ReserveMeetingRoom />;
   }
   return (
      <Flex
         flexDirection="column"
         alignItems={'flex-start'}
         justifyContent={'flex-start'}
         padding={'0 10% 3% 10%'}
         paddingMobile={'5%'}
      >
         {renderReserveMeetingRoomPlaceholder()}
      </Flex>
   );
};

export default ReserveMeetingRoomForm;
