import React, { ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFirebase } from 'react-redux-firebase';
import _ from 'underscore';
import styled from 'styled-components';

import { Button, Flex, Input, ModalComponent, NoDataPlaceholderComponent, Spinner, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { InlineElementsWrapper } from 'src/components/SharedComponents';

import { RootState } from 'src/app/rootReducer';
import { replaceLocation, RoutesEnum } from 'src/routes/routes';
import { translate } from 'src/translations/translations';
import { calculateLatLongDistanceInKm, getCurrentLocation, parseError, useWindowWide } from 'src/utilities/helpers';
import { updateCoworkingSpacesSearchTerm } from 'src/system/state/actions';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { FavoriteIconFill, FavoriteIconOutline, PurpleArrowLeft, SpacePlaceholder } from 'src/assets/images';
import theme from 'src/styles/theme';
import { ISpace } from '../space_management/interfaces';
import { ButtonVariantsType } from 'src/components/Button';

import ModalFilter from 'src/components/ModalFilter';
import ApiRequests from '../settings/data/ApiRequests';

const CarouselImg = styled.img`
   width: 100%;
   height: 200px;
   object-fit: cover;
   border-radius: 10px;
`;

const FavoritesIconWrapper = styled.div`
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   right: 0;
   bottom: 10px;
   min-height: 60px;
`;

const HashtagWrapper = styled.div`
   margin: 5px 0 0 0;
   white-space: nowrap;
   color: ${theme.colors.mainPurple};
   text-transform: uppercase;
   font-size: 12px;
   overflow: auto;
   width: 100%;
   ::-webkit-scrollbar {
      background-color: transparent;
      height: 2px;
   }
`;

const Spaces = (): ReactElement => {
   const firebase = getFirebase();
   const dispatch = useDispatch();
   const history = useHistory();
   const currentUser = firebase.auth()?.currentUser;
   const [isCitiesModalOpen, setIsCitiesModalOpen] = useState(false);
   const [isAmenitiesModalOpen, setIsAmenitiesModalOpen] = useState(false);
   const [isVibesModalOpen, setIsVibesModalOpen] = useState(false);

   const wide = useWindowWide(768);

   const [user, setUser] = useState<any>(currentUser);
   const [isFavorite, setIsFavorite] = useState(false);
   const [loading, setLoading] = useState(true);
   const [spaces, setSpaces] = useState<ISpace[]>([]);
   const [filteredSpaces, setFilteredSpaces] = useState<ISpace[]>([]);
   const [offices, setOffices] = useState<ISpace[]>([]);
   const coworkingSpacesSearchTerm = useSelector((state: RootState) => state.systemReducer).coworkingSpacesSearchTerm;
   const [filteredCoworkingSpaces, setFilteredCoworkingSpaces] = useState([]);

   const [currentLocation, setCurrentLocation] = useState<any>(null);
   const [cities, setCities] = useState<any>([]);
   const [vibes, setVibes] = useState<any>([]);
   const [amenities, setAmenities] = useState<any>([]);

   const [favoriteChecked, setFavoriteChecked] = useState<any>([]);

   let favoritesList: any;

   const [checked, setChecked] = useState<any>({
      citiesList: [],
      amenitiesList: [],
      vibesList: [],
   });
   let newCheckedObject: any = {
      citiesList: [],
      amenitiesList: [],
      vibesList: [],
   };

   const getCheckedFilters = () => {
      const urlParams: any = new URLSearchParams(window.location.search);
      const citiesFilter = urlParams.getAll('cities');
      const vibesFilter = urlParams.getAll('vibes');
      const amenitiesFilter = urlParams.getAll('amenities');

      const splitCities = [...(citiesFilter[0]?.split(', ') || [])];
      const splitAmenities = [...(amenitiesFilter[0]?.split(', ') || [])];
      const splitVibes = [...(vibesFilter[0]?.split(', ') || [])];

      newCheckedObject = {
         citiesList: splitCities,
         amenitiesList: splitAmenities,
         vibesList: splitVibes,
      };

      setChecked(newCheckedObject);
   };
   const filterSpaces = (activeSpaces: any) => {
      const urlParams: any = new URLSearchParams(window.location.search);

      const citiesFilter = urlParams.getAll('cities');
      const amenitiesFilter = urlParams.getAll('amenities');
      const vibesFilter = urlParams.getAll('vibes');

      let list = activeSpaces;

      const checkedCitiesItems =
         citiesFilter && citiesFilter.length
            ? citiesFilter.reduce((total: any, item: any) => {
                 return total + ', ' + item;
              })
            : '';

      if (citiesFilter.length > 0) {
         list = list.filter(
            (space: any) => space.location && space.location.city && checkedCitiesItems.includes(space.location.city),
         );
      }

      if (amenitiesFilter.length > 0) {
         list = list.filter(
            (space: any) =>
               space.amenities && amenitiesFilter.every((el: any) => space.amenities.some((el2: any) => el === el2)),
         );
      }

      if (vibesFilter.length > 0) {
         list = list.filter(
            (space: any) => space.vibes && vibesFilter.every((el: any) => space.vibes.some((el2: any) => el === el2)),
         );
      }

      setFilteredSpaces(list);
   };

   const getCoworkingSpaces = async () => {
      const coworkingSpacesRef = firebase.database().ref('spaces-list');

      coworkingSpacesRef.once(
         'value',
         async (snapshot) => {
            if (!snapshot.val()) {
               setLoading(false);
               return;
            }

            const data = snapshot.val();

            const spacesList = Object.keys(data.data).map((key) => {
               return {
                  ...data.data[key],
                  uid: key,
               };
            });

            const activeSpaces = spacesList ? spacesList.filter((space) => space.status === '1') : [];
            if (user) {
               const userDomain = user.email?.substring(user.email?.lastIndexOf('@') + 1, user.email?.length);
               const activeOffices = spacesList.filter(
                  (space) => space.status === '5' && space.website.includes(userDomain),
               );

               setOffices(activeOffices);
            }
            setSpaces(activeSpaces);
            setFilteredSpaces(activeSpaces);

            filterSpaces(activeSpaces);

            await checkCurrentLocation();
            setLoading(false);
         },
         (error) => {
            NotificationsProvider.error(error.message || 'home.error.getCoworkingSpaces');
            setLoading(false);
         },
      );
   };

   const getFavoriteSpaces = async () => {
      const userRef = firebase.database().ref(`users/${currentUser?.uid}`);

      userRef.once(
         'value',
         async (snapshot) => {
            if (!snapshot.val()) {
               setLoading(false);
               return;
            }

            const data = snapshot.val();

            favoritesList = data.profile['favorite-spaces'];

            setFavoriteChecked(favoritesList);
         },

         (error) => {
            NotificationsProvider.error(error.message || 'home.error.getCoworkingSpaces');
            setLoading(false);
         },
      );
   };

   // const addToFavorites = async (space_uid: string) => {
   //    try {
   //       const response = await ApiRequests.addToFavorites({
   //          payload: { space_id: space_uid, uuid: currentUser?.uid },
   //       });

   //       getCoworkingSpaces();

   //       if (!(response as any).success) {
   //          return NotificationsProvider.error(
   //             response.data?.message || translate('coWorkingSpaceDetails.favorites.error'),
   //          );
   //       }
   //    } catch (error) {
   //       NotificationsProvider.error(parseError(error) || translate('coWorkingSpaceDetails.favorites.error'));
   //    }
   // };

   const filterByCity = (city: string) => {
      return filteredSpaces.filter((s: any) => s.location && s.location.city && s.location.city === city);
   };

   const getUniqueCitiesWithLocations = () => {
      const citiesWithLocations = spaces
         ? _.chain(spaces)
              //eslint-disable-next-line
              .map(function (item) {
                 if (item && item.location && item.location.city) {
                    return {
                       name: item.location.city,
                       latitude: item.location.lattitude,
                       longitude: item.location.longitude,
                    };
                 }
              })
              .compact()
              .value()
         : [];

      const uniqueCities = _.uniq(citiesWithLocations, (c: any) => c?.name);
      return uniqueCities;
   };

   const checkCurrentLocation = async () => {
      try {
         const location = await getCurrentLocation();
         setCurrentLocation(location);
      } catch (err) {
         setCurrentLocation(null);
      }
   };

   const getCitiesByProximity = () => {
      //eslint-disable-next-line
      let citiesWithLocations: any = getUniqueCitiesWithLocations();

      citiesWithLocations = _.compact(citiesWithLocations);

      for (let i = 0; i < citiesWithLocations.length; i++) {
         if (citiesWithLocations[i]) {
            const distance = calculateLatLongDistanceInKm(
               currentLocation.latitude,
               currentLocation.longitude,
               citiesWithLocations[i].latitude,
               citiesWithLocations[i].longitude,
            );

            citiesWithLocations[i].distance = distance;
         }
      }

      citiesWithLocations.sort(function (a: any, b: any) {
         return a.distance - b.distance;
      });

      // setCities(citiesWithLocations);
   };

   const filterCoworkingSpacesBySearchTerm = (term: string) => {
      const filtered: any = filteredSpaces.filter(
         (s: any) =>
            (s.name && s.name.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
            (s.location && s.location.city && s.location?.city.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
            (s.location && s.location?.country && s.location?.country.toLowerCase().indexOf(term.toLowerCase()) > -1) ||
            (s.area && s.area.toLowerCase().indexOf(term.toLowerCase()) > -1),
      );

      return filtered;
   };

   const onSpaceClick = (id: number, name: string) => {
      const nameLowercased = name.replaceAll(' ', '-').toLocaleLowerCase();
      history.push(
         replaceLocation(RoutesEnum.SPACE_DETAILS, {
            id,
            name: nameLowercased,
         }),
      );
   };
   const renderOffices = (spaces: any) => {
      if (!spaces) {
         return null;
      }

      return (
         <div
            className={
               'd-flex w-100 flex-row flex-md-row align-items-start justify-content-start flex-nowrap overflow-auto '
            }
         >
            {spaces.map((space: any, index: number) => {
               const gallery = space.images?.gallery;

               return (
                  <div
                     key={index}
                     onClick={() => {
                        onSpaceClick(space.uid, space.name);
                     }}
                     className={
                        'd-flex col col-12 col-md-4 flex-column align-items-start justify-content-start px-0 px-md-3 mb-4 cursor-pointer'
                     }
                     style={{ width: wide ? '' : '48%', marginRight: wide ? 0 : 20 }}
                  >
                     <CarouselImg
                        src={gallery && gallery[0] ? gallery[0] : SpacePlaceholder}
                        alt={space.name}
                        onError={({ currentTarget }) => {
                           currentTarget.src = SpacePlaceholder;
                        }}
                     />
                     <StyledText
                        width={'100%'}
                        text={space?.name}
                        variant={StyledTextVariantsType.LABEL_TEXT}
                        margin={'16px 0 4px 0'}
                     />
                     <StyledText
                        variant={StyledTextVariantsType.NOTE_TEXT}
                        text={`${space?.location?.city}, ${space?.location?.country}`}
                        colorVariant={theme.colors.gray}
                     />
                     <StyledText
                        variant={StyledTextVariantsType.NOTE_TEXT}
                        text={`${translate('coWorkingSpacesCarousel.label')} ${space.availableSeats}`}
                        colorVariant={theme.colors.gray}
                     />
                  </div>
               );
            })}
         </div>
      );
   };

   const clearAllFilters = () => {
      if (spaces != filteredSpaces) {
         window.history.pushState({}, document.title, window.location.pathname);

         setChecked({
            citiesList: [],
            amenitiesList: [],
            vibesList: [],
         });
         if (isFavorite) setIsFavorite(!isFavorite);
         setFilteredSpaces(spaces);
      }
   };

   const getFavorites = async () => {
      if (isFavorite) {
         if (spaces != filteredSpaces) {
            window.history.pushState({}, document.title, window.location.pathname);

            setChecked({
               citiesList: [],
               amenitiesList: [],
               vibesList: [],
            });
            setFilteredSpaces(spaces);
         }
      } else {
         let favoriteSpaces = filteredSpaces;

         favoriteSpaces = filteredSpaces.filter((space) => favoritesList.includes(space.uid));

         setFilteredSpaces(favoriteSpaces);
      }
      setIsFavorite(!isFavorite);
   };

   const filterFavorites = async () => {
      await getFavoriteSpaces();
      await getFavorites();
   };

   const renderSpaces = (spaces: any) => {
      if (!spaces) {
         return null;
      }

      return (
         <div className={'d-flex w-100 flex-column flex-md-row align-items-start justify-content-start  flex-wrap'}>
            {spaces.map((space: any, index: number) => {
               const gallery = space.images?.gallery;
               return (
                  <>
                     <div
                        key={index}
                        className={
                           'd-flex col col-12 col-md-4 flex-column align-items-start justify-content-start px-0 px-md-3 mb-4 cursor-pointer'
                        }
                        style={{ position: 'relative' }}
                     >
                        <CarouselImg
                           src={gallery && gallery[0] ? gallery[0] : SpacePlaceholder}
                           alt={space.name}
                           onError={({ currentTarget }) => {
                              currentTarget.src = SpacePlaceholder;
                           }}
                           onClick={() => {
                              onSpaceClick(space.uid, space.name);
                           }}
                        />
                        {space?.vibes && (
                           <HashtagWrapper>
                              {space.vibes.map((vibe: string, index: number) => {
                                 return ` #${vibe}`;
                              })}
                           </HashtagWrapper>
                        )}
                        <StyledText
                           width={'100%'}
                           text={space?.name}
                           variant={StyledTextVariantsType.LABEL_TEXT}
                           margin={'5px 0 4px 0'}
                           onClick={() => {
                              onSpaceClick(space.uid, space.name);
                           }}
                        />
                        <StyledText
                           variant={StyledTextVariantsType.NOTE_TEXT}
                           text={`${space?.location?.city}, ${space?.location?.country}`}
                           colorVariant={theme.colors.gray}
                        />
                        <StyledText
                           variant={StyledTextVariantsType.NOTE_TEXT}
                           text={`${translate('coWorkingSpacesCarousel.label')} ${space.availableSeats}`}
                           colorVariant={theme.colors.gray}
                        />
                        {/* <FavoritesIconWrapper>
                           {favoriteChecked.includes(space.uid) ? (
                              <Button
                                 text=""
                                 variant={ButtonVariantsType.TRANSPARENT}
                                 reduceHeight={true}
                                 icon={FavoriteIconFill}
                              />
                           ) : (
                              <Button
                                 text=""
                                 variant={ButtonVariantsType.TRANSPARENT}
                                 reduceHeight={true}
                                 icon={FavoriteIconOutline}
                                 onClick={() => addToFavorites(space.uid)}
                              />
                           )}
                        </FavoritesIconWrapper> */}
                     </div>
                  </>
               );
            })}
         </div>
      );
   };

   const getCities = async () => {
      const cities = await ApiRequests.getCitiesList();
      setCities(cities.data);
   };
   const getVibes = async () => {
      const vibes = await ApiRequests.getVibesList();
      setVibes(vibes.data);
   };
   const getAmenities = async () => {
      const amenities = await ApiRequests.getAmenitiesList();
      setAmenities(amenities.data);
   };

   useEffect(() => {
      void getCheckedFilters();
      void getCoworkingSpaces();
      // void getFavoriteSpaces();

      return () => {
         dispatch(updateCoworkingSpacesSearchTerm(''));
      };
      //eslint-disable-next-line
   }, []);

   useEffect(() => {
      if (currentLocation) {
         getCitiesByProximity();
      }
      //eslint-disable-next-line
   }, [currentLocation]);

   useEffect(() => {
      if (coworkingSpacesSearchTerm) {
         setFilteredCoworkingSpaces(filterCoworkingSpacesBySearchTerm(coworkingSpacesSearchTerm));
      } else {
         setFilteredCoworkingSpaces([]);
      }
      //eslint-disable-next-line
   }, [coworkingSpacesSearchTerm]);

   useEffect(() => {
      getCities();
      getVibes();
      getAmenities();
   }, []);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   if (spaces.length === 0) {
      return <NoDataPlaceholderComponent margin={'100px 0 120px 0'} />;
   }
   return (
      <>
         <ModalFilter
            isOpen={isCitiesModalOpen}
            setIsOpen={setIsCitiesModalOpen}
            filtered={cities}
            setFiltered={cities}
            spaces={spaces}
            setFilteredSpaces={setFilteredSpaces}
            filteredSpaces={filteredSpaces}
            citiesFilter={true}
            checked={checked}
            setChecked={setChecked}
         ></ModalFilter>
         <ModalFilter
            isOpen={isAmenitiesModalOpen}
            setIsOpen={setIsAmenitiesModalOpen}
            filtered={amenities}
            spaces={spaces}
            setFilteredSpaces={setFilteredSpaces}
            filteredSpaces={filteredSpaces}
            amenitiesFilter={true}
            checked={checked}
            setChecked={setChecked}
         ></ModalFilter>
         <ModalFilter
            isOpen={isVibesModalOpen}
            setIsOpen={setIsVibesModalOpen}
            filtered={vibes}
            spaces={spaces}
            setFilteredSpaces={setFilteredSpaces}
            filteredSpaces={filteredSpaces}
            vibesFilter={true}
            checked={checked}
            setChecked={setChecked}
         ></ModalFilter>

         <Flex
            flexDirection="column"
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            padding={'0 10% 3% 10%'}
            paddingMobile={'5%'}
            width={'100%'}
         >
            <StyledText
               margin={'0 0 20px 0'}
               text={translate('home.title')}
               variant={StyledTextVariantsType.HEADING_1}
            />

            <div
               className={'d-inline-flex flex-row justify-content-start align-items-start overflow-auto cursor-pointer'}
            >
               <Button
                  variant={
                     checked.citiesList.length > 0
                        ? ButtonVariantsType.PURPLE_BORDER
                        : ButtonVariantsType.SECONDARYWHITE
                  }
                  margin={'0 10px 20px 0'}
                  marginMobile={'0 10px 20px 0'}
                  text={
                     checked.citiesList.length > 1
                        ? `${checked.citiesList[0]} + ${checked.citiesList.length - 1}`
                        : checked.citiesList.length > 0
                        ? `${checked.citiesList[0]} `
                        : 'Cities'
                  }
                  onClick={() => {
                     setIsCitiesModalOpen(true);
                  }}
                  isFilterButton={true}
                  reduceHeight={true}
                  minWidth={true}
               ></Button>
               <Button
                  variant={
                     checked.amenitiesList.length > 0
                        ? ButtonVariantsType.PURPLE_BORDER
                        : ButtonVariantsType.SECONDARYWHITE
                  }
                  margin={'0 10px 20px 0'}
                  marginMobile={'0 10px 20px 0'}
                  text={
                     checked.amenitiesList.length > 1
                        ? `${checked.amenitiesList[0]} + ${checked.amenitiesList.length - 1}`
                        : checked.amenitiesList.length > 0
                        ? `${checked.amenitiesList[0]} `
                        : 'Amenities'
                  }
                  onClick={() => setIsAmenitiesModalOpen(true)}
                  isFilterButton={true}
                  reduceHeight={true}
                  minWidth={true}
               ></Button>
               <Button
                  variant={
                     checked.vibesList.length > 0 ? ButtonVariantsType.PURPLE_BORDER : ButtonVariantsType.SECONDARYWHITE
                  }
                  margin={'0 10px 20px 0'}
                  marginMobile={'0 10px 20px 0'}
                  text={
                     checked.vibesList.length > 1
                        ? `${checked.vibesList[0]} + ${checked.vibesList.length - 1}`
                        : checked.vibesList.length > 0
                        ? `${checked.vibesList[0]} `
                        : 'Vibes'
                  }
                  onClick={() => setIsVibesModalOpen(true)}
                  isFilterButton={true}
                  reduceHeight={true}
                  minWidth={true}
               ></Button>
               <Button
                  variant={isFavorite ? ButtonVariantsType.PURPLE_BORDER : ButtonVariantsType.SECONDARYWHITE}
                  margin={'0 10px 20px 0'}
                  marginMobile={'0 10px 20px 0'}
                  text={'Favorites'}
                  onClick={user && filterFavorites}
                  reduceHeight={true}
                  minWidth={true}
               ></Button>
               <Button
                  variant={ButtonVariantsType.SECONDARYWHITE}
                  margin={'0 0 20px 0'}
                  marginMobile={'0 10px 20px 0'}
                  text={'Clear all'}
                  isClearButton={true}
                  onClick={clearAllFilters}
                  reduceHeight={true}
                  minWidth={true}
               ></Button>
            </div>

            {offices.length > 0 && (
               <Flex width={'100%'} flexDirection="column" alignItems={'flex-start'} justifyContent={'flex-start'}>
                  <StyledText
                     text={translate('home.offices.title')}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'0 0 24px 0'}
                  />
                  {renderOffices(offices)}
               </Flex>
            )}

            {coworkingSpacesSearchTerm && filteredCoworkingSpaces.length > 0 && (
               <Flex width={'100%'} flexDirection="column" alignItems={'flex-start'} justifyContent={'flex-start'}>
                  <StyledText
                     text={translate('home.searchResult.title')}
                     variant={StyledTextVariantsType.HEADING_2}
                     margin={'0 0 24px 0'}
                  />
                  {renderSpaces(filteredCoworkingSpaces)}
               </Flex>
            )}

            {coworkingSpacesSearchTerm && filteredCoworkingSpaces.length === 0 && (
               <Flex margin={'0 0 24px 0'} alignItems={'center'} justifyContent={'center'}>
                  <StyledText text={translate('home.searchResult.title')} variant={StyledTextVariantsType.HEADING_3} />
                  <StyledText
                     text={translate('home.searchResult.emptyState.message')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.gray}
                     margin={'0 0 0 5px'}
                  />
               </Flex>
            )}

            {!coworkingSpacesSearchTerm && (
               <Flex
                  flexDirection={'column'}
                  width={'100%'}
                  justifyContent={'center'}
                  alignItems={'flex-start'}
                  paddingMobile={'0%'}
               >
                  {filteredSpaces.length === 0 && (
                     <Flex margin={'0 0 24px 0'} alignItems={'center'} justifyContent={'center'}>
                        <StyledText
                           text={translate('home.searchResult.emptyState.message')}
                           variant={StyledTextVariantsType.PARAGRAPH_1}
                           colorVariant={theme.colors.mainPurple}
                           margin={'0'}
                        />
                     </Flex>
                  )}

                  {cities.map((city: any, index: number) => {
                     const spacesByCity = filterByCity(city);

                     if (spacesByCity && spacesByCity.length > 0) {
                        return (
                           <Flex
                              key={index}
                              flexDirection={'column'}
                              width={'100%'}
                              justifyContent={'center'}
                              alignItems={'flex-start'}
                              margin={'0 0 24px 0'}
                              borderBottom={`1px solid #ededed`}
                           >
                              <InlineElementsWrapper margin={'0 0 24px 0'}>
                                 <StyledText
                                    text={translate('home.filteredByCity.title')}
                                    variant={StyledTextVariantsType.HEADING_2}
                                 />
                                 <StyledText
                                    text={city}
                                    variant={StyledTextVariantsType.HEADING_2}
                                    colorVariant={theme.colors.mainPurple}
                                    margin={'0 0 0 5px'}
                                 />
                              </InlineElementsWrapper>

                              <div
                                 className={
                                    'd-flex w-100 flex-column flex-md-row align-items-start justify-content-start flex-wrap'
                                 }
                              >
                                 {renderSpaces(spacesByCity)}
                              </div>
                           </Flex>
                        );
                     }
                  })}
               </Flex>
            )}
         </Flex>
      </>
   );
};

export default Spaces;
