import React, { ReactElement, useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import styled from 'styled-components';

import { StyledText, Spinner, Flex } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import VerticalBarChart from './VerticalBarChart';
import HorizontalBarChart from './HorizontalBarChart';
import CheckinBar from './CheckinBar';

import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { translate } from 'src/translations/translations';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from '../../../../styles/theme';

import { useLocation } from 'react-router-dom';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartContainer = styled.div<any>`
   background: #ffffff;
   border: 1px solid ${theme.colors.lightGray};
   box-sizing: border-box;
   border-radius: 15px;
   padding: 30px;
   height: ${(props) => props.height || 'auto'};

   p {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: ${theme.colors.black};
   }
`;

const SpaceOverview = (): ReactElement => {
   const [statisticsLoading, setStatisticsLoading] = useState(true);
   const [statistics, setStatistics] = useState<any>(null);
   const location = useLocation();

   const company = location.state.company;

   const wide = useWindowWide(768);

   const goBack = () => {
      history.back();
   };

   const getCompanyStatistics = async (enterpriseId: string) => {
      try {
         const response = await ApiRequests.getCompanyStatistics(enterpriseId, 'weekly');
         setStatistics(response.data);
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('hrManagement.settings.error.getCompanyStatistics'),
         );
      } finally {
         setStatisticsLoading(false);
      }
   };

   useEffect(() => {
      if (company) {
         void getCompanyStatistics(company.enterprise_id);
      } else {
         setStatisticsLoading(false);
      }
   }, [company]);

   if (statisticsLoading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         width={'68%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('hrManagement.insights.spaceOverview.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('hrManagement.insights.spaceOverview.title')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}
         <div className="container">
            <div className="row">
               <div className="col col-md-6">
                  <ChartContainer>
                     <p className="mb-4">Weekly checkin overview</p>
                     {statistics && statistics.checkin_overview && (
                        <VerticalBarChart data={statistics.checkin_overview} />
                     )}
                  </ChartContainer>
               </div>
               <div className="col col-md-6">
                  <ChartContainer height={'100%'}>
                     <p className="mb-4">Checkins by period in the last 7 days</p>
                     {statistics && statistics.checkins_by_week && <CheckinBar data={statistics.checkins_by_week} />}
                  </ChartContainer>
               </div>
            </div>
            <div className="row mt-5">
               <div className="col col-md-12">
                  <ChartContainer>
                     <p className="mb-4">Top 5 visited spaces </p>
                     {statistics && statistics.most_visited_spaces && (
                        <HorizontalBarChart data={statistics.most_visited_spaces} />
                     )}
                  </ChartContainer>
               </div>
            </div>
         </div>
      </Flex>
   );
};

export default SpaceOverview;
