import React, { ReactElement, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Flex, StyledText, Spinner, Table } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn, TableItemAction } from 'src/components/table/interfaces';

import InviteToEnterpriseModal from '../modals/InviteToEnterpriseModal';

import ApiRequests from 'src/features/enterprise_management/data/ApiRequests';
import { RoutesEnum, replaceLocation } from 'src/routes/routes';
import { ICompany } from 'src/features/enterprise_management/interfaces';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { translate } from 'src/translations/translations';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import { config } from 'src/app/config';
import moment from 'moment';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

const EnterpriseMembers = (): ReactElement => {
   const history = useHistory();
   const location = useLocation();

   const wide = useWindowWide(768);

   const goBack = () => {
      history.goBack();
   };

   const company = location.state.company;
   const [loading, setLoading] = useState(true);
   const [teamMembers, setTeamMembers] = useState<any>([]);

   const [addTeamMemberLoading, setAddTeamMemberLoading] = useState(false);
   const [inviteEmail, setInviteEmail] = useState('');
   const [showInviteEmailModal, setShowInviteEmailModal] = useState(false);

   const columns: Array<TableColumn<any>> = [
      {
         title: '',
         key: 'image',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (member: any) => `${member.photo ? config.STORAGE_URL + member.photo : ''}`,
      },
      {
         title: 'Name',
         key: 'name',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (member: any) => <>{member.name}</>,
      },
      {
         title: 'Email',
         key: 'email',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (member: any) => <>{member.email}</>,
      },
      {
         title: 'Joined At',
         key: 'joined_at',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (checkin: any) => <>{moment(checkin.joined_at).format('DD/MM/YYYY HH:mm')}</>,
      },
   ];

   const itemActions: Array<TableItemAction<any>> = [
      {
         title: translate('hrManagement.settings.manageYourTeam.view.table.action.viewProfile'),
         clickHandler: (member: any) => {
            history.push(
               replaceLocation(RoutesEnum.ENTERPRISE_MANAGEMENT_USER_PROFILE, {
                  id: member.uuid,
               }),
            );
         },
      },
   ];

   // eslint-disable-next-line
   const getCompanyTeamMembers = async () => {
      if (company && company.enterprise_id) {
         try {
            const response = await ApiRequests.getCompanyTeamMembers(company.enterprise_id);
            setTeamMembers(response.data);
         } catch (error) {
            NotificationsProvider.error(
               parseError(error) || translate('hrManagement.settings.error.getCompanyTeamMembers'),
            );
         } finally {
            setLoading(false);
         }
      } else {
         setLoading(false);
         NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }
   };

   const inviteToEnterprise = async () => {
      if (company && company.enterprise_id) {
         try {
            setAddTeamMemberLoading(true);
            const response: any = await ApiRequests.inviteToEnterprise({
               payload: {
                  enterprise_id: company.enterprise_id,
                  email: inviteEmail,
               },
            });

            if (response.success) {
               await getCompanyTeamMembers();
               setInviteEmail('');
               setShowInviteEmailModal(false);
               NotificationsProvider.success(response.data.message);
            } else {
               NotificationsProvider.error(parseError(response));
            }
         } catch (error) {
            NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.addTeamMember'));
         } finally {
            setAddTeamMemberLoading(false);
         }
      } else {
         NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }
   };

   useEffect(() => {
      void getCompanyTeamMembers();
      //eslint-disable-next-line
   }, []);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {showInviteEmailModal && (
            <InviteToEnterpriseModal
               isOpen={showInviteEmailModal}
               setShowModal={setShowInviteEmailModal}
               loading={addTeamMemberLoading}
               inviteEmail={inviteEmail}
               setInviteEmail={setInviteEmail}
               onInviteToEnterpriseHandler={inviteToEnterprise}
            />
         )}

         <Flex
            margin={'0 0 32px 0'}
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
            flexDirectionMobile={'column'}
            alignItemsMobile={'center'}
         >
            {wide ? (
               <StyledText
                  justifyContent={'flex-start'}
                  text={translate('hrManagement.settings.manageYourTeam.view.title')}
                  variant={StyledTextVariantsType.HEADING_1}
               />
            ) : (
               <HeaderWrapper>
                  <img
                     src={PurpleArrowLeft}
                     style={{ height: 34, cursor: 'pointer' }}
                     alt="navigate"
                     onClick={goBack}
                  />
                  <StyledText
                     justifyContent={'flex-start'}
                     text={translate('hrManagement.settings.manageYourTeam.view.title')}
                     variant={StyledTextVariantsType.HEADING_1}
                     margin={'0 0 0 32px'}
                  />
               </HeaderWrapper>
            )}

            <Button
               variant={ButtonVariantsType.PURPLE}
               text={translate('hrManagement.settings.manageYourTeam.view.headerButton.text')}
               onClick={() => {
                  setShowInviteEmailModal(true);
               }}
            />
         </Flex>

         <Table
            onSelectAll={() => null}
            columns={columns}
            data={teamMembers}
            itemActions={itemActions}
            customClass={'w-100'}
         />
      </Flex>
   );
};

export default EnterpriseMembers;
