import React, { ReactElement, useState } from 'react';
import { StyledText, EmptyImages as ImagePicker } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { parseError } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import ApiRequests from 'src/features/space_management/data/ApiRequests';
import NotificationsProvider from 'src/utilities/notifications-provider';

interface IProps {
   spaceId: string;
   logo: string;
   getLogo: () => void;
}

const Logo = ({ spaceId, logo, getLogo }: IProps): ReactElement => {
   const [fileChangeLoading, setFileChangeLoading] = useState(false);

   const logoChangeHandler = async (e: any) => {
      setFileChangeLoading(true);
      const file = e.target.files[0];

      try {
         const formData = new FormData();
         formData.append('logo', file);
         formData.append('space_id', spaceId);

         const response: any = await ApiRequests.uploadSpaceLogo({
            payload: formData,
         });

         if (response && response.data) {
            await getLogo();
            NotificationsProvider.success(
               response.data.message || translate('spaceManagement.success.updateSpaceLogo'),
            );
         } else {
            NotificationsProvider.error(response.error?.message || translate('spaceManagement.error.updateSpaceLogo'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.updateSpaceLogo'));
      } finally {
         setFileChangeLoading(false);
      }
   };

   return (
      <>
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('spaceManagement.settings.galleryAndLogo.logoSection.title')}
            variant={StyledTextVariantsType.PARAGRAPH_2}
            margin={'0 0 16px 0'}
         />

         <ImagePicker
            enableAdding={!logo}
            loading={fileChangeLoading}
            onImageChange={logoChangeHandler}
            images={logo ? [logo] : []}
            logo={true}
         ></ImagePicker>
      </>
   );
};

export default Logo;
