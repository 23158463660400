import React, { ReactElement } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options: any = {
   type: 'horizontalBar',
   indexAxis: 'y' as const,
   responsive: true,
   scales: {
      x: {
         beginAtZero: true,
         display: false,
         grid: {
            display: false,
         },
      },
      y: {
         beginAtZero: true,
         display: true,
         grid: {
            drawBorder: false,
            display: false,
         },
         ticks: {
            display: true,
         },
      },
   },
   plugins: {
      legend: {
         display: false,
      },
      title: {
         display: false,
      },
      // Core options
      maintainAspectRatio: false,
   },
};

const getLabels = (statistics: any) => {
   // eslint-disable-next-line
   let labels: any[] = [];

   Object.keys(statistics).forEach((key: any) => {
      const itemObject = statistics[key];

      labels.push(itemObject.space_name);
   });

   return labels;
};

const getData = (statistics: any) => {
   // eslint-disable-next-line
   let dataArray: any[] = [];

   Object.keys(statistics).forEach((key: any) => {
      const itemObject = statistics[key];

      dataArray.push(itemObject.visits);
   });

   return dataArray;
};

const getDataObject = (statistics: any) => {
   return {
      labels: getLabels(statistics),
      datasets: [
         {
            label: 'Top 5 visited spaces',
            data: getData(statistics),
            borderColor: 'rgb(255, 255, 255)',
            barPercentage: 0.5,
            categoryPercentage: 1,
            borderRadius: 10,
            borderWidth: 1,
            backgroundColor: ['#7AD3FF', '#FF9364', '#B09FFF', '#FE6C6C', '#FFCE56'],
            datalabels: {
               color: '#FFCE56',
            },
         },
      ],
   };
};

interface IProps {
   data: any;
}

const HorizontalBarChart = ({ data }: IProps): ReactElement => {
   return <Bar options={options} data={getDataObject(data)} height={60} />;
};

export default HorizontalBarChart;
