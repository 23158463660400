import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { IntercomProvider } from 'react-use-intercom';

// Store
import store, { rrfProps } from 'src/app/store';

import App from 'src/app/App';

import * as serviceWorker from 'src/serviceWorker';
import { translations } from 'src/translations/translations';
import theme from 'src/styles/theme';
import { config } from 'src/app/config';

import 'react-toastify/dist/ReactToastify.css';
import 'font-awesome/css/font-awesome.min.css';
// import main app styles
import './styles/styles.css';

ReactDOM.render(
   <Suspense fallback={false}>
      <IntercomProvider autoBoot appId={config.INTERCOM_APP_ID as string}>
         <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
               <ThemeProvider theme={theme}>
                  <IntlProvider
                     locale={config.DEFAULT_LANGUAGE}
                     messages={
                        // eslint-disable-next-line
                               // @ts-ignore
                        translations[config.DEFAULT_LANGUAGE]
                     }
                  >
                     <App />
                  </IntlProvider>
               </ThemeProvider>
            </ReactReduxFirebaseProvider>
         </Provider>
      </IntercomProvider>
   </Suspense>,
   document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
