import React, { ReactElement, useEffect, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Flex, StyledText, Spinner, Button } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { ButtonVariantsType } from 'src/components/Button';
import { InlineElementsWrapper } from 'src/components/SharedComponents';

import ApiCalls from '../auth/data/ApiRequests';
import { RootState } from 'src/app/rootReducer';
import { AcceptInviteIllustration } from 'src/assets/images';
import { RoutesEnum } from 'src/routes/routes';
import { selectFirebaseAuthLoader } from 'src/features/auth/authSlice';
import { translate } from 'src/translations/translations';
import { parseError, useQuery } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';

const EnterpriseInvitation = (): ReactElement => {
   const history = useHistory();
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;
   const firebaseAuthLoaded = useSelector(selectFirebaseAuthLoader);
   const loggedInToken = useSelector((state: RootState) => state.systemReducer).token;

   const query = useQuery();
   const token = query.get('token');

   const [loading, setLoading] = useState(true);
   const [showRetry, setShowRetry] = useState(false);
   const [showSignInForm, setShowSignInForm] = useState(false);

   const [inviteUuid, setInviteUuid] = useState('');
   const [inviteEmail, setInviteEmail] = useState('');
   const [inviteToken, setInviteToken] = useState('');
   const [inviteEnterpriseId, setInviteEnterpriseId] = useState('');
   const [inviteEnterpriseName, setInviteEnterpriseName] = useState('');

   const getUserInfoFromToken = async () => {
      if (!token) {
         setLoading(false);
         NotificationsProvider.error(translate('acceptInvite.error.missingUrlToken'));
         return history.push(RoutesEnum.HOME);
      }

      try {
         const response = await ApiCalls.getInvitation(token);
         if (response.data) {
            setInviteUuid(response.data.uuid);
            setInviteEmail(response.data.email);
            setInviteToken(response.data.invite_token);
            setInviteEnterpriseId(response.data.enterprise_id);
            setInviteEnterpriseName(response.data.enterprise_name);
            setLoading(false);
         } else {
            NotificationsProvider.error(translate('acceptInvite.error.getUserInfoFromToken'));
            history.push(RoutesEnum.HOME);
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('acceptInvite.error.getUserInfoFromToken'));
         history.push(RoutesEnum.HOME);
      }
   };

   const handleAcceptInvite = async () => {
      await getUserInfoFromToken();

      // if not logged in, show sign in form
      if (!currentUser && !loggedInToken) {
         setShowSignInForm(true);
      }
   };

   const acceptInvite = async () => {
      if (!inviteToken) {
         return NotificationsProvider.error(translate('acceptInvite.error.missingInviteToken'));
      }

      // at this step, user is logged in
      // if invite user uuid exists (invited user has account), compare it with currentUser uuid
      // if does not exist (invited user does not have account), compare invite email with currentUser email

      // if uuid or email don't match, user is logged with another account, decline invite
      if (inviteUuid && inviteUuid !== 'null') {
         if (currentUser?.uid !== inviteUuid) {
            NotificationsProvider.error(translate('acceptInvite.error.uuidMissmatch'));
            return history.push(RoutesEnum.HOME);
         }
      } else {
         if (currentUser?.email !== inviteEmail) {
            NotificationsProvider.error(translate('acceptInvite.error.emailMissmatch'));
            return history.push(RoutesEnum.HOME);
         }
      }

      // if invite data matches currentUser, proceed with accept invite
      try {
         const response = await ApiCalls.acceptInvite({
            payload: {
               uuid: currentUser?.uid,
               token: inviteToken,
               enterprise_id: inviteEnterpriseId,
            },
         });

         if (response.data) {
            NotificationsProvider.success(response.data.message);
            history.push(RoutesEnum.HOME);
         } else {
            setShowRetry(true);
            NotificationsProvider.error(translate('acceptInvite.error'));
         }
      } catch (err) {
         setShowRetry(true);
         NotificationsProvider.error(parseError(err) || translate('acceptInvite.error'));
      }
   };

   useEffect(
      () => {
         if (firebaseAuthLoaded) {
            void handleAcceptInvite();
         }
      },
      //eslint-disable-next-line
[firebaseAuthLoaded]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         flexDirection="column"
         alignItems={'flex-start'}
         justifyContent={'flex-start'}
         padding={'0 10% 3% 10%'}
         paddingMobile={'5%'}
      >
         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('acceptInvite.title')}
            variant={StyledTextVariantsType.HEADING_1}
            margin={'0 0 38px 0'}
         />

         {showSignInForm && !currentUser && !loggedInToken ? (
            <Flex
               width={'100%'}
               justifyContent="center"
               alignItems="center"
               flexWrap="nowrap"
               backgroundColor={'#F9F9F9'}
               borderRadius={'15px'}
               padding={'10%'}
               position={'relative'}
            >
               <InlineElementsWrapper>
                  <StyledText
                     justifyContent={'flex-start'}
                     text={translate('acceptInvite.loginWithEmailMessage.text1')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                  />
                  <StyledText
                     justifyContent={'flex-start'}
                     margin={'0 0 0 5px'}
                     text={inviteEmail || 'N/A'}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     colorVariant={theme.colors.mainPurple}
                  />
                  <StyledText
                     justifyContent={'flex-start'}
                     margin={'0 0 0 5px'}
                     text={translate('acceptInvite.loginWithEmailMessage.text2')}
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                  />
               </InlineElementsWrapper>
            </Flex>
         ) : (
            <Flex
               justifyContent="flex-start"
               alignItems="stretch"
               flexDirectionMobile={'column'}
               flexWrap="nowrap"
               backgroundColor={'#F9F9F9'}
               borderRadius={'15px'}
               padding={'5% 10% 5% 10%'}
               flex={1}
               position={'relative'}
            >
               <Flex
                  width={'32%'}
                  flexDirection="column"
                  justifyContent="flex-start"
                  backgroundColor={'#F9F9F9'}
                  alignItems="flex-start"
                  position={'relative'}
                  overflow={'hidden'}
               >
                  <img src={AcceptInviteIllustration} alt="add-company" style={{ width: '85%', height: 'auto' }} />
               </Flex>

               <Flex
                  width={'68%'}
                  flexDirection="column"
                  justifyContent="center"
                  backgroundColor={'#F9F9F9'}
                  alignItems="center"
                  position={'relative'}
                  padding={'0 0 0 32px'}
                  overflow={'hidden'}
               >
                  <InlineElementsWrapper margin={'0 0 38px 0'} width={'100%'}>
                     <StyledText
                        justifyContent={'flex-start'}
                        text={inviteEnterpriseName || 'N/A'}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                        colorVariant={theme.colors.mainPurple}
                     />
                     <StyledText
                        justifyContent={'flex-start'}
                        margin={'0 0 0 5px'}
                        text={translate('acceptInvite.text')}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                     />
                  </InlineElementsWrapper>

                  <Button
                     variant={ButtonVariantsType.PURPLE}
                     text={
                        showRetry ? translate('acceptInvite.retryButton.text') : translate('acceptInvite.button.text')
                     }
                     width={'50%'}
                     margin={'20px 0 0 0'}
                     disabled={!inviteToken}
                     onClick={acceptInvite}
                  />

                  <Button
                     variant={ButtonVariantsType.WHITE}
                     text={translate('acceptInvite.declineButton.text')}
                     width={'50%'}
                     margin={'20px 0 0 0'}
                     onClick={() => {
                        history.push(RoutesEnum.HOME);
                     }}
                  />
               </Flex>
            </Flex>
         )}
      </Flex>
   );
};

export default EnterpriseInvitation;
