import React, { ReactElement, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { PurpleArrowLeft } from 'src/assets/images';

import { Button, Input, StyledText } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { StyledTextVariantsType } from 'src/components/StyledText';

import ApiRequests from 'src/features/settings/data/ApiRequests';
import { IUser } from 'src/features/settings/interfaces';
import { RoutesEnum } from 'src/routes/routes';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';

const BasicInfo = (): ReactElement => {
   const history = useHistory();
   const [updateLoading, setUpdateLoading] = useState(false);
   const goBack = () => {
      history.goBack();
   };
   const location = useLocation();
   const [user, setUser] = useState<IUser>(location.state.user);

   const updateUser = async () => {
      setUpdateLoading(true);

      if (user.uuid) {
         try {
            const response = await ApiRequests.updateUser({ payload: user });
            NotificationsProvider.success(response.data.message);
            history.push(RoutesEnum.SETTINGS);
         } catch (error) {
            NotificationsProvider.error(parseError(error) || translate('common.error.updateUser'));
         } finally {
            setUpdateLoading(false);
         }
      }
   };

   return (
      <>
         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('settings.account.basicInfo.form.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 32px'}
            />
         </HeaderWrapper>

         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 24px 0'}
            placeholder={translate('settings.account.basicInfo.form.labels.name')}
            onChange={(value) => setUser({ ...user, name: value })}
            validators={['required']}
            value={user.name}
         />

         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 24px 0'}
            placeholder={translate('settings.account.basicInfo.form.labels.biography')}
            onChange={(value) => setUser({ ...user, biography: value })}
            value={user.biography}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 24px 0'}
            placeholder={translate('settings.account.basicInfo.form.labels.description')}
            onChange={(value) => setUser({ ...user, description: value })}
            value={user.description}
         />

         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 72px 0'}
            placeholder={translate('settings.account.basicInfo.form.labels.profession')}
            onChange={(value) => setUser({ ...user, profession: value })}
            value={user.profession}
         />

         <Button
            disabled={!user.name}
            isLoading={updateLoading}
            variant={ButtonVariantsType.PURPLE}
            text={translate('common.saveChanges')}
            width={'100%'}
            onClick={updateUser}
         />
      </>
   );
};

export default BasicInfo;
