import styled from 'styled-components';

const HeaderWrapper = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   width: 100%;
   margin-bottom: 32px;
`;

export default HeaderWrapper;
