import React, { useEffect, useState, ReactElement } from 'react';
import { GoogleAPI, Map, Marker } from 'google-maps-react';
import styled from 'styled-components';
import { Wrapper } from '@googlemaps/react-wrapper';

import { InfoWindowWithEventHandlers as InfoWindow } from 'src/components/index';
import { IUpdateSpaceAddress } from 'src/features/space_management/interfaces';

import { MapMarker, PurpleArrowRight } from 'src/assets/images';
import theme from 'src/styles/theme';
import { config } from 'src/app/config';

import mapStyle from './google_map_style.json';
import { IUpdateSpaceAddressDTO } from 'src/features/space_management/data/RequestDtos';

const MapWrapper = styled.div<any>`
   margin-bottom: ${(props) => props.marginBottom || 0};

   @media only screen and (max-width: 768px) {
      > div {
         width: 90% !important;
      }
   }
`;

const InfoWindowContent = styled.div`
   padding: 10px 20px;
   display: flex;
   align-items: center;
   background-color: ${theme.colors.white};
   border-radius: 15px;
   z-index: 1;
`;

const InfoWindowContentText = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;

   h2 {
      margin: 0 0 10px 0;
      font-weight: 500;
      font-size: 16px;
   }

   p {
      margin: 0;
      font-weight: 300;
      font-size: 14px;
      color: ${theme.colors.gray};
   }
`;

const InfoWindowContentAction = styled.div<any>`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-left: 32px;
   z-index: 2;
`;

interface IProps {
   google: GoogleAPI;
   marker: any;
   height: string;
   width?: string;
   zoom?: number;
   currentLocation?: any;
   infoWindowActionHandler?: (id: string) => void;
   spaceAddress?: any;
   setSpaceAddress?: React.Dispatch<React.SetStateAction<IUpdateSpaceAddressDTO>> | undefined;
   onClick?: (lat: any, lon: any) => void;
}

function GoogleMapAddressDetails({
   google,
   marker,
   height,
   width,
   zoom,
   currentLocation,
   infoWindowActionHandler,
   spaceAddress,
   setSpaceAddress,
   onClick,
}: IProps): ReactElement {
   const centered = currentLocation
      ? { lat: currentLocation.latitude, lng: currentLocation.longitude }
      : marker && marker?.location
      ? {
           lat: Number(marker?.location?.lattitude),
           lng: Number(marker?.location?.longitude),
        }
      : { lat: 43.8563, lng: 18.4131 };

   const [initialCenter, setInitialCenter] = useState<any>(centered);
   const [activeMarker, setActiveMarker] = useState<any>();
   const [selectedPlace, setSelectedPlace] = useState<any>();
   const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);

   const onMarkerClick = (markerData: any, props: any, marker: any) => {
      setActiveMarker(marker);
      setSelectedPlace({ ...props, ...markerData });

      setIsInfoWindowOpen(true);
   };
   const resetInputFields = (address: any) => {
      address.address = '';
      address.area = '';
      address.street_number = '';
      address.zip = '';
      address.country = '';
      address.city = '';
   };

   const onInfoWindowClose = () => {
      setActiveMarker(undefined);
      setIsInfoWindowOpen(false);
   };

   const onMapClicked = (t: any, map: any, coord: any) => {
      const { latLng } = coord;

      const lat = latLng.lat();
      const lng = latLng.lng();

      spaceAddress.longitude = lng.toString();
      spaceAddress.latitude = lat.toString();

      onClick(spaceAddress.latitude, spaceAddress.longitude);

      const latlng = new google.maps.LatLng(lat, lng);

      // This is making the Geocode request
      const geocoder = new google.maps.Geocoder();

      const newSpaceAddress = { ...spaceAddress };

      geocoder.geocode({ latLng: latlng }, (results: any, status: any) => {
         if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
         }
         // This is checking to see if the Geoeode Status is OK before proceeding
         if (status == google.maps.GeocoderStatus.OK) {
            const typesArray = ['street_number', 'route', 'sublocality', 'postal_code', 'locality', 'country'];
            const fields = ['street_number', 'address', 'area', 'zip', 'city', 'country'];

            resetInputFields(newSpaceAddress);

            results[0].address_components.forEach((element: any) => {
               typesArray.forEach((type, index) => {
                  if (element.types.includes(type)) {
                     newSpaceAddress[fields[index]] = element.long_name;
                  }
               });
            });
            setSpaceAddress(newSpaceAddress);
         }
      });

      if (isInfoWindowOpen) {
         setActiveMarker(undefined);
         setIsInfoWindowOpen(false);
      }
   };

   const _mapLoaded = (mapProps: any, map: any) => {
      map.setOptions({
         styles: mapStyle,
      });
   };

   useEffect(() => {
      if (currentLocation) {
         setInitialCenter({ lat: currentLocation.latitude, lng: currentLocation.longitude });
      }
   }, [currentLocation]);

   return (
      <Wrapper apiKey={config.GOOGLE_MAPS_API_KEY as string}>
         <MapWrapper marginBottom={height}>
            <Map
               google={google}
               // @ts-ignore
               zoom={zoom || 15}
               zoomControl={true}
               mapTypeControl={false}
               scaleControl={false}
               streetViewControl={false}
               rotateControl={false}
               fullscreenControl={false}
               onClick={onMapClicked}
               showsUserLocation={true}
               initialCenter={initialCenter}
               resetBoundsOnResize={true}
               containerStyle={{
                  height: height,
                  width: width || '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
               }}
               style={{ borderRadius: 15, marginBottom: 32 }}
               onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
            >
               {marker && marker?.location && (
                  <Marker
                     // @ts-ignore
                     position={{ lat: Number(marker.location?.lattitude), lng: Number(marker.location?.longitude) }}
                     icon={{
                        url: MapMarker,
                        scaledSize: new google.maps.Size(28, 28),
                     }}
                     defaultAnimation={1}
                     onClick={(props, markerItem) => {
                        onMarkerClick(marker, props, markerItem);
                     }}
                  />
               )}
               <InfoWindow
                  google={google}
                  map={google.map}
                  marker={activeMarker}
                  // @ts-ignore
                  onClose={onInfoWindowClose}
                  visible={isInfoWindowOpen}
               >
                  <InfoWindowContent>
                     <InfoWindowContentText>
                        {selectedPlace?.name && <h2>{selectedPlace.name}</h2>}
                        {selectedPlace?.location.city && selectedPlace?.location.country && (
                           <p>{`${selectedPlace.location.city}, ${selectedPlace.location.country}`}</p>
                        )}
                     </InfoWindowContentText>
                     {infoWindowActionHandler && (
                        <InfoWindowContentAction
                           onClick={(e: any) => {
                              e.preventDefault();
                              infoWindowActionHandler(selectedPlace?.uid);
                           }}
                        >
                           <img src={PurpleArrowRight} style={{ height: 24, cursor: 'pointer' }} alt="navigate" />
                        </InfoWindowContentAction>
                     )}
                  </InfoWindowContent>
               </InfoWindow>
            </Map>
         </MapWrapper>
      </Wrapper>
   );
}

export default GoogleMapAddressDetails;
