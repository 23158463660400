import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Flex, StyledText } from './index';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { HorizontalSeparator } from 'src/components/SharedComponents';

import { LogoIcon } from 'src/assets/images';
import { FacebookIcon, LinkedinIcon, InstagramIcon, TwitterIcon } from 'src/assets/images/social-media';
import { translate } from 'src/translations/translations';
import { config } from 'src/app/config';
import theme from 'src/styles/theme';

const FooterWrapper = styled.div`
   width: 100%;

   footer {
      width: 100%;
      background-color: ${theme.colors.grayBackground};
      display: flex;
      align-items: center;
      padding: 50px 10% 44px 10%;
      height: auto;
      box-shadow: 1px 2px 15px -5px rgba(0, 0, 0, 0.15);

      @media only screen and (max-width: 768px) {
         flex-direction: column;
         padding: 0;
      }
   }
`;

const Footer = (): ReactElement => {
   const goToPage = (url: string) => {
      if (!url) {
         return;
      }

      window.open(url, '_blank');
   };

   return (
      <FooterWrapper>
         <footer>
            <Flex width={'100%'} flexDirection="column" backgroundColor={theme.colors.grayBackground}>
               <Flex width={'100%'} flexDirectionMobile={'column'} backgroundColor={theme.colors.grayBackground}>
                  <Flex
                     width={'25%'}
                     paddingMobile={'0px'}
                     flexDirection="column"
                     backgroundColor={theme.colors.grayBackground}
                  >
                     <StyledText
                        width={'100%'}
                        text={translate('footer.label.support')}
                        variant={StyledTextVariantsType.HEADING_3}
                        colorVariant={theme.colors.black}
                        margin={'32px 0 16px 0'}
                        marginMobile={'0 0 16px 0'}
                        justifyContentMobile={'center'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('footer.link.help')}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                        colorVariant={theme.colors.black}
                        margin={'0 0 16px 0'}
                        justifyContentMobile={'center'}
                        cursor={'pointer'}
                        onClick={() => {
                           goToPage(config.HELP_CENTER_PAGE);
                        }}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('footer.link.privacy')}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                        colorVariant={theme.colors.black}
                        margin={'0 0 16px 0'}
                        justifyContentMobile={'center'}
                        cursor={'pointer'}
                        onClick={() => {
                           goToPage(config.PRIVACY_POLICY_PAGE);
                        }}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('footer.link.imprint')}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                        colorVariant={theme.colors.black}
                        margin={'0 0 16px 0'}
                        justifyContentMobile={'center'}
                        cursor={'pointer'}
                        onClick={() => {
                           goToPage(config.IMPRINT_PAGE);
                        }}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('footer.link.terms')}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                        colorVariant={theme.colors.black}
                        justifyContentMobile={'center'}
                        margin={'0 0 32px 0'}
                        marginMobile={'0 0 16px 0'}
                        cursor={'pointer'}
                        onClick={() => {
                           goToPage(config.TERMS_OF_SERVICE_PAGE);
                        }}
                     />
                  </Flex>
                  <Flex
                     width={'25%'}
                     paddingMobile={'0px'}
                     flexDirection="column"
                     backgroundColor={theme.colors.grayBackground}
                  >
                     <StyledText
                        width={'100%'}
                        text={translate('footer.label.applications')}
                        variant={StyledTextVariantsType.HEADING_3}
                        justifyContentMobile={'center'}
                        colorVariant={theme.colors.black}
                        margin={'32px 0 16px 0'}
                        marginMobile={'0 0 16px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('footer.link.ios')}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                        colorVariant={theme.colors.black}
                        justifyContentMobile={'center'}
                        margin={'0 0 16px 0'}
                        cursor={'pointer'}
                        onClick={() => {
                           goToPage(config.IOS_APP_URL);
                        }}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('footer.link.android')}
                        variant={StyledTextVariantsType.PARAGRAPH_2}
                        colorVariant={theme.colors.black}
                        justifyContentMobile={'center'}
                        margin={'0 0 32px 0'}
                        cursor={'pointer'}
                        onClick={() => {
                           goToPage(config.ANDROID_APP_URL);
                        }}
                     />
                  </Flex>
                  <Flex
                     width={'50%'}
                     paddingMobile={'0px'}
                     flexDirection="column"
                     backgroundColor={theme.colors.grayBackground}
                     alignItems={'flex-end'}
                     alignItemsMobile={'center'}
                     justifyContent={'center'}
                     marginMobile={'0 0 16px 0'}
                  >
                     <LogoIcon />
                  </Flex>
               </Flex>

               <HorizontalSeparator />

               <Flex
                  flexDirectionMobile={'column'}
                  justifyContent={'space-between'}
                  backgroundColor={theme.colors.grayBackground}
               >
                  <StyledText
                     text="OneCoworking GmBH 2021 All rights reserved"
                     variant={StyledTextVariantsType.PARAGRAPH_2}
                     justifyContentMobile={'center'}
                     colorVariant={theme.colors.black}
                     margin={'32px 0 32px 0'}
                     marginMobile={'16px 0 16px 0'}
                  />

                  <Flex
                     justifyContent={'flex-end'}
                     justifyContentMobile={'center'}
                     alignItems={'center'}
                     width={'auto'}
                     backgroundColor={theme.colors.grayBackground}
                  >
                     <img
                        src={FacebookIcon}
                        style={{ height: 32, width: 'auto', cursor: 'pointer', marginRight: 24 }}
                        alt="facebook"
                        onClick={() => {
                           goToPage(config.FACEBOOK_URL);
                        }}
                     />
                     <img
                        src={InstagramIcon}
                        style={{ height: 32, width: 'auto', cursor: 'pointer', marginRight: 24 }}
                        alt="instagram"
                        onClick={() => {
                           goToPage(config.INSTAGRAM_URL);
                        }}
                     />
                     <img
                        src={LinkedinIcon}
                        style={{ height: 32, width: 'auto', cursor: 'pointer', marginRight: 24 }}
                        alt="linkedin"
                        onClick={() => {
                           goToPage(config.LINKEDIN_URL);
                        }}
                     />
                     <img
                        src={TwitterIcon}
                        style={{ height: 32, width: 'auto', cursor: 'pointer' }}
                        alt="twitter"
                        onClick={() => {
                           goToPage(config.TWITTER_URL);
                        }}
                     />
                  </Flex>
               </Flex>
            </Flex>
         </footer>
      </FooterWrapper>
   );
};

export default Footer;
