import { createIntl, createIntlCache } from 'react-intl';

import { config } from 'src/app/config';
import en from './en';

export const translations = {
   en,
};

const cache = createIntlCache();

let int = createIntl(
   {
      locale: config.DEFAULT_LANGUAGE,
      // @ts-ignore
      messages: translations[config.DEFAULT_LANGUAGE],
   },
   cache,
);

export const changeLanguage = (lang: string) => {
   int = createIntl(
      {
         locale: lang,
         // @ts-ignore
         messages: translations[lang],
      },
      cache,
   );
};

export const translate = (id: string, values?: {}) => {
   return int.formatMessage({ id }, values);
};
