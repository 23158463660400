// @ts-nocheck
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { InfoWindow } from 'google-maps-react';

// By default, InfoWindow does not support to attach event handlers to info window dynamic content.

export default function InfoWindowExtended(props: any) {
   const infoWindowRef: any = React.createRef();
   const contentElement = document.createElement(`div`);

   useEffect(() => {
      ReactDOM.render(React.Children.only(props.children), contentElement);
      infoWindowRef.current.infowindow.setContent(contentElement);
      // eslint-disable-next-line
   }, [props.children]);
   return <InfoWindow ref={infoWindowRef} {...props} />;
}
