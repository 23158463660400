import React, { ReactElement, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Flex, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { SettingsItem } from '../../shared/SharedComponents';

import {
   FacebookUnlinked,
   FacebookLinked,
   GoogleUnlinked,
   GoogleLinked,
   AppleUnlinked,
   AppleLinked,
   PurpleArrowLeft,
} from 'src/assets/images';
import { translate } from 'src/translations/translations';
import { parseError } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import HeaderWrapper from 'src/components/HeaderWrapper';

enum SocialMediaEnum {
   FACEBOOK = 'facebook',
   GOOGLE = 'google',
   APPLE = 'apple',
}

const SocialMedia = (): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(false);

   const linkAccount = async (socialMedia: SocialMediaEnum) => {
      const firebase = getFirebase();

      try {
         setLoading(true);
         let provider;

         if (socialMedia === SocialMediaEnum.FACEBOOK) {
            provider = new (firebase as any).auth.FacebookAuthProvider();
         } else if (socialMedia === SocialMediaEnum.GOOGLE) {
            provider = new (firebase as any).auth.GoogleAuthProvider();
         } else if (socialMedia === SocialMediaEnum.APPLE) {
            provider = new (firebase as any).auth.OAuthProvider('apple.com');
         }

         await firebase.auth().currentUser?.linkWithPopup(provider);
         await firebase.reloadAuth();
      } catch (error) {
         NotificationsProvider.error(parseError(error) || 'common.error.linkAccount');
      } finally {
         setLoading(false);
      }
   };

   const goBack = () => {
      history.back();
   };

   const isConnected = (providerId: string) => {
      return currentUser && currentUser.providerData.find((item: any) => item.providerId === providerId);
   };

   const renderLinkItems = () => (
      <>
         <SettingsItem
            onClick={async () => {
               await linkAccount(SocialMediaEnum.FACEBOOK);
            }}
         >
            <Flex width={'100%'} alignItems={'center'}>
               {isConnected('facebook.com') ? <FacebookLinked /> : <FacebookUnlinked />}
               <Flex
                  width={'auto'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  margin={'0 0 0 20px'}
                  widthMobile={'auto'}
               >
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={'Facebook'}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 4px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={
                        isConnected('facebook.com') ? translate('common.connected') : translate('common.notConnected')
                     }
                     variant={StyledTextVariantsType.PARAGRAPH_3}
                     colorVariant={theme.colors.gray}
                  />
               </Flex>
            </Flex>
         </SettingsItem>

         <SettingsItem
            onClick={async () => {
               await linkAccount(SocialMediaEnum.GOOGLE);
            }}
         >
            <Flex width={'100%'} alignItems={'center'}>
               {isConnected('google.com') ? <GoogleLinked /> : <GoogleUnlinked />}
               <Flex
                  width={'auto'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  margin={'0 0 0 20px'}
                  widthMobile={'auto'}
               >
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={'Google'}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 4px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={isConnected('google.com') ? translate('common.connected') : translate('common.notConnected')}
                     variant={StyledTextVariantsType.PARAGRAPH_3}
                     colorVariant={theme.colors.gray}
                  />
               </Flex>
            </Flex>
         </SettingsItem>

         <SettingsItem
            onClick={async () => {
               await linkAccount(SocialMediaEnum.APPLE);
            }}
         >
            <Flex width={'100%'} alignItems={'center'}>
               {isConnected('apple.com') ? <AppleLinked /> : <AppleUnlinked />}
               <Flex
                  width={'auto'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  margin={'0 0 0 20px'}
                  widthMobile={'auto'}
               >
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={'Apple'}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 4px 0'}
                  />
                  <StyledText
                     width={'100%'}
                     justifyContent={'flex-start'}
                     text={isConnected('apple.com') ? translate('common.connected') : translate('common.notConnected')}
                     variant={StyledTextVariantsType.PARAGRAPH_3}
                     colorVariant={theme.colors.gray}
                  />
               </Flex>
            </Flex>
         </SettingsItem>
      </>
   );

   return (
      <>
         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('settings.account.signInMethods.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 32px'}
            />
         </HeaderWrapper>

         {loading ? <CircularProgress size={40} /> : renderLinkItems()}

         <StyledText
            width={'100%'}
            justifyContent={'flex-start'}
            text={translate('settings.account.signInMethods.subtitle')}
            variant={StyledTextVariantsType.PARAGRAPH_2}
            margin={'0 0 32px 0'}
         />
      </>
   );
};

export default SocialMedia;
