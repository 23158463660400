import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { config } from 'src/app/config';

import { StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { AvatarPlaceholder } from 'src/assets/images';

const Wrapper = styled.div<any>`
   display: flex;
   align-items: center;
   justify-content: flex-start;
   height: auto;
   width: ${(props) => props.width || '100%'};
   margin: ${(props) => props.margin || '0'};

   @media only screen and (max-width: 768px) {
      justify-content: space-between;
   }
`;

const NameAndEmailContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: center;
   height: 100%;
   width: 70%;
`;

const ImageContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-end;
   height: 100%;
   width: 30%;

   img {
      height: 77px;
      width: 77px;
      object-fit: cover;
      border-radius: 15px;
   }
`;

interface IProps {
   margin?: string;
   width?: string;
   image: string;
   name: string;
   email: string;
}

const ProfileInfo = ({ name, margin, width, image, email }: IProps): ReactElement => {
   const getImageUrl = () => {
      if (!image) {
         return '';
      }

      if (image.substring(0, 12) === '/user-images') {
         return `${config.STORAGE_URL}${image}`;
      } else {
         return image;
      }
   };

   return (
      <Wrapper margin={margin} width={width}>
         <NameAndEmailContainer>
            <StyledText
               width={'100%'}
               text={`Hi ${name}!`}
               variant={StyledTextVariantsType.HEADING_2}
               margin={'0 0 6px 0'}
            />

            <StyledText width={'100%'} text={email} variant={StyledTextVariantsType.PARAGRAPH_2} />
         </NameAndEmailContainer>

         <ImageContainer>
            <img
               src={getImageUrl() || AvatarPlaceholder}
               alt="profile"
               onError={({ currentTarget }) => {
                  currentTarget.src = AvatarPlaceholder;
               }}
            />
         </ImageContainer>
      </Wrapper>
   );
};

export default ProfileInfo;
