import React, { ReactElement, useState, useEffect } from 'react';
import { getFirebase } from 'react-redux-firebase';
import styled from 'styled-components';

import { Button, Flex, Input, Spinner, StyledText, CountryDropdown } from 'src/components';
import { ButtonVariantsType } from 'src/components/Button';
import { StyledTextVariantsType } from 'src/components/StyledText';

import { parseError, useWindowWide } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import { isValidHttpUrl } from 'src/utilities/helpers';

import NotificationsProvider from 'src/utilities/notifications-provider';
import ApiRequests from 'src/features/space_management/data/ApiRequests';
import { IUpdateSpaceRequestDTO } from '../../data/RequestDtos';
import { useLocation } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { RoutesEnum } from 'src/routes/routes';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

const INITIAL_STATE: IUpdateSpaceRequestDTO = {
   uuid: '',
   space_id: '',
   name: '',
   provider: '',
   wifi_text: '',
   coffee_text: '',
   printer_text: '',
   website: '',
   instagram: '',
   facebook: '',
   twitter: '',
   description: '',
};

const AddressContainer = styled.div`
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;

   @media only screen and (max-width: 768px) {
      flex-direction: column;
   }
`;
export const LabelWrapper = styled.div<{
   width?: string;
}>`
   display: inline;
   width: ${(props) => (props.width ? props.width : '')};

   & > div,
   span {
      display: inline;
   }
`;
export const LabelStar = styled.span`
   display: inline;
   color: red;
   margin-left: 5px;
   font-size: 18px;
   font-weight: bold;
`;

const BasicDetails = (): ReactElement => {
   const location = useLocation();

   console.log(location);
   const [spaceId, setSpaceId] = useState(location.state.spaceId);

   console.log(spaceId);
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(true);
   const [updateLoading, setUpdateLoading] = useState(false);

   const wide = useWindowWide(768);

   const goBack = () => {
      history.back();
   };

   const [basicDetails, setBasicDetails] = useState<IUpdateSpaceRequestDTO>({
      ...INITIAL_STATE,
      uuid: currentUser?.uid as string,
      space_id: spaceId,
   });

   const isFormValid = () => {
      return (
         basicDetails.uuid &&
         basicDetails.space_id &&
         basicDetails.name &&
         basicDetails.description &&
         basicDetails.wifi_text &&
         basicDetails.coffee_text &&
         basicDetails.printer_text &&
         basicDetails.website
      );
   };

   const getSpaceDetails = async () => {
      try {
         setLoading(true);
         const response = await ApiRequests.getSpaceDetails(spaceId);
         if (response && response.data) {
            setBasicDetails({
               uuid: currentUser?.uid as string,
               space_id: spaceId,
               name: response.data.name,
               description: response.data.description,
               wifi_text: response.data.wiFiText,
               coffee_text: response.data.coffeeText,
               printer_text: response.data.printerText,
               website: response.data.website,
               instagram: response.data.instagram,
               facebook: response.data.facebook,
               twitter: response.data.twitter,
               provider: response.data.provider,
            });
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.getSpaceAddressDetails'));
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.getSpaceAddressDetails'));
      } finally {
         setLoading(false);
      }
   };

   const updateSpaceDetails = async () => {
      setUpdateLoading(true);
      try {
         const response: any = await ApiRequests.updateSpace({
            payload: { ...basicDetails, uuid: currentUser?.uid },
         });
         if (response.success) {
            NotificationsProvider.success(translate('spaceManagement.success.spaceDetailsUpdated'));
         } else {
            NotificationsProvider.error(translate('spaceManagement.error.updateSpaceBasicDetails'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('spaceManagement.error.updateSpaceBasicDetails'));
      } finally {
         setUpdateLoading(false);
      }
   };

   useEffect(() => {
      if (currentUser && spaceId) {
         void getSpaceDetails();
      }
      //eslint-disable-next-line
   }, [currentUser, spaceId]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('spaceManagement.settings.basicDetails.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('spaceManagement.settings.basicDetails.title')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 16px 0'}
            placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.name')}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.name')}
            onChange={(value) => setBasicDetails({ ...basicDetails, name: value })}
            validators={['required']}
            value={basicDetails.name}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 16px 0'}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.description')}
            placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.description')}
            onChange={(value) => setBasicDetails({ ...basicDetails, description: value })}
            validators={['required']}
            value={basicDetails.description}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 16px 0'}
            placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.provider')}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.provider')}
            onChange={(value) => setBasicDetails({ ...basicDetails, provider: value })}
            value={basicDetails.provider}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 16px 0'}
            placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.wifiText')}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.wifiText')}
            onChange={(value) => setBasicDetails({ ...basicDetails, wifi_text: value })}
            validators={['required']}
            value={basicDetails.wifi_text}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 16px 0'}
            placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.coffeeText')}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.coffeeText')}
            onChange={(value) => setBasicDetails({ ...basicDetails, coffee_text: value })}
            validators={['required']}
            value={basicDetails.coffee_text}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 16px 0'}
            placeholder={translate('spaceManagement.settings.addSpaceForm.placeholders.printerText')}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.printerText')}
            onChange={(value) => setBasicDetails({ ...basicDetails, printer_text: value })}
            validators={['required']}
            value={basicDetails.printer_text}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 16px 0'}
            placeholder={'www.example.com'}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.website')}
            onChange={(value) => setBasicDetails({ ...basicDetails, website: value })}
            validators={['required']}
            value={basicDetails.website}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 16px 0'}
            placeholder={'www.instagram.com/example'}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.instagram')}
            onChange={(value) => setBasicDetails({ ...basicDetails, instagram: value })}
            value={basicDetails.instagram}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 16px 0'}
            placeholder={'www.facebook.com/example'}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.facebook')}
            onChange={(value) => setBasicDetails({ ...basicDetails, facebook: value })}
            value={basicDetails.facebook}
         />
         <Input
            width={'100%'}
            type={'text'}
            margin={'0 0 32px 0'}
            label={translate('spaceManagement.settings.addSpaceForm.placeholders.twitter')}
            placeholder={'www.twitter.com/example'}
            onChange={(value) => setBasicDetails({ ...basicDetails, twitter: value })}
            value={basicDetails.twitter}
         />
         <Button
            disabled={!isFormValid()}
            isLoading={updateLoading}
            variant={ButtonVariantsType.PURPLE}
            text={translate('common.saveChanges')}
            width={'100%'}
            onClick={updateSpaceDetails}
         />
      </Flex>
   );
};

export default BasicDetails;
