import React, { ReactElement, useEffect, useState } from 'react';

import { Flex, ModalComponent, Spinner, StyledText, Table } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { TableColumn, TableItemAction } from 'src/components/table/interfaces';

import ApiRequests from '../../data/ApiRequests';
import { ICompany } from '../../interfaces';
import { parseError } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { config } from 'src/app/config';

interface IProps {
   isOpen: boolean;
   setShowModal: (value: boolean) => void;
   company: ICompany;
   getCompanyManagers: () => void;
}

const AddAdminModal = ({ setShowModal, isOpen, company, getCompanyManagers }: IProps): ReactElement => {
   const [loading, setLoading] = useState(false);
   const [teamMembers, setTeamMembers] = useState<any>([]);

   const columns: Array<TableColumn<any>> = [
      {
         title: '',
         key: 'image',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (member: any) => `${member.photo ? config.STORAGE_URL + member.photo : ''}`,
      },
      {
         title: 'User name',
         key: 'name',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (member: any) => <>{member.name}</>,
      },
      {
         title: 'Email',
         key: 'email',
         enableSort: false,
         // eslint-disable-next-line react/display-name
         rowParser: (member: any) => <>{member.email}</>,
      },
   ];

   const itemActions: Array<TableItemAction<any>> = [
      {
         title: translate('hrManagement.settings.companyManagers.addNewAdmin.modal.actionButton.text'),
         clickHandler: async (member: any) => {
            await promoteToAdmin(member.uuid);
         },
      },
   ];

   const getCompanyTeamMembers = async () => {
      setLoading(true);

      if (company && company.enterprise_id) {
         try {
            const response = await ApiRequests.getCompanyTeamMembers(company.enterprise_id);
            setTeamMembers(response.data);
         } catch (error) {
            NotificationsProvider.error(
               parseError(error) || translate('hrManagement.settings.error.getCompanyTeamMembers'),
            );
         } finally {
            setLoading(false);
         }
      } else {
         setLoading(false);
         NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }
   };

   // eslint-disable-next-line
   const promoteToAdmin = async (memberId: string) => {
      if (company && company.enterprise_id) {
         try {
            const response: any = await ApiRequests.addCompanyManager({
               payload: {
                  uuid: memberId,
                  enterprise_id: company.enterprise_id,
               },
            });

            if (response.data) {
               NotificationsProvider.success(response.data.message);
               setShowModal(false);
               await getCompanyManagers();
            } else {
               setShowModal(false);
               NotificationsProvider.error(response.error?.message);
            }
         } catch (error) {
            NotificationsProvider.error(parseError(error) || translate('hrManagement.settings.error.addTeamMember'));
         } finally {
            setLoading(false);
         }
      } else {
         setLoading(false);
         NotificationsProvider.error(translate('hrManagement.settings.error.missingCompanyId'));
      }
   };

   useEffect(() => {
      void getCompanyTeamMembers();
      //eslint-disable-next-line
   }, []);

   return (
      <ModalComponent
         size="medium"
         visible={isOpen}
         width={'60%'}
         closeModal={() => {
            setTeamMembers([]);
            setShowModal(false);
         }}
      >
         <Flex
            flexDirection="column"
            width={'100%'}
            minHeight={'40vh'}
            justifyContent="flex-start"
            alignItems="flex-start"
            backgroundColor={'white'}
         >
            <StyledText
               justifyContent={'flex-start'}
               text={translate('hrManagement.settings.companyManagers.addNewAdmin.modal.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 16px 0'}
            />

            <StyledText
               justifyContent={'flex-start'}
               text={translate('hrManagement.settings.companyManagers.addNewAdmin.modal.subtitle')}
               variant={StyledTextVariantsType.PARAGRAPH_1}
               margin={'0 0 24px 0'}
            />

            {loading ? (
               <Spinner height={'400px'} />
            ) : (
               <Table
                  onSelectAll={() => null}
                  columns={columns}
                  data={teamMembers}
                  itemActions={itemActions}
                  customClass={'w-100'}
               />
            )}
         </Flex>
      </ModalComponent>
   );
};

export default AddAdminModal;
