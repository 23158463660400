import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Flex, StyledText, Spinner, Button } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { ButtonVariantsType } from 'src/components/Button';

import AddCreditCardModal from '../modals/add_credit_card/AddCreditCardModal';
import EditCreditCardModal from '../modals/edit_credit_card/EditCreditCardModal';

import { CardIcon, PurpleArrowLeft } from 'src/assets/images';
import ApiRequests from 'src/features/settings/data/ApiRequests';
import { capitalize, parseError } from 'src/utilities/helpers';
import { translate } from 'src/translations/translations';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { getFirebase } from 'react-redux-firebase';
import HeaderWrapper from 'src/components/HeaderWrapper';

export const CreditCardItem = styled.div`
   width: 100%;
   height: auto;
   display: flex;
   justify-content: flex-start;
   align-items: center;
   border-radius: 15px;
   padding: 24px;
   margin-bottom: 24px;
   background: ${theme.colors.white};
   border: 1px solid ${theme.colors.lightGray};
   cursor: pointer;

   &:hover {
      border: 1px solid ${theme.colors.mainPurple};
   }

   div {
      cursor: pointer;
   }
`;

export type ActionType = 'setAsDefault' | 'deleteCard';

interface IProps {
   minHeight?: string;
}

const CreditCards = ({ minHeight }: IProps): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;

   const [loading, setLoading] = useState(true);
   const [confirmEditLoading, setConfirmEditLoading] = useState(false);

   const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<any>(null);
   const [paymentMethods, setPaymentMethods] = useState<any>([]);
   const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>(null);
   const [showEditPaymentMethodModal, setShowEditPaymentMethodModal] = useState(false);
   const [showAddCreditCardModal, setShowAddCreditCardModal] = useState(false);

   const confirmEditCard = async (paymentMethod: any, action: ActionType) => {
      setConfirmEditLoading(true);

      switch (action) {
         case 'setAsDefault':
            await setAsDefault(paymentMethod);
            setConfirmEditLoading(false);
            return;
         case 'deleteCard':
            await deletePaymentMethod(paymentMethod);
            setConfirmEditLoading(false);
            return;
         default:
            setConfirmEditLoading(true);
            return;
      }
   };
   const goBack = () => {
      history.back();
   };

   const filterDefaultPaymentMethod = (paymentMethods: any) => {
      const found = paymentMethods.find((item: any) => item?.is_default);
      setDefaultPaymentMethod(found);
   };

   const getPaymentMethods = async () => {
      try {
         const response = await ApiRequests.getPaymentMethods(currentUser?.uid as string);
         if (response.data?.payment_methods) {
            const filtered = response.data.payment_methods.filter((item: any) => item?.is_default !== true);
            setPaymentMethods(filtered);
            filterDefaultPaymentMethod(response.data.payment_methods);
         }
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('settings.manageCreditCards.error.getPaymentMethods'),
         );
      } finally {
         setLoading(false);
      }
   };

   const setAsDefault = async (paymentMethod: any) => {
      try {
         await ApiRequests.setDefaultPaymentMethod(currentUser?.uid as string, paymentMethod.id);
         setDefaultPaymentMethod(selectedPaymentMethod);
         await getPaymentMethods();
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('settings.manageCreditCards.error.setAsDefault'));
      } finally {
         setShowEditPaymentMethodModal(false);
      }
   };

   const deletePaymentMethod = async (paymentMethod: any) => {
      if (paymentMethod.id === defaultPaymentMethod?.id) {
         return NotificationsProvider.error(translate('settings.manageCreditCards.error.deleteDefaultAttempt'));
      }

      try {
         await ApiRequests.deletePaymentMethod(currentUser?.uid as string, paymentMethod.id);
         await getPaymentMethods();
      } catch (error) {
         NotificationsProvider.error(
            parseError(error) || translate('settings.manageCreditCards.error.deletePaymentMethod'),
         );
      } finally {
         setShowEditPaymentMethodModal(false);
      }
   };

   useEffect(() => {
      currentUser && void getPaymentMethods();
      //eslint-disable-next-line
   }, [currentUser]);

   if (loading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <>
         {showEditPaymentMethodModal && selectedPaymentMethod && (
            <EditCreditCardModal
               loading={confirmEditLoading}
               selectedPaymentMethod={selectedPaymentMethod}
               isOpen={showEditPaymentMethodModal}
               setShowModal={setShowEditPaymentMethodModal}
               onConfirmClickHandler={confirmEditCard}
            />
         )}

         {showAddCreditCardModal && (
            <AddCreditCardModal
               isOpen={showAddCreditCardModal}
               setShowModal={setShowAddCreditCardModal}
               getPaymentMethods={getPaymentMethods}
            />
         )}

         <HeaderWrapper>
            <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('settings.manageCreditCards.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 0 32px'}
            />
         </HeaderWrapper>

         <Flex
            minHeight={minHeight || '100vh'}
            width={'100%'}
            height={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
         >
            {defaultPaymentMethod && (
               <>
                  <StyledText
                     width={'100%'}
                     text={translate('settings.manageCreditCards.defaultCard.title')}
                     variant={StyledTextVariantsType.HEADING_3}
                     margin={'0 0 16px 0'}
                  />
                  <CreditCardItem
                     onClick={() => {
                        setSelectedPaymentMethod(defaultPaymentMethod);
                        setShowEditPaymentMethodModal(true);
                     }}
                  >
                     <Flex
                        flexDirection="column"
                        width={'80%'}
                        height={'100%'}
                        justifyContent="center"
                        alignItems="flex-start"
                     >
                        <StyledText
                           width={'95%'}
                           text={capitalize(defaultPaymentMethod.card.brand)}
                           variant={StyledTextVariantsType.LABEL_TEXT_2}
                           margin={'0 0 5px 0'}
                        />
                        <StyledText
                           width={'95%'}
                           text={`${translate('common.currency')}: ${defaultPaymentMethod.card.country}`}
                           variant={StyledTextVariantsType.PARAGRAPH_3}
                           colorVariant={theme.colors.gray}
                           margin={'0 0 52px 0'}
                        />
                        <StyledText
                           width={'95%'}
                           text={`${translate('settings.manageCreditCards.card.expiresOn')} ${
                              defaultPaymentMethod.card.exp_month
                           }/${defaultPaymentMethod.card.exp_year}`}
                           variant={StyledTextVariantsType.PARAGRAPH_3}
                           colorVariant={theme.colors.gray}
                           margin={'0 5px 0 0'}
                        />
                        <StyledText
                           width={'95%'}
                           text={`**** **** **** ${defaultPaymentMethod.card.last4}`}
                           variant={StyledTextVariantsType.HEADING_3}
                        />
                     </Flex>
                     <Flex
                        flexDirection="column"
                        width={'20%'}
                        height={'100%'}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                     >
                        <CardIcon height={24} />
                     </Flex>
                  </CreditCardItem>
               </>
            )}

            {(!paymentMethods || paymentMethods.length === 0) && !defaultPaymentMethod && (
               <StyledText
                  width={'100%'}
                  text={'Payment methods not added yet'}
                  variant={StyledTextVariantsType.PARAGRAPH_2}
               />
            )}

            {paymentMethods && paymentMethods.length > 0 && (
               <StyledText
                  width={'100%'}
                  text={translate('settings.manageCreditCards.otherCards.title')}
                  variant={StyledTextVariantsType.HEADING_3}
                  margin={'0 0 16px 0'}
               />
            )}
            {paymentMethods.map((item: any, index: number) => (
               <CreditCardItem
                  key={index}
                  onClick={() => {
                     setSelectedPaymentMethod(item);
                     setShowEditPaymentMethodModal(true);
                  }}
               >
                  <Flex
                     flexDirection="column"
                     width={'50%'}
                     height={'100%'}
                     justifyContent="center"
                     alignItems="flex-start"
                  >
                     <StyledText
                        width={'95%'}
                        text={capitalize(item.card.brand)}
                        variant={StyledTextVariantsType.LABEL_TEXT_2}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'95%'}
                        text={`${translate('settings.manageCreditCards.card.expiresOn')} ${item.card.exp_month}/${
                           item.card.exp_year
                        }`}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 5px 0 0'}
                     />
                  </Flex>
                  <Flex
                     flexDirection="column"
                     width={'50%'}
                     height={'100%'}
                     justifyContent="center"
                     alignItems="flex-end"
                  >
                     <StyledText
                        width={'95%'}
                        text={`**** **** **** ${item.card.last4}`}
                        variant={StyledTextVariantsType.HEADING_3}
                        justifyContent={'flex-end'}
                     />
                  </Flex>
               </CreditCardItem>
            ))}
            <Button
               variant={ButtonVariantsType.PURPLE}
               margin={'80px 0 0 0'}
               text={translate('settings.manageCreditCards.addCreditCard.button.text')}
               width={'100%'}
               onClick={() => {
                  setShowAddCreditCardModal(true);
               }}
            />
         </Flex>
      </>
   );
};

export default CreditCards;
