import React, { ReactElement, useState } from 'react';
import { getFirebase } from 'react-redux-firebase';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

import { Button, Flex, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';
import { ButtonVariantsType } from 'src/components/Button';
import { InlineElementsWrapper } from 'src/components/SharedComponents';

import CancelPlanModal from './modals/cancel_plan/CancelPlanModal';
import ActivateVoucherModal from '../modals/voucher/ActivateVoucherModal';

import {
   SettingsItemTitle,
   SettingsItemIcon,
   SettingsItemContent,
   SettingsItemArrow,
   SettingsItem,
   SettingsCardItem,
} from 'src/features/settings/shared/SharedComponents';
import { BillingSettingsItemEnum } from 'src/features/enterprise_management/settings/billing/EnterpriseBilling';
import { ICompany } from 'src/features/enterprise_management/interfaces';

import SettingsApiRequests from 'src/features/settings/data/ApiRequests';
import { UserEditIcon, ChevronRightElement, ShieldTickIcon, PurpleArrowLeft } from 'src/assets/images';
import { translate } from 'src/translations/translations';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import theme from 'src/styles/theme';
import { RoutesEnum } from 'src/routes/routes';
import { useHistory } from 'react-router';
import HeaderWrapper from 'src/components/HeaderWrapper';

interface IProps {
   company: ICompany;
   getCompanyDetails: () => void;
   activeBillingSettingsItem: BillingSettingsItemEnum;
   setActiveBillingSettingsItem: (item: BillingSettingsItemEnum) => void;
   isSettingItemSelected: (selectedItem: BillingSettingsItemEnum, item: BillingSettingsItemEnum) => boolean;
}

const EnterpriseBillingRoot = ({
   company,
   getCompanyDetails,
   activeBillingSettingsItem,
   setActiveBillingSettingsItem,
   isSettingItemSelected,
}: IProps): ReactElement => {
   const firebase = getFirebase();
   const currentUser = firebase.auth()?.currentUser;
   const history = useHistory();

   const wide = useWindowWide(768);

   const goBack = () => {
      history.goBack();
   };

   const [cancelPlanLoading, setCancelPlanLoading] = useState(false);
   const [showCancelModal, setShowCancelModal] = useState(false);
   const [showActivateVoucherModal, setShowActivateVoucherModal] = useState(false);

   const cancelPlan = async () => {
      try {
         setCancelPlanLoading(true);
         const response: any = await SettingsApiRequests.cancelPlan(currentUser?.uid as string);

         if (response.data) {
            NotificationsProvider.success(response.data.message);
            await getCompanyDetails();
         } else {
            NotificationsProvider.error(translate('settings.billing.error.cancelPlan'));
         }
      } catch (error) {
         NotificationsProvider.error(parseError(error) || translate('settings.billing.error.cancelPlan'));
      } finally {
         setCancelPlanLoading(false);
         setShowCancelModal(false);
      }
   };

   const renderPurchasePlanListItem = () => (
      <SettingsItem
         className={`${
            isSettingItemSelected(activeBillingSettingsItem, BillingSettingsItemEnum.PURCHASE_PLAN) ? 'active' : ''
         }`}
         onClick={() => {
            setActiveBillingSettingsItem(BillingSettingsItemEnum.PURCHASE_PLAN);
            history.push({
               pathname: RoutesEnum.ENTERPRISE_SETTINGS_PURCHASE_PLAN,
               state: {
                  company: company,
               },
            });
         }}
      >
         <SettingsItemIcon>
            <UserEditIcon />
         </SettingsItemIcon>
         <SettingsItemContent>
            <SettingsItemTitle>
               <StyledText
                  width={'100%'}
                  text={translate('settings.billing.purchasePlan.subtitle')}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
                  colorVariant={theme.colors.gray}
                  margin={'0 0 5px 0'}
               />
               <StyledText
                  width={'100%'}
                  text={translate('settings.billing.purchasePlan.title')}
                  variant={StyledTextVariantsType.HEADING_3}
               />
            </SettingsItemTitle>
            <SettingsItemArrow>
               <ChevronRightElement />
            </SettingsItemArrow>
         </SettingsItemContent>
      </SettingsItem>
   );

   const renderActiveEnerprisePlanListItem = () => (
      <SettingsCardItem>
         <CancelPlanModal
            loading={cancelPlanLoading}
            setShowModal={setShowCancelModal}
            isOpen={showCancelModal}
            onCancelClickHandler={cancelPlan}
         />
         <Flex
            flexDirection={'column'}
            width={'100%'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            backgroundColor={theme.colors.grayBackground}
         >
            <StyledText
               width={'100%'}
               text={translate('settings.billing.activePlan.subtitle')}
               variant={StyledTextVariantsType.PARAGRAPH_3}
               margin={'0 0 5px 0'}
            />
            <StyledText
               width={'100%'}
               margin={'0 0 5px 0'}
               text={company?.subscription?.name}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <InlineElementsWrapper margin={'0 0 24px 0'}>
               <StyledText
                  text={translate('settings.billing.activePlan.renewText')}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
               />
               <StyledText
                  text={moment(company?.subscription?.renewing_on).format('DD/MM/YYYY')}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
                  margin={'0 0 0 5px'}
               />
            </InlineElementsWrapper>
            <Flex
               justifyContent={'space-between'}
               width={'100%'}
               backgroundColor={theme.colors.grayBackground}
               flexDirectionMobile={'column'}
            >
               <Button
                  variant={ButtonVariantsType.WHITE}
                  text={translate('settings.billing.activePlan.changeSubscription.button.text')}
                  width={'48%'}
                  onClick={() => {
                     setActiveBillingSettingsItem(BillingSettingsItemEnum.PURCHASE_PLAN);
                     history.push({
                        pathname: RoutesEnum.ENTERPRISE_SETTINGS_PURCHASE_PLAN,
                        state: {
                           company: company,
                        },
                     });
                  }}
               />
               <Button
                  variant={ButtonVariantsType.RED_BORDER}
                  text={translate('settings.billing.activePlan.cancelSubscription.button.text')}
                  width={'48%'}
                  onClick={() => {
                     setShowCancelModal(true);
                  }}
               />
            </Flex>
         </Flex>
      </SettingsCardItem>
   );

   const renderCancelledEnterprisePlanListItem = () => (
      <SettingsCardItem>
         <Flex
            flexDirection={'column'}
            width={'100%'}
            alignItems={'flex-start'}
            justifyContent={'flex-start'}
            backgroundColor={theme.colors.grayBackground}
         >
            <StyledText
               width={'100%'}
               text={translate('settings.billing.activePlan.subtitle')}
               variant={StyledTextVariantsType.PARAGRAPH_3}
               colorVariant={theme.colors.gray}
               margin={'0 0 5px 0'}
            />
            <StyledText
               width={'100%'}
               margin={'0 0 5px 0'}
               colorVariant={theme.colors.red}
               text={company?.subscription?.name}
               variant={StyledTextVariantsType.HEADING_2}
            />
            <InlineElementsWrapper margin={'0 0 24px 0'}>
               <StyledText
                  text={translate('settings.billing.cancelledPlan.cancelText')}
                  colorVariant={theme.colors.red}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
               />
               <StyledText
                  text={moment(company?.subscription?.ends_at).format('DD/MM/YYYY')}
                  variant={StyledTextVariantsType.PARAGRAPH_3}
                  colorVariant={theme.colors.red}
                  margin={'0 0 0 5px'}
               />
            </InlineElementsWrapper>
            <Flex justifyContent={'center'} width={'100%'} backgroundColor={theme.colors.grayBackground}>
               <Button
                  variant={ButtonVariantsType.WHITE}
                  text={translate('settings.billing.cancelledPlan.button.text')}
                  width={'100%'}
                  onClick={() => {
                     setActiveBillingSettingsItem(BillingSettingsItemEnum.PURCHASE_PLAN);
                     history.push({
                        pathname: RoutesEnum.ENTERPRISE_SETTINGS_PURCHASE_PLAN,
                        state: {
                           company: company,
                        },
                     });
                  }}
               />
            </Flex>
         </Flex>
      </SettingsCardItem>
   );

   return (
      <Flex
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="center"
         height={'100%'}
         width={'100%'}
         position={'relative'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('hrManagement.settings.billingDetails.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('hrManagement.settings.billingDetails.title')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}

         <ActivateVoucherModal
            company={company}
            isOpen={showActivateVoucherModal}
            setShowModal={setShowActivateVoucherModal}
         />

         <Flex
            minHeight={'100vh'}
            width={'100%'}
            height={'100%'}
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            position={'relative'}
            padding={'0 20px 0 0'}
            borderRight={`1px solid ${theme.colors.gray}`}
         >
            {!company && <CircularProgress size={50} />}

            {company && !company.subscription && renderPurchasePlanListItem()}

            {company &&
               company.subscription &&
               !company.subscription?.is_canceled &&
               renderActiveEnerprisePlanListItem()}

            {company &&
               company.subscription &&
               company.subscription.is_canceled &&
               renderCancelledEnterprisePlanListItem()}

            <SettingsItem
               onClick={() => {
                  setShowActivateVoucherModal(true);
               }}
            >
               <SettingsItemIcon>
                  <ShieldTickIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.activateVoucher.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.activateVoucher.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${
                  isSettingItemSelected(activeBillingSettingsItem, BillingSettingsItemEnum.MANAGE_CREDIT_CARDS)
                     ? 'active'
                     : ''
               }`}
               onClick={() => {
                  setActiveBillingSettingsItem(BillingSettingsItemEnum.MANAGE_CREDIT_CARDS);
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_SETTINGS_CREDIT_CARDS,
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.manageCreditCards.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.manageCreditCards.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${
                  isSettingItemSelected(activeBillingSettingsItem, BillingSettingsItemEnum.BILLING_ADDRESS)
                     ? 'active'
                     : ''
               }`}
               onClick={() => {
                  setActiveBillingSettingsItem(BillingSettingsItemEnum.BILLING_ADDRESS);
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_SETTINGS_BILLING_ADDRESS,
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.billingAddress.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.billingAddress.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>

            <SettingsItem
               className={`${
                  isSettingItemSelected(activeBillingSettingsItem, BillingSettingsItemEnum.INVOICES) ? 'active' : ''
               }`}
               onClick={() => {
                  setActiveBillingSettingsItem(BillingSettingsItemEnum.INVOICES);
                  history.push({
                     pathname: RoutesEnum.ENTERPRISE_SETTINGS_INVOICES,
                  });
               }}
            >
               <SettingsItemIcon>
                  <UserEditIcon />
               </SettingsItemIcon>
               <SettingsItemContent>
                  <SettingsItemTitle>
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.invoices.subtitle')}
                        variant={StyledTextVariantsType.PARAGRAPH_3}
                        colorVariant={theme.colors.gray}
                        margin={'0 0 5px 0'}
                     />
                     <StyledText
                        width={'100%'}
                        text={translate('settings.billing.invoices.title')}
                        variant={StyledTextVariantsType.HEADING_3}
                     />
                  </SettingsItemTitle>
                  <SettingsItemArrow>
                     <ChevronRightElement />
                  </SettingsItemArrow>
               </SettingsItemContent>
            </SettingsItem>
         </Flex>
      </Flex>
   );
};

export default EnterpriseBillingRoot;
