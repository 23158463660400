import { ajaxService } from 'src/services/AjaxService';
import { IRequest, IResponse } from 'src/system/interfaces';
import {
   ICreateSpaceRequestDTO,
   IUpdateSpaceRequestDTO,
   IUpdateSpaceBankDetailsRequestDTO,
   IUpdateSpaceAddressRequestDTO,
   IUpdateSpaceCapacityDTO,
   IDeletePhotoDTO,
   ICheckOutDTO,
} from './RequestDtos';

export default class ApiRequests {
   public static getAllSpaces(manager_uuid: string) {
      return ajaxService.get<IResponse<any>>(`/spaces?manager_uuid=${manager_uuid}`);
   }

   public static getSpaceDetails(space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space?space_id=${space_id}`, {
         noAuthentication: true,
      });
   }

   public static createSpace(request: IRequest<ICreateSpaceRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/space/create', request.payload);
   }

   public static updateSpace(request: IRequest<IUpdateSpaceRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/space/update', request.payload);
   }

   public static updateSpaceAddress(request: IRequest<IUpdateSpaceAddressRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/space/address/update', request.payload);
   }

   public static updateSpaceCapacity(request: IRequest<IUpdateSpaceCapacityDTO>) {
      return ajaxService.post<IResponse<any>>('/space/capacity/update', request.payload);
   }

   public static updateSpaceBankDetails(request: IRequest<IUpdateSpaceBankDetailsRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/space/bank-details/update', request.payload);
   }

   public static getSpaceAddressDetails(manager_uuid: string, space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space/address?manager_uuid=${manager_uuid}&space_id=${space_id}`);
   }

   public static getSpaceBankDetails(manager_uuid: string, space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space/bank-details?manager_uuid=${manager_uuid}&space_id=${space_id}`);
   }

   public static getSpaceManagers(manager_uuid: string, space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space/managers?manager_uuid=${manager_uuid}&space_id=${space_id}`);
   }

   public static getSpaceStatistics(space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space/statistics?space_id=${space_id}`);
   }

   public static getSpaceEarnings(space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space/earnings?space_id=${space_id}`);
   }

   public static getSpaceCheckins(space_id: string, period: string, limit: number, sort: string, order: string) {
      return ajaxService.get<IResponse<any>>(
         `/space/checkins?space_id=${space_id}&period=${period}&limit=${limit}&sort=${sort}&order=${order}`,
      );
   }

   public static getSpaceInsights(space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space/insights?space_id=${space_id}`);
   }

   public static getSpaceGallery(space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space/gallery?space_id=${space_id}`);
   }

   public static getSpaceLogo(space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space/logo?space_id=${space_id}`);
   }

   public static uploadSpaceGallery(request: IRequest<any>) {
      return ajaxService.post<IResponse<any>>(`/space/gallery/upload`, request.payload, {
         headers: { 'Content-Type': 'multipart/form-data' },
      });
   }

   public static uploadSpaceLogo(request: IRequest<any>) {
      return ajaxService.post<IResponse<any>>(`/space/logo/upload`, request.payload, {
         headers: { 'Content-Type': 'multipart/form-data' },
      });
   }
   public static deleteSpacePhoto(request: IRequest<IDeletePhotoDTO>) {
      return ajaxService.post<IResponse<any>>(`/space/image/delete`, request.payload);
   }
   public static checkOutFromSpace(request: IRequest<ICheckOutDTO>) {
      return ajaxService.post<IResponse<any>>(`/space/checkin/checkout`, request.payload);
   }
}
