import React, { ReactElement, useEffect, useState } from 'react';

import { Flex, Spinner, StyledText } from 'src/components';
import { StyledTextVariantsType } from 'src/components/StyledText';

import Logo from './Logo';
import Gallery from './Gallery';

import ApiRequests from '../../data/ApiRequests';
import { translate } from 'src/translations/translations';
import { parseError, useWindowWide } from 'src/utilities/helpers';
import NotificationsProvider from 'src/utilities/notifications-provider';
import { useLocation } from 'react-router';
import HeaderWrapper from 'src/components/HeaderWrapper';
import { PurpleArrowLeft } from 'src/assets/images';

const GalleryAndLogo = (): ReactElement => {
   const location = useLocation();
   const [logoLoading, setLogoLoading] = useState(true);
   const [logo, setLogo] = useState<any>(null);

   const wide = useWindowWide(768);

   const goBack = () => {
      history.back();
   };

   const [galleryLoading, setGalleryLoading] = useState(true);
   const [gallery, setGallery] = useState<any>(null);
   const [spaceId, setSpaceId] = useState<any>(location.state.spaceId);

   const getLogo = async (triggerLoading?: boolean) => {
      try {
         triggerLoading && setLogoLoading(true);
         const response = await ApiRequests.getSpaceLogo(spaceId);

         if (response && response.data) {
            setLogo(response.data.photo);
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.getSpaceLogo'));
      } finally {
         setLogoLoading(false);
      }
   };

   const getGallery = async (triggerLoading?: boolean) => {
      try {
         triggerLoading && setGalleryLoading(true);
         const response = await ApiRequests.getSpaceGallery(spaceId);

         if (response && response.data) {
            setGallery(response.data);
         }
      } catch (err) {
         NotificationsProvider.error(parseError(err) || translate('spaceManagement.error.getSpaceGallery'));
      } finally {
         setGalleryLoading(false);
      }
   };

   useEffect(() => {
      if (spaceId) {
         void getLogo(true);
         void getGallery(true);
      }
      //eslint-disable-next-line
   }, [spaceId]);

   if (logoLoading || galleryLoading) {
      return <Spinner height={'400px'} />;
   }

   return (
      <Flex
         width={'100%'}
         flexDirection="column"
         justifyContent="flex-start"
         alignItems="flex-start"
         position={'relative'}
         padding={'0 0 0 32px'}
         overflow={'hidden'}
      >
         {wide ? (
            <StyledText
               width={'100%'}
               justifyContent={'flex-start'}
               text={translate('spaceManagement.settings.galleryAndLogo.title')}
               variant={StyledTextVariantsType.HEADING_1}
               margin={'0 0 32px 0'}
            />
         ) : (
            <HeaderWrapper>
               <img src={PurpleArrowLeft} style={{ height: 34, cursor: 'pointer' }} alt="navigate" onClick={goBack} />
               <StyledText
                  width={'100%'}
                  justifyContent={'flex-start'}
                  text={translate('spaceManagement.settings.galleryAndLogo.title')}
                  variant={StyledTextVariantsType.HEADING_1}
                  margin={'0 0 0 32px'}
               />
            </HeaderWrapper>
         )}

         <Logo spaceId={spaceId} logo={logo} getLogo={getLogo} />

         <Gallery spaceId={spaceId} gallery={gallery} getGallery={getGallery} />
      </Flex>
   );
};

export default GalleryAndLogo;
