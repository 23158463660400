import { useLocation } from 'react-router-dom';
import { config } from '../app/config';
import NotificationsProvider from './notifications-provider';
import { translate } from '../translations/translations';
import { useEffect, useState } from 'react';

const emailRegex = /.+@[^@]+\.[^@]{2,}$/;

export const useQuery = () => {
   return new URLSearchParams(useLocation().search);
};

export const isNullOrUndefined = (value?: unknown): boolean => {
   return value === undefined || value === null;
};

export const isNotNullOrUndefined = (value?: unknown): boolean => {
   return !isNullOrUndefined(value);
};

export const isEmail = (value?: string): boolean => {
   if (value) {
      return isNotNullOrUndefined(value) && emailRegex.test(value);
   }
   return false;
};

export function isValidHttpUrl(string: string) {
   let url;

   try {
      url = new URL(string);
   } catch (_) {
      return false;
   }

   return url.protocol === 'http:' || url.protocol === 'https:';
}

export const truncate = (str: string | undefined, n: number, ellipsis: string) => {
   if (!str) {
      return '';
   }

   const tooLong = str.length > n;
   const singular = str.search(/\s/) === -1;

   // Edge case where someone enters a ridiculously long string.
   str = tooLong ? str.substr(0, n - 1) : str;

   if (!singular) {
      str = ellipsis && tooLong ? str.substr(0, str.lastIndexOf(' ')) : str;
   }

   return tooLong ? str + ellipsis : str;
};

export const capitalize = (s: string) => {
   if (!s) {
      return '';
   }

   return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getTimeFromTimestamp = (timestamp: number, timeStyle?: string) => {
   if (!timestamp) {
      return '';
   }

   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

   const dtFormat = new Intl.DateTimeFormat('en-GB', {
      timeStyle: timeStyle || 'short',
      timeZone: timeZone || 'UTC',
   } as any);

   return dtFormat.format(new Date(timestamp * 1e3));
};

export const blobToJsonObject = async (blob: any) => {
   return JSON.parse(await blob.text());
};

function deg2rad(deg: number) {
   return deg * (Math.PI / 180);
}

export const calculateLatLongDistanceInKm = (lat1: number, long1: number, lat2: number, long2: number) => {
   const R = 6371; // Radius of the earth in km
   const dLat = deg2rad(lat2 - lat1); // deg2rad below
   const dLon = deg2rad(long2 - long1);

   const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
   const d = R * c; // Distance in km
   return d;
};

const getLocationFromBrowser = () => {
   return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
   });
};

export const scrollToTop = () => {
   window.scrollTo(0, 0);
};

const getLocationFromApi = async () => {
   try {
      const response = await fetch('https://geolocation-db.com/json/');
      const data = await response.json();
      const ipAddress = data.IPv4;
      const geoResponse = await fetch(
         `https://api.ipstack.com/${ipAddress}?access_key=${config.IPSTACK_API_KEY}&format=1`,
      );
      const geoData = await geoResponse.json();
      return { latitude: geoData.latitude, longitude: geoData.longitude };
   } catch (err) {
      NotificationsProvider.error(parseError(err) || translate('mapView.error.getCurrentLocation'));
      return null;
   }
};

export const getCurrentLocation = async () => {
   if (!navigator.geolocation) {
      return getLocationFromApi();
   }

   try {
      const position: any = await getLocationFromBrowser();

      if (position) {
         return {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
         };
      } else {
         return getLocationFromApi();
      }
   } catch (err) {
      return getLocationFromApi();
   }
};

export const parseGoogleAutoCompleteResponse = (response: any) => {
   /* eslint-disable */
   let address = '',
      city = '',
      street_number = '',
      zip_code = '',
      country = '',
      latitude = null,
      longitude = null;

   if (response.hasOwnProperty('address_components') && response.address_components.length) {
      const streetNumberComponent = response.address_components.find((c: any) => c.types.includes('street_number'));
      street_number = streetNumberComponent?.long_name ?? '';

      const addressComponent = response.address_components.find((c: any) => c.types.includes('route'));
      address = addressComponent?.long_name ?? '';

      const cityComponent = response.address_components.find((c: any) => c.types.includes('locality'));
      city = cityComponent?.long_name ?? '';

      const zipCodeComponent = response.address_components.find((c: any) => c.types.includes('postal_code'));
      zip_code = zipCodeComponent?.long_name ?? '';

      const countryComponent = response.address_components.find((c: any) => c.types.includes('country'));
      country = countryComponent?.long_name ?? '';
   }

   if (response.hasOwnProperty('geometry')) {
      latitude = response.geometry.location?.lat().toString() ?? null;
      longitude = response.geometry.location?.lng().toString() ?? null;
   }

   return { address, street_number, city, zip_code, country, latitude, longitude };
};

export const parseError = (error: any, fallbackErrorMessage = '') => {
   if (typeof error === 'string') {
      return error;
   }

   if (error?.response && error?.response?.data) {
      if (error.response.data.message) {
         return error.response.data.message;
      }

      if (error.response.data.error?.message) {
         return error.response.data.error.message;
      }
   }

   if (error?.data) {
      if (error.data?.message) {
         return error.data.message;
      }

      if (error?.data?.error) {
         return error.data.error;
      }
   }

   if (error?.error?.message) {
      return error.error.message;
   }

   if (error?.message) {
      return error.message;
   }

   return fallbackErrorMessage;
};
export const useWindowWide = (size: number) => {
   const [width, setWidth] = useState(0);

   useEffect(() => {
      function handleResize() {
         setWidth(window.innerWidth);
      }

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => {
         window.removeEventListener('resize', handleResize);
      };
   }, [setWidth]);

   return width > size;
};
