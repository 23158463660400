// @ts-nocheck
import React from 'react';
import styled from 'styled-components';

// TODO Integrate TailwindCSS into project
interface IProps {
   flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'initial' | 'inherit';
   justifyContent?:
      | 'flex-start'
      | 'flex-end'
      | 'center'
      | 'space-between'
      | 'space-around'
      | 'space-evenly'
      | 'initial'
      | 'inherit';
   alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
   flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'initial' | 'inherit';
   alignContent?:
      | 'stretch'
      | 'center'
      | 'flex-start'
      | 'flex-end'
      | 'space-between'
      | 'space-around'
      | 'initial'
      | 'inherit';
   order?: number | 'initial' | 'inherit';
   position?: number | 'relative' | 'absolute' | 'fixed' | 'static' | 'sticky' | 'inherit' | 'initial';
   alignSelf?: 'auto' | 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
   flexGrow?: number | 'initial' | 'inherit';
   flexShrink?: number | 'initial' | 'inherit';
   flexBasis?: number | 'auto' | 'initial' | 'inherit';
   classNames?: string;
   flex?: string | number;
   width?: string;
   widthMobile?: string;
   backgroundColor?: string;
   styles?: Record<string, unknown>;
   maxHeight?: string;
   minHeight?: string;
   cursor?: string;
   hide?: boolean;
   height?: string;
   mobileMinHeight?: string;
   onClick?: (event: any) => void;
   flexDirectionMobile?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'initial' | 'inherit';
   alignItemsMobile?: string;
   justifyContentMobile?: string;
   margin?: string;
   padding?: string;
   marginMobile?: string;
   paddingMobile?: string;
   positionMobile?: string;
   id?: string;
   border?: string;
   borderTop?: string;
   borderRight?: string;
   borderBottom?: string;
   borderLeft?: string;
   overflow?: string;
   borderRadius?: string;
   // @ts-ignore
   children?: any;
}

const FlexWrapper = styled.section<IProps>`
   display: flex;
   flex-direction: ${(props) => props.flexDirection};
   justify-content: ${(props) => props.justifyContent};
   align-items: ${(props) => props.alignItems};
   align-content: ${(props) => props.alignContent};
   align-self: ${(props) => props.alignSelf};
   background-color: ${(props) => props.backgroundColor || 'white'};
   flex-wrap: ${(props) => props.flexWrap};
   order: ${(props) => props.order};
   flex-grow: ${(props) => props.flexGrow};
   flex-shrink: ${(props) => props.flexShrink};
   flex-basis: ${(props) => props.flexBasis};
   flex: ${(props) => props.flex};
   cursor: ${(props) => props.cursor || 'initial'};
   margin: ${(props) => props.margin};
   padding: ${(props) => props.padding};
   width: ${(props) => (props.width ? props.width : null)};
   max-height: ${(props) => (props.maxHeight ? props.maxHeight : 'unset')};
   min-height: ${(props) => (props.minHeight ? props.minHeight : 'unset')};
   height: ${(props) => (props.height ? props.height : 'auto')};
   position: ${(props) => (props.position ? props.position : 'initial')};
   border: ${(props) => `${props.border} !important` || 'none'};
   border-bottom: ${(props) => props.borderBottom || 'none'};
   border-top: ${(props) => props.borderTop || 'none'};
   border-right: ${(props) => props.borderRight || 'none'};
   border-left: ${(props) => props.borderLeft || 'none'};
   overflow: ${(props) => props.overflow || 'unset'};
   border-radius: ${(props) => props.borderRadius || 'unset'};

   a {
      cursor: pointer !important;

      span,
      div {
         cursor: pointer !important;
      }
   }

   @media only screen and (max-width: 768px) {
      width: ${(props) => (props.widthMobile ? props.widthMobile : '100%')};
      height: ${(props) => (props.height ? props.height : 'auto')};
      min-height: ${(props) => (props.mobileMinHeight ? props.mobileMinHeight : 'unset')};
      margin: ${(props) => (props.marginMobile ? props.marginMobile : 0)};
      padding: ${(props) => props.paddingMobile || '10px'};
      align-items: ${(props) => props.alignItemsMobile || 'initial'};
      flex-direction: ${(props) => props.flexDirectionMobile || props.flexDirection};
      border: none !important;
      position: ${(props) => (props.positionMobile ? props.positionMobile : 'initial')};
      justify-content: ${(props) => (props.justifyContentMobile ? props.justifyContentMobile : 'initial')};
      display: ${(props) => (props.hide ? 'none' : 'flex')};
      button {
         width: ${(props) =>
            (props.flexDirectionMobile && props.flexDirectionMobile === 'column') ||
            (props.flexDirection && props.flexDirection === 'column')
               ? '100%'
               : 'auto'};
         margin: 10px 0;
      }
   }
`;

export class Flex extends React.Component<IProps> {
   public static defaultProps: IProps = {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      classNames: '',
      flex: 'none',
      margin: '0',
      padding: '0',
   };
   public render() {
      return (
         <FlexWrapper {...this.props} style={this.props.styles} className={[this.props.classNames].join(' ')}>
            <>{this.props.children}</>
         </FlexWrapper>
      );
   }
}

export default Flex;
