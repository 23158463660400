import { ajaxService } from 'src/services/AjaxService';
import { IRequest, IResponse } from 'src/system/interfaces';
import {
   ICreateCompanyRequestDTO,
   IUpdateCompanyRequestDTO,
   IAddTeamMemberRequestDTO,
   IRemoveTeamMemberRequestDTO,
   IAddCompanyManagerRequestDTO,
   ISubscribeToEnterprisePlanRequestDTO,
   IInviteToEnterpriseRequestDTO,
   IVerifyTeamMemberRequestDTO,
} from './RequestDtos';

type IReportType = 0 | 1;

export default class ApiRequests {
   public static getSpaceDetails(space_id: string) {
      return ajaxService.get<IResponse<any>>(`/space?space_id=${space_id}`, {
         noAuthentication: true,
      });
   }

   public static getUserDetails(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/user?uuid=${uuid}`);
   }

   public static getCompanyDetails(enterprise_id: string) {
      return ajaxService.get<IResponse<any>>(`/enterprise/details?enterprise_id=${enterprise_id}`);
   }

   public static getCompanyCheckins(enterprise_id: string, period: string, limit: number, sort: string, order: string) {
      /*return ajaxService.get<IResponse<any>>(
         `/enterprise/checkins?enterprise_id=${enterprise_id}&period=${period}&limit=${limit}&sort=${sort}&order=${order}`,
      );*/
      return ajaxService.get<IResponse<any>>(`/enterprise/checkins/all?enterprise_id=${enterprise_id}`);
   }

   public static getCompanyStatistics(enterprise_id: string, period: string) {
      return ajaxService.get<IResponse<any>>(`/enterprise/statistics/${period}?enterprise_id=${enterprise_id}`);
   }

   public static createCompany(request: IRequest<ICreateCompanyRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/enterprise/create', request.payload);
   }

   public static updateCompany(request: IRequest<IUpdateCompanyRequestDTO>) {
      return ajaxService.post<IResponse<any>>('/enterprise/details/update', request.payload);
   }

   public static inviteToEnterprise(request: IRequest<IInviteToEnterpriseRequestDTO>) {
      return ajaxService.post<IResponse<any>>(`/enterprise/invite`, request.payload);
   }

   // members
   public static getCompanyTeamMembers(enterprise_id: string) {
      return ajaxService.get<IResponse<any>>(`/enterprise/members?enterprise_id=${enterprise_id}`);
   }

   public static addTeamMember(request: IRequest<IAddTeamMemberRequestDTO>) {
      return ajaxService.post<IResponse<any>>(`/enterprise/members/add`, request.payload);
   }

   public static verifyTeamMember(request: IRequest<IVerifyTeamMemberRequestDTO>) {
      return ajaxService.post<IResponse<any>>(`/enterprise/members/status`, request.payload);
   }

   public static getTeamMemberCheckins(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/checkins/users?uuid=${uuid}`);
   }

   public static removeTeamMember(request: IRequest<IRemoveTeamMemberRequestDTO>) {
      return ajaxService.post<IResponse<any>>(`/enterprise/members/remove`, request.payload);
   }

   public static getCompanyTeamMemberReport(enterprise_id: string, member_uuid: string, isPdfReport: IReportType) {
      return ajaxService.get<IResponse<any>>(
         `/enterprise/members/report?enterprise_id=${enterprise_id}&member_uuid=${member_uuid}&pdf=${isPdfReport}`,
         {
            responseType: 'blob',
            headers: { Accept: 'application/pdf', 'Content-Type': 'application/pdf' },
         },
      );
   }

   // managers
   public static getCompanyManagers(enterprise_id: string) {
      return ajaxService.get<IResponse<any>>(`/enterprise/managers?enterprise_id=${enterprise_id}`);
   }

   public static addCompanyManager(request: IRequest<IAddCompanyManagerRequestDTO>) {
      return ajaxService.post<IResponse<any>>(`/enterprise/managers/add`, request.payload);
   }

   // billing
   public static getEnterprisePlans(uuid: string) {
      return ajaxService.get<IResponse<any>>(`/enterprise/plans?uuid=${uuid}`);
   }

   public static subscribeToEnterprisePlan(request: IRequest<ISubscribeToEnterprisePlanRequestDTO>) {
      return ajaxService.post<IResponse<any>>(`/enterprise/subscribe`, request.payload);
   }
}
